import { Divider, Typography, Box, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';
import { FormikHelpers } from 'formik/dist/types';
import { FunctionComponent } from 'react';
import { AuthWithGoogleButton } from 'widgets/auth/_common/ui/AuthWithGoogleButton';
import { checkUser } from 'entities/Auth/api';
import { ColorPalette } from 'shared/consts/colorPalette';
import { isEmptyObject } from 'shared/helpers/isEmptyObject';
import { isEmptyObjectValues } from 'shared/helpers/isEmptyObjectValues';
import { InputField } from 'shared/ui/InputField';
import { Checkbox } from 'shared/ui/Checkbox';
import { Paper } from 'shared/ui/Paper';
import { Step1ValidationSchema } from 'widgets/auth/signUp/_MainModal/consts/validation';
import { useSignInStore } from 'widgets/auth/signUp/_MainModal/model/state';
import { Step1 } from 'widgets/auth/signUp/_MainModal/types';
import { Footer } from 'widgets/auth/signUp/_MainModal/ui/Footer';
import { PasswordRequirements } from 'shared/ui/PasswordRequirements';
import { StepLabel } from 'widgets/auth/signUp/_MainModal/ui/StepLabel';
import { Button } from 'shared/ui/Button';

export const SignUpStep1: FunctionComponent = () => {
	const {
		goToNextStep,
		signUpData,
		confirmFirstStep,
		updateEmailToSend,
		updateUserSignUpData,
		updateConfirmationFirstStep,
	} = useSignInStore();
	const { t } = useTranslation();

	const initialValues = {
		email: signUpData.user.email,
		password: signUpData.user.password,
		confirmPassword: signUpData.user.confirmPassword,
	};

	const handleSubmit = (values: Step1, formikHelpers: FormikHelpers<Step1>): void => {
		checkUser(values.email).then((res) => {
			if (!res?.data?.success) {
				formikHelpers.setErrors({ email: t('auth.validation.email_taken') as string });
				return;
			}
			updateEmailToSend({ email: values.email });
			updateUserSignUpData({ ...values });
			goToNextStep();
		});
	};

	return (
		<Paper
			title={t('auth.sign_up') as string}
			sx={{
				'& *': {
					userSelect: 'none',
				},
			}}
		>
			<StepLabel />
			<Typography variant={'body1'} color={ColorPalette.baliHai} sx={{ marginBottom: '35px' }}>
				{t('auth.sign_up_description')}
			</Typography>

			<AuthWithGoogleButton label={t('auth.sign_up_with_google')} />
			<Divider
				flexItem
				sx={{
					'margin': '18px 0 24px',
					'fontSize': '14px',
					'color': ColorPalette.baliHai,
					'&:before, &:after': {
						borderTop: `1px solid ${ColorPalette.periwinkle}`,
					},
				}}
			>
				or
			</Divider>

			<Formik
				initialValues={initialValues}
				validationSchema={Step1ValidationSchema}
				onSubmit={handleSubmit}
			>
				{({ errors, values }: FormikProps<Step1>) => (
					<Form>
						<InputField
							name={'email'}
							label={'Email'}
							placeholder={t('auth.validation.enter_email')}
							required
						/>
						<InputField
							name={'password'}
							label={'Password'}
							placeholder={t('auth.validation.enter_password')}
							type={'password'}
							required
						/>
						<InputField
							name={'confirmPassword'}
							label={t('auth.confirm_password')}
							placeholder={t('auth.confirm_password')}
							type={'password'}
							required
						/>
						<PasswordRequirements />
						<Box
							sx={{
								display: 'flex',
								margin: '5px 0',
							}}
						>
							<Checkbox
								onChange={() =>
									updateConfirmationFirstStep({ isRead: !confirmFirstStep.isRead })
								}
							/>
							<Typography
								variant={'body2'}
								color={ColorPalette.astronaut}
								sx={{ marginLeft: '10px' }}
							>
								{t('auth.read_conditions')}{' '}
								<Link href="https://retica.ai/condizioni-generali/">
									{t('auth.general_onditions')}
								</Link>
							</Typography>
						</Box>

						<Box
							sx={{
								display: 'flex',
								margin: '5px 0',
								paddingBottom: '36px',
							}}
						>
							<Checkbox
								onChange={() =>
									updateConfirmationFirstStep({ isAgreed: !confirmFirstStep.isAgreed })
								}
							/>
							<Typography
								variant={'body2'}
								color={ColorPalette.astronaut}
								sx={{ marginLeft: '10px' }}
							>
								{t('auth.agree_with_conditions')}{' '}
								<Link href="https://retica.ai/condizioni-generali/">
									{t('auth.general_onditions')}
								</Link>{' '}
								{t('auth.agree_with_conditions_2')}{' '}
							</Typography>
						</Box>
						<Button
							disabled={
								!isEmptyObject(errors) ||
								isEmptyObjectValues(values) ||
								!confirmFirstStep.isAgreed ||
								!confirmFirstStep.isRead
							}
							type={'submit'}
							fullWidth
						>
							{t('shared_texts.continue')}
						</Button>
					</Form>
				)}
			</Formik>
			<Footer />
		</Paper>
	);
};
