import { useState, useEffect } from 'react';
import {
	Paper,
	Table as MUITable,
	Table,
	TableCell,
	TableContainer,
	TableHead,
	TableBody,
	TableProps,
	TableRow,
	TableBody as MUITableBody,
} from '@mui/material';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { ColorPalette } from 'shared/consts/colorPalette';
import { prepareCell } from 'shared/ui/tables/_common/helpers/prepareCell';
import { Column } from 'shared/ui/tables/_common/types';
import { TableHeader } from 'shared/ui/tables/_common/ui/TableHeader';

interface Props<T> extends TableProps {
	columns: Array<Column<T>>;
	rows: Array<T & { id: string; createdAt?: string }>;
	onDragEndHandler: (data: T[]) => void;
}
export function DraggableTable<T>({ columns, rows, onDragEndHandler }: Props<T>) {
	const [rowState, setRowState] = useState(rows);
	const firstRow = rowState[0];

	const onDragEnd = (data: DropResult) => {
		const fromIndex = data.source.index;
		const toIndex = data.destination?.index;
		if (typeof fromIndex === 'number' && typeof toIndex === 'number') {
			const element = rowState.splice(fromIndex, 1)[0];
			rowState.splice(toIndex, 0, element);
			setRowState(rowState);
			onDragEndHandler(rowState);
		}
	};

	useEffect(() => {
		setRowState(rows);
	}, [rows]);

	return (
		<TableContainer>
			<MUITable>
				<TableHead>
					<TableRow>
						<TableCell
							sx={{ padding: '17px 0px', borderBottom: 'none' }}
							colSpan={columns.length}
						>
							<TableHeader columns={columns} additionalData={firstRow} />
						</TableCell>
					</TableRow>
				</TableHead>
				<MUITableBody>
					<TableRow>
						<TableCell sx={{ padding: 0, borderBottom: 'none' }} colSpan={columns.length}>
							<Paper
								sx={{
									padding: '0',
									boxShadow: 'none',
									backgroundColor: ColorPalette.white,
									borderRadius: '20px',
									overflow: 'hidden',
								}}
							>
								<Table>
									<DragDropContext onDragEnd={onDragEnd}>
										<Droppable droppableId="droppable">
											{(provided) => (
												<TableBody
													{...provided.droppableProps}
													ref={provided.innerRef}
													sx={{
														'& > * + *': {
															borderTop: `1.5px solid ${ColorPalette.zircon}`,
														},
													}}
												>
													{rowState.map((item, index) => (
														<Draggable
															key={item.id}
															draggableId={item.id}
															index={index}
														>
															{(provided) => (
																<TableRow
																	ref={provided.innerRef}
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																	sx={{
																		'position': 'relative',
																		'margin': '0',
																		'&:hover': {
																			backgroundColor:
																				ColorPalette.zircon,
																		},
																		'cursor': 'pointer',
																		'background': `${ColorPalette.white}`,
																	}}
																	key={item.id}
																>
																	{columns.map((column) => {
																		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
																		// @ts-ignore
																		let prepareFieldFn,
																			renderCellFn,
																			restBodyCellSettings;
																		if (column?.bodyCellSettings) {
																			const {
																				prepareField,
																				renderCell,
																				...rest
																			} = column.bodyCellSettings;
																			prepareFieldFn = prepareField;
																			renderCellFn = renderCell;
																			restBodyCellSettings = rest;
																		}

																		const preparedCell = prepareCell(
																			item,
																			prepareFieldFn,
																			renderCellFn,
																		);

																		return preparedCell !== null ? (
																			<TableCell
																				key={column.field}
																				sx={{
																					'padding': '0 10px',
																					'border': 'none',
																					'height': '81px',
																					'color':
																						ColorPalette.astronaut,
																					'&:first-of-type': {
																						paddingLeft: '24px',
																					},
																					'&:last-of-type': {
																						paddingRight: '24px',
																					},
																				}}
																				{...column?.commonCellSettings}
																				{...restBodyCellSettings}
																			>
																				{preparedCell}
																			</TableCell>
																		) : null;
																	})}
																</TableRow>
															)}
														</Draggable>
													))}
												</TableBody>
											)}
										</Droppable>
									</DragDropContext>
								</Table>
							</Paper>
						</TableCell>
					</TableRow>
				</MUITableBody>
			</MUITable>
		</TableContainer>
	);
}
