import { axiosInstance } from 'shared/config/axios';
import {
	UploadFileFromFtpRequest,
	GetCustomModelFilesRequest,
	DeleteCustomModelFilesRequest,
	GetModelsRequest,
	DeleteCustomModelRequest,
	StartTrainingRequest,
	UpdateFileLabelForCustomModelRequest,
} from 'entities/CustomModel/api/types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createCustomModel: any = (data: { name: string }) => {
	return axiosInstance.post('/custom-models', data);
};

export const getCustomModels: GetModelsRequest = () => {
	return axiosInstance.get(`/custom-models`);
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getQueryCustomModel: any = (params: string) => {
	return axiosInstance.get(`/custom-models?status=${params}`);
};
export const deleteCustomModel: DeleteCustomModelRequest = (modelId: string) => {
	return axiosInstance.delete(`/custom-models/delete-model/${modelId}`);
};
export const getCustomModelFiles: GetCustomModelFilesRequest = (id) => {
	return axiosInstance.get(`/custom-models/${id}/files`);
};
export const deleteCustomModelFiles: DeleteCustomModelFilesRequest = (data) => {
	return axiosInstance.delete(`/custom-models/delete-files`, { data });
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const patchCustomModel: any = (data: { name: string }, modelId: string) => {
	return axiosInstance.patch(`/custom-models/${modelId}`, data);
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getModelFile: any = (modelId: string) => {
	return axiosInstance.get(`/custom-models/file-labels/${modelId}`);
};

export const retryImportModelFile: UpdateFileLabelForCustomModelRequest = ({ data, customModelFileId }) => {
	return axiosInstance.put(`/custom-models/file-labels/${customModelFileId}`, data);
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getChangingModelText: any = ({ data, modelFileId }: { data: any; modelFileId: string }) => {
	return axiosInstance.post(`/custom-models/file-rectangle-content/${modelFileId}`, data);
};

export const startTrainingCustomModel: StartTrainingRequest = ({ customModelId, data }) => {
	return axiosInstance.post(`/custom-models/start-training/${customModelId}`, data);
};

export const uploadFileFromFtp: UploadFileFromFtpRequest = ({ id, data }) => {
	return axiosInstance.post(`/custom-models/ftp-uploading/${id}`, data);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const directCustomModelImport = ({ id, data }: any) => {
	return axiosInstance.post(`/custom-models/direct-uploading/${id}`, data, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
};
