import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { AssertionsSetsType } from 'entities/Assertions/types';

type AssertionSetsStore = {
	assertionsSets: AssertionsSetsType[] | null;
	warehousesForAssertions: string[] | null;
};

type AssertionSetsActions = {
	setAssertionsSetsData: (filesData: AssertionsSetsType[]) => void | Promise<void>;
	setWarehousesForAssertions: (initialValues: string[] | null) => void;
	reset: () => void;
};

const initialState: AssertionSetsStore = {
	assertionsSets: null,
	warehousesForAssertions: null,
};

export const assertionsSetsStore = create<AssertionSetsStore & AssertionSetsActions>()(
	persist(
		(set) => ({
			...initialState,
			setAssertionsSetsData: (assertionsSets) => set(() => ({ assertionsSets })),
			setWarehousesForAssertions: (warehousesForAssertions) => set(() => ({ warehousesForAssertions })),
			reset: () => set(() => ({ ...initialState })),
		}),
		{
			name: 'assertionsSetsStore',
		},
	),
);

export const useAssertionSetsStore = () => assertionsSetsStore((state) => state);
