import { FC } from 'react';
import { Box } from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';
import { addExtension } from 'entities/Extensions/api';
import { ExtensionsEnum } from 'entities/Extensions/enums';
import { ActionsForDialog } from 'shared/ui/ActionsForDialog';
import { Drawer } from 'shared/ui/Drawer';
import { Loader } from 'shared/ui/Loader';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { ExDrawerContent } from 'widgets/Extensions/ExtensionsToSelect/ui/ExDrawer/ExDrawerContent';

type ExDrawerPropsType = {
	open: boolean;
	handleClose: () => void;
	selectedExtension: ExtensionsEnum;
	handleOpenContactUsModal: () => void;
};

export const ExDrawer: FC<ExDrawerPropsType> = ({
	open,
	handleClose,
	selectedExtension,
	handleOpenContactUsModal,
}) => {
	const queryClient = useQueryClient();
	const { t } = useTranslation();

	const { mutate, isLoading } = useMutation({
		mutationKey: ['post/add/extension'],
		mutationFn: addExtension,
	});

	const applyExtensionHandler = () => {
		if (selectedExtension) {
			mutate(
				{ extension: selectedExtension },
				{
					onSuccess: async () => {
						await queryClient.refetchQueries({ queryKey: ['validate-token'] });
						enqueueSnackbar({
							message: t('extensions.snackbar.success_adding_extension'),
							variant: 'success',
						});
						handleClose();
					},
					onError: (err) => {
						const errorMessage = getErrorMessage(err);
						enqueueSnackbar({ message: errorMessage, variant: 'error' });
					},
				},
			);
		}
	};

	const contactUsHandler = () => {
		handleOpenContactUsModal();
	};

	return (
		<Drawer open={open} onClose={handleClose}>
			{isLoading ? (
				<Loader />
			) : (
				<>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
							height: '100%',
							gap: '40px',
						}}
					>
						<ExDrawerContent selectedExtension={selectedExtension} />
						<ActionsForDialog
							cancelLabel={t('shared_texts.contact_us')}
							cancelAction={contactUsHandler}
							applyLabel={t('shared_texts.add')}
							applyAction={applyExtensionHandler}
						/>
					</Box>
				</>
			)}
		</Drawer>
	);
};
