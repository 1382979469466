import { FC, useEffect, useMemo } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Routes } from 'app/config/routes/consts';
import { CustomOutputFileTypeEnum } from 'entities/Extensions/enums';
import { getCustomOutputFile } from 'entities/Extensions/api';
import { PageHeader } from 'shared/ui/PageHeader';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { useCustomOutputFileStore } from 'widgets/Extensions/Details/ui/CustomOutputFile/model/state';
import { OutputFieldsView } from './ui/OutputFieldsView';
import { OutputTypeView } from './ui/OutputTypeView';

export const CustomOutputFileWidget: FC = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { selectedOutputType, resetOutputStore } = useCustomOutputFileStore();
	const [searchParams, setSearchParams] = useSearchParams();

	const typeSearchParam = useMemo(
		() => searchParams.get('type') as CustomOutputFileTypeEnum,
		[searchParams],
	);

	const { data: response, isLoading: isOutputLoading } = useQuery({
		queryKey: ['get/custom-output-file', typeSearchParam],
		queryFn: () => getCustomOutputFile({ type: typeSearchParam }),
		onError: (err) => {
			const errorMessage = getErrorMessage(err);
			enqueueSnackbar({ message: errorMessage, variant: 'error' });
		},
		enabled: !!typeSearchParam,
	});

	useEffect(() => {
		if (!typeSearchParam) {
			setSearchParams({ type: CustomOutputFileTypeEnum.prebuild });
			resetOutputStore();
		}
	}, [typeSearchParam]);

	const renderContent = () => {
		if (selectedOutputType) {
			return <OutputFieldsView outputType={typeSearchParam} />;
		}
		if (typeSearchParam && !selectedOutputType) {
			return (
				<OutputTypeView
					loading={isOutputLoading}
					outputTypeData={response?.data || []}
					outputType={typeSearchParam}
				/>
			);
		}
	};

	const renderTitle = () => {
		if (selectedOutputType?.customModelId) {
			return selectedOutputType?.customModelId.name;
		}
		if (selectedOutputType && !selectedOutputType?.customModelId) {
			return t('extensions.ex_list.output_file.your_standard_templates');
		}
		return t('extensions.ex_list.output_file.title');
	};

	const onBackHandler = () => {
		if (selectedOutputType) {
			resetOutputStore();
		} else {
			navigate(Routes.extensions);
		}
	};

	return (
		<>
			<PageHeader
				title={renderTitle()}
				description={t('extensions.ex_list.output_file.page_description')}
				backBtn
				onClick={onBackHandler}
			/>

			{renderContent()}
		</>
	);
};
