import { FC } from 'react';
import { Dialog, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/ui/Button';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Paper } from 'shared/ui/Paper';

interface InfoModalAfterCsvUploadPropsType {
	open: boolean;
	handleClose: () => void;
}

export const AfterCsvCreationModal: FC<InfoModalAfterCsvUploadPropsType> = ({ open, handleClose }) => {
	const { t } = useTranslation();
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			PaperComponent={Paper}
			PaperProps={{ title: t('shared_texts.attention') as string }}
		>
			<Typography variant={'body1'} color={ColorPalette.baliHai}>
				{t('extensions.modals.warehouse_huge_file_via_csv')}
			</Typography>
			<Button
				size="small"
				onClick={handleClose}
				sx={{
					alignSelf: 'flex-end',
					marginTop: '40px',
				}}
			>
				{t('shared_texts.continue')}
			</Button>
		</Dialog>
	);
};
