import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, TextField, Dialog, Typography, FormControlLabel, styled } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useMutation, useQuery } from 'react-query';
import { getChangingModelText } from 'entities/CustomModel/api';
import { getChangingText } from 'entities/ImportSessions/api';
import { ExtensionsEnum } from 'entities/Extensions/enums';
import { FileType } from 'entities/Files/types';
import { FC, useEffect, useState } from 'react';
import { ActionsForDialog } from 'shared/ui/ActionsForDialog';
import { useExtension } from 'shared/hooks/useExtension';
import { Icon } from 'shared/ui/Icon';
import { Input } from 'shared/ui/Input';
import { Paper } from 'shared/ui/Paper';
import { createAssertion, getAssertionsSets, findAssertion } from 'entities/Assertions/api';
import { CustomModelSelect } from 'shared/ui/Select/CustomModelSelect';
import { Select } from 'shared/ui/Select';
import { Switch } from 'shared/ui/Switch';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Routes } from 'app/config/routes/consts';

interface Props {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	region: any;
	handleClose: () => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onAddLabel: any;
	file?: FileType;
	editMode?: boolean;
	currentFilePage?: number;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	deleteRegion?: any;
	modelId?: string | undefined;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	labels?: any;
}

export const AddLabelModal: FC<Props> = ({
	region,
	labels,
	modelId,
	handleClose,
	onAddLabel,
	file,
	editMode,
	currentFilePage,
	deleteRegion,
}) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { getExtension } = useExtension();
	const [assertionSetId, setAssertionSetId] = useState('');
	const [replacedValue, setReplacedValue] = useState('');
	const [labelName, setLabelName] = useState<string>('');
	const [regionText, setRegionText] = useState<string>('');
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [labelModel, setLabelModel] = useState<any>({});
	const [errorText, setErrorText] = useState<string>('');

	const [disableReplaceVal, setDisableReplaceVal] = useState(false);
	const [assertionIsVisible, setAssertionIsVisible] = useState(false);

	const disableActiveAssertion = useMemo(
		() => !!file?.assertionSetsId || Boolean(region?.autocomplete),
		[file?.assertionSetsId, region?.autocomplete],
	);

	const regexp = /[~`'"!?@#$^&\s<>[\]+:|/\\]/g;

	const { data } = useQuery({
		queryKey: ['get/assertion-sets'],
		queryFn: () => getAssertionsSets(),
		onError: (err) => {
			const errorMessage = getErrorMessage(err);
			enqueueSnackbar({
				message: errorMessage,
				variant: 'error',
			});
		},
	});

	const { mutate: createAssertionMutate } = useMutation({
		mutationKey: ['create/assertion-sets/assertions'],
		mutationFn: createAssertion,
	});

	const { mutate: findAssertionMutate } = useMutation({
		mutationKey: ['assertion-sets/assertions/find'],
		mutationFn: findAssertion,
	});

	const selectedOptions = useMemo(
		() =>
			data?.data.map((item) => ({
				label: item.name,
				value: item._id,
			})),
		[data],
	);

	useEffect(() => {
		const regionObj = {
			pageNumber: currentFilePage,
			boxPosition: {
				width: region?.width || 0,
				height: region?.height || 0,
				x: !region?.x || region?.x < 0 ? 0 : region.x,
				y: !region?.y || region?.y < 0 ? 0 : region.y,
			},
		};
		if (modelId) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			getChangingModelText({ modelFileId: file?._id, data: regionObj }).then((res: any) => {
				if (region?.text) {
					setRegionText(region?.text);
					// eslint-disable-next-line no-dupe-else-if
				} else if (!res.data && region?.text) {
					setRegionText(region?.text);
				} else if (!res.data) {
					setRegionText('');
				} else if (!region?.text) {
					setRegionText(res.data);
				}
			});
		}

		!modelId &&
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			getChangingText({ id: file?._id, data: regionObj }).then((res: any) => {
				if (!res.data && region?.text) {
					setRegionText(region?.text);
				} else if (!res.data) {
					setRegionText('');
				} else if (region?.text) {
					setRegionText(region?.text);
				} else if (!region?.text) {
					setRegionText(res.data);
				}
			});
		setLabelName(region?.name);
		setAssertionSetId(region?.assertionSetsId || '');
		setReplacedValue(region?.replaceVal || '');
	}, [region]);

	useEffect(() => {
		setAssertionIsVisible(!!region?.assertionSetsId);
		setDisableReplaceVal(!!region?.assertionSetsId);
		setErrorText('');
	}, [region?.assertionSetsId]);

	const handleLabelNameChange = (name: string) => {
		if (!name.match(regexp)) {
			setLabelName(name);
			setErrorText('');
		} else {
			setErrorText(t('files.details.error_text') as string);
			setLabelName(name.replace(regexp, ''));
		}
	};

	const handleRegionTextChange = (text: string) => {
		setRegionText(text);
	};

	const handleAddLabel = () => {
		if (labelModel?.name && modelId) {
			const regionToAdd = {
				...region,
				assertionSetsId: assertionIsVisible ? assertionSetId : null,
				replaceVal: assertionIsVisible ? replacedValue : null,
				text: regionText || labelModel.text,
				name: labelModel?.name,
				id: labelModel?.id,
				page: currentFilePage,
				x: region.x < 0 ? labelModel.x : region.x,
				y: region.y < 0 ? labelModel.y : region.y,
				height: region.height < 0 ? labelModel.height : region.height,
				width: region.width < 0 ? labelModel.width : region.width,
			};
			onAddLabel(regionToAdd, labelModel.data.index);
		} else {
			const regionToAdd = {
				...region,
				assertionSetsId: assertionIsVisible ? assertionSetId : null,
				replaceVal: assertionIsVisible ? replacedValue : null,
				name: labelName,
				text: regionText,
				page: currentFilePage,
			};
			onAddLabel(regionToAdd);
		}
	};

	const handleChangeModelLabel = (value: string) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const labelModelFind = labels.find((label: any) => label?.data?.index === value);
		setLabelModel(labelModelFind);
		setLabelName(labelModelFind?.name);
	};

	const switchAssertion = () => {
		setAssertionIsVisible((prevState) => !prevState);
	};

	const onAssertionSelect = (assertionSetsId: string) => {
		setAssertionSetId(assertionSetsId);
		findAssertionMutate(
			{
				assertionSetsId,
				initialVal: regionText,
			},
			{
				onSuccess: (response) => {
					if (response.data.replaceVal) {
						setReplacedValue(response.data.replaceVal);
						setDisableReplaceVal(true);
					} else {
						setReplacedValue('');
						setDisableReplaceVal(false);
					}
				},
				onError: (err) => {
					const errorMessage = getErrorMessage(err);
					enqueueSnackbar({
						message: errorMessage,
						variant: 'error',
					});
				},
			},
		);
	};

	const onCancelSubmit = () => {
		handleClose();
		setLabelName('');
		setLabelModel({});
	};

	const onSubmitHandler = () => {
		if (!disableReplaceVal && assertionIsVisible) {
			if (!assertionSetId) {
				enqueueSnackbar({
					message: t('400_errors.files.assertion_id_empty'),
					variant: 'error',
				});
			} else if (!replacedValue) {
				enqueueSnackbar({
					message: t('400_errors.files.assertion_replaced_val_empty'),
					variant: 'error',
				});
			} else {
				createAssertionMutate(
					{
						id: assertionSetId,
						data: {
							assertions: [
								{
									initialVal: regionText,
									replaceVal: replacedValue,
								},
							],
						},
					},
					{
						onSuccess: async () => {
							handleAddLabel();
							handleClose();
							setLabelName('');
							setLabelModel({});
							enqueueSnackbar({
								message: t('assertions.snackbar.success_assertion_creation'),
								variant: 'success',
							});
						},
						onError: (err) => {
							const errorMessage = getErrorMessage(err);
							enqueueSnackbar({
								message: errorMessage,
								variant: 'error',
							});
						},
					},
				);
			}
		} else {
			handleAddLabel();
			handleClose();
			setLabelName('');
			setLabelModel({});
		}
	};

	return (
		<Dialog
			open={Boolean(region)}
			onClose={handleClose}
			PaperComponent={Paper}
			PaperProps={{
				title: editMode
					? (t('files.details.edit_label') as string)
					: (t('files.details.add_label') as string),
			}}
		>
			<Box
				onClick={() => {
					deleteRegion(region?.data?.index);
					handleClose();
					setLabelName('');
					setLabelModel({});
				}}
				width={28}
				height={28}
				sx={{
					position: 'absolute',
					top: '64px',
					right: '59px',
					cursor: 'pointer',
				}}
			>
				<Icon
					id={'trush_icon_red'}
					style={{
						marginLeft: '16px',
						cursor: 'pointer',
					}}
				/>
			</Box>
			{modelId && (
				<CustomModelSelect
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					onSelect={(value: any) => {
						handleChangeModelLabel(value);
					}}
					value={labelModel?.name ? labelModel?.data?.index : ''}
					onChange={(event) => {
						handleChangeModelLabel(event?.target?.value);
					}}
					label={t('files.labels.change_label')}
					placeholder={t('files.placeholder.change_label') as string}
					required
					options={labels || []}
				/>
			)}

			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					flexDirection: 'column',
					justifyContent: 'space-between',
					marginBottom: '40px',
				}}
			>
				<Input
					sx={{ marginBottom: '7px' }}
					name="labelName"
					label={t('files.labels.label_name')}
					placeholder={t('files.placeholder.label_name') as string}
					type="text"
					required
					onChange={(event) => {
						handleLabelNameChange(event?.target?.value);
					}}
					value={labelName}
					disabled={Boolean(region?.autocomplete)}
				/>
				{errorText && <Box sx={{ color: 'red', fontSize: '12px' }}>{errorText}</Box>}
			</Box>

			<NoteTextField
				placeholder={t('files.placeholder.text_inside_label') as string}
				required
				value={regionText}
				multiline
				maxRows={6}
				variant="outlined"
				onChange={(event) => {
					handleRegionTextChange(event?.target?.value);
				}}
				disabled={Boolean(region?.autocomplete)}
			/>
			{!modelId && getExtension(ExtensionsEnum.assertions_sets)?.isEnabled && (
				<>
					<FormControlLabel
						control={
							<Switch
								disabled={disableActiveAssertion}
								checked={assertionIsVisible}
								onChange={switchAssertion}
								sx={{ marginRight: '10px' }}
							/>
						}
						label={<Typography variant={'body2'}>{t('assertions.set_assertion')}</Typography>}
						sx={{ margin: 0 }}
					/>
					{assertionIsVisible && (
						<Box sx={{ marginTop: '15px' }}>
							<Select
								label={t('assertions.as_title_list') as string}
								placeholder={t('assertions.placeholder.select_as_set')}
								value={assertionSetId || ''}
								onSelect={(item) => onAssertionSelect(item as string)}
								options={selectedOptions || []}
								disabled={disableActiveAssertion}
							/>
							<Input
								sx={{ marginBottom: '10px' }}
								name="replaceVal"
								value={replacedValue}
								label={t('assertions.label.replaced_value')}
								placeholder={t('assertions.placeholder.replaced_value') as string}
								disabled={disableReplaceVal}
								onChange={(e) => setReplacedValue(e.target.value)}
								multiline
							/>
						</Box>
					)}
					<Typography
						variant={'body2'}
						color={ColorPalette.royalBlue}
						onClick={() =>
							navigate(
								`${Routes.extensions}/${
									getExtension(ExtensionsEnum.assertions_sets)?.extension
								}`,
							)
						}
						sx={{
							cursor: 'pointer',
							margin: '20px 0',
						}}
					>
						{t('assertions.modal.see_all_ass')}
					</Typography>
				</>
			)}

			<ActionsForDialog
				applyActionDisabled={!labelName}
				cancelLabel={t('shared_texts.cancel')}
				cancelAction={onCancelSubmit}
				applyLabel={t('shared_texts.save')}
				applyAction={onSubmitHandler}
			/>
		</Dialog>
	);
};

const NoteTextField = styled(TextField)`
	width: 100%;
	padding-bottom: 23px;
	.MuiOutlinedInput-root {
		display: flex;
		flex-direction: column;
		padding: 0;
		border-radius: 10px;
		textarea {
			color: #2b3674;
			background: #ffffff;
			padding: 8px 8px 8px 12px;
			width: 95%;
			font-family: Comfortaa;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: 19px;
			letter-spacing: 0.5px;
		}
		&.Mui-focused fieldset {
			border-radius: 10px;
			border: 1px solid #fa4d81;
		}
	}
	& label.Mui-focused {
		color: #2b3674;
	}
`;
