import { Dialog, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { inviteCoUser } from 'entities/CoUser/api';
import { Form, Formik, FormikProps } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { FunctionComponent } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { ColorPalette } from 'shared/consts/colorPalette';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { isEmptyObject } from 'shared/helpers/isEmptyObject';
import { isEmptyObjectValues } from 'shared/helpers/isEmptyObjectValues';
import { ActionsForDialog } from 'shared/ui/ActionsForDialog';
import { InputField } from 'shared/ui/InputField';
import { Paper } from 'shared/ui/Paper';
import { InviteCoUserValidationSchema } from 'widgets/CoUsers/ui/InviteCoUserModal/consts/validation';
import { InviteCoUser } from 'widgets/CoUsers/ui/InviteCoUserModal/types';

interface Props {
	open: boolean;
	handleClose: () => void;
}

export const InviteCoUserModal: FunctionComponent<Props> = ({ open, handleClose }) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const { mutate } = useMutation({
		mutationKey: ['co-users'],
		mutationFn: inviteCoUser,
	});

	const initialValues = {
		email: '',
	};

	const handleSubmit = (values: InviteCoUser): void => {
		const mutationValues = { ...values };
		// const userChecked = await checkUser(values.email);
		// if (!userChecked?.data?.success) {
		// 	formikHelpers.setErrors({ email: 'This user already exists in the system' });
		// 	return;
		// }
		mutate(mutationValues, {
			onSuccess: async () => {
				await queryClient.invalidateQueries({ queryKey: ['co-users'] });
				enqueueSnackbar({
					message: t('co_users.invitation_sent'),
					variant: 'success',
				});
				handleClose();
			},
			onError: (err) => {
				const defaultErrorMessage = t('co_users.error_while_sending_invitation');
				const errorMessage = getErrorMessage(err, defaultErrorMessage);
				enqueueSnackbar({
					message: errorMessage,
					variant: 'error',
				});
			},
		});
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			PaperComponent={Paper}
			PaperProps={{ title: t('co_users.invite_co_user') as string }}
		>
			<Typography variant={'body1'} color={ColorPalette.baliHai} sx={{ marginBottom: '24px' }}>
				{t('co_users.modal.invite.description')}
			</Typography>
			<Typography variant={'body1'} color={ColorPalette.baliHai} sx={{ marginBottom: '40px' }}>
				{t('co_users.modal.invite.description_2')}
			</Typography>

			<Formik
				initialValues={initialValues}
				validationSchema={InviteCoUserValidationSchema}
				onSubmit={handleSubmit}
			>
				{({ errors, values, resetForm }: FormikProps<InviteCoUser>) => (
					<Form>
						<InputField
							name={'email'}
							label={t('co_users.modal.invite.co_user_email')}
							placeholder={t('co_users.modal.invite.enter_co_user_email')}
							required
						/>

						<ActionsForDialog
							cancelLabel={t('shared_texts.cancel')}
							cancelAction={() => {
								resetForm();
								handleClose();
							}}
							applyLabel={t('co_users.invite_co_user')}
							applyActionDisabled={!isEmptyObject(errors) || isEmptyObjectValues(values)}
						/>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};
