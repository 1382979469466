import { axiosInstance } from 'shared/config/axios';
import {
	GetWarehouseItemsRequest,
	CreateWarehouseItemsRequest,
	CreateWarehouseItemsViaFileRequest,
	RemoveWarehouseItemsRequest,
	UpdateWarehouseItemsRequest,
	GetWarehouseRequest,
	UpdateWarehouseRequest,
	GetWarehouseItemsForAssertionsRequest,
} from 'entities/Warehouse/api/types';

export const getWarehouseItems: GetWarehouseItemsRequest = (queries) => {
	return axiosInstance.get('/warehouses/items', { params: { ...queries } });
};

export const postWarehouseItems: CreateWarehouseItemsRequest = ({ data }) => {
	return axiosInstance.post('/warehouses/items', data);
};

export const postWarehouseItemsViaFile: CreateWarehouseItemsViaFileRequest = (data) => {
	return axiosInstance.post('/warehouses/items/via-file', data, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
};

export const deleteWarehouseItems: RemoveWarehouseItemsRequest = (data) => {
	return axiosInstance.delete('/warehouses/items', { data });
};

export const putWarehouseItems: UpdateWarehouseItemsRequest = ({ warehouseItemId, data }) => {
	return axiosInstance.put(`/warehouses/items/${warehouseItemId}`, data);
};

export const getWarehouseProcesses: GetWarehouseRequest = () => {
	return axiosInstance.get('/warehouses');
};

export const patchWarehouseProcesses: UpdateWarehouseRequest = (data) => {
	return axiosInstance.patch('/warehouses', data);
};

export const getWarehouseItemsForAssertions: GetWarehouseItemsForAssertionsRequest = (queries) => {
	return axiosInstance.get('/warehouses/items-initial-values', { params: { ...queries } });
};
