import { ColorPalette } from 'shared/consts/colorPalette';

export type ContrastTextKeys = keyof typeof ContrastText;

export type ContrastTextValues = (typeof ContrastText)[ContrastTextKeys];

export const ContrastText = {
	[ColorPalette.royalBlue]: ColorPalette.white,
	[ColorPalette.astronaut]: ColorPalette.white,
	[ColorPalette.baliHai]: ColorPalette.white,
	[ColorPalette.rockBlue]: ColorPalette.white,
	[ColorPalette.periwinkle]: ColorPalette.ebonyClay,
	[ColorPalette.zircon]: ColorPalette.ebonyClay,
	[ColorPalette.catskillWhite]: ColorPalette.ebonyClay,
	[ColorPalette.white]: ColorPalette.ebonyClay,

	[ColorPalette.ebonyClay]: ColorPalette.white,
	[ColorPalette.charade]: ColorPalette.white,
	[ColorPalette.brightGray]: ColorPalette.white,
	[ColorPalette.manatee]: ColorPalette.white,
	[ColorPalette.lightGray]: ColorPalette.ebonyClay,

	[ColorPalette.persianPink]: ColorPalette.white,
	[ColorPalette.atlantis]: ColorPalette.white,
	[ColorPalette.scooter]: ColorPalette.white,
	[ColorPalette.portage]: ColorPalette.white,

	[ColorPalette.violetRed]: ColorPalette.white,
	[ColorPalette.violetRedTransparent]: ColorPalette.white,
	[ColorPalette.fuelYellow]: ColorPalette.white,
	[ColorPalette.jungleGreen]: ColorPalette.white,

	[ColorPalette.narvik]: ColorPalette.baliHai,
	[ColorPalette.offYellow]: ColorPalette.baliHai,
	[ColorPalette.lavenderBlush]: ColorPalette.baliHai,
} as const;
