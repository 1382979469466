import { Box } from '@mui/material';
import { FunctionComponent } from 'react';
import { ColorPaletteValues } from 'shared/consts/colorPalette';
import { Icon } from 'shared/ui/Icon';

interface Props {
	iconId: string;
	color: ColorPaletteValues;
}

export const BigIcon: FunctionComponent<Props> = ({ iconId, color }) => {
	return (
		<Box sx={{ width: '125px', height: '125px', position: 'relative', margin: '0 auto' }}>
			<Icon
				id={iconId}
				style={{
					width: '125px',
					height: '125px',
					margin: '56px 0 0',
				}}
				color={color}
			/>
			<Box
				sx={{
					width: '119px',
					height: '119px',
					borderRadius: '50%',
					background: `${color}33`,
					position: 'absolute',
					top: '10px',
					left: '-10px',
				}}
			/>
		</Box>
	);
};
