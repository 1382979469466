import { Switch as MUISwitch, SwitchProps } from '@mui/material';
import { FunctionComponent } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Shadow } from 'shared/consts/shadow';

export const Switch: FunctionComponent<SwitchProps> = ({ sx, ...props }) => {
	return (
		<MUISwitch
			sx={{
				'width': '36px',
				'height': '18px',
				'padding': 0,
				'margin': 0,
				'borderRadius': '9px',
				'boxShadow': `${props?.checked ? Shadow['1'] : 'none'}`,

				'& .MuiSwitch-track': {
					borderRadius: '9px',
					backgroundColor: ColorPalette.rockBlue,
					transitionDuration: '500ms',
					opacity: 1,
				},

				'& .MuiSwitch-switchBase': {
					'padding': 0,
					'transitionDuration': '300ms',

					'& .MuiSwitch-track': {
						borderRadius: '9px',
						backgroundColor: ColorPalette.rockBlue,
						transitionDuration: '500ms',
						opacity: 1,
					},

					'& .MuiSwitch-thumb': {
						margin: '2px',
						width: '14px',
						height: '14px',
						boxShadow: 'none',
					},

					'&.Mui-checked': {
						'transform': 'translateX(18px)',
						'color': ColorPalette.white,

						'& + .MuiSwitch-track': {
							backgroundColor: ColorPalette.royalBlue,
							opacity: 1,
							border: 0,
						},
					},

					'&.Mui-disabled ': {
						'& .MuiSwitch-thumb': {
							backgroundColor: ColorPalette.white,
							opacity: 1,
						},
						'& + .MuiSwitch-track': {
							backgroundColor: ColorPalette.periwinkle,
							opacity: 1,
						},
					},
				},
				...sx,
			}}
			{...props}
		/>
	);
};
