import * as yup from 'yup';

export const CompanyInfoValidationSchema = yup.object({
	name: yup
		.string()
		.required('auth.validation.enter_company_name')
		.max(250, 'auth.validation.company_name_too_long'),
	vatNumber: yup
		.string()
		.matches(/^\S*$/, 'auth.validation.without_spaces')
		.required('auth.validation.enter_vat_number')
		.max(250, 'auth.validation.vat_number_too_long'),
	sdiCode: yup
		.string()
		.matches(/^\S*$/, 'auth.validation.without_spaces')
		.required('auth.validation.enter_sdi_code')
		.max(250, 'auth.validation.sdi_code_too_long'),
	country: yup
		.string()
		.required('auth.validation.enter_country')
		.max(250, 'auth.validation.country_too_long'),
	city: yup.string().required('auth.validation.enter_city').max(250, 'auth.validation.city_too_long'),
	street: yup.string().required('auth.validation.enter_street').max(250, 'auth.validation.street_too_long'),
	houseNumber: yup
		.string()
		.matches(/^\S*$/, 'auth.validation.without_spaces')
		.required('auth.validation.enter_house_number')
		.max(250, 'auth.validation.house_number_too_long'),
	postalCode: yup
		.string()
		.matches(/^\S*$/, 'auth.validation.without_spaces')
		.required('auth.validation.enter_postal_code')
		.max(250, 'auth.validation.postal_code_too_long'),
});
