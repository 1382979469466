import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';
import React, { FC, useMemo } from 'react';
import { ProtocolEnum } from 'entities/Integrations/enums';
import { FormSelect } from 'shared/ui/Select/FormSelect';
import { SelectOption } from 'shared/ui/Select/types';
import { ActionsForDialog } from 'shared/ui/ActionsForDialog';
import { InputField } from 'shared/ui/InputField';
import { Paper } from 'shared/ui/Paper';
import { FtpSettingsValidationSchema } from 'widgets/integrations/FtpSettings/Form/consts/validation';
import { FtpSettings } from 'widgets/integrations/FtpSettings/Form/types';

type FTPFormPropsType = {
	handleSubmit: (values: FtpSettings) => void;
	ftpSettingsData: FtpSettings | undefined;
	checkbox: React.ReactNode;
};

export const FTPForm: FC<FTPFormPropsType> = ({ handleSubmit, ftpSettingsData, checkbox }) => {
	const { t } = useTranslation();

	const initialValues = useMemo(
		() => ({
			host: ftpSettingsData?.host ?? '',
			port: ftpSettingsData?.port ?? '',
			path: ftpSettingsData?.path ?? '',
			protocol: ftpSettingsData?.protocol ?? ProtocolEnum.FTP,
			username: ftpSettingsData?.username ?? '',
			password: '',
		}),
		[ftpSettingsData],
	);

	return (
		<Paper>
			<Formik
				initialValues={initialValues}
				enableReinitialize
				validationSchema={FtpSettingsValidationSchema}
				onSubmit={handleSubmit}
			>
				{({ values, resetForm, setFieldValue }: FormikProps<FtpSettings>) => (
					<Form>
						<InputField
							name={'host'}
							label={t('integrations.labels.host_name')}
							placeholder={t('integrations.placeholder.host_name')}
							required
						/>
						<InputField
							name={'port'}
							label={t('integrations.labels.port')}
							placeholder={t('integrations.placeholder.port')}
							required
						/>
						<InputField
							name={'path'}
							label={t('integrations.labels.path')}
							placeholder={t('integrations.placeholder.path')}
							required
						/>
						<FormSelect
							name={'protocol'}
							label={t('integrations.labels.protocol') as string}
							placeholder={t('integrations.placeholder.protocol')}
							options={selectOptions}
							value={values.protocol}
							onSelect={(value) => setFieldValue('protocol', value)}
							required
						/>
						<InputField
							name={'username'}
							label={t('integrations.labels.user_name')}
							placeholder={t('integrations.placeholder.user_name')}
							required
						/>
						<InputField
							name={'password'}
							label={t('integrations.labels.password')}
							placeholder={t('integrations.placeholder.password')}
							type={'password'}
							required
						/>
						{checkbox}
						<ActionsForDialog
							cancelLabel={t('shared_texts.cancel')}
							cancelAction={() => {
								resetForm();
							}}
							applyLabel={t('shared_texts.save')}
						/>
					</Form>
				)}
			</Formik>
		</Paper>
	);
};

const selectOptions: Array<SelectOption> = [
	{
		value: ProtocolEnum.FTP,
		label: ProtocolEnum.FTP,
	},
	{
		value: ProtocolEnum.FTPS,
		label: ProtocolEnum.FTPS,
	},
	{
		value: ProtocolEnum.SFTP,
		label: ProtocolEnum.SFTP,
	},
];
