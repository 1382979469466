import { Box, Typography } from '@mui/material';
import { UserForAdmin } from 'entities/admin/Users/types';
import { format } from 'date-fns';
import { TableSortLabel } from 'shared/ui/tables/_common/ui/TableSortLabel';
import { ColorPalette } from 'shared/consts/colorPalette';
import { OptionsDropdown } from 'shared/ui/OptionsDropdown';
import { Column } from 'shared/ui/tables/_common/types';
import { getMaxValue } from 'shared/helpers/getMaxValue';
import { TableTranslationLabel } from 'shared/ui/tables/_common/ui/TableTranslationLabel';
import { capitalizeFirstLetter } from 'shared/helpers/capitalizeFirstLetter';

export const UsersTableColumns: Array<
	Column<
		UserForAdmin & {
			openModal: (user: UserForAdmin) => void;
			navigateToDetails: (user: UserForAdmin) => void;
			sortFunction: (sort: 'asc' | 'desc') => void;
			sort: 'asc' | 'desc';
		}
	>
> = [
	{
		field: 'email',
		commonCellSettings: {
			style: {
				minWidth: '250px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="email" />,
		},
		bodyCellSettings: {
			prepareField: (user) => user?.email ?? '-',
		},
	},
	{
		field: 'firstName',
		commonCellSettings: {
			style: {
				minWidth: '150px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="first_name" />,
		},
		bodyCellSettings: {
			prepareField: (user) => user?.firstName || '-',
		},
	},
	{
		field: 'lastName',
		commonCellSettings: {
			style: {
				minWidth: '150px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="last_name" />,
		},
		bodyCellSettings: {
			prepareField: (user) => user?.lastName || '-',
		},
	},
	{
		field: 'businessName',
		commonCellSettings: {
			style: {
				minWidth: '150px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="business_name" />,
		},
		bodyCellSettings: {
			prepareField: (user) => user?.company?.name || '-',
		},
	},
	{
		field: 'address',
		commonCellSettings: {
			style: {
				minWidth: '250px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="address" />,
		},
		bodyCellSettings: {
			prepareField: (user) => {
				if (user?.company) {
					const country = user?.company.address.country.trim()
						? `${user?.company.address.country},`
						: '';
					const city = user?.company.address.city.trim() ? `${user?.company.address.city},` : '';
					const street = user?.company.address.street.trim()
						? `${user?.company.address.street},`
						: '';
					const houseNumber = user?.company.address.houseNumber.trim()
						? `${user?.company.address.houseNumber},`
						: '';
					const postalCode = user?.company.address.postalCode.trim()
						? `${user?.company.address.postalCode}`
						: '';
					return `${country} ${city} ${street} ${houseNumber} ${postalCode}`;
				}
				return '-';
			},
		},
	},
	{
		field: 'registrationDate',
		commonCellSettings: {
			style: {
				minWidth: '200px',
				cursor: 'pointer',
			},
		},
		headerCellSettings: {
			renderCell: (coUser) => {
				return (
					<TableSortLabel sort={coUser?.sort} onSort={coUser?.sortFunction}>
						<TableTranslationLabel label="registration_date" />
					</TableSortLabel>
				);
			},
		},
		bodyCellSettings: {
			renderCell: (coUser) => {
				return coUser?.createdAt ? (
					<Typography variant={'body2'} component={'span'} color={ColorPalette.astronaut}>
						{format(new Date(coUser.createdAt), 'HH:mm\u00A0\u00A0\u00A0dd/MM/yyyy')}
					</Typography>
				) : (
					<Typography variant={'body2'} component={'span'} color={ColorPalette.baliHai}>
						{'--:--\u00A0\u00A0\u00A0--/--/----'}
					</Typography>
				);
			},
		},
	},
	{
		field: 'lastInvoice',
		commonCellSettings: {
			style: {
				minWidth: '150px',
			},
			align: 'center',
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="subscription" />,
		},
		bodyCellSettings: {
			renderCell: (user) => {
				return (
					<Box sx={{ display: 'flex', flexDirection: 'column' }}>
						{capitalizeFirstLetter(user?.subscription?.subscriptionType || '')}
						{/* TODO add sub status */}
						{/* <Typography variant={'overline1'} component={'span'} color={ColorPalette.baliHai}>
							({user?.subscription.status})
						</Typography> */}
					</Box>
				);
			},
		},
	},
	{
		field: 'amountOfCoUsers',
		commonCellSettings: {
			style: {
				minWidth: '150px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="co_users" />,
		},
		bodyCellSettings: {
			prepareField: (user) =>
				`${user?.subscription?.totalCousers}/${getMaxValue(
					user?.subscription.maxCousers || 0,
					user?.subscription?.maxFreeCousers || 0,
				)}`,
		},
	},
	{
		field: 'amountOfFiles',
		commonCellSettings: {
			style: {
				minWidth: '150px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="imported_files" />,
		},
		bodyCellSettings: {
			prepareField: (user) =>
				`${user?.subscription?.totalFiles}/${getMaxValue(
					user?.subscription?.totalFiles || 0,
					user?.subscription?.maxFreeFiles || 0,
				)}`,
		},
	},
	{
		field: 'dateOfLastBilling',
		commonCellSettings: {
			style: {
				minWidth: '200px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="last_billing_date" />,
		},
		bodyCellSettings: {
			renderCell: (user) => {
				return user?.lastInvoice?.billingAt ? (
					<Typography variant={'body2'} component={'span'} color={ColorPalette.astronaut}>
						{format(new Date(user?.lastInvoice?.billingAt), 'HH:mm\u00A0\u00A0\u00A0dd/MM/yyyy')}
					</Typography>
				) : (
					<Typography variant={'body2'} component={'span'} color={ColorPalette.baliHai}>
						{'--:--\u00A0\u00A0\u00A0--/--/----'}
					</Typography>
				);
			},
		},
	},
	{
		field: 'amountOfLastBilling',
		commonCellSettings: {
			style: {
				minWidth: '150px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="last_billing_amount" />,
		},
		bodyCellSettings: {
			prepareField: (user) =>
				user?.lastInvoice?.totalExcludingTax
					? `${user?.lastInvoice?.totalExcludingTax / 100} €`
					: user?.lastInvoice?.amount
					? `${user?.lastInvoice?.amount / 100} €`
					: '--',
		},
	},
	{
		field: 'dateOfLastPayment',
		commonCellSettings: {
			style: {
				minWidth: '200px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="last_payment_date" />,
		},
		bodyCellSettings: {
			renderCell: (user) => {
				return user?.lastInvoice?.paymentAt ? (
					<Typography variant={'body2'} component={'span'} color={ColorPalette.astronaut}>
						{format(new Date(user?.lastInvoice?.paymentAt), 'HH:mm\u00A0\u00A0\u00A0dd/MM/yyyy')}
					</Typography>
				) : (
					<Typography variant={'body2'} component={'span'} color={ColorPalette.baliHai}>
						{'--:--\u00A0\u00A0\u00A0--/--/----'}
					</Typography>
				);
			},
		},
	},
	{
		field: 'amountOfLastPayment',
		commonCellSettings: {
			style: {
				minWidth: '150px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="last_payment_amount" />,
		},
		bodyCellSettings: {
			prepareField: (user) =>
				user?.lastInvoice?.totalExcludingTax
					? `${user?.lastInvoice?.totalExcludingTax / 100} €`
					: user?.lastInvoice?.amount
					? `${user?.lastInvoice?.amount / 100} €`
					: '--',
		},
	},
	{
		field: 'options',
		commonCellSettings: {
			style: {
				minWidth: '100px',
				maxWidth: '100px',
			},
		},
		bodyCellSettings: {
			renderCell: (user) => {
				return (
					<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
						<OptionsDropdown
							iconId={'more_horizontal'}
							optionList={[
								{
									label: 'shared_texts.table_dropdown.details',
									value: 'Details info',
									action: () => {
										user?.navigateToDetails && user.navigateToDetails(user);
									},
								},
								{
									label: 'shared_texts.table_dropdown.remove',
									value: 'Remove user',
									action: () => {
										user?.openModal && user.openModal(user);
									},
								},
							]}
						/>
					</Box>
				);
			},
		},
	},
];
