import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { Typography, Divider } from '@mui/material';
import { ColorPalette } from 'shared/consts/colorPalette';

type PageHeaderPropsType = {
	title: string;
	description: string;
	backBtn?: boolean;
	onClick?: () => void;
};

export const PageHeader: FC<PageHeaderPropsType> = ({ title, description, backBtn, onClick }) => {
	const navigate = useNavigate();
	return (
		<>
			<Typography variant={'h4'} color={ColorPalette.astronaut} sx={{ display: 'flex', gap: '10px' }}>
				{backBtn && (
					<Box sx={{ cursor: 'pointer' }} onClick={() => (onClick ? onClick() : navigate(-1))}>
						{'<'}
					</Box>
				)}
				{title}
			</Typography>
			{description && (
				<Typography variant={'subtitle1'} color={ColorPalette.baliHai} sx={{ marginTop: '24px' }}>
					{description}
				</Typography>
			)}

			<Divider color={ColorPalette.periwinkle} sx={{ margin: '44px 0' }} />
		</>
	);
};
