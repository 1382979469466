import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useStripe } from '@stripe/react-stripe-js';
import { Dialog, Typography, Box } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { Paper } from 'shared/ui/Paper';
import { Button } from 'shared/ui/Button';
import { ColorPalette } from 'shared/consts/colorPalette';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { useSubscriptionStoreStore } from 'widgets/Subscriptions/model/state';
import { STypesEnum } from 'entities/Subscriptions/enums';
import { SubscriptionType } from 'entities/Subscriptions/types';
import { createSubscription } from 'entities/Subscriptions/api';
import { Loader } from 'shared/ui/Loader';
import {
	Step1,
	Step2,
	Step3,
} from 'widgets/Subscriptions/ui/SubCurrentTab/Tabs/CurrentTab/ui/UpdateSubscriptionModal/steps';

interface UpdateSubscriptionModalTypes {
	currentSubscription: SubscriptionType;
	open: boolean;
	handleClose: () => void;
}

export const UpdateSubscriptionModal = ({
	currentSubscription,
	open,
	handleClose,
}: UpdateSubscriptionModalTypes) => {
	const { t } = useTranslation();
	const stripe = useStripe();
	const queryClient = useQueryClient();
	const { step, goToNextStep, goToInitialStep } = useSubscriptionStoreStore();
	const [subscriptionType, setSubscriptionType] = useState<STypesEnum>(
		currentSubscription.subscriptionType,
	);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const isSubscriptionDowngrading = useMemo(
		() =>
			(currentSubscription?.subscriptionType === STypesEnum.PRO ||
				currentSubscription?.subscriptionType === STypesEnum.PAY_AS_YOU_GO) &&
			(subscriptionType === STypesEnum.STARTER || subscriptionType === STypesEnum.PRO),
		[subscriptionType, currentSubscription],
	);

	const lastStep = useMemo(() => {
		if (step === 2 && !isSubscriptionDowngrading) return true;
		if (step === 3 && isSubscriptionDowngrading) return true;
		return false;
	}, [isSubscriptionDowngrading, step]);

	const { mutate } = useMutation({
		mutationKey: ['create/subscription'],
		mutationFn: createSubscription,
	});

	const modalOnClose = () => {
		handleClose();
		goToInitialStep();
		setSubscriptionType(currentSubscription.subscriptionType);
	};

	const updateSubscriptionHandler = () => {
		setIsLoading(true);
		mutate(
			{ subscriptionType },
			{
				onSuccess: async (response) => {
					if (response.data.clientSecret && stripe) {
						const { error } = await stripe.confirmCardPayment(response.data.clientSecret);
						if (!error) {
							enqueueSnackbar({
								message: t('subscriptions.tab_1.sub_updated'),
								variant: 'success',
							});
						} else {
							enqueueSnackbar({
								message: error.message,
								variant: 'error',
							});
						}
					}
					modalOnClose();
					setIsLoading(false);
					queryClient.invalidateQueries('get/subscriptions');
				},
				onError: (err) => {
					const errorMessage = getErrorMessage(err);
					enqueueSnackbar({
						message: errorMessage,
						variant: 'error',
					});
					setIsLoading(false);
				},
			},
		);
	};

	return (
		<Dialog
			open={open}
			onClose={modalOnClose}
			PaperComponent={Paper}
			PaperProps={{ title: t('subscriptions.tab_1.current_sub.sub_update') as string }}
			sx={{
				'width': '65vw',
				'margin': '0 auto',
				'& .MuiPaper-root': {
					minWidth: '100%',
				},
			}}
		>
			{isLoading ? (
				<Loader />
			) : (
				<>
					{step === 1 && (
						<Step1
							subscriptionType={subscriptionType}
							setSubscriptionType={(sub: STypesEnum) => setSubscriptionType(sub)}
						/>
					)}
					{step === 2 && <Step2 subscriptionType={subscriptionType} />}
					{step === 3 && isSubscriptionDowngrading && <Step3 />}
					<Box
						sx={{ display: 'flex', justifyContent: 'flex-end', gap: '100px', marginTop: '50px' }}
					>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
							onClick={modalOnClose}
						>
							<Typography
								variant={'button'}
								color={ColorPalette.astronaut}
								sx={{ cursor: 'pointer' }}
							>
								{t('shared_texts.cancel')}
							</Typography>
						</Box>
						{lastStep ? (
							<Button size="small" onClick={updateSubscriptionHandler}>
								{t('shared_texts.buy')}
							</Button>
						) : (
							<Button
								size="small"
								disabled={subscriptionType === currentSubscription.subscriptionType}
								onClick={goToNextStep}
							>
								{t('shared_texts.next')}
							</Button>
						)}
					</Box>
				</>
			)}
		</Dialog>
	);
};
