// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const compareObjects = (o1: Record<string, any>, o2: Record<string, any>) => {
	return Object.keys(o2).reduce((diff, key) => {
		if (o1[key] === o2[key]) return diff;
		return {
			...diff,
			[key]: o2[key],
		};
	}, {});
};
