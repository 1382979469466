import { Box } from '@mui/material';
import { CustomOutputFileCategoryEnum } from 'entities/Extensions/enums';
import { CustomOutputFileType } from 'entities/Extensions/types';
import { Switch } from 'shared/ui/Switch';
import { IconButton } from 'shared/ui/IconButton';
import { TableTranslationLabel } from 'shared/ui/tables/_common/ui/TableTranslationLabel';
import { Column } from 'shared/ui/tables/_common/types';

export type CustomOutputFileTableColumnsType = CustomOutputFileType & {
	id: string;
	toggleSwitchHandler: (data: { id: string; isEnabled: boolean }) => void;
	customOutputFileDetailsHandler: (typeId: string) => void;
};

export const CustomOutputFileTableColumns: Array<Column<CustomOutputFileTableColumnsType>> = [
	{
		field: 'outputSwitcher',
		commonCellSettings: {
			style: {
				width: '7%',
			},
		},
		bodyCellSettings: {
			renderCell: (outputCell) => {
				return (
					<Switch
						checked={outputCell?.isEnabled}
						onChange={() => {
							outputCell?.toggleSwitchHandler({
								id: outputCell.id,
								isEnabled: !outputCell.isEnabled,
							});
						}}
					/>
				);
			},
		},
	},
	{
		field: 'outputTitle',
		commonCellSettings: {
			style: {
				width: '80%',
			},
		},
		headerCellSettings: {
			renderCell: (outputCell) =>
				outputCell?.category === CustomOutputFileCategoryEnum.custom ? (
					<TableTranslationLabel path="extensions.ex_list.output_file.your_custom_templates" />
				) : (
					<TableTranslationLabel path="extensions.ex_list.output_file.your_standard_templates" />
				),
		},
		bodyCellSettings: {
			renderCell: (outputCell) =>
				outputCell?.category === CustomOutputFileCategoryEnum.custom ? (
					outputCell.customModelId.name
				) : (
					<TableTranslationLabel path={`sidebar.menu./files/${outputCell?.category}`} />
				),
		},
	},
	{
		field: 'options',
		commonCellSettings: {
			style: {
				minWidth: '100px',
				maxWidth: '100px',
			},
		},
		bodyCellSettings: {
			renderCell: (outputCell) => (
				<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
					<IconButton
						iconId={'expand_right'}
						onClick={() => {
							outputCell?.customOutputFileDetailsHandler(outputCell._id);
						}}
					/>
				</Box>
			),
		},
	},
];
