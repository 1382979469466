export const Config = {
	NODE_ENV: process.env.NODE_ENV,
	API_URL: process.env.REACT_APP_API_URL,
	APP_URL: process.env.REACT_APP_APP_URL,

	GOOGLE_CLIENT_ID: process.env.REACT_APP_CLIENT_ID as string,
	GOOGLE_SECRET: process.env.REACT_APP_CLIENT_SECRET,

	STRIPE_TOKEN: process.env.REACT_APP_STRIPE_TOKEN as string,
};
