import { emailRegEx } from 'shared/consts/emailRegEx';
import { passwordRegEx } from 'shared/consts/passwordRegEx';
import * as yup from 'yup';

export const Step1ValidationSchema = yup.object({
	email: yup
		.string()
		.required('auth.validation.enter_email')
		.matches(emailRegEx, { message: 'auth.validation.email_not_valid' })
		.max(50, 'auth.validation.email_too_long'),
	password: yup
		.string()
		.required('auth.validation.enter_password')
		.matches(passwordRegEx, {
			message: 'auth.validation.password_requirements',
		})
		.max(50, 'auth.validation.password_too_long'),
	confirmPassword: yup
		.string()
		.required('auth.validation.enter_password')
		.oneOf([yup.ref('password')], 'auth.validation.password_match')
		.max(50, 'auth.validation.password_too_long'),
});

export const Step2ValidationSchema = yup.object({
	firstName: yup.string().required('auth.validation.enter_name').max(250, 'auth.validation.name_too_long'),
	lastName: yup
		.string()
		.required('auth.validation.enter_last_name')
		.max(250, 'auth.validation.last_name_too_long'),
});
