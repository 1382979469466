import { Box } from '@mui/material';
import { Checkbox } from 'shared/ui/Checkbox';
import { OptionsDropdown } from 'shared/ui/OptionsDropdown';
import { Column } from 'shared/ui/tables/_common/types';
import { TableTranslationLabel } from 'shared/ui/tables/_common/ui/TableTranslationLabel';
import { AssertionsSetsType } from 'entities/Assertions/types';

type AssertionsSetTableColumnsType = AssertionsSetsType & {
	setsCount: number;
	selectedSets: Array<string>;
	toggleAllSets: () => void;
	toggleSets: (fileId: string) => void;
	details: (id: string) => void;
	edit: (id: string) => void;
	delete: (id?: string) => void;
};

export const AssertionsSetTableColumns: Array<Column<AssertionsSetTableColumnsType>> = [
	{
		field: 'select',
		commonCellSettings: {
			style: {
				width: '70px',
			},
		},
		headerCellSettings: {
			renderCell: (assertions) => {
				return (
					<Checkbox
						checked={assertions?.setsCount === assertions?.selectedSets.length}
						onChange={() => assertions?.toggleAllSets()}
					/>
				);
			},
		},
		bodyCellSettings: {
			renderCell: (assertions) => {
				return (
					<Checkbox
						checked={assertions?.selectedSets.includes(assertions?._id)}
						onChange={() => assertions?._id && assertions.toggleSets(assertions?._id)}
					/>
				);
			},
		},
	},
	{
		field: 'icon',
		commonCellSettings: {
			style: {
				width: '70px',
			},
		},
		headerCellSettings: {
			renderCell: (assertions) => {
				return (
					<OptionsDropdown
						iconId={'more_horizontal_small'}
						optionList={[
							{
								label: 'shared_texts.table_dropdown.remove',
								value: 'Remove all assertions sets',
								action: () => {
									assertions?._id && assertions.delete();
								},
							},
						]}
						forTableHeader={true}
					/>
				);
			},
		},
	},
	{
		field: 'name',
		commonCellSettings: {
			style: { width: '950px' },
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="name" />,
		},
		bodyCellSettings: {
			prepareField: (assertions) => assertions?.name ?? '-',
		},
	},
	{
		field: 'options',
		commonCellSettings: {
			style: {
				minWidth: '100px',
				maxWidth: '100px',
			},
		},
		bodyCellSettings: {
			renderCell: (assertions) => {
				return (
					<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
						<OptionsDropdown
							iconId={'more_horizontal'}
							optionList={[
								{
									label: 'shared_texts.table_dropdown.show_list',
									value: 'Details info',
									action: () => {
										assertions?.details(assertions._id);
									},
								},
								{
									label: 'shared_texts.table_dropdown.edit',
									value: 'Edit assertions sets',
									action: () => {
										assertions?.edit(assertions._id);
									},
								},
								{
									label: 'shared_texts.table_dropdown.remove',
									value: 'Remove assertions sets',
									action: () => {
										assertions?.delete(assertions._id);
									},
								},
							]}
						/>
					</Box>
				);
			},
		},
	},
];
