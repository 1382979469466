import { FC, useState, ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useQuery } from 'react-query';
import { getWarehouseItems } from 'entities/Warehouse/api';
import { ColorPalette } from 'shared/consts/colorPalette';
import { SearchInput } from 'shared/ui/SearchInput';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { useWarehouseStore } from 'widgets/Extensions/Details/ui/Warehouse/state';
import { ArticlesTab } from 'widgets/Extensions/Details/ui/Warehouse/ui/Tabs/ArticlesTab';

export const ArticlesSettings: FC = () => {
	const { t } = useTranslation();
	const { partnerId } = useParams();
	const { setWarehouseListData } = useWarehouseStore();
	const [searchParam, setSearchParam] = useState<string | null>(null);
	const [page, setTablePage] = useState(1);

	const { isLoading: getItemsIsLoading } = useQuery({
		queryKey: ['get/warehouse-items', searchParam, partnerId],
		queryFn: () => getWarehouseItems({ search: searchParam, partnerId, page }),
		onSuccess: (response) => {
			setWarehouseListData(response.data);
		},
		onError: (err) => {
			const errorMessage = getErrorMessage(err);
			enqueueSnackbar({ message: errorMessage, variant: 'error' });
		},
	});

	const handleSearchInputChange = _.debounce((event: ChangeEvent<HTMLInputElement>) => {
		setSearchParam(event.target.value || null);
		setTablePage(1);
	}, 300);

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					gap: '20px',
					marginBottom: '44px',
				}}
			>
				<Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
					<Typography variant={'h5'} color={ColorPalette.astronaut}>
						{t('partners.articles_title')}
					</Typography>
					<Typography variant={'subtitle1'} color={ColorPalette.baliHai}>
						{t('partners.articles_description')}
					</Typography>
				</Box>

				<SearchInput onChange={handleSearchInputChange} />
			</Box>

			<ArticlesTab isLoading={getItemsIsLoading} onPageChange={(page) => setTablePage(page)} />
		</>
	);
};
