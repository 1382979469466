import { Box, Dialog, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { startRecognition } from 'entities/ImportSessions/api';
import { ImportSession } from 'entities/ImportSessions/types';
import { FileType } from 'entities/Files/types';
import { enqueueSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ColorPalette } from 'shared/consts/colorPalette';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { ActionsForDialog } from 'shared/ui/ActionsForDialog';
import { CloseModalButton } from 'shared/ui/CloseModalButton';
import { Paper } from 'shared/ui/Paper';
import { FilesComparisonHeader } from 'widgets/importFiles/importFilesDirect/ui/DuplicatedFilesModal/ui/FilesComparisonHeader';
import { FilesComparisonRow } from 'widgets/importFiles/importFilesDirect/ui/DuplicatedFilesModal/ui/FilesComparisonRow';

interface Props {
	open: boolean;
	handleClose: () => void;
	importSession: ImportSession;
	duplicates?: Array<{ existFile: FileType; newFile: FileType }>;
}

export const DuplicatedFilesModal: FC<Props> = ({ open, handleClose, importSession, duplicates }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { mutate } = useMutation({
		mutationKey: ['start-recognition', importSession._id],
		mutationFn: startRecognition,
	});

	const [compareDuplicates, setCompareDuplicates] = useState<boolean>(false);

	const [preparedDuplicates, setPreparedDuplicates] = useState<
		Array<{ existFile: FileType & { checked: boolean }; newFile: FileType & { checked: boolean } }>
	>([]);

	useEffect(() => {
		const _preparedDuplicates = (duplicates ?? []).map(({ existFile, newFile }) => ({
			existFile: { ...existFile, checked: false },
			newFile: { ...newFile, checked: false },
		}));
		setPreparedDuplicates(_preparedDuplicates);
	}, [duplicates]);

	useEffect(() => {
		return () => {
			setCompareDuplicates(false);
			setPreparedDuplicates([]);
		};
	}, []);

	const handleToggleNewFile = (fileId: string) => {
		setPreparedDuplicates((prevState) => [
			...prevState.map(({ existFile, newFile }) => ({
				existFile: {
					...existFile,
					checked:
						existFile?._id === fileId
							? !existFile.checked
							: newFile?._id !== fileId
							? existFile.checked
							: false,
				},
				newFile: {
					...newFile,
					checked:
						newFile?._id === fileId
							? !newFile.checked
							: existFile?._id !== fileId
							? newFile.checked
							: false,
				},
			})),
		]);
	};

	const handleSelectAllFiles = (type: 'exist' | 'new', checked: boolean) => {
		setPreparedDuplicates((prevState) => [
			...prevState.map(({ existFile, newFile }) => ({
				existFile: { ...existFile, checked: type === 'exist' ? checked : false },
				newFile: { ...newFile, checked: type === 'new' ? checked : false },
			})),
		]);
	};

	const handleUploadFiles = (recognizeAllDuplicates: boolean) => {
		const mutationValues = {
			id: importSession._id,
			data: {
				handledDuplicates: !recognizeAllDuplicates
					? [
							...preparedDuplicates.map((duplicate) => ({
								fileId: duplicate.newFile._id,
								solution: (duplicate.newFile.checked ? 'RECOGNIZE' : 'SKIP') as
									| 'SKIP'
									| 'RECOGNIZE',
							})),
					  ]
					: [],
				recognizeAllDuplicates: recognizeAllDuplicates,
			},
		};
		mutate(mutationValues, {
			onSuccess: async (res) => {
				if (res.data.success && !res.data.duplicates) {
					enqueueSnackbar({
						message: t('start_recognition.leave_page_modal.started_success'),
						variant: 'success',
					});
					handleClose();
					navigate('/files/all');
				} else {
					enqueueSnackbar({
						message: t('start_recognition.leave_page_modal.error_delete'),
						variant: 'error',
					});
				}
			},
			onError: (err) => {
				const defaultErrorMessage = t('start_recognition.leave_page_modal.error_delete');
				const errorMessage = getErrorMessage(err, defaultErrorMessage);
				enqueueSnackbar({
					message: errorMessage,
					variant: 'error',
				});
			},
		});
	};

	const handleCompareFiles = () => {
		setCompareDuplicates(true);
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			PaperComponent={Paper}
			PaperProps={{ title: t('start_recognition.duplicate_modal.title') as string }}
			sx={{
				'& .MuiPaper-root': {
					width: '536px',
					maxWidth: '536px',
					overflow: 'inherit',
				},
			}}
		>
			<CloseModalButton action={handleClose} />
			{!compareDuplicates ? (
				<>
					<Typography variant={'body1'} color={ColorPalette.baliHai} sx={{ marginBottom: '35px' }}>
						{t('start_recognition.duplicate_modal.compared_description')}
					</Typography>
					<ActionsForDialog
						cancelLabel={t('start_recognition.duplicate_modal.no_copmare')}
						cancelAction={handleCompareFiles}
						applyLabel={t('shared_texts.yes')}
						applyAction={() => handleUploadFiles(true)}
					/>
				</>
			) : (
				<>
					<Typography variant={'body1'} color={ColorPalette.baliHai} sx={{ marginBottom: '35px' }}>
						{t('start_recognition.duplicate_modal.not_compared_description')}
					</Typography>
					<FilesComparisonHeader
						isExistFilesSelected={preparedDuplicates.every(
							(duplicate) => duplicate.existFile.checked,
						)}
						isNewFilesSelected={preparedDuplicates.every(
							(duplicate) => duplicate.newFile.checked,
						)}
						sessionUploadSource={importSession.uploadSource}
						onSelectAllFiles={handleSelectAllFiles}
					/>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							maxHeight: '390px',
							overflowY: 'auto',
							marginBottom: '44px',
						}}
					>
						{preparedDuplicates?.length
							? preparedDuplicates?.map((duplicate) => {
									return (
										<FilesComparisonRow
											key={duplicate.newFile._id}
											existFile={{ ...duplicate.existFile }}
											newFile={{ ...duplicate.newFile }}
											onToggle={handleToggleNewFile}
										/>
									);
							  })
							: null}
					</Box>
					<ActionsForDialog
						cancelLabel={t('shared_texts.cancel')}
						cancelAction={handleClose}
						applyLabel={t('shared_texts.save')}
						applyAction={() => handleUploadFiles(false)}
					/>
				</>
			)}
		</Dialog>
	);
};
