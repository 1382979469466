import { FC, useMemo } from 'react';
import { Typography, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Paper } from 'shared/ui/Paper';
import { SheetTable } from 'shared/ui/SheetTable';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Button } from 'shared/ui/Button';

const WarehouseCsvOptions: FC = () => {
	const { i18n, t } = useTranslation();

	const pathToDownload = useMemo(() => `/files/Articoli_${i18n.language}.csv`, [i18n]);

	const sheetPreviewArr = [
		{ cellContent: t('extensions.ex_list.warehouse.csv_cells.article_code') },
		{ cellContent: t('extensions.ex_list.warehouse.csv_cells.article_description') },
		{ cellContent: t('extensions.ex_list.warehouse.csv_cells.code_from_document') },
		{ cellContent: t('extensions.ex_list.warehouse.csv_cells.description_from_document') },
		{ cellContent: t('extensions.ex_list.warehouse.csv_cells.partner_vat_number') },
		{ cellContent: t('extensions.ex_list.warehouse.csv_cells.partner_vat_code') },
	];

	return (
		<Paper
			sx={{
				width: '100%',
				padding: '25px 20px',
				display: 'flex',
				flexDirection: 'column',
				gap: '15px',
			}}
		>
			<Typography variant={'h5'} color={ColorPalette.astronaut}>
				{t('sync_settings.csv_example.warehouse_title')}
			</Typography>
			<Typography variant={'body2'} color={ColorPalette.baliHai}>
				{t('sync_settings.csv_example.warehouse_description')}
			</Typography>

			<SheetTable cells={sheetPreviewArr} />

			<Button
				size={'small'}
				sx={{
					alignSelf: 'flex-end',
				}}
			>
				<Link
					sx={{
						height: '100%',
						width: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
					underline="none"
					color={ColorPalette.white}
					href={pathToDownload}
					target="_blank"
					download
				>
					{t('sync_settings.csv_example.warehouse_download_btn')}
				</Link>
			</Button>
		</Paper>
	);
};

export default WarehouseCsvOptions;
