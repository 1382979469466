import { Box, Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { ActionsForDialog } from 'shared/ui/ActionsForDialog';
import { Paper } from 'shared/ui/Paper';

interface Props {
	open: boolean;
	handleClose: () => void;
	indexArray: number;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	allTableLabels: any[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setTableLabelsData: any;
}

export const DeleteTableModal: FC<Props> = ({
	open,
	handleClose,
	indexArray,
	allTableLabels,
	setTableLabelsData,
}) => {
	const { t } = useTranslation();
	const handleDeleteTable = (indexArray: number) => {
		const allLabels = [...allTableLabels];
		allLabels.splice(indexArray, 1);
		setTableLabelsData(allLabels);
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			PaperComponent={Paper}
			PaperProps={{ title: t('files.modal.delete_table_title') as string }}
		>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					flexDirection: 'column',
					justifyContent: 'space-between',
					marginBottom: '40px',
				}}
			>
				<Box sx={{ marginBottom: '7px' }}>{t('files.modal.delete_table_description')}</Box>
			</Box>
			<ActionsForDialog
				cancelLabel={t('shared_texts.cancel')}
				cancelAction={() => {
					handleClose();
				}}
				applyLabel={t('shared_texts.yes')}
				applyAction={() => {
					handleClose();
					handleDeleteTable(indexArray);
				}}
			/>
		</Dialog>
	);
};
