import { Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { updatePassword } from 'entities/Auth/api';
import { Form, Formik, FormikProps } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { FunctionComponent } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ColorPalette } from 'shared/consts/colorPalette';
import { ChangeLanguage } from 'shared/ui/ChangeLanguage';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { isEmptyObject } from 'shared/helpers/isEmptyObject';
import { isEmptyObjectValues } from 'shared/helpers/isEmptyObjectValues';
import { ActionsForDialog } from 'shared/ui/ActionsForDialog';
import { InputField } from 'shared/ui/InputField';
import { PasswordRequirements } from 'shared/ui/PasswordRequirements';
import { FormContainer } from 'widgets/PersonalAccount/ui/Paper';
import { ChangePasswordValidationSchema } from 'widgets/PersonalAccount/ui/tabs/Security/ui/ChangePassword/consts/validation';
import { ChangePasswordType } from 'widgets/PersonalAccount/ui/tabs/Security/ui/ChangePassword/types';

export const ChangePassword: FunctionComponent = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { mutate } = useMutation({
		mutationFn: updatePassword,
	});
	const initialValues = {
		currentPassword: '',
		password: '',
		confirmPassword: '',
	};

	const handleSubmit = (values: ChangePasswordType): void => {
		const mutationValues = {
			password: values.password,
			currentPassword: values.currentPassword,
		};
		mutate(mutationValues, {
			onSuccess: () => {
				enqueueSnackbar({
					message: t('shared_texts.success_update'),
					variant: 'success',
				});
			},
			onError: (err) => {
				const defaultErrorMessage = t('shared_texts.success_update');
				const errorMessage = getErrorMessage(err, defaultErrorMessage);
				enqueueSnackbar({
					message: errorMessage,
					variant: 'error',
				});
			},
		});
	};

	return (
		<>
			<Typography variant={'h5'} color={ColorPalette.astronaut}>
				{t('personal_account.tab_3.title_2')}
			</Typography>
			<Box sx={{ position: 'absolute', top: '49px', right: '40px' }}>
				<ChangeLanguage />
			</Box>
			<FormContainer sx={{ marginTop: '34px', marginBottom: '36px' }}>
				<Formik
					initialValues={initialValues}
					enableReinitialize
					validationSchema={ChangePasswordValidationSchema}
					onSubmit={handleSubmit}
				>
					{({ errors, values, resetForm }: FormikProps<ChangePasswordType>) => (
						<Form>
							<InputField
								name={'currentPassword'}
								label={t('personal_account.tab_3.labels.current_password')}
								placeholder={t('personal_account.tab_3.placeholder.current_password')}
								type={'password'}
								required
							/>
							<InputField
								name={'password'}
								label={t('personal_account.tab_3.labels.new_password')}
								placeholder={t('personal_account.tab_3.placeholder.new_password')}
								type={'password'}
								required
							/>
							<InputField
								name={'confirmPassword'}
								label={t('personal_account.tab_3.labels.confirm_new_password')}
								placeholder={t('personal_account.tab_3.placeholder.confirm_new_password')}
								type={'password'}
								required
							/>
							<PasswordRequirements />
							<ActionsForDialog
								cancelLabel={t('shared_texts.cancel')}
								cancelAction={() => {
									resetForm();
								}}
								applyLabel={t('shared_texts.save')}
								applyActionDisabled={!isEmptyObject(errors) || isEmptyObjectValues(values)}
							/>
							<Typography
								variant={'body2'}
								color={ColorPalette.royalBlue}
								onClick={() => navigate('/forgot-password', { state: { access: true } })}
								sx={{
									width: 'fit-content',
									cursor: 'pointer',
									marginTop: '30px',
									marginLeft: 'auto',
								}}
							>
								{t('auth.forgot_password')}
							</Typography>
						</Form>
					)}
				</Formik>
			</FormContainer>
		</>
	);
};
