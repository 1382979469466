import { FC } from 'react';
import { useQuery } from 'react-query';
import { Box } from '@mui/material';
import { FtpSettingsTypeEnum } from 'entities/Integrations/enums';
import { getFtpSyncSettings } from 'entities/Integrations/api';
import { FtpForm } from './ui/FtpForm';
import { PartnersCsvOptions, WarehouseCsvOptions } from './ui/CsvOptions';
import { SyncOptions } from './ui/SyncOptions';
import { Loader } from 'shared/ui/Loader';

type SyncSettingsPropsType = {
	syncSettingsType: FtpSettingsTypeEnum;
};

export const SyncSettingsWidget: FC<SyncSettingsPropsType> = ({ syncSettingsType }) => {
	const { data: ftpSettingsData, isLoading } = useQuery({
		queryKey: ['get/sync-settings'],
		queryFn: () => getFtpSyncSettings({ syncSettingsType }),
	});

	const renderCsvOptions = () => {
		switch (syncSettingsType) {
			case FtpSettingsTypeEnum.partners:
				return <PartnersCsvOptions />;
			case FtpSettingsTypeEnum.warehouse:
				return <WarehouseCsvOptions />;
			default:
				return null;
		}
	};

	if (isLoading) return <Loader />;
	return (
		<Box
			sx={{
				'display': 'flex',
				'gap': '40px',
				'flexDirection': 'column',
				'@media screen and (min-width: 1024px)': {
					flexDirection: 'row',
				},
			}}
		>
			{ftpSettingsData?.data && (
				<>
					<Box
						sx={{
							'width': '100%',
							'@media screen and (min-width: 1024px)': {
								width: '48%',
								maxWidth: '500px',
							},
						}}
					>
						<FtpForm
							ftpSettingsData={ftpSettingsData?.data}
							syncSettingsType={syncSettingsType}
						/>
					</Box>
					<Box
						sx={{
							'display': 'flex',
							'flexDirection': 'column',
							'gap': '20px',
							'width': '100%',
							'@media screen and (min-width: 1024px)': {
								maxWidth: '850px',
								width: '52%',
							},
						}}
					>
						{renderCsvOptions()}
						<SyncOptions
							ftpSettingsData={ftpSettingsData?.data}
							syncSettingsType={syncSettingsType}
						/>
					</Box>
				</>
			)}
		</Box>
	);
};
