import { CategoryTypeEnum, SavedFileStatus } from 'entities/Files/types';
import { useMemo, useState } from 'react';

export type SearchParams = {
	status: SavedFileStatus;
	sort: 'asc' | 'desc';
	uploadSource: 'FTP' | 'DIRECT' | 'all';
	category: Array<CategoryTypeEnum>;
	coUserId: string;
	importFrom?: string;
	importTo?: string;
	autoRemoveFrom?: string;
	autoRemoveTo?: string;
	search: string;
	partnerId?: string;
};

const initialValues: SearchParams = {
	status: 'RECOGNIZED',
	sort: 'desc',
	uploadSource: 'all',
	category: [],
	coUserId: 'nobody',
	search: '',
};

export const useFilesSearchParams = (): {
	searchParams: SearchParams;
	changeSearchParams: (searchParams: Partial<SearchParams>) => void;
} => {
	const [searchParams, setSearchParams] = useState<SearchParams>(initialValues);
	const changeSearchParams = (searchParams: Partial<SearchParams>) =>
		setSearchParams((prev) => ({ ...prev, ...searchParams }));

	return useMemo(
		() => ({
			searchParams,
			changeSearchParams,
		}),
		[searchParams, changeSearchParams],
	);
};
