import { useMemo } from 'react';
import { CircularProgress, Box } from '@mui/material';
import { FunctionComponent } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';

type LoaderPropsType = {
	type?: 'fullpage' | 'mid' | 'min';
	width?: string;
	height?: string;
};

export const Loader: FunctionComponent<LoaderPropsType> = ({ type = 'mid', width, height }) => {
	const heightMemo = useMemo(() => {
		if (height) return height;
		return type === 'mid' ? '350px' : type === 'min' ? '150px' : '100vh';
	}, [height, type]);

	const widthMemo = useMemo(() => {
		if (width) return width;
		return type === 'fullpage' ? '100vw' : '100%';
	}, [width, type]);

	return (
		<Box
			sx={{
				width: widthMemo,
				height: heightMemo,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<CircularProgress size={'70px'} sx={{ color: ColorPalette.royalBlue }} />
		</Box>
	);
};
