import { Typography } from '@mui/material';
import * as React from 'react';
import { FunctionComponent } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Paper } from 'shared/ui/Paper';
import { BigIcon } from 'widgets/auth/_common/ui/BigIcon';

interface Props {
	type: 'info' | 'success' | 'error';
	title: string;
	message: string;
	icon?: string;
	children?: React.ReactNode;
}

export const StatusModal: FunctionComponent<Props> = ({ type, title, message, icon, children }) => {
	return (
		<Paper
			title={title}
			titleAlign={'center'}
			sx={{
				'& *': {
					userSelect: 'none',
				},
			}}
		>
			{type === 'info' && icon && <BigIcon iconId={icon} color={ColorPalette.royalBlue} />}
			{type === 'success' && (
				<BigIcon iconId={icon ?? 'done_ring_round'} color={ColorPalette.royalBlue} />
			)}
			{type === 'error' && <BigIcon iconId={icon ?? 'dell'} color={ColorPalette.violetRed} />}
			<Typography
				variant={'body1'}
				color={ColorPalette.baliHai}
				align={'center'}
				sx={{
					margin: '44px auto 0',
					marginBottom: type === 'info' ? '27px' : '73px',
					maxWidth: '370px',
				}}
			>
				{message}
			</Typography>
			{children}
		</Paper>
	);
};
