import { FC, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Dialog, Typography, Box } from '@mui/material';
import { Form, Formik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { GetWarehouseItemsForAssertionsReq } from 'entities/Warehouse/api/types';
import { getPartners } from 'entities/Partners/api';
import { getWarehouseItemsForAssertions } from 'entities/Warehouse/api';
import { InitialValuesTypesEnum } from 'entities/Warehouse/enums';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { SelectOption } from 'shared/ui/Select/types';
import { ColorPalette } from 'shared/consts/colorPalette';
import { FormSelect } from 'shared/ui/Select/FormSelect';
import { Paper } from 'shared/ui/Paper';
import { ActionsForDialog } from 'shared/ui/ActionsForDialog';
import { Loader } from 'shared/ui/Loader';
import { useAssertionSetsStore } from 'widgets/Extensions/Details/ui/AssertionSets/model/state';
import { WarehouseForAssertionsType } from './types';
import { WarehouseForAssertionsValidationSchema } from './consts/validation';

interface ImportFromWarehouseModalPropsType {
	open: boolean;
	handleClose: () => void;
}

export const ImportFromWarehouseModal: FC<ImportFromWarehouseModalPropsType> = ({ open, handleClose }) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const { setWarehousesForAssertions } = useAssertionSetsStore();

	const [itemsForAssertionsPayload, setItemsForAssertionsPayload] =
		useState<GetWarehouseItemsForAssertionsReq>({
			valuesTypes: InitialValuesTypesEnum.all,
			partnerId: undefined,
		});

	const { isLoading: getItemsForAssertionsIsLoading } = useQuery({
		queryKey: ['get/items-for-assertions', itemsForAssertionsPayload],
		queryFn: () => getWarehouseItemsForAssertions(itemsForAssertionsPayload),
		onSuccess: (response) => {
			if (response.data.length) {
				setWarehousesForAssertions(response.data);
				handleClose();
			} else {
				enqueueSnackbar({
					message: t('assertions.snackbar.warehouse_for_assertions_empty'),
					variant: 'success',
				});
			}
		},
		onError: (err) => {
			const errorMessage = getErrorMessage(err);
			enqueueSnackbar({ message: errorMessage, variant: 'error' });
		},
		retry: false,
		enabled: false,
	});

	const { data: partners, isLoading: getPartnersIsLoading } = useQuery({
		queryKey: ['get/partners'],
		queryFn: () => getPartners({ type: null }),
		onError: (err) => {
			const errorMessage = getErrorMessage(err);
			enqueueSnackbar({ message: errorMessage, variant: 'error' });
		},
	});

	const partnersSeletcOptions: SelectOption[] = useMemo(
		() =>
			partners?.data?.results
				? partners.data.results.map((item) => ({
						value: item._id,
						label: item.name,
						customLabel: (
							<Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
								<Typography>{item.name}</Typography>
								<Typography variant="body2" color={ColorPalette.baliHai}>
									{item.type}
								</Typography>
							</Box>
						),
				  }))
				: [],
		[partners],
	);

	const submitFormHandler = async (values: WarehouseForAssertionsType) => {
		setItemsForAssertionsPayload({
			...values,
			partnerId: values.partnerId === 'all' ? undefined : values.partnerId,
		});
		await queryClient.fetchQuery({
			queryKey: ['get/items-for-assertions', itemsForAssertionsPayload],
		});
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			PaperComponent={Paper}
			PaperProps={{ title: t('assertions.modal.creation_as_via_warehouse_title') as string }}
		>
			{getPartnersIsLoading || getItemsForAssertionsIsLoading ? (
				<Loader />
			) : (
				<>
					<Typography variant={'body1'} color={ColorPalette.baliHai} sx={{ marginBottom: '34px' }}>
						{t('assertions.modal.creation_as_via_warehouse_description')}
					</Typography>
					<Formik
						initialValues={{
							valuesTypes: InitialValuesTypesEnum.default,
							partnerId: '',
						}}
						validationSchema={WarehouseForAssertionsValidationSchema}
						onSubmit={submitFormHandler}
					>
						{({ values, setFieldValue }) => (
							<Form>
								<FormSelect
									name={'valuesTypes'}
									label={t('assertions.label.warehouse_values_type') as string}
									placeholder={t('assertions.placeholder.warehouse_values_type')}
									options={valuesTypesSelectOptions}
									value={values.valuesTypes}
									onSelect={(value) => setFieldValue('valuesTypes', value)}
									required
								/>
								<FormSelect
									name={'partnerId'}
									label={t('assertions.label.warehouse_partners') as string}
									placeholder={t('assertions.placeholder.warehouse_partners')}
									options={[
										{ value: 'all', label: 'assertions.warehouse_options.all_articles' },
										...partnersSeletcOptions,
									]}
									value={values.partnerId}
									onSelect={(value) => setFieldValue('partnerId', value)}
									required
								/>
								<ActionsForDialog
									cancelLabel={t('shared_texts.cancel')}
									cancelAction={handleClose}
									applyLabel={t('shared_texts.next_step')}
								/>
							</Form>
						)}
					</Formik>
				</>
			)}
		</Dialog>
	);
};

const valuesTypesSelectOptions: Array<SelectOption> = [
	{
		value: InitialValuesTypesEnum.all,
		label: 'assertions.warehouse_options.option_1',
	},
	{
		value: InitialValuesTypesEnum.code,
		label: 'assertions.warehouse_options.option_2',
	},
	{
		value: InitialValuesTypesEnum.description,
		label: 'assertions.warehouse_options.option_3',
	},
];
