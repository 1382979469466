import { AssertionsType } from 'entities/Assertions/types';
export type UploadSource = 'DIRECT' | 'FTP' | 'GOOGLE';

export enum CategoryTypeEnum {
	custom = 'custom',
	purchase_orders = 'purchase-orders',
	sales_orders = 'sales-orders',
	receipts = 'receipts',
	general_documents = 'general-documents',
	transport_documents = 'transport-documents',
	invoices = 'invoices',
}

export type SavedFileStatus = 'IN_PROCESS' | 'UNRECOGNIZED' | 'RECOGNIZED';

export type LabelType = 'table' | 'string';

export type BoundingRegionsType = {
	pageNumber: number;
	boxPosition: {
		x: 0;
		y: 0;
		width: 0;
		height: 0;
	};
};

export type LabelStructureType = {
	label?: string;
	content: string;
	boundingRegions?: BoundingRegionsType[];
	assertionSetsId?: string;
	assertionsData?: AssertionsType;
	autocomplete?: boolean;
};

export type TableCellType = LabelStructureType & {
	rowIndex: number;
	columnIndex: number;
};
export type TableLabelsStructureType = {
	columnsHeaders: {
		columnName: string;
		autocomplete?: boolean;
	}[];
	cells: TableCellType[];
};

export type RecognizedLabelsStructureType = LabelStructureType &
	TableLabelsStructureType & {
		type: LabelType;
	};

export type FileType = {
	_id: string;
	fileName: string;
	size: 0;
	companyId: string;
	uploaderId: {
		_id: string;
		email: string;
		firstName: string;
		lastName: string;
		avatar: string;
	};
	uploadSource: UploadSource;
	category: CategoryTypeEnum;
	status: SavedFileStatus;
	convertedFilePath: string;
	pathIdentifier: string;
	recognitionTemplateId: string;
	hash: string;
	autoRemoveAt: string;
	createdAt: string;
	updatedAt: string;
	host?: string;
	assertionSetsId: string;
	recognizedLabels: RecognizedLabelsStructureType[];
	autocomplete: boolean;
	requiresReview: boolean;
};
