import { passwordRegEx } from 'shared/consts/passwordRegEx';
import * as yup from 'yup';

export const ChangePasswordValidationSchema = yup.object({
	currentPassword: yup
		.string()
		.required('personal_account.tab_3.validation.current_password')
		.matches(passwordRegEx, {
			message: 'auth.validation.password_requirements',
		})
		.max(50, 'auth.validation.password_too_long'),
	password: yup
		.string()
		.required('personal_account.tab_3.validation.new_password')
		.matches(passwordRegEx, {
			message: 'auth.validation.password_requirements',
		})
		.max(50, 'auth.validation.password_too_long'),
	confirmPassword: yup
		.string()
		.required('personal_account.tab_3.validation.new_password')
		.oneOf([yup.ref('password')], 'auth.validation.password_match')
		.max(50, 'auth.validation.password_too_long'),
});
