import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IResource } from 'pages/admin/Resources';
import { TableWithHeader } from 'shared/ui/tables/TableWithHeader';
import { EmptyTable } from 'shared/ui/EmptyTable';
import { ResourcesTableColumns } from './consts/ResourceTableColumns';

type Props = {
	resources: IResource[];
};
const ResourcesTable: FC<Props> = ({ resources }) => {
	const { t } = useTranslation();
	return (
		<>
			{resources?.length ? (
				<TableWithHeader
					columns={ResourcesTableColumns}
					rows={resources.map((resource) => ({ ...resource, id: resource._id }))}
				/>
			) : (
				<EmptyTable text={t('shared_texts.not_found')} />
			)}
		</>
	);
};

export default ResourcesTable;
