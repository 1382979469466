import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Paper } from 'shared/ui/Paper';
import emptyPaymentMethod from 'shared/assets/empty-payment-method.png';
import { useSubscriptionStoreStore } from 'widgets/Subscriptions/model/state';

interface PaymentMethodPropsType {
	addPaymentHandler: () => void;
}

export const PaymentMethod: FC<PaymentMethodPropsType> = ({ addPaymentHandler }) => {
	const { t } = useTranslation();
	const { paymentMethodLast4 } = useSubscriptionStoreStore();

	return (
		<Paper
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				textAlign: 'center',
			}}
		>
			{paymentMethodLast4 ? (
				<Input
					name={'cardNumber'}
					label={t('subscriptions.tab_3.labels.card_number')}
					value={`**** **** **** ${paymentMethodLast4}`}
					disabled
				/>
			) : (
				<>
					<img
						src={emptyPaymentMethod}
						alt={'empty-payment-method'}
						style={{ width: '134px', height: '134px' }}
					/>

					<Typography
						variant={'h6'}
						color={ColorPalette.manatee}
						sx={{ maxWidth: '350px', margin: '40px 0' }}
					>
						{t('subscriptions.tab_3.card_dont_exist')}
					</Typography>
				</>
			)}
			<Button fullWidth onClick={addPaymentHandler}>
				{paymentMethodLast4
					? t('subscriptions.tab_3.change_payment')
					: t('subscriptions.tab_3.add_payment')}
			</Button>
		</Paper>
	);
};
