import {
	GetFtpSettingsRequest,
	SaveFtpSettingsRequest,
	GetSyncSettingsRequest,
	PostSaveFtpConnectionRequest,
	PostSyncSettingsRequest,
} from 'entities/Integrations/api/types';
import { axiosInstance } from 'shared/config/axios';

export const getFtpSettings: GetFtpSettingsRequest = (queries) => {
	return axiosInstance.get('/ftp/settings', { params: { ...queries } });
};
export const saveFtpSettings: SaveFtpSettingsRequest = (data) => {
	return axiosInstance.post('/ftp/settings', data);
};

export const getFtpSyncSettings: GetSyncSettingsRequest = (queries) => {
	return axiosInstance.get('/sync-settings', { params: { ...queries } });
};

export const postFtpSyncSettings: PostSyncSettingsRequest = (data) => {
	return axiosInstance.post('/sync-settings', data);
};

export const postSaveFtpSyncSettingsConnection: PostSaveFtpConnectionRequest = (data) => {
	return axiosInstance.post('/sync-settings/ftp', data);
};
