import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, InputAdornment, InputBase, InputBaseProps } from '@mui/material';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Icon } from 'shared/ui/Icon';
import { InputError } from 'shared/ui/InputError';
import { InputLabel } from 'shared/ui/InputLabel';

interface Props extends InputBaseProps {
	name: string;
	label: string;
	type?: 'text' | 'email' | 'password' | 'select' | 'number';
	errorText?: string;
}

export const Input: FunctionComponent<Props> = ({
	sx,
	name,
	label,
	type = 'text',
	required,
	fullWidth = true,
	errorText,
	placeholder,
	...props
}) => {
	const { t } = useTranslation();
	const [showPassword, setShowPassword] = useState(false);

	const handleClickShowPassword = () => setShowPassword((prevState) => !prevState);

	return (
		<FormControl variant={'standard'} fullWidth={fullWidth} sx={{ marginBottom: '40px', ...sx }}>
			{label && <InputLabel name={name} label={label} required={required} />}

			<InputBase
				id={name}
				type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
				endAdornment={
					type === 'password' && (
						<InputAdornment position="end">
							<Box onClick={handleClickShowPassword}>
								<Icon
									id={'view_alt'}
									style={{
										cursor: 'pointer',
										color: showPassword ? ColorPalette.astronaut : ColorPalette.rockBlue,
									}}
								/>
							</Box>
						</InputAdornment>
					)
				}
				sx={{
					'& .MuiInputBase-input': {
						'height': 'initial',
						'margin': '0',

						'padding': `${type === 'password' ? '20px 53px 20px 16px' : '20px 16px'}`,
						'border': `1px solid ${errorText ? ColorPalette.violetRed : ColorPalette.periwinkle}`,
						'borderRadius': '10px',

						'fontWeight': 600,
						'fontSize': '14px',
						'lineHeight': '18px',

						'color': ColorPalette.astronaut,
						'&:placeholder': {
							color: ColorPalette.baliHai,
						},
					},
					'&.MuiInputBase-root': {
						'position': 'relative',
						'& .MuiInputAdornment-root': {
							position: 'absolute',
							transform: 'translateX(-50%)',
							right: '9px',
						},

						'&.Mui-focused .MuiInputBase-input': {
							border: `1px solid ${
								errorText ? ColorPalette.violetRed : ColorPalette.periwinkle
							}`,
						},
						'& .Mui-disabled': {
							border: `1px solid ${ColorPalette.zircon}`,
						},
					},

					'label + &': {
						marginTop: '18px',
					},
				}}
				placeholder={t(placeholder as string) as string}
				{...props}
			/>

			<InputError text={errorText} />
		</FormControl>
	);
};
