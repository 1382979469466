import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type ForgotPasswordStore = {
	emailToSend: string;
};

type ForgotPasswordActions = {
	updateEmailToSend: (data: { email: string }) => void;
	reset: () => void;
};

const initialState: ForgotPasswordStore = {
	emailToSend: '',
};

const forgotPasswordStore = create<ForgotPasswordStore & ForgotPasswordActions>()(
	persist(
		(set) => ({
			...initialState,
			updateEmailToSend: ({ email }) => set(() => ({ emailToSend: email })),
			reset: () => set(() => ({ ...initialState })),
		}),
		{
			name: 'forgotPasswordStore',
		},
	),
);

export const useForgotPasswordStore = () => forgotPasswordStore((state) => state);
