import { FC } from 'react';
import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';
import { Form, Formik, FormikProps } from 'formik';
import { useMutation, useQueryClient } from 'react-query';
import { createAssertionsSet, updateAssertionsSet } from 'entities/Assertions/api';
import { AssertionsSetsType } from 'entities/Assertions/types';
import { isEmptyObject } from 'shared/helpers/isEmptyObject';
import { isEmptyObjectValues } from 'shared/helpers/isEmptyObjectValues';
import { ActionsForDialog } from 'shared/ui/ActionsForDialog';
import { InputField } from 'shared/ui/InputField';
import { Paper } from 'shared/ui/Paper';
import { AssertionsSetEditorValidationSchema } from 'widgets/Extensions/Details/ui/AssertionSets/ui/AsModals/AssertionsSetManualCreationModal/consts/validation';
import { AssertionsSetEditorType } from 'widgets/Extensions/Details/ui/AssertionSets/ui/AsModals/AssertionsSetManualCreationModal/types';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';

interface Props {
	open: boolean;
	handleClose: () => void;
	assertionSetToEdit?: AssertionsSetsType;
}

export const AssertionsSetManualCreationModal: FC<Props> = ({ open, handleClose, assertionSetToEdit }) => {
	const queryClient = useQueryClient();
	const { t } = useTranslation();

	const { mutate: createAssertionSetMutate } = useMutation({
		mutationKey: ['create/assertion-sets'],
		mutationFn: createAssertionsSet,
	});

	const { mutate: updateAssertionSetMutate } = useMutation({
		mutationKey: ['update/assertion-sets'],
		mutationFn: updateAssertionsSet,
	});

	const handleSubmitCreation = (values: AssertionsSetEditorType) => {
		createAssertionSetMutate(values, {
			onSuccess: async () => {
				await queryClient.invalidateQueries({ queryKey: ['get/assertion-sets'] });
				enqueueSnackbar({
					message: t('assertions.snackbar.success_assertions_set_creation'),
					variant: 'success',
				});
				handleClose();
			},
			onError: (err) => {
				const errorMessage = getErrorMessage(err);
				enqueueSnackbar({
					message: errorMessage,
					variant: 'error',
				});
			},
		});
	};

	const handleSubmitEdit = (values: AssertionsSetEditorType) => {
		if (assertionSetToEdit) {
			updateAssertionSetMutate(
				{
					id: assertionSetToEdit._id,
					data: values,
				},
				{
					onSuccess: async () => {
						await queryClient.invalidateQueries({ queryKey: ['get/assertion-sets'] });
						enqueueSnackbar({
							message: t('assertions.snackbar.success_assertions_set_edit'),
							variant: 'success',
						});
						handleClose();
					},
					onError: (err) => {
						const errorMessage = getErrorMessage(err);
						enqueueSnackbar({
							message: errorMessage,
							variant: 'error',
						});
					},
				},
			);
		}
	};

	const handleSubmit = (values: AssertionsSetEditorType) => {
		if (assertionSetToEdit) {
			handleSubmitEdit(values);
		} else {
			handleSubmitCreation(values);
		}
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			PaperComponent={Paper}
			PaperProps={{
				title: (assertionSetToEdit
					? t('assertions.modal.edit_as_set_title')
					: t('assertions.modal.add_as_set_title')) as string,
			}}
		>
			<Formik
				initialValues={{
					name: assertionSetToEdit?.name || '',
				}}
				validationSchema={AssertionsSetEditorValidationSchema}
				onSubmit={handleSubmit}
			>
				{({ errors, values, resetForm }: FormikProps<AssertionsSetEditorType>) => (
					<Form>
						<InputField
							name={'name'}
							label={t('assertions.label.as_set_name')}
							placeholder={t('assertions.placeholder.as_set_name')}
							required
						/>

						<ActionsForDialog
							cancelLabel={t('shared_texts.cancel')}
							cancelAction={() => {
								resetForm();
								handleClose();
							}}
							applyLabel={t('shared_texts.save')}
							applyActionDisabled={!isEmptyObject(errors) || isEmptyObjectValues(values)}
						/>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};
