import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InputBaseProps, NativeSelect as MUINativeSelect } from '@mui/material';
import { ColorPalette } from 'shared/consts/colorPalette';
import { SelectOption } from 'shared/ui/Select/types';

export interface SelectProps extends Omit<InputBaseProps, 'onSelect'> {
	value: string | string[] | undefined;
	defaultValue?: string | string[] | undefined;
	placeholder: string;
	onSelect: (value: string | string[] | undefined) => void;
	options: Array<SelectOption>;
}

export const NativeSelect: FC<SelectProps> = ({ sx, value, onSelect, options, defaultValue }) => {
	const { t } = useTranslation();
	return (
		<MUINativeSelect
			value={value}
			defaultValue={defaultValue}
			onChange={(e) => onSelect(e.target.value)}
			sx={{
				'background': 'transparent',
				'border': 'none',
				'fontSize': '14px',
				'color': ColorPalette.astronaut,
				'& .MuiInputBase-input:focus': {
					backgroundColor: 'transparent',
					border: 'none',
				},
				'&:before, &:after': {
					display: 'none',
				},
				...sx,
			}}
		>
			{options.map((option) => (
				<option value={option.value ?? ''} key={String(option.value)}>
					{t(option.label)}
				</option>
			))}
		</MUINativeSelect>
	);
};
