import { axiosInstance } from 'shared/config/axios';
import {
	GetCompanyUsersInfoRequest,
	GetPersonalInfoRequest,
	GetUsersLogsRequest,
	UpdateUserInfoRequest,
} from 'entities/User/api/types';
import { getAccessToken } from 'shared/helpers/getAccessToken';

export const getPersonalInfo: GetPersonalInfoRequest = (userId) => {
	return axiosInstance.get(`/users/${userId}`, {
		headers: {
			Authorization: `Bearer ${getAccessToken()}`,
		},
	});
};

export const updatePersonalInfo: UpdateUserInfoRequest = (data) => {
	return axiosInstance.patch('/users/info', data, {
		headers: {
			Authorization: `Bearer ${getAccessToken()}`,
		},
	});
};

export const getCompanyUsersInfo: GetCompanyUsersInfoRequest = () => {
	return axiosInstance.get(`/users/company-users`, {
		headers: {
			Authorization: `Bearer ${getAccessToken()}`,
		},
	});
};

export const getUsersLogs: GetUsersLogsRequest = (userId) => {
	return axiosInstance.get(`/logs/${userId}`, {
		headers: {
			Authorization: `Bearer ${getAccessToken()}`,
		},
	});
};
