import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { format } from 'date-fns';
import { it, enUS } from 'date-fns/locale';
import { Typography, Box } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useAuthStore } from 'processes/auth/model/state';
import { postFtpSyncSettings } from 'entities/Integrations/api';
import { SyncSettingsType } from 'entities/Integrations/types';
import { FtpSettingsTypeEnum, SyncPeriodEnum, LastSyncStatusEnum } from 'entities/Integrations/enums';
import { parseError } from 'shared/helpers/getErrorMessage';
import { Paper } from 'shared/ui/Paper';
import { Icon } from 'shared/ui/Icon';
import { Select } from 'shared/ui/Select';
import { ColorPalette } from 'shared/consts/colorPalette';
import { SelectOption } from 'shared/ui/Select/types';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { Loader } from 'shared/ui/Loader';
import { Button } from 'shared/ui/Button';

type SyncOptionsPropsType = {
	ftpSettingsData: SyncSettingsType;
	syncSettingsType: FtpSettingsTypeEnum;
};

enum UpdateExistingEnum {
	update = 'update',
	ignore = 'ignore',
}

const locale = {
	it,
	en: enUS,
};

export const SyncOptions: FC<SyncOptionsPropsType> = ({ ftpSettingsData, syncSettingsType }) => {
	const { t } = useTranslation();
	const { syncSettings } = ftpSettingsData;
	const queryClient = useQueryClient();
	const { user } = useAuthStore();
	const [syncPeriod, setSyncPeriod] = useState<SyncPeriodEnum>(SyncPeriodEnum.day);
	const [updateExisting, setUpdateExisting] = useState<UpdateExistingEnum>(UpdateExistingEnum.update);

	const { mutate, isLoading } = useMutation({
		mutationKey: ['/post/sync-settings-connection'],
		mutationFn: postFtpSyncSettings,
	});

	useEffect(() => {
		if (syncSettings) {
			setUpdateExisting(
				syncSettings.updateExisting ? UpdateExistingEnum.update : UpdateExistingEnum.ignore,
			);
			setSyncPeriod(syncSettings.syncPeriod);
		}
	}, []);

	const enableSettingsHandler = (isEnabled: boolean) => {
		const commonValues = {
			isEnabled,
			syncSettingsType,
			syncPeriod: syncPeriod,
		};

		const mutationValues =
			syncSettingsType === FtpSettingsTypeEnum.partners
				? { ...commonValues, updateExisting: updateExisting === UpdateExistingEnum.update }
				: { ...commonValues };

		mutate(mutationValues, {
			onSuccess: async () => {
				await queryClient.refetchQueries({ queryKey: ['get/sync-settings'] });
				enqueueSnackbar({
					message: t('sync_settings.sync_options.success_updating'),
					variant: 'success',
				});
			},
			onError: (err) => {
				const errorMessage = getErrorMessage(err);
				enqueueSnackbar({ message: errorMessage, variant: 'error' });
			},
		});
	};

	const renderSyncStatus = () => {
		if (syncSettings?.isEnabled) {
			return (
				<>
					<Icon id={'done_ring_round'} color={ColorPalette.jungleGreen} />
					{t('sync_settings.sync_options.status.enabled')}
				</>
			);
		}
		return (
			<>
				<Icon id={'dell_fill'} color={ColorPalette.violetRed} />
				{t('sync_settings.sync_options.status.disabled')}
			</>
		);
	};

	const renderLastSyncDetails = () => {
		if (syncSettings?.lastSyncDetails?.status === LastSyncStatusEnum.success) {
			return (
				<>
					<Icon id={'done_ring_round'} color={ColorPalette.jungleGreen} />
					{t('sync_settings.sync_options.status.last_sync', {
						value: format(new Date(syncSettings.lastSyncDetails.processedAt), 'dd MMMM yyyy', {
							locale: user?.language ? locale[user?.language] : it,
						}),
					})}
				</>
			);
		} else if (syncSettings?.lastSyncDetails?.status === LastSyncStatusEnum.error) {
			return (
				<>
					<Icon id={'dell_fill'} color={ColorPalette.violetRed} />
					{t('sync_settings.sync_options.status.last_sync', {
						value: format(new Date(syncSettings.lastSyncDetails.processedAt), 'dd MMMM yyyy', {
							locale: user?.language ? locale[user?.language] : it,
						}),
					})}{' '}
					{parseError(syncSettings?.lastSyncDetails?.errorMessage)}
				</>
			);
		} else {
			return null;
		}
	};

	if (isLoading) return <Loader />;

	return (
		<>
			<Paper
				sx={{
					width: '100%',
					padding: '25px 20px',
					display: 'flex',
					flexDirection: 'column',
					gap: '30px',
				}}
			>
				<Typography variant={'h5'} color={ColorPalette.astronaut}>
					{t('sync_settings.sync_options.title')}
				</Typography>
				<Select
					label={t('sync_settings.sync_options.labels.sync_every') as string}
					value={syncPeriod}
					placeholder={t('sync_settings.sync_options.select_options.day')}
					onSelect={(value) => setSyncPeriod(value as SyncPeriodEnum)}
					options={syncPeriodOptions}
					sx={{ margin: 0 }}
					required
				/>
				{syncSettingsType === FtpSettingsTypeEnum.partners && (
					<Select
						label={t('sync_settings.sync_options.labels.partner_already_present') as string}
						value={updateExisting}
						placeholder={t('sync_settings.sync_options.select_options.update')}
						onSelect={(value) => setUpdateExisting(value as UpdateExistingEnum)}
						options={subscriptionSelectOptions}
						sx={{ margin: 0 }}
						required
					/>
				)}
			</Paper>
			<Paper
				sx={{
					width: '100%',
					padding: '25px 20px',
					display: 'flex',
					flexDirection: 'column',
					gap: '15px',
				}}
			>
				<Typography variant={'h5'} color={ColorPalette.astronaut}>
					{t('shared_texts.table_columns.status')}
				</Typography>

				<Typography
					variant={'body2'}
					color={syncSettings?.isEnabled ? ColorPalette.astronaut : ColorPalette.violetRed}
					sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}
				>
					{renderSyncStatus()}
				</Typography>

				{syncSettings?.lastSyncDetails && (
					<Typography
						variant={'body2'}
						color={
							syncSettings?.lastSyncDetails?.status === LastSyncStatusEnum.success
								? ColorPalette.astronaut
								: ColorPalette.violetRed
						}
						sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}
					>
						{renderLastSyncDetails()}
					</Typography>
				)}
			</Paper>
			<Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '20px' }}>
				<Button
					size={'small'}
					variant={'outlined'}
					onClick={() => enableSettingsHandler(false)}
					sx={{
						'&.MuiButton-outlined': {
							'backgroundColor': 'transparent',
							'border': '1px solid transparent',
							'color': ColorPalette.astronaut,
							'boxShadow': 'none',
							'&.MuiButton-root:hover': {
								backgroundColor: ColorPalette.periwinkle,
								color: ColorPalette.astronaut,
								boxShadow: 'none',
							},
						},
					}}
				>
					{t('shared_texts.disable')}
				</Button>
				<Button size={'small'} onClick={() => enableSettingsHandler(true)}>
					{t('shared_texts.enable')}
				</Button>
			</Box>
		</>
	);
};

const syncPeriodOptions: Array<SelectOption> = [
	{
		value: SyncPeriodEnum.day,
		label: 'sync_settings.sync_options.select_options.day',
	},
	{
		value: SyncPeriodEnum.week,
		label: 'sync_settings.sync_options.select_options.week',
	},
	{
		value: SyncPeriodEnum.month,
		label: 'sync_settings.sync_options.select_options.month',
	},
];

const subscriptionSelectOptions: Array<SelectOption> = [
	{
		value: UpdateExistingEnum.update,
		label: 'sync_settings.sync_options.select_options.update',
	},
	{
		value: UpdateExistingEnum.ignore,
		label: 'sync_settings.sync_options.select_options.ignore',
	},
];
