import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, styled } from '@mui/material';
import { Paper } from 'shared/ui/Paper';
import { Button } from 'shared/ui/Button';
import { ColorPalette } from 'shared/consts/colorPalette';
import selectedExtension from 'shared/assets/selected-extension.png';

type ExCardPropsType = {
	title: string;
	description: string;
	isSelected: boolean;
	applyAction: () => void;
};

export const ExCard: FC<ExCardPropsType> = ({ title, description, isSelected, applyAction }) => {
	const { t } = useTranslation();
	return (
		<CardStyled>
			<CardHeaderStyled>{t(title)}</CardHeaderStyled>
			<CardBodyStyled>
				<Box>{t(description)}</Box>
				{isSelected ? (
					<img src={selectedExtension} alt="selected-extension" height={60} />
				) : (
					<Button
						variant={'contained'}
						onClick={applyAction}
						size="small"
						sx={{
							width: 'inherit!important',
							height: '40px',
							fontSize: '14px',
						}}
					>
						{t('shared_texts.add')}
					</Button>
				)}
			</CardBodyStyled>
		</CardStyled>
	);
};

const CardStyled = styled(Paper)`
	padding: 0;
	overflow: hidden;
	width: 400px;
	min-width: 340px;
	text-align: center;
	display: flex;
	flex-direction: column;
`;

const CardHeaderStyled = styled(Box)`
	background-color: ${ColorPalette.royalBlue};
	color: ${ColorPalette.white};
	padding: 15px;
	font-size: 20px;
`;

const CardBodyStyled = styled(Box)`
	padding: 20px;
	color: ${ColorPalette.baliHai};
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: space-between;
	height: 100%;
	gap: 25px;
`;
