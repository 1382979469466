import {
	Collapse,
	Table,
	TableCell,
	TableBody as MUITableBody,
	TableHead,
	TableProps,
	TableRow,
	Typography,
	Paper,
} from '@mui/material';
import React from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Shadow } from 'shared/consts/shadow';
import { Icon } from 'shared/ui/Icon';
import { TableBody } from 'shared/ui/tables/_common/ui/TableBody';
import { Column } from 'shared/ui/tables/_common/types';

interface Props<T> extends TableProps {
	columns: Array<Column<T>>;
	rows: Array<T & { id: string }>;
	title?: string;
}
export function CollapsableTableInner<T>({ columns, rows, title }: Props<T>) {
	const [open, setOpen] = React.useState(true);

	const handleClickOpenRow = () => setOpen((prevState) => !prevState);

	return (
		<Table
			sx={{
				borderRadius: '20px',
				marginBottom: '20px',
				boxShadow: Shadow['1'],
				overflow: 'hidden',
			}}
		>
			<TableHead>
				<TableRow onClick={handleClickOpenRow}>
					<TableCell
						sx={{
							height: '51px',
							padding: '0 24px',
							background: ColorPalette.royalBlue,
							borderRadius: '20px 20px 0px 0px',
							borderBottom: 'none',
							transition: 'border-radius .3s',
							cursor: 'pointer',
							display: 'flex',
							justifyContent: 'letf',
							alignItems: 'center',
						}}
						colSpan={columns.length}
					>
						<Icon
							id={'custom_arrow_down'}
							style={{
								width: '8px',
								height: '8px',
								color: ColorPalette.white,
								transform: open ? 'rotate(-180deg)' : 'none',
								transition: 'transform .3s',
							}}
						/>
						<Typography variant={'body2'} color={ColorPalette.white} sx={{ marginLeft: '30px' }}>
							{title}
						</Typography>
					</TableCell>
				</TableRow>
			</TableHead>
			<MUITableBody>
				<TableRow>
					<TableCell
						sx={{ height: 'fit-content', padding: 0, borderBottom: 'none' }}
						colSpan={columns.length}
					>
						<Collapse in={open} timeout="auto" unmountOnExit>
							<Paper
								sx={{
									padding: '0',
									boxShadow: 'none',
									backgroundColor: ColorPalette.white,
									borderRadius: '0 0 20px 20px',
									overflow: 'hidden',
								}}
							>
								<Table>
									<TableBody columns={columns} rows={rows} />
								</Table>
							</Paper>
						</Collapse>
					</TableCell>
				</TableRow>
			</MUITableBody>
		</Table>
	);
}
