import { FC } from 'react';
import { Box, BoxProps, Typography } from '@mui/material';
import { ColorPalette } from 'shared/consts/colorPalette';
import { getHumanReadableFileSize } from 'shared/helpers/getHumanReadableFileSize';
import { Checkbox } from 'shared/ui/Checkbox';
import filePreview from 'shared/assets/file_preview.png';

interface Props extends BoxProps {
	fileName: string;
	fileSize: number;
	checked: boolean;
	onToggle: (fileName: string) => void;
	exportFtp: boolean;
}

export const ItemFileCard: FC<Props> = ({ sx, fileName, fileSize, checked, onToggle, exportFtp }) => {
	const fileExt = fileName.split('.').pop()?.toUpperCase();

	return (
		<Box sx={{ display: 'flex', flexDirection: 'row', ...sx }}>
			{!exportFtp && fileExt && ['PNG', 'JPEG', 'TIFF', 'PDF', 'JPG', 'TIF'].includes(fileExt) ? (
				<>
					<Checkbox checked={checked} onChange={() => onToggle(fileName)} />
					<img
						src={filePreview}
						alt={'file-preview'}
						style={{ width: '62px', height: '62px', margin: '0 8px' }}
					/>
				</>
			) : (
				<img
					src={filePreview}
					alt={'file-preview'}
					style={{ width: '62px', height: '62px', margin: '0 8px 0 28px' }}
				/>
			)}

			<Box sx={{ display: 'flex', flexDirection: 'column' }}>
				<Typography component={'p'} variant={'caption'} color={ColorPalette.baliHai}>
					{fileName ?? '-'}
				</Typography>
				<Typography variant={'caption'} color={ColorPalette.ebonyClay}>
					{fileSize ? getHumanReadableFileSize(fileSize, true) : '- B'}
				</Typography>
			</Box>
		</Box>
	);
};
