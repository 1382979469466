import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { EmptyTable } from 'shared/ui/EmptyTable';
import { TableWithHeader } from 'shared/ui/tables/TableWithHeader';
import { SubscriptionsTableColumns } from 'widgets/admin/UserDetails/ui/tabs/Subscriptions/SubscriptionsTable/SubscriptionsTableColumns';
import { TableColumnsType } from '../index';

type Props = {
	subscriptions: Array<TableColumnsType>; // TODO change type
};

export const SubscriptionsTable: FC<Props> = ({ subscriptions }) => {
	const { t } = useTranslation();
	const preparedSubscriptions = useMemo(() => {
		if (!subscriptions.length) return [];
		return subscriptions;
	}, [subscriptions]);

	return (
		<>
			{subscriptions?.length ? (
				<TableWithHeader
					columns={SubscriptionsTableColumns}
					rows={preparedSubscriptions.map((subscription) => ({
						...subscription,
						id: subscription._id,
					}))}
				/>
			) : (
				<EmptyTable text={t('admin.users.details.no_sub_yet')} />
			)}
		</>
	);
};
