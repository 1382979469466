import { FunctionComponent } from 'react';
import { useSignInStore } from 'widgets/auth/signUp/_MainModal/model/state';
import { BackButton } from 'shared/ui/BackButton';
import { SignUpStep1 } from 'widgets/auth/signUp/_MainModal/ui/steps/Step1';
import { SignUpStep2 } from 'widgets/auth/signUp/_MainModal/ui/steps/Step2';
import { SignUpStep3 } from 'widgets/auth/signUp/_MainModal/ui/steps/Step3';

export const SUMainModal: FunctionComponent = () => {
	const { step, goToPrevStep } = useSignInStore();
	return (
		<>
			{step > 1 && <BackButton action={goToPrevStep} />}
			{step === 1 && <SignUpStep1 />}
			{step === 2 && <SignUpStep2 />}
			{step === 3 && <SignUpStep3 />}
		</>
	);
};
