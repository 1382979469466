import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { Dialog, Typography, Box } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { postPartnerViaFile } from 'entities/Partners/api';
import { ColorPalette } from 'shared/consts/colorPalette';
import { ActionsForDialog } from 'shared/ui/ActionsForDialog';
import { Paper } from 'shared/ui/Paper';
import { SheetTable } from 'shared/ui/SheetTable';
import { DnDImportFiles } from 'shared/ui/DndImportFiles';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { Loader } from 'shared/ui/Loader';

interface ImportCsvModalPropsType {
	open: boolean;
	handleClose: () => void;
	setIsOpenInfoModal: () => void;
}

export const ImportCsvModal: FC<ImportCsvModalPropsType> = ({ open, handleClose, setIsOpenInfoModal }) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const [file, setFile] = useState<File>();

	const sheetPreviewArr = [
		{ cellContent: t('partners.csv_cells.company_name') },
		{ cellContent: t('partners.csv_cells.vat_number') },
		{ cellContent: t('partners.csv_cells.client_supplier') },
		{ cellContent: t('partners.csv_cells.address') },
		{ cellContent: t('partners.csv_cells.erpCode') },
		{ cellContent: t('partners.csv_cells.replacePartnerInfo') },
		{ cellContent: t('partners.csv_cells.replaceCompanyInfo') },
		{ cellContent: t('partners.csv_cells.assertionSet') },
		{ cellContent: t('partners.csv_cells.modelName') },
	];

	const { mutate: postViaFileMutate, isLoading: postIsLoading } = useMutation({
		mutationKey: ['post/partner-via-csv'],
		mutationFn: postPartnerViaFile,
	});

	const uploadCsvHandler = () => {
		if (file) {
			postViaFileMutate(
				{ file },
				{
					onSuccess: () => {
						queryClient.refetchQueries({ queryKey: ['get/partners'] });
						enqueueSnackbar({
							message: t('partners.snackbar.partners_created_siccess'),
							variant: 'success',
						});
						setIsOpenInfoModal();
						handleClose();
						setFile(undefined);
					},
					onError: (err) => {
						const errorMessage = getErrorMessage(err);
						enqueueSnackbar({ message: errorMessage, variant: 'error' });
						setFile(undefined);
					},
				},
			);
		}
	};

	const uploadedFilesHandler = (files: Array<File>) => {
		if (files && files?.length > 1) {
			enqueueSnackbar({ message: t('partners.modals.import_csv_file_only_one'), variant: 'error' });
		} else if (files) {
			setFile(files[0]);
		}
	};

	return (
		<Dialog
			open={open}
			onClose={() => {
				handleClose();
				setFile(undefined);
			}}
			PaperComponent={Paper}
			PaperProps={{ title: t('shared_texts.csv_modal_title') as string }}
			sx={{
				'width': '65vw',
				'margin': '0 auto',
				'& .MuiPaper-root': {
					minWidth: '100%',
				},
			}}
		>
			{postIsLoading ? (
				<Loader />
			) : (
				<Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
					<Typography variant={'body1'} color={ColorPalette.baliHai}>
						{t('partners.modals.import_csv_creation_desctiption')}
					</Typography>

					<DnDImportFiles
						setUploadedFiles={uploadedFilesHandler}
						fileDescription={t('partners.modals.import_csv_file_type')}
						uploaded={Boolean(file)}
					/>

					<SheetTable cells={sheetPreviewArr} />

					<Typography variant={'body1'} color={ColorPalette.baliHai}>
						{t('partners.modals.import_csv_creation_desctiption_2')}
					</Typography>
					<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Box sx={{ width: '400px' }}>
							<ActionsForDialog
								cancelLabel={t('shared_texts.cancel')}
								cancelAction={handleClose}
								applyLabel={t('shared_texts.save')}
								applyAction={uploadCsvHandler}
							/>
						</Box>
					</Box>
				</Box>
			)}
		</Dialog>
	);
};
