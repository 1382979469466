import axios from 'axios';
import { signOut } from 'processes/auth/helpers/signOut';
import { Config } from 'shared/config/_common';
import { getAccessToken } from 'shared/helpers/getAccessToken';

export const axiosInstance = axios.create({
	baseURL: Config.API_URL,
	headers: {
		'Content-Type': 'application/json',
	},
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
	function (config) {
		// Do something before request is sent
		const token = getAccessToken();
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error);
	},
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
	function (response) {
		// Any status code that lie within the range of 2xx cause this function to trigger
		// Do something with response data
		return response;
	},
	function (error) {
		if (error.response) {
			// handle server error state
			switch (error.response.status) {
				case 401:
					signOut();
					break;
				default:
					// eslint-disable-next-line no-console
					console.error('Request made and server responded with a non-2xx status code:\n', error);
					break;
			}
		} else if (error.request) {
			// handle network error state
			// eslint-disable-next-line no-console
			console.error('Request made but no response received:\n', error);
		} else {
			// handle general error state
			// eslint-disable-next-line no-console
			console.error('An error occurred while setting up the request:\n', error);
		}

		return Promise.reject(error);
	},
);
