import { Option } from 'shared/ui/OptionsDropdown/types';

export const SortOptionsList: Array<Option> = [
	{
		label: 'co_users.filter_drop_down.label_1',
		value: 'createdAt:desc',
	},
	{
		label: 'co_users.filter_drop_down.label_2',
		value: 'createdAt:asc',
	},
	{
		label: 'co_users.filter_drop_down.label_3',
		value: 'joinedAt:asc',
	},
	{
		label: 'co_users.filter_drop_down.label_4',
		value: 'joinedAt:desc',
	},
];
