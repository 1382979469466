import { FunctionComponent } from 'react';
import { useField } from 'formik';
import { Select, SelectProps } from 'shared/ui/Select';

interface Props extends SelectProps {
	name: string;
}

export const FormSelect: FunctionComponent<Props> = ({ name, ...props }) => {
	const [, meta] = useField({ name });

	return <Select {...props} errorText={(meta.touched && meta.error) || ''} />;
};
