import { FormControl, InputBaseProps, MenuItem, Select as MUISelect } from '@mui/material';
import { FC } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Icon } from 'shared/ui/Icon';
import { InputLabel } from 'shared/ui/InputLabel';

type SelectOption = {
	data: {
		index: number;
	};
	name: string;
};

export interface Props extends Omit<InputBaseProps, 'onSelect'> {
	label: string;
	value: string | string[] | undefined;
	placeholder: string;
	onSelect: (value: string | string[] | undefined) => void;
	options: Array<SelectOption>;
	multiple?: boolean;
}

export const CustomModelSelect: FC<Props> = ({
	sx,
	label,
	value,
	placeholder,
	onSelect,
	options,
	multiple,
}) => {
	return (
		<FormControl variant={'standard'} fullWidth={true} sx={{ marginBottom: '40px', ...sx }}>
			<InputLabel name={label} label={label} />
			<MUISelect
				multiple={multiple}
				value={value}
				IconComponent={() => (
					<Icon
						id={'custom_arrow_down'}
						style={{ width: '18px', height: '9px', marginRight: '10px' }}
						color={ColorPalette.astronaut}
					/>
				)}
				displayEmpty={true}
				renderValue={() => {
					return (
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						options.find((option: any) => {
							return option?.data?.index === value;
						})?.name || placeholder
					);
				}}
				placeholder={placeholder}
				onChange={(event) => onSelect(event.target.value)}
				sx={{
					'position': 'relative',
					'height': '60px',
					'margin': '0',
					'border': `1px solid ${ColorPalette.periwinkle}`,
					'borderRadius': '10px',

					'color': ColorPalette.astronaut,
					'fontWeight': 600,
					'fontSize': '14px',
					'lineHeight': '18px',

					'&:placeholder': {
						color: ColorPalette.baliHai,
					},
					'& .MuiInputBase-input': {
						display: 'flex',
						alignItems: 'center',
						height: '100%',
						padding: '20px 16px',
					},
					'& .MuiInputBase-input:focus': {
						backgroundColor: 'transparent',
					},
					'&:before, &:after': {
						display: 'none',
					},
					'label + &': {
						marginTop: '18px',
					},
				}}
			>
				{/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
				{options.map((option: any) => (
					<MenuItem key={String(option?.data?.index)} value={option?.data?.index}>
						{option?.name}
					</MenuItem>
				))}
			</MUISelect>
		</FormControl>
	);
};
