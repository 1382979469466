import { Box, Typography } from '@mui/material';
import { OptionsDropdown } from 'shared/ui/OptionsDropdown';
import { Column } from 'shared/ui/tables/_common/types';
import { ColorPalette } from 'shared/consts/colorPalette';

export type CategoriesTableType = {
	id: string;
	img: string;
	title: string;
	subtitle: string;
	deleteHandler: (id: string) => void;
};

export const CategoriesTableColumns: Array<Column<CategoriesTableType>> = [
	{
		field: 'categoryImage',
		commonCellSettings: {
			style: {
				width: '5%',
			},
		},
		bodyCellSettings: {
			renderCell: (category) => (
				<img src={category?.img} alt={'category-img'} style={{ height: '40px' }} />
			),
		},
	},
	{
		field: 'categoryTitle',
		commonCellSettings: {
			style: {
				width: '100%',
			},
		},
		bodyCellSettings: {
			renderCell: (category) => (
				<Box sx={{ display: 'flex', flexDirection: 'column' }}>
					<Typography>{category?.title}</Typography>
					<Typography variant="overline1" color={ColorPalette.baliHai}>
						{category?.subtitle}
					</Typography>
				</Box>
			),
		},
	},
	{
		field: 'options',
		commonCellSettings: {
			style: {
				minWidth: '100px',
				maxWidth: '100px',
			},
		},
		bodyCellSettings: {
			renderCell: (category) => (
				<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
					<OptionsDropdown
						iconId={'more_horizontal'}
						optionList={[
							{
								label: 'shared_texts.table_dropdown.remove',
								value: 'Remove partner',
								action: () => {
									category?.deleteHandler(category.id);
								},
							},
						]}
					/>
				</Box>
			),
		},
	},
];
