import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { BackButton } from 'shared/ui/BackButton';
import { ColorPalette } from 'shared/consts/colorPalette';
import { isEmptyObject } from 'shared/helpers/isEmptyObject';
import { isEmptyObjectValues } from 'shared/helpers/isEmptyObjectValues';
import { InputField } from 'shared/ui/InputField';
import { Paper } from 'shared/ui/Paper';
import { Button } from 'shared/ui/Button';
import { sendRestorePasswordEmail } from 'entities/Auth/api';
import { useForgotPasswordStore } from 'widgets/auth/forgotPassword/_MainModal/model/state';
import { Footer } from 'widgets/auth/forgotPassword/_MainModal/ui/Footer';
import { ForgotPasswordValidationSchema } from 'widgets/auth/forgotPassword/_MainModal/consts/validation';
import { ForgotPassword } from 'widgets/auth/forgotPassword/_MainModal/types';

export const FPMainModal: FunctionComponent = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { updateEmailToSend } = useForgotPasswordStore();

	const initialValues = {
		email: '',
	};

	const handleSubmit = (values: ForgotPassword): void => {
		updateEmailToSend({ email: values.email });
		sendRestorePasswordEmail(values)
			.then((res) => {
				if (res?.data?.success) {
					navigate('/forgot-password/email-sent', { state: { access: true } });
				}
			})
			.catch((err) => {
				const defaultErrorMessage = t('shared_texts.smth_went_wrong');
				const errorMessage = getErrorMessage(err, defaultErrorMessage);
				enqueueSnackbar({
					message: errorMessage,
					variant: 'error',
				});
			});
	};

	return (
		<Paper
			title={t('auth.forgot_password') as string}
			sx={{
				'& *': {
					userSelect: 'none',
				},
			}}
		>
			<BackButton action={() => navigate(-1)} />
			<Typography variant={'body1'} color={ColorPalette.baliHai} sx={{ marginBottom: '35px' }}>
				{t('auth.validation.enter_email')}
			</Typography>

			<Formik
				initialValues={initialValues}
				validationSchema={ForgotPasswordValidationSchema}
				onSubmit={handleSubmit}
			>
				{({ errors, values }: FormikProps<ForgotPassword>) => (
					<Form>
						<InputField
							name={'email'}
							label={'Email'}
							placeholder={t('auth.validation.enter_email')}
							required
						/>
						<Button
							disabled={!isEmptyObject(errors) || isEmptyObjectValues(values)}
							type={'submit'}
							fullWidth
						>
							{t('auth.send_link')}
						</Button>
					</Form>
				)}
			</Formik>
			<Footer />
		</Paper>
	);
};
