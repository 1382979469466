import { Paper as MUIPaper, PaperProps } from '@mui/material';
import { FunctionComponent } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Shadow } from 'shared/consts/shadow';

interface Props extends PaperProps {}

export const InfoContainer: FunctionComponent<Props> = ({ sx, ...props }) => {
	return (
		<MUIPaper
			sx={{
				width: '503px',
				height: 'fit-content',
				padding: '56px 45px',
				backgroundColor: ColorPalette.white,
				boxShadow: Shadow['2'],
				borderRadius: '20px',
				position: 'relative',
				...sx,
			}}
			{...props}
		>
			{props.children}
		</MUIPaper>
	);
};
