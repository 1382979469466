import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FileType } from 'entities/Files/types';
import { FC, useMemo } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';
import { isEmptyObject } from 'shared/helpers/isEmptyObject';
import { CollapsableTable } from 'shared/ui/tables/CollapsableTable';
import { useExtension } from 'shared/hooks/useExtension';
import {
	FilesDashboardColumns,
	FileForDashboard,
} from 'widgets/files/FilesDashboard/consts/DashboardColumns';
import { useAssertionSetsStore } from 'widgets/Extensions/Details/ui/AssertionSets/model/state';
import { ExtensionsEnum } from 'entities/Extensions/enums';

interface Props {
	files: Record<string, Array<FileType>>;
	filesCount: number;
	selectedFiles: Array<string>;
	handleToggleFile: (fileId: string) => void;
	handleToggleAllFiles: (refresh?: boolean) => void;
	handleEditFile: (fileId: string) => void;
	handleExportFile: (fileId?: string) => void;
	handleDeleteFile: (fileId?: string) => void;
	handleAssertions: (fileId?: string) => void;
}

export const FilesDashboard: FC<Props> = ({
	files,
	filesCount,
	selectedFiles,
	handleToggleFile,
	handleToggleAllFiles,
	handleEditFile,
	handleExportFile,
	handleDeleteFile,
	handleAssertions,
}) => {
	const { t } = useTranslation();
	const { partnerId } = useParams();
	const { assertionsSets } = useAssertionSetsStore();
	const { getExtension } = useExtension();

	const preparedForDashboardFiles = useMemo(() => {
		const preparedData: Record<string, Array<FileForDashboard>> = {};
		files &&
			Object.keys(files)?.forEach((key) => {
				const subFiles = files[key];
				preparedData[`${key} \u00A0\u00A0\u00A0 ${subFiles.length} File(-s)`] = subFiles.map(
					(file) => ({
						...file,
						id: file._id,
						filesCount,
						selectedFiles,
						assertionsIsEnabled: !!getExtension(ExtensionsEnum.assertions_sets)?.isEnabled,
						assertionSetsId:
							assertionsSets?.find((item) => item._id === file?.assertionSetsId)?.name || '-',
						toggleFile: handleToggleFile,
						toggleAllFiles: handleToggleAllFiles,
						edit: handleEditFile,
						export: handleExportFile,
						deleteFile: handleDeleteFile,
						assertionsHandler: handleAssertions,
						isPartnerSection: Boolean(partnerId),
					}),
				);
			});

		return preparedData;
	}, [
		files,
		filesCount,
		selectedFiles,
		handleToggleFile,
		handleToggleAllFiles,
		handleEditFile,
		handleExportFile,
		handleDeleteFile,
		assertionsSets,
	]);

	return (
		<>
			{files && !isEmptyObject(files) ? (
				<CollapsableTable columns={FilesDashboardColumns} subTables={preparedForDashboardFiles} />
			) : (
				<Typography
					variant={'subtitle1'}
					color={ColorPalette.astronaut}
					sx={{ margin: 'auto', width: '100%', textAlign: 'center' }}
				>
					{t('shared_texts.no_files')}
				</Typography>
			)}
		</>
	);
};
