import { Box } from '@mui/material';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { CategoryTypeEnum } from 'entities/Files/types';
import { FC, useMemo } from 'react';
import { enqueueSnackbar } from 'notistack';
import { Navigate, useSearchParams } from 'react-router-dom';
import { ActionsRow } from 'widgets/importFiles/importFilesDirect/ui/ActionsRow';
import { DnDImportFiles } from 'widgets/importFiles/importFilesDirect/ui/DnDImportFiles';
import { ImportFilesDashboard } from 'widgets/importFiles/common/ui/ImportFilesDashboard';
import { PageHeader } from 'shared/ui/PageHeader';
import { useCustomModelStore } from 'widgets/customModel/state';
import { useImportFilesStore } from 'widgets/importFiles/state';
import { getImportSessions } from 'entities/ImportSessions/api';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';

export const ImportFilesDirect: FC = () => {
	const { t } = useTranslation();
	const { data, setData } = useImportFilesStore();
	const [searchParams] = useSearchParams();
	const { currentCustomModelId } = useCustomModelStore();
	const categoryType = searchParams.get('category');

	const query = useQuery({
		queryKey: ['get/import-sessions'],
		queryFn: () => getImportSessions(),
		onSuccess: (response) => {
			setData(response.data);
			const isUploading = response.data
				.map(
					(item) =>
						item.importSessionFiles.filter((sessionItem) => sessionItem.status === 'UPLOADING')
							.length,
				)
				.filter((item) => item).length;

			if (isUploading) {
				setTimeout(() => {
					query.refetch();
				}, 6000);
			}
		},
		onError: (err) => {
			const errorMessage = getErrorMessage(err);
			enqueueSnackbar({
				message: errorMessage,
				variant: 'error',
			});
		},
	});

	const importSession = useMemo(() => {
		return data?.find((session) => session.uploadSource === 'DIRECT');
	}, [data]);

	const files = useMemo(() => {
		return importSession ? importSession.importSessionFiles : [];
	}, [importSession]);

	if (!categoryType) {
		return <Navigate to={'/'} />;
	}

	return (
		<Box sx={{ marginBottom: '70px' }}>
			<PageHeader
				title={t('start_recognition.upload_files_section.title')}
				description={t('start_recognition.upload_files_section.description')}
			/>
			{/* TODO replace this component with shared/ui/DndImportFiles */}
			<DnDImportFiles
				categoryType={categoryType as CategoryTypeEnum}
				modelId={currentCustomModelId}
				directImport
			/>
			<ImportFilesDashboard categoryType={importSession?.category} files={files} />
			{importSession && <ActionsRow importSession={importSession} />}
		</Box>
	);
};
