import { Box, BoxProps, Typography } from '@mui/material';
import { format } from 'date-fns';
import { FileType } from 'entities/Files/types';
import { FC } from 'react';
import filePreview from 'shared/assets/file_preview.png';
import { ColorPalette } from 'shared/consts/colorPalette';
import { getHumanReadableFileSize } from 'shared/helpers/getHumanReadableFileSize';
import { Checkbox } from 'shared/ui/Checkbox';

interface Props extends BoxProps {
	file?: FileType;
	checked: boolean;
	onToggle: (fileId: string) => void;
}
export const FileCard: FC<Props> = ({ sx, file, checked, onToggle }) => {
	return (
		<Box sx={{ display: 'flex', flexDirection: 'row', ...sx }}>
			<Checkbox checked={checked} onChange={() => onToggle(file?._id as string)} />
			<img
				src={filePreview}
				alt={'file-preview'}
				style={{ width: '62px', height: '62px', margin: '0 8px' }}
			/>
			<Box sx={{ display: 'flex', flexDirection: 'column' }}>
				<Typography component={'p'} variant={'caption'} color={ColorPalette.ebonyClay}>
					{file?.createdAt ? format(new Date(file?.createdAt), 'dd.MM.yyyy\u00A0HH:mm') : '-'}
				</Typography>
				<Typography variant={'caption'} color={ColorPalette.ebonyClay}>
					{file?.size ? getHumanReadableFileSize(file.size, true) : '- B'}
				</Typography>
			</Box>
		</Box>
	);
};
