import { Drawer as MUIDrawer, DrawerProps, Box } from '@mui/material';
import { FunctionComponent } from 'react';

interface Props extends DrawerProps {}

export const Drawer: FunctionComponent<Props> = ({ sx, onClose, children, ...props }) => {
	return (
		<MUIDrawer
			anchor="right"
			sx={{
				'.MuiDrawer-paper': {
					top: '20px',
					bottom: '20px',
					right: '20px',
					height: 'inherit',
					width: '500px',
					borderRadius: '20px',
					padding: '30px',
				},
				...sx,
			}}
			onClose={onClose}
			{...props}
		>
			{children}
		</MUIDrawer>
	);
};
