import { FC } from 'react';
import { Dialog, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/ui/Button';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Paper } from 'shared/ui/Paper';

interface SelectArticleCreationModalPropsType {
	open: boolean;
	handleClose: () => void;
	openManualModal: () => void;
	openImportCsvModal: () => void;
}

export const SelectArticleCreationModal: FC<SelectArticleCreationModalPropsType> = ({
	open,
	handleClose,
	openManualModal,
	openImportCsvModal,
}) => {
	const { t } = useTranslation();
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			PaperComponent={Paper}
			PaperProps={{ title: t('shared_texts.add') as string }}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: '30px',
				}}
			>
				<Typography variant={'body1'} color={ColorPalette.baliHai}>
					{t('extensions.modals.warehouse_select_creation_description')}
				</Typography>

				<Button fullWidth onClick={openManualModal}>
					{t('shared_texts.add_manualy')}
				</Button>
				<Button fullWidth onClick={openImportCsvModal}>
					{t('shared_texts.add_via_csv')}
				</Button>
			</Box>
		</Dialog>
	);
};
