import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ImportSession } from 'entities/ImportSessions/types';
import { FC, useMemo } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';
import { TableWithHeader } from 'shared/ui/tables/TableWithHeader';
import { SessionsDashboardColumns } from 'widgets/startRecognition/ui/SessionsDashboard/consts/DashboardColumns';

interface Props {
	sessions: Array<ImportSession>;
}

export const SessionsDashboard: FC<Props> = ({ sessions }) => {
	const { t } = useTranslation();
	const preparedSessions = useMemo(() => {
		return [
			{
				uploadSource: 'DIRECT',
				source_title: t('shared_texts.table_columns.from_pc'),
				...sessions.find((session) => session.uploadSource === 'DIRECT'),
			},
			{
				uploadSource: 'FTP',
				source_title: t('shared_texts.table_columns.from_ftp'),
				...sessions.find((session) => session.uploadSource === 'FTP'),
			},
		] as Array<ImportSession & { source_title: string }>;
	}, [sessions]);

	return (
		<>
			<Typography variant={'h5'} color={ColorPalette.astronaut} sx={{ mt: '44px', mb: '24px' }}>
				{t('start_recognition.import_session_table.title')}
			</Typography>
			<TableWithHeader
				columns={SessionsDashboardColumns}
				rows={preparedSessions.map((session, index) => ({
					...session,
					id: session._id ?? index,
				}))}
			/>
		</>
	);
};
