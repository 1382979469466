import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from 'shared/ui/PageHeader';
import { PartnersWidget } from 'widgets/Partners';

export const Partners: FC = () => {
	const { t } = useTranslation();
	return (
		<>
			<PageHeader title={t('partners.title')} description={t('partners.description')} />
			<PartnersWidget />
		</>
	);
};
