import { FunctionComponent } from 'react';
import { TabButton } from 'shared/ui/TabButton';
import { usePersonalAccountStore } from 'widgets/PersonalAccount/model/state';
import { PersonaAccountTabs } from 'widgets/PersonalAccount/types';

interface Props {
	label: string;
	tab: PersonaAccountTabs;
}

export const Tab: FunctionComponent<Props> = ({ label, tab }) => {
	const { activeTab, changeActiveTab } = usePersonalAccountStore();
	const pathMatch = activeTab === tab;

	return <TabButton label={label} isActive={pathMatch} action={() => changeActiveTab(tab)} />;
};
