import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { TabButton } from 'shared/ui/TabButton';
import { SubTabType } from 'widgets/Subscriptions/context/SubscriptionsTabContext';
import { useSubTab } from 'widgets/Subscriptions/context/useSubTab';

const subscriptionsTabs = [
	{ type: SubTabType.CURRENT, name: 'subscriptions.tabs.tab_1' },
	{ type: SubTabType.BILLING, name: 'subscriptions.tabs.tab_2' },
	{ type: SubTabType.PAYMENT, name: 'subscriptions.tabs.tab_3' },
];

export const SubTabs: FC = () => {
	const { setTab, tab } = useSubTab();
	const { t } = useTranslation();

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'flex-start',
				alignItems: 'center',
				gap: '20px',
				marginBottom: '44px',
			}}
		>
			{subscriptionsTabs.map(({ type, name }) => (
				<TabButton key={type} label={t(name)} isActive={type === tab} action={() => setTab(type)} />
			))}
		</Box>
	);
};
