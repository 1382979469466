import { Dialog, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CoUser } from 'entities/CoUser/types';
import { FunctionComponent } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';
import { ActionsForDialog } from 'shared/ui/ActionsForDialog';
import { Paper } from 'shared/ui/Paper';

interface Props {
	coUser?: CoUser;
	open: boolean;
	handleClose: () => void;
	handleRemoveCoUser: () => void;
}

export const RemoveCoUserModal: FunctionComponent<Props> = ({
	coUser,
	open,
	handleClose,
	handleRemoveCoUser,
}) => {
	const { t } = useTranslation();
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			PaperComponent={Paper}
			PaperProps={{ title: 'Remove co-users?' }}
		>
			<Typography variant={'body1'} color={ColorPalette.baliHai}>
				{t('co_users.modal.remove.description', { value: coUser?.email })}
			</Typography>
			<Typography variant={'body1'} color={ColorPalette.baliHai} sx={{ margin: '24px 0 34px 0' }}>
				{t('co_users.modal.remove.description_2')}
			</Typography>
			<ActionsForDialog
				cancelLabel={t('shared_texts.cancel')}
				cancelAction={handleClose}
				applyLabel={t('shared_texts.yes')}
				applyAction={handleRemoveCoUser}
				applyActionDisabled={false}
			/>
		</Dialog>
	);
};
