import { IconButton } from '@mui/material';
import { format } from 'date-fns';
import { OptionsDropdown } from 'shared/ui/OptionsDropdown';
import { ModelsType } from 'entities/CustomModel/types';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Column } from 'shared/ui/tables/_common/types';
import { Icon } from 'shared/ui/Icon';
import { Shadow } from 'shared/consts/shadow';
import { TableTranslationLabel } from 'shared/ui/tables/_common/ui/TableTranslationLabel';

export const ModelsTableColumns: Array<
	Column<
		ModelsType & {
			openModal: (model: ModelsType) => void;
			removeModel: (model: ModelsType) => void;
			navigateToDetails: (model: ModelsType) => void;
		}
	>
> = [
	{
		field: 'endpoint',
		commonCellSettings: {
			style: {
				minWidth: '150px',
				maxWidth: '250px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="name" />,
		},
		bodyCellSettings: {
			prepareField: (model) => model?.name ?? '-',
		},
	},
	{
		field: 'modelBuildMode',
		commonCellSettings: {
			style: {
				minWidth: '150px',
				maxWidth: '250px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="model_type" />,
		},
		bodyCellSettings: {
			renderCell: (model) =>
				model?.modelBuildMode ? (
					<TableTranslationLabel path={`custom_model.model_types.${model?.modelBuildMode}`} />
				) : (
					'-'
				),
		},
	},

	{
		field: 'addingDate',
		commonCellSettings: {
			style: {
				minWidth: '150px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="total_file_qty" />,
		},
		bodyCellSettings: {
			prepareField: (model) => `${model?.totalFiles}` ?? '-',
		},
	},
	{
		field: 'createdDate',
		commonCellSettings: {
			style: {
				minWidth: '150px',
				maxWidth: '250px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="create_date" />,
		},
		bodyCellSettings: {
			prepareField: (model) =>
				model?.createdAt ? format(new Date(model?.createdAt), 'dd/MM/yyyy') : null ?? '-',
		},
	},
	{
		field: 'counterOfNeuralModels',
		commonCellSettings: {
			style: {
				minWidth: '150px',
				maxWidth: '250px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="status" />,
		},
		bodyCellSettings: {
			renderCell: (model) =>
				model?.status ? <TableTranslationLabel label={`model_status.${model?.status}`} /> : '-',
		},
	},
	{
		field: 'lastChanges',
		commonCellSettings: {
			style: {
				minWidth: '150px',
				maxWidth: '250px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="last_changes" />,
		},
		bodyCellSettings: {
			prepareField: (model) =>
				model?.updatedAt ? format(new Date(model?.updatedAt), 'dd/MM/yyyy') : null ?? '-',
		},
	},
	{
		field: 'options',
		commonCellSettings: {
			style: {
				minWidth: '100px',
				maxWidth: '7100px5px',
			},
		},
		bodyCellSettings: {
			renderCell: (model) => {
				return (
					<OptionsDropdown
						iconId={'more_horizontal'}
						optionList={[
							{
								label: 'shared_texts.edit',
								value: 'Edit info',
								action: () => {
									model?.openModal && model.openModal(model);
								},
							},
							{
								label: 'shared_texts.remove',
								value: 'Remove model',
								action: () => {
									model?.removeModel && model.removeModel(model);
								},
							},
						]}
					/>
				);
			},
		},
	},
	{
		field: 'redirect_option',
		commonCellSettings: {
			style: {
				minWidth: '100px',
				maxWidth: '100px',
			},
		},
		bodyCellSettings: {
			renderCell: (model) => {
				return (
					<IconButton
						sx={{
							'width': '20px',
							'height': '20px',
							'padding': 0,
							'boxSizing': 'content-box',
							'border': `5px solid ${ColorPalette.catskillWhite}`,
							'backgroundColor': ColorPalette.royalBlue,
							'boxShadow': Shadow['1'],
							'&:hover': {
								backgroundColor: ColorPalette.royalBlue,
							},
						}}
						onClick={() => {
							model?.navigateToDetails && model.navigateToDetails(model);
						}}
					>
						<Icon
							id={'custom_arrow_down'}
							style={{
								width: '18px',
								height: '9px',
								color: ColorPalette.white,
								transform: 'rotate(270deg) translateY(0.5px)',
							}}
						/>
					</IconButton>
				);
			},
		},
	},
];
