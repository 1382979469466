import { useAuthStore } from 'processes/auth/model/state';
import { FeaturesEnum } from 'entities/Company/types';

export function useFeature() {
	const { company } = useAuthStore();

	const getFeature = (featName: FeaturesEnum) =>
		company?.additionalFeatures?.find((item) => item.feature === featName);

	return { getFeature };
}
