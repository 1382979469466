import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { ImportSession } from 'entities/ImportSessions/types';

type ImportFilesStore = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: ImportSession[];
};

type ImportFilesActions = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setData: (data: ImportSession[]) => void;
	reset: () => void;
};

const initialState: ImportFilesStore = {
	data: [],
};

const importFilesStore = create<ImportFilesStore & ImportFilesActions>()(
	persist(
		(set) => ({
			...initialState,
			setData: (data) => set(() => ({ data })),
			reset: () => set(() => ({ ...initialState })),
		}),
		{
			name: 'importFilesStore',
		},
	),
);

export const useImportFilesStore = () => importFilesStore((state) => state);
