import { FC, useEffect, useState, useMemo } from 'react';
import { Box, TextField, FormControlLabel, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { styled, Dialog } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useMutation, useQuery } from 'react-query';
import { getChangingModelText } from 'entities/CustomModel/api';
import { getChangingText } from 'entities/ImportSessions/api';
import { FileType } from 'entities/Files/types';
import { ExtensionsEnum } from 'entities/Extensions/enums';
import { createAssertion, getAssertionsSets, findAssertion } from 'entities/Assertions/api';
import { ActionsForDialog } from 'shared/ui/ActionsForDialog';
import { useExtension } from 'shared/hooks/useExtension';
import { Select } from 'shared/ui/Select';
import { Input } from 'shared/ui/Input';
import { Icon } from 'shared/ui/Icon';
import { Paper } from 'shared/ui/Paper';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { Switch } from 'shared/ui/Switch';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Routes } from 'app/config/routes/consts';

interface Props {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	region: any;
	handleClose: () => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	allTableLabels: any[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setTableLabelsData: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	lastChangeRegion: any;
	currentFilePage: number;
	modelId?: string | undefined;
	file?: FileType;
	deleteRegion: (index: string) => void;
}

export const EditTableLabelModal: FC<Props> = ({
	region,
	handleClose,
	allTableLabels,
	setTableLabelsData,
	lastChangeRegion,
	currentFilePage,
	modelId,
	file,
	deleteRegion,
}) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { getExtension } = useExtension();
	const [regionText, setRegionText] = useState<string>('');
	const [errorText, setErrorText] = useState<string>('');
	const [assertionSetId, setAssertionSetId] = useState('');
	const [replacedValue, setReplacedValue] = useState('');
	const [disableReplaceVal, setDisableReplaceVal] = useState(false);
	const [assertionIsVisible, setAssertionIsVisible] = useState(false);
	const regexp = /[~`'"!?@#$^&\s<>[\]+:|/\\]/g;

	const disableActiveAssertion = useMemo(() => !!file?.assertionSetsId, [file?.assertionSetsId]);

	const { data } = useQuery({
		queryKey: ['get/assertion-sets'],
		queryFn: () => getAssertionsSets(),
		onError: (err) => {
			const errorMessage = getErrorMessage(err);
			enqueueSnackbar({
				message: errorMessage,
				variant: 'error',
			});
		},
	});

	const { mutate: createAssertionMutate } = useMutation({
		mutationKey: ['create/assertion-sets/assertions'],
		mutationFn: createAssertion,
	});

	const { mutate: findAssertionMutate } = useMutation({
		mutationKey: ['assertion-sets/assertions/find'],
		mutationFn: findAssertion,
	});

	const selectedOptions = useMemo(
		() =>
			data?.data.map((item) => ({
				label: item.name,
				value: item._id,
			})),
		[data],
	);

	useEffect(() => {
		setAssertionIsVisible(!!region?.region?.assertionSetsId || !!region?.region?.assertionsData);
		setDisableReplaceVal(!!region?.region?.assertionSetsId || !!region?.region?.assertionsData);
		setErrorText('');
	}, [region?.region]);

	useEffect(() => {
		if (region?.columnHeader) {
			setRegionText(region?.columnHeader?.columnName);
		} else if (
			region?.region?.content === '' &&
			!lastChangeRegion?.name &&
			!lastChangeRegion?.page &&
			!lastChangeRegion?.text
		) {
			const regionObj = {
				pageNumber: currentFilePage,
				boxPosition: {
					width: lastChangeRegion?.width || 0,
					height: lastChangeRegion?.height || 0,
					x: lastChangeRegion?.x || 0,
					y: lastChangeRegion?.y || 0,
				},
			};
			modelId &&
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				getChangingModelText({ modelFileId: file?._id, data: regionObj }).then((res: any) => {
					if (!res.data) {
						setRegionText('');
					} else {
						setRegionText(res.data);
					}
				});
			!modelId &&
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				getChangingText({ id: file?._id, data: regionObj }).then((res: any) => {
					if (!res.data) {
						setRegionText('');
					} else {
						setRegionText(res.data);
					}
				});
		} else {
			setRegionText(region?.region?.content);
			setAssertionSetId(
				region?.region?.assertionSetsId || region?.region?.assertionsData?.assertionSetsId,
			);
			setReplacedValue(region?.region?.replaceVal || region?.region?.assertionsData?.replaceVal);
		}
	}, [region]);

	const handleRegionTextChange = (name: string) => {
		if (!name.match(regexp)) {
			setRegionText(name);
			setErrorText('');
		} else {
			setRegionText(name.replace(regexp, ''));
			setErrorText(t('files.details.error_text') as string);
		}
	};

	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	const deleteTableRegion = (region: any) => {
		const allLabels = [...allTableLabels];
		const tableIndex = region?.indexArray;
		if (region?.tableKey) {
			const arrayFilter = allLabels[tableIndex]?.cells?.filter(
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				(item: any) => item?.rowIndex !== Number(region?.tableKey),
			);
			const maxRowIndex = Math.max(...arrayFilter.map((cell: any) => cell.rowIndex));
			const parceCellsArray = Array.from({ length: maxRowIndex + 1 }, (_, rowIndex) =>
				arrayFilter.filter((cell: any) => cell.rowIndex === rowIndex),
			).filter((item) => item.length);
			const newLabelArr = parceCellsArray
				.map((rowItem, index) =>
					rowItem.map((cellItem: any) => ({
						...cellItem,
						rowIndex: index,
					})),
				)
				.flat();
			allLabels[tableIndex].cells = newLabelArr;
		} else if (region?.columnHeader) {
			allLabels[tableIndex]?.columnsHeaders?.splice(region?.index - 1, 1);
			const cellsFilter = allLabels[tableIndex]?.cells?.filter(
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				(item: any) => item?.columnIndex !== region?.index - 1,
			);
			const maxRowIndex = Math.max(...cellsFilter.map((cell: any) => cell.rowIndex));
			const parceCellsArray = Array.from({ length: maxRowIndex + 1 }, (_, rowIndex) =>
				cellsFilter.filter((cell: any) => cell.rowIndex === rowIndex),
			);
			const newLabelArr = parceCellsArray
				.map((rowItem) =>
					rowItem.map((cellItem: any, i: number) => ({
						...cellItem,
						columnIndex: i,
					})),
				)
				.flat();
			allLabels[tableIndex].cells = newLabelArr;
		} else {
			const columnIndex = region?.region?.columnIndex;
			const rowIndex = region?.region?.rowIndex;
			for (let i = 0; i < allLabels.length; i++) {
				if (i === tableIndex) {
					if (allLabels[i]?.cells.length > 0) {
						for (const cell of allLabels[i].cells) {
							if (cell?.columnIndex === columnIndex && cell?.rowIndex === rowIndex) {
								cell.content = '';
							}
						}
					}
				}
			}
		}
		setTableLabelsData(allLabels);
	};

	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleChangeRegionText = (text: string, region: any) => {
		const tableIndex = region?.indexArray;
		const columnIndex = region?.region?.columnIndex;
		const rowIndex = region?.region?.rowIndex;
		const allLabels = [...allTableLabels];
		if (region?.columnHeader) {
			const columnObj = allLabels[tableIndex]?.columnsHeaders[region?.index - 1];
			allLabels[tableIndex]?.columnsHeaders?.splice(region?.index - 1, 1, {
				...columnObj,
				columnName: text,
			});
		} else {
			for (let i = 0; i < allLabels.length; i++) {
				if (i === tableIndex) {
					if (allLabels[i]?.cells.length > 0) {
						for (const cell of allLabels[i].cells) {
							if (cell?.columnIndex === columnIndex && cell?.rowIndex === rowIndex) {
								cell.content = text;
								cell.assertionSetsId = assertionIsVisible ? assertionSetId : null;
								cell.replaceVal = assertionIsVisible ? replacedValue : null;
								cell.assertionsData = assertionIsVisible ? cell.assertionsData : null;
								if (
									!lastChangeRegion?.name &&
									!lastChangeRegion?.page &&
									!lastChangeRegion?.text
								) {
									cell.boundingRegions = [
										{
											pageNumber: currentFilePage,
											boxPosition: {
												x: lastChangeRegion?.x ?? 0,
												y: lastChangeRegion?.y ?? 0,
												width: lastChangeRegion?.width ?? 0,
												height: lastChangeRegion?.height ?? 0,
											},
										},
									];
									deleteRegion(lastChangeRegion?.data?.index);
								}
							}
						}
					}
				}
			}
		}
		setTableLabelsData(allLabels);
	};

	const switchAssertion = () => {
		setAssertionIsVisible((prevState) => !prevState);
	};

	const onAssertionSelect = (assertionSetsId: string) => {
		setAssertionSetId(assertionSetsId);
		findAssertionMutate(
			{
				assertionSetsId,
				initialVal: regionText,
			},
			{
				onSuccess: (response) => {
					if (response.data.replaceVal) {
						setReplacedValue(response.data.replaceVal);
						setDisableReplaceVal(true);
					} else {
						setReplacedValue('');
						setDisableReplaceVal(false);
					}
				},
				onError: (err) => {
					const errorMessage = getErrorMessage(err);
					enqueueSnackbar({
						message: errorMessage,
						variant: 'error',
					});
				},
			},
		);
	};

	const onCancelSubmit = () => {
		handleClose();
		setRegionText('');
	};

	const onSubmitHandler = () => {
		if (!disableReplaceVal && assertionIsVisible) {
			if (!assertionSetId) {
				enqueueSnackbar({
					message: t('400_errors.files.assertion_id_empty'),
					variant: 'error',
				});
			} else if (!replacedValue) {
				enqueueSnackbar({
					message: t('400_errors.files.assertion_replaced_val_empty'),
					variant: 'error',
				});
			} else {
				createAssertionMutate(
					{
						id: assertionSetId,
						data: {
							assertions: [
								{
									initialVal: regionText,
									replaceVal: replacedValue,
								},
							],
						},
					},
					{
						onSuccess: async () => {
							handleChangeRegionText(regionText, region);
							handleClose();
							setRegionText('');

							enqueueSnackbar({
								message: t('assertions.snackbar.success_assertion_creation'),
								variant: 'success',
							});
						},
						onError: (err) => {
							const errorMessage = getErrorMessage(err);
							enqueueSnackbar({
								message: errorMessage,
								variant: 'error',
							});
						},
					},
				);
			}
		} else {
			handleChangeRegionText(regionText, region);
			handleClose();
			setRegionText('');
		}
	};

	return (
		<Dialog
			open={Boolean(region)}
			onClose={handleClose}
			PaperComponent={Paper}
			PaperProps={{
				title: region?.tableKey
					? (t('files.details.remove_cell') as string)
					: (t('files.details.edit_cell') as string),
			}}
		>
			{!region?.columnHeader?.autocomplete && !region?.region?.autocomplete && (
				<Box
					onClick={() => {
						deleteTableRegion(region);
						handleClose();
					}}
					width={28}
					height={28}
					sx={{
						position: 'absolute',
						top: '64px',
						right: '59px',
						cursor: 'pointer',
					}}
				>
					<Icon
						id={'trush_icon_red'}
						style={{
							marginLeft: '16px',
							cursor: 'pointer',
						}}
					/>
				</Box>
			)}

			{!region?.tableKey && (
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						flexDirection: 'column',
						justifyContent: 'space-between',
					}}
				>
					<NoteTextField
						placeholder={t('files.placeholder.text_inside_label') as string}
						required
						value={regionText}
						multiline
						maxRows={6}
						variant="outlined"
						onChange={(event) => {
							handleRegionTextChange(event?.target?.value);
						}}
						disabled={region?.columnHeader?.autocomplete || region?.region?.autocomplete}
					/>
					{errorText && <Box sx={{ color: 'red', fontSize: '12px' }}>{errorText}</Box>}
				</Box>
			)}
			{!region?.columnHeader &&
				!modelId &&
				getExtension(ExtensionsEnum.assertions_sets)?.isEnabled &&
				!region?.region?.autocomplete &&
				!region?.tableKey && (
					<>
						<FormControlLabel
							control={
								<Switch
									checked={assertionIsVisible}
									onChange={switchAssertion}
									sx={{ marginRight: '10px' }}
									disabled={disableActiveAssertion}
								/>
							}
							label={<Typography variant={'body2'}>{t('assertions.set_assertion')}</Typography>}
							sx={{ margin: '0' }}
						/>

						{assertionIsVisible && (
							<Box sx={{ marginTop: '15px' }}>
								<Select
									label={t('assertions.as_title_list') as string}
									placeholder={t('assertions.placeholder.select_as_set')}
									value={assertionSetId || ''}
									onSelect={(item) => onAssertionSelect(item as string)}
									options={selectedOptions || []}
									disabled={disableActiveAssertion}
								/>
								<Input
									sx={{ marginBottom: '10px' }}
									name="replaceVal"
									value={replacedValue}
									label={t('assertions.label.replaced_value')}
									placeholder={t('assertions.placeholder.replaced_value') as string}
									disabled={disableReplaceVal}
									onChange={(e) => setReplacedValue(e.target.value)}
									multiline
								/>
							</Box>
						)}

						<Typography
							variant={'body2'}
							color={ColorPalette.royalBlue}
							onClick={() =>
								navigate(
									`${Routes.extensions}/${
										getExtension(ExtensionsEnum.assertions_sets)?.extension
									}`,
								)
							}
							sx={{
								cursor: 'pointer',
								margin: '20px 0',
							}}
						>
							{t('assertions.modal.see_all_ass')}
						</Typography>
					</>
				)}

			{!region?.tableKey && (
				<ActionsForDialog
					applyActionDisabled={!regionText}
					cancelLabel={t('shared_texts.cancel')}
					cancelAction={onCancelSubmit}
					applyLabel={t('shared_texts.save')}
					applyAction={onSubmitHandler}
				/>
			)}
		</Dialog>
	);
};

const NoteTextField = styled(TextField)`
	width: 100%;
	padding-bottom: 23px;
	.MuiOutlinedInput-root {
		display: flex;
		flex-direction: column;
		padding: 0;
		border-radius: 10px;
		textarea {
			color: #2b3674;
			background: #ffffff;
			padding: 8px 8px 8px 12px;
			width: 95%;
			font-family: Comfortaa;
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: 19px;
			letter-spacing: 0.5px;
		}
		&.Mui-focused fieldset {
			border-radius: 10px;
			border: 1px solid #fa4d81;
		}
	}
	& label.Mui-focused {
		color: #2b3674;
	}
`;
