import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { ModelsType } from 'entities/CustomModel/types';
import { FileType } from 'entities/Files/types';

type CustomModelStore = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	customFileDetails:
		| (FileType & {
				recognizedLabels: any;
		  })
		| null;
	customModels: ModelsType[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	currentCustomModel: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	currentCustomModelFiles: any[];
	currentCustomModelId: string;
};

type CustomModelActions = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setCustomFileDetails: (data: any) => void;
	setCustomModels: (customModels: ModelsType[]) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setCurrentCustomModel: (customModel: any) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setCurrentCustomModelFiles: (customModelFiles: any) => void;
	setCurrentCustomModelId: (customModelId: string) => void;
	reset: () => void;
};

const initialState: CustomModelStore = {
	customFileDetails: null,
	customModels: [],
	currentCustomModel: {},
	currentCustomModelFiles: [],
	currentCustomModelId: '',
};

const customModelStore = create<CustomModelStore & CustomModelActions>()(
	persist(
		(set) => ({
			...initialState,
			setCustomModels: (customModels) => set(() => ({ customModels })),
			setCurrentCustomModel: (customModel) => set(() => ({ currentCustomModel: customModel })),
			setCurrentCustomModelFiles: (customModelFiles) =>
				set(() => ({ currentCustomModelFiles: customModelFiles })),
			setCurrentCustomModelId: (customModelId) => set(() => ({ currentCustomModelId: customModelId })),
			setCustomFileDetails: (customFileDetails) => set(() => ({ customFileDetails })),
			reset: () => set(() => ({ ...initialState })),
		}),
		{
			name: 'customModelStore',
		},
	),
);

export const useCustomModelStore = () => customModelStore((state) => state);
