import { Box, InputAdornment, InputBase, InputBaseProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FunctionComponent } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Icon } from 'shared/ui/Icon';

interface Props extends InputBaseProps {}

export const SearchInput: FunctionComponent<Props> = ({ sx, ...props }) => {
	const { t } = useTranslation();
	return (
		<Box
			sx={{
				backgroundColor: ColorPalette.white,
				padding: '8px',
				borderRadius: '10px',
				width: '100%',
				maxWidth: '570px',
				...sx,
			}}
		>
			<InputBase
				placeholder={t('shared_texts.placeholder.search') as string}
				startAdornment={
					<InputAdornment position="start">
						<Box>
							<Icon
								id={'search'}
								style={{
									cursor: 'pointer',
									margin: '0 28px 0 23px',
								}}
								color={ColorPalette.astronaut}
							/>
						</Box>
					</InputAdornment>
				}
				sx={{
					'height': '45px',
					'width': '100%',
					'borderRadius': '10px',
					'backgroundColor': ColorPalette.catskillWhite,
					'& .MuiInputBase-input': {
						'height': 'initial',
						'margin': '0',

						'borderRadius': '10px',

						'fontWeight': 600,
						'fontSize': '14px',
						'lineHeight': '18px',

						'color': ColorPalette.astronaut,
						'&:placeholder': {
							color: ColorPalette.baliHai,
						},
					},
				}}
				{...props}
			/>
		</Box>
	);
};
