import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { List, ListItem, Typography, ListProps } from '@mui/material';
import { ColorPalette } from 'shared/consts/colorPalette';

interface ListItemsPropsType extends ListProps {
	list: string[];
	textColor?: string;
}

export const ListItems: FC<ListItemsPropsType> = ({ list, sx, textColor = ColorPalette.baliHai }) => {
	const { t } = useTranslation();

	return (
		<List
			sx={{
				'& .MuiListItem-root': {
					'position': 'relative',
					'paddingLeft': '24px',
					'paddingTop': '6px',
					'paddingBottom': '6px',
					'&:before': {
						content: '""',
						display: 'block',
						position: 'absolute',
						width: '8px',
						height: '8px',
						top: '50%',
						left: '4px',
						borderRadius: '50%',
						transform: 'translateY(-50%)',
						backgroundColor: ColorPalette.royalBlue,
					},
				},
				...sx,
			}}
		>
			{list.map((item, i) => (
				<ListItem key={`list-${i}`}>
					<Typography variant={'body2'} color={textColor}>
						{t(item)}
					</Typography>
				</ListItem>
			))}
		</List>
	);
};
