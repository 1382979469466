import { Box, Divider, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';
import { InShortParagraph } from 'widgets/privacyPolicy/InShortParagraph';
import { ListItemParagraph } from 'widgets/privacyPolicy/ListItemParagraph';
import { Paragraph } from 'widgets/privacyPolicy/Paragraph';
import { Title } from 'widgets/privacyPolicy/Title';

export const PrivacyPolicy: FunctionComponent = () => {
	return (
		<Box
			sx={{
				minHeight: '100vh',
				display: 'flex',
				flexDirection: 'column',
				backgroundColor: ColorPalette.catskillWhite,
				padding: '36px 40px',
			}}
		>
			<Typography variant={'h4'} color={ColorPalette.astronaut}>
				Privacy policy
			</Typography>
			<Typography variant={'subtitle1'} color={ColorPalette.baliHai} sx={{ marginTop: '24px' }}>
				Privacy Notice for Nano Net Technologies Inc. Effective day 2nd Dec 2020
			</Typography>

			<Divider color={ColorPalette.periwinkle} sx={{ margin: '42px 0 44px 0' }} />

			<Paragraph>
				Thank you for choosing to be part of our community at Nano Net Technologies Inc., doing
				business as Nanonets (&quot;Nanonets&quot;, &quot;we&quot;, &quot;us&quot;, &quot;our&quot;).
				We are committed to protecting your personal information and your right to privacy. If you
				have any questions or concerns about this privacy notice, or our practices with regards to
				your personal information, please contact us at{' '}
				<a href="mailto:info@nanonets.com">info@nanonets.com</a>.
			</Paragraph>

			<Title number={1}>What information do we collect?</Title>

			<Paragraph>
				This privacy notice applies to all information collected through our Services (which, as
				described above, includes our Website), as well as, any related services, sales, marketing or
				events.
			</Paragraph>
			<Paragraph variant={'bold'}>
				Please read this privacy notice carefully as it will help you understand what we do with the
				information that we collect.
			</Paragraph>

			<InShortParagraph>We collect personal information that you provide to us.</InShortParagraph>

			<Title number={2}>How do we use your information?</Title>

			<InShortParagraph>
				Some information — such as your Internet Protocol (IP) address and/or browser and device
				characteristics — is collected automatically when you visit our Website.
			</InShortParagraph>

			<ListItemParagraph title={'Personal Information Provided by You.'}>
				We collect names; phone numbers; email addresses; mailing addresses; job titles; usernames;
				passwords; contact preferences; contact or authentication data; billing addresses;
				debit/credit card numbers; and other similar information.
			</ListItemParagraph>
			<ListItemParagraph title={'Payment Data.'}>
				We may collect data necessary to process your payment if you make purchases, such as your
				payment instrument number (such as a credit card number), and the security code associated
				with your payment instrument. All payment data is stored by Stripe. You may find their privacy
				notice link(s) here:{' '}
				<a href={'https://stripe.com/en-in/privacy'} target={'_blank'} rel="noreferrer">
					https://stripe.com/en-in/privacy
				</a>
				.
			</ListItemParagraph>
		</Box>
	);
};
