import { FC, useMemo } from 'react';
import { Box, styled, Typography } from '@mui/material';
import { TableLabelsStructureType } from 'entities/Files/types';
import { ColorPalette } from 'shared/consts/colorPalette';

interface LabelTable {
	tableData: TableLabelsStructureType;
}

export const LabelTable: FC<LabelTable> = ({ tableData }) => {
	const transformedCells = useMemo(() => {
		const maxRowIndex = Math.max(...tableData.cells.map((cell) => cell.rowIndex));
		return Array.from({ length: maxRowIndex + 1 }, (_, rowIndex) =>
			tableData.cells.filter((cell) => cell.rowIndex === rowIndex),
		);
	}, [tableData]);

	return (
		<Box sx={{ overflow: 'auto' }}>
			<table>
				<tr>
					<ThStyled></ThStyled>
					{tableData.columnsHeaders.map((item, i) => (
						<ThStyled
							key={`${item.columnName}-${i}`}
							sx={{
								borderColor: item.autocomplete
									? ColorPalette.violetRedTransparent
									: ColorPalette.zircon,
							}}
						>
							<Typography
								variant={'body1'}
								sx={{
									fontSize: '14px',
									display: 'flex',
									flexDirection: 'column',
								}}
							>
								<span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
									{item.columnName}
								</span>
								{tableData.columnsHeaders.length - 1 === i && (
									<span style={{ cursor: 'pointer', fontSize: '20px' }}>+</span>
								)}
							</Typography>
						</ThStyled>
					))}
				</tr>

				{transformedCells.map((row, i) => (
					<tr key={`row-${i}`}>
						<TdStyled sx={{ display: 'flex' }}>{i}</TdStyled>
						{row.map((cell) => (
							<TdStyled
								key={`cell-${cell.rowIndex}-${cell.columnIndex}`}
								sx={{
									borderColor: cell.autocomplete
										? ColorPalette.violetRedTransparent
										: ColorPalette.zircon,
								}}
							>
								{cell.content}
							</TdStyled>
						))}
					</tr>
				))}
			</table>
		</Box>
	);
};

const ThStyled = styled('th')`
	border: 1.5px solid ${ColorPalette.zircon};
	min-width: 50px;
	max-width: 150px;
	color: ${ColorPalette.astronaut};
`;

const TdStyled = styled(ThStyled)`
	font-size: 10px;
	text-align: start;
`;
