import { FileType } from 'entities/Files/types';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type FilesStore = {
	files: FileType[] | null;
	fileDetails: FileType | null;
	filePartnerId: string | null;
};

type FilesActions = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setFileDetails: (data: any) => void;
	updateFilesData: (filesData: any) => void | Promise<void>;
	setFilePartnerId: (id: string | null) => void;
	reset: () => void;
};

const initialState: FilesStore = {
	fileDetails: null,
	files: null,
	filePartnerId: null,
};

export const filesStore = create<FilesStore & FilesActions>()(
	persist(
		(set) => ({
			...initialState,
			updateFilesData: (filesData) => set(() => ({ ...filesData })),
			setFileDetails: (fileDetails) => set(() => ({ fileDetails })),
			setFilePartnerId: (filePartnerId) => set(() => ({ filePartnerId })),
			reset: () => set(() => ({ ...initialState })),
		}),
		{
			name: 'filesStore',
		},
	),
);

export const useFilesStore = () => filesStore((state) => state);
