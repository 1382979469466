import { CompanyForRequest } from 'entities/Company/types';
import { UserForRequest } from 'entities/User/types';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type SignUpData = {
	user: UserForRequest & {
		confirmPassword: string;
	};
	company: CompanyForRequest;
};

type AuthType = 'google' | 'credentials';

type SignUpStore = {
	step: number;
	emailToSend: string;
	authType: AuthType;
	signUpData: SignUpData;
	confirmFirstStep: {
		isRead: boolean;
		isAgreed: boolean;
	};
};

type SignUpActions = {
	goToStep: (step: number) => void;
	goToNextStep: () => void;
	goToPrevStep: () => void;
	updateEmailToSend: (data: { email: string }) => void;
	updateAuthType: (data: { authType: AuthType }) => void;
	updateUserSignUpData: (data: Partial<SignUpData['user']>) => void;
	updateCompanySignUpData: (data: Partial<SignUpData['company']>) => void;
	updateConfirmationFirstStep: (data: { isRead?: boolean; isAgreed?: boolean }) => void;
	reset: () => void;
};

const initialState: SignUpStore = {
	step: 1,
	emailToSend: '',
	authType: 'credentials',
	confirmFirstStep: {
		isRead: false,
		isAgreed: false,
	},
	signUpData: {
		user: {
			email: '',
			password: '',
			confirmPassword: '',
			firstName: '',
			lastName: '',
			language: 'it',
		},
		company: {
			name: '',
			country: '',
			city: '',
			street: '',
			houseNumber: '',
			postalCode: '',
			vatNumber: '',
			sdiCode: '',
		},
	},
};

const signUpStore = create<SignUpStore & SignUpActions>()(
	persist(
		(set, get) => ({
			...initialState,
			goToStep: (step) => set(() => ({ step: step })),
			goToNextStep: () => set(() => ({ step: get().step + 1 })),
			goToPrevStep: () => set(() => ({ step: get().step - 1 })),
			updateEmailToSend: ({ email }) => set(() => ({ emailToSend: email })),
			updateAuthType: ({ authType }) => set(() => ({ authType })),
			updateUserSignUpData: (data) =>
				set(({ signUpData }) => ({
					signUpData: {
						...signUpData,
						user: { ...signUpData.user, ...data },
					},
				})),
			updateCompanySignUpData: (data) =>
				set(({ signUpData }) => ({
					signUpData: {
						...signUpData,
						company: { ...signUpData.company, ...data },
					},
				})),
			updateConfirmationFirstStep: (data) =>
				set(({ confirmFirstStep }) => ({
					confirmFirstStep: { ...confirmFirstStep, ...data },
				})),
			reset: () => set(({ emailToSend }) => ({ ...initialState, emailToSend })),
		}),
		{
			name: 'signUpStore',
		},
	),
);

export const useSignInStore = () => signUpStore((state) => state);
