import { FC } from 'react';
import { Dialog, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ColorPalette } from 'shared/consts/colorPalette';
import { ActionsForDialog } from 'shared/ui/ActionsForDialog';
import { Paper } from 'shared/ui/Paper';

interface Props {
	open: boolean;
	handleClose: () => void;
	handleExport: () => void;
}

const RequiresReviewModal: FC<Props> = ({ open, handleClose, handleExport }) => {
	const { t } = useTranslation();

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			PaperComponent={Paper}
			PaperProps={{ title: t('shared_texts.attention') as string }}
		>
			<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginBottom: 3 }}>
				<Typography variant={'body1'} color={ColorPalette.baliHai}>
					{t('files.modal.requires_review_description_1')}
				</Typography>
				<Typography variant={'body1'} color={ColorPalette.baliHai}>
					{t('files.modal.requires_review_description_2')}
				</Typography>
			</Box>

			<ActionsForDialog
				cancelLabel={t('shared_texts.cancel')}
				cancelAction={handleClose}
				applyLabel={t('shared_texts.export')}
				applyAction={handleExport}
			/>
		</Dialog>
	);
};

export default RequiresReviewModal;
