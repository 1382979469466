import { Box, Divider, Drawer, IconButton, List, Typography, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuthStore } from 'processes/auth/model/state';
import { FunctionComponent, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { enqueueSnackbar } from 'notistack';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Icon } from 'shared/ui/Icon';
import { ListItems } from 'shared/ui/ListItems';
import { capitalizeFirstLetter } from 'shared/helpers/capitalizeFirstLetter';
import { SidebarLinks } from 'widgets/Sidebar/config';
import { useSidebarStore } from 'widgets/Sidebar/model/state';
import { SidebarMenuLink } from 'widgets/Sidebar/types';
import { MenuListItem } from 'widgets/Sidebar/ui/MenuListItem';
import { useSubscriptionStoreStore } from 'widgets/Subscriptions/model/state';
import { getSubscription } from 'entities/Subscriptions/api';
import { validateToken } from 'entities/Auth/api';
import { UserRoles } from 'entities/User/types';
import { STypesEnum } from 'entities/Subscriptions/enums';
import { signOut } from 'processes/auth/helpers/signOut';
import { getMaxValue } from 'shared/helpers/getMaxValue';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import logo from 'shared/assets/logo-blue.png';

export const Sidebar: FunctionComponent = () => {
	const navigate = useNavigate();
	const { i18n, t } = useTranslation();
	const { user, updateAuthData } = useAuthStore();
	const { isSidebarOpened, toggleSidebar, toggleMenuList } = useSidebarStore();
	const { setCurrentSubscription } = useSubscriptionStoreStore();

	const { data: subscription } = useQuery({
		queryKey: ['get/subscriptions'],
		queryFn: () => getSubscription(),
		onSuccess: (response) => {
			setCurrentSubscription(response.data);
		},
		onError: (error: AxiosError) => {
			const errorMessage = getErrorMessage(error);
			enqueueSnackbar({ message: errorMessage, variant: 'error' });
		},
		enabled: user?.role !== UserRoles.admin,
	});

	useQuery({
		queryKey: ['validate-token'],
		queryFn: () => validateToken(),
		onSuccess: (response) => {
			updateAuthData(response.data);
		},
		onError: () => {
			signOut();
		},
	});

	useEffect(() => {
		i18n.changeLanguage(user?.language);
	}, []);

	const handleToggleSidebar = () => {
		toggleMenuList(null);
		toggleSidebar();
	};

	const handleNavigate = () => {
		navigate('/');
	};

	const subscriptionPrice = useMemo(() => {
		if (
			subscription?.data.subscriptionType === STypesEnum.STARTER ||
			subscription?.data.subscriptionType === STypesEnum.PRO ||
			subscription?.data.subscriptionType === STypesEnum.PAY_AS_YOU_GO
		) {
			return ` - ${subscription.data?.subscriptionCost / 100}€`;
		}
		return null;
	}, [subscription?.data]);

	const subscriptionList = useMemo(() => {
		if (subscription?.data && user?.role === 'USER') {
			const {
				totalFiles,
				maxFiles,
				maxFreeFiles,
				totalCousers,
				maxCousers,
				maxFreeCousers,
				totalCustomModels,
				maxCustomModels,
				maxFreeCustomModels,
			} = subscription.data;
			return [
				t(`sidebar.subscriptions.${subscription.data.subscriptionType}.rule_1`, {
					value: `${totalFiles}/${getMaxValue(maxFiles, maxFreeFiles)}`,
				}),
				t(`sidebar.subscriptions.${subscription.data.subscriptionType}.rule_2`, {
					value: `${totalCousers}/${getMaxValue(maxCousers, maxFreeCousers)}`,
				}),
				t(`sidebar.subscriptions.${subscription.data.subscriptionType}.rule_3`, {
					value: `${totalCustomModels}/${getMaxValue(maxCustomModels, maxFreeCustomModels)}`,
				}),
			];
		}
		return null;
	}, [subscription]);

	const PreparedSidebarLinks = useMemo(() => {
		return SidebarLinks.map((link) => {
			if (user?.role && link?.route.access.includes(user?.role)) {
				return link;
			}
		}).filter((link) => link);
	}, [user]);

	return (
		<Drawer
			variant="permanent"
			open={isSidebarOpened}
			sx={{
				'width': isSidebarOpened ? '285px' : '87px',
				'height': '100vh',
				'position': 'relative',
				'flexShrink': 0,
				'whiteSpace': 'nowrap',
				'overflowX': 'hidden',
				'transition': 'width .3s',
				'& > .MuiPaper-elevation': {
					width: isSidebarOpened ? '285px' : '87px',
					border: 'none',
					overflow: 'visible',
					backgroundColor: ColorPalette.ebonyClay,
					transition: 'width .3s',
				},
			}}
		>
			<IconButton
				sx={{
					'width': '20px',
					'height': '20px',
					'padding': 0,
					'position': 'absolute',
					'right': '-15px',
					'top': '20px',
					'boxSizing': 'content-box',
					'border': `5px solid ${ColorPalette.catskillWhite}`,
					'backgroundColor': ColorPalette.ebonyClay,
					'&:hover': {
						backgroundColor: ColorPalette.ebonyClay,
					},
				}}
				onClick={() => handleToggleSidebar()}
			>
				<Icon
					id={'custom_arrow_down'}
					style={{
						width: '18px',
						height: '9px',
						color: ColorPalette.white,
						transform: `rotate(${isSidebarOpened ? '90deg' : '-90deg'}) translateY(0.5px)`,
						transition: 'transform .3s',
					}}
				/>
			</IconButton>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-start',
					alignItems: 'center',
					margin: '27px 0 17px 26px',
					cursor: 'pointer',
				}}
				onClick={handleNavigate}
			>
				<img src={logo} alt={'logo-with-text'} style={{ width: '48px' }} />
				<Typography
					sx={{
						fontWeight: '700',
						fontSize: '28.5px',
						lineHeight: '32px',
						color: ColorPalette.white,
						paddingTop: '14px',
						opacity: isSidebarOpened ? 1 : 0,
						width: isSidebarOpened ? 'fit-content' : 0,
						overflow: isSidebarOpened ? 'visible' : 'hidden',
						transition: 'opacity .3s',
					}}
				>
					Retica
				</Typography>
			</Box>
			<Divider
				sx={{
					width: 'calc(100% - 42px)',
					marginLeft: '26px',
					borderRadius: '1.5px',
					backgroundColor: ColorPalette.brightGray,
				}}
			/>
			<Box sx={{ overflow: 'auto' }}>
				<List sx={{ padding: 0, marginBottom: '50px' }}>
					{PreparedSidebarLinks &&
						PreparedSidebarLinks.map((sidebarLink) => (
							<MenuListItem
								key={sidebarLink?.route.path}
								menuLink={sidebarLink as SidebarMenuLink}
							/>
						))}
				</List>

				{subscriptionList && isSidebarOpened && (
					<Box
						sx={{
							backgroundColor: ColorPalette.charade,
							margin: 'auto 16px 50px 26px',
							borderRadius: '10px',
							padding: '15px',
							whiteSpace: 'initial',
						}}
					>
						<Typography variant={'body2'} color={ColorPalette.white}>
							{capitalizeFirstLetter(subscription?.data.subscriptionType || '')}
							{subscriptionPrice}
						</Typography>
						<ListItems
							list={subscriptionList}
							sx={{ padding: '0', marginTop: '15px' }}
							textColor={ColorPalette.white}
						/>
					</Box>
				)}
				<Box
					sx={{
						display: 'inline-block',
						margin: `${subscriptionList ? '0' : 'auto'} 0 17px 26px`,
						opacity: isSidebarOpened ? 1 : 0,
						width: isSidebarOpened ? 'fit-content' : 0,
						overflow: isSidebarOpened ? 'visible' : 'hidden',
					}}
				>
					<Link
						href="https://retica.ai/privacy-policy/"
						color={ColorPalette.royalBlue}
						variant="body2"
					>
						{t('auth.privacy_policy')}
					</Link>
				</Box>
			</Box>
		</Drawer>
	);
};
