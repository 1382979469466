import { useEffect, useState, FC } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GetAdminInvoisesFiltersType } from 'entities/admin/Users/api/types';
import { STypesEnum } from 'entities/Subscriptions/enums';
import { FilterDrawer } from 'shared/ui/FilterDrawer';
import { Select } from 'shared/ui/Select';
import { DateRangeInput } from 'shared/ui/DateRangePicker';
import { Button } from 'shared/ui/Button';
import { SelectOption } from 'shared/ui/Select/types';

export type usersRange = {
	from?: string | undefined;
	to?: string | undefined;
};
interface Props {
	filters: GetAdminInvoisesFiltersType;
	setFilters: (filters: GetAdminInvoisesFiltersType) => void;
}

export const UsersFilter: FC<Props> = ({ filters, setFilters }) => {
	const { t } = useTranslation();
	const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
	const [newFilters, setNewFilters] = useState<GetAdminInvoisesFiltersType>(filters);

	useEffect(() => {
		setNewFilters(filters);
	}, [filters]);

	const submitFilters = () => {
		setIsOpenDrawer(false);
		setFilters(newFilters);
	};

	return (
		<>
			<Box sx={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
				<FilterDrawer
					isOpen={isOpenDrawer}
					handleOpen={() => setIsOpenDrawer(true)}
					handleClose={() => setIsOpenDrawer(false)}
				>
					<DateRangeInput
						name={'User’s billings period'}
						label={t('admin.users.labels.user_billing_period')}
						placeholder={t('shared_texts.placeholder.date') as string}
						startDate={newFilters.billingAtFrom}
						endDate={newFilters.billingAtTo}
						changeRange={(billingAtFrom, billingAtTo) => {
							setNewFilters({
								...newFilters,
								billingAtFrom,
								billingAtTo,
							});
						}}
					/>
					<DateRangeInput
						name={'User’s payments period'}
						label={t('admin.users.labels.user_payment_period')}
						placeholder={t('shared_texts.placeholder.date') as string}
						startDate={newFilters.paymentAtFrom}
						endDate={newFilters.paymentAtTo}
						changeRange={(paymentAtFrom, paymentAtTo) => {
							setNewFilters({
								...newFilters,
								paymentAtFrom,
								paymentAtTo,
							});
						}}
					/>

					<Select
						label={t('shared_texts.table_columns.status') as string}
						value={newFilters.isPaid ? String(newFilters.isPaid) : ''}
						placeholder={t('admin.users.placeholder.status')}
						onSelect={(value) => {
							setNewFilters({
								...newFilters,
								isPaid: value as GetAdminInvoisesFiltersType['isPaid'],
							});
						}}
						options={statusSelectOptions}
					/>
					<Select
						label={t('subscriptions.title') as string}
						value={newFilters.subscriptionType ? newFilters.subscriptionType : ''}
						placeholder={t('admin.users.placeholder.subscription')}
						onSelect={(value) => {
							setNewFilters({
								...newFilters,
								subscriptionType: value as GetAdminInvoisesFiltersType['subscriptionType'],
							});
						}}
						options={subscriptionSelectOptions}
					/>
					<Button onClick={submitFilters} variant={'contained'} fullWidth>
						{t('shared_texts.apply_filters')}
					</Button>
				</FilterDrawer>
			</Box>
		</>
	);
};

const statusSelectOptions: Array<SelectOption> = [
	{
		value: 'true',
		label: 'shared_texts.paid',
	},
	{
		value: 'false',
		label: 'shared_texts.unpaid',
	},
];

const subscriptionSelectOptions: Array<SelectOption> = [
	{
		value: STypesEnum.STARTER,
		label: 'Starter',
	},
	{
		value: STypesEnum.PRO,
		label: 'Pro',
	},
	{
		value: STypesEnum.PAY_AS_YOU_GO,
		label: 'Pay as you go',
	},
];
