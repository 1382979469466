import { createContext } from 'react';

export enum SubTabType {
	CURRENT = 'current',
	BILLING = 'billing',
	PAYMENT = 'payment',
}

export interface SubTabContextProps {
	tab?: SubTabType;
	setTab: (tab: SubTabType) => void;
}

export const SubTabContext = createContext<SubTabContextProps>({ setTab: () => undefined });
