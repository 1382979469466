import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Typography } from '@mui/material';
import { ColorPalette } from 'shared/consts/colorPalette';
import { ActionsForDialog } from 'shared/ui/ActionsForDialog';
import { Paper } from 'shared/ui/Paper';

interface Props {
	email?: string;
	open: boolean;
	handleClose: () => void;
	handleRemoveUser: () => void;
}

export const RemoveUserModal: FC<Props> = ({ email, open, handleClose, handleRemoveUser }) => {
	const { t } = useTranslation();

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			PaperComponent={Paper}
			PaperProps={{ title: `${t('admin.users.details.remove_user')}?` }}
		>
			<Typography variant={'body1'} color={ColorPalette.baliHai} sx={{ margin: '24px 0 34px 0' }}>
				{t('admin.users.snackbar.confirm_user_removal', { value: email })}
			</Typography>
			<ActionsForDialog
				cancelLabel={t('shared_texts.cancel')}
				cancelAction={handleClose}
				applyLabel={t('shared_texts.yes')}
				applyAction={handleRemoveUser}
				applyActionDisabled={false}
			/>
		</Dialog>
	);
};
