import { Checkbox as MUICheckbox } from '@mui/material';
import { CheckboxProps } from '@mui/material/Checkbox/Checkbox';
import { FunctionComponent } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Icon } from 'shared/ui/Icon';

export const Checkbox: FunctionComponent<CheckboxProps> = ({ sx, ...props }) => {
	return (
		<MUICheckbox
			sx={{
				width: '20px',
				height: '20px',
				padding: '0',
				color: props.checked ? ColorPalette.royalBlue : ColorPalette.rockBlue,
				...sx,
			}}
			icon={<Icon id={'custom_checkbox'} />}
			checkedIcon={<Icon id={'custom_checkbox_checked'} />}
			{...props}
		/>
	);
};
