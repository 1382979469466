import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Typography } from '@mui/material';
import { ColorPalette } from 'shared/consts/colorPalette';
import { ActionsForDialog } from 'shared/ui/ActionsForDialog';
import { Paper } from 'shared/ui/Paper';

interface Props {
	open: boolean;
	handleClose: () => void;
	handleApply: () => void;
}

export const ConfirmRestoreModal: FC<Props> = ({ open, handleClose, handleApply }) => {
	const { t } = useTranslation();

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			PaperComponent={Paper}
			PaperProps={{ title: t('extensions.ex_list.output_file.restore_modal_title') as string }}
		>
			<Typography variant={'body1'} color={ColorPalette.baliHai} sx={{ margin: '24px 0 34px 0' }}>
				{t('extensions.ex_list.output_file.restore_modal_description')}
			</Typography>
			<ActionsForDialog
				cancelLabel={t('shared_texts.cancel')}
				cancelAction={handleClose}
				applyLabel={'Ripristina'}
				applyAction={handleApply}
				applyActionDisabled={false}
			/>
		</Dialog>
	);
};
