import { ChangeEvent, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { FtpSettingsTypeEnum } from 'entities/Integrations/enums';
import { Routes } from 'app/config/routes/consts';
import { OptionsDropdown } from 'shared/ui/OptionsDropdown';
import { SearchInput } from 'shared/ui/SearchInput';
import { TabButton } from 'shared/ui/TabButton';

export enum WarehouseTabsEnum {
	warehouses = 'warehouses',
	process = 'process',
}

interface WarehouseFilterAndSearchProps {
	activeTab: WarehouseTabsEnum;
	setTab: (type: WarehouseTabsEnum) => void;
	setSearch: (search: string | null) => void;
	createAction: () => void;
	setTablePage: () => void;
}

export const WarehouseFilterAndSearch: FC<WarehouseFilterAndSearchProps> = ({
	activeTab,
	setTab,
	setSearch,
	createAction,
	setTablePage,
}) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const handleSearchInputChange = _.debounce((event: ChangeEvent<HTMLInputElement>) => {
		setSearch(event.target.value || null);
		setTablePage();
	}, 300);

	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				gap: '20px',
				marginBottom: '44px',
			}}
		>
			<Box sx={{ display: 'flex', gap: '20px' }}>
				{tabs.map(({ type, name }) => (
					<TabButton
						key={type}
						label={t(name)}
						isActive={type === activeTab}
						action={() => {
							setTab(type);
						}}
					/>
				))}
			</Box>

			{activeTab === WarehouseTabsEnum.warehouses && (
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-end',
						width: '100%',
						gap: '20px',
					}}
				>
					<SearchInput onChange={handleSearchInputChange} />
					<OptionsDropdown
						iconId={'setting_line_fill'}
						optionList={[
							{
								label: t('shared_texts.add'),
								value: 'createAction',
								action: () => createAction(),
							},
							{
								label: t('shared_texts.table_dropdown.auto_sync'),
								value: 'autoSync',
								action: () => {
									navigate(`${Routes.syncSettings}?type=${FtpSettingsTypeEnum.warehouse}`);
								},
							},
						]}
					/>
				</Box>
			)}
		</Box>
	);
};

const tabs = [
	{
		type: WarehouseTabsEnum.warehouses,
		name: 'extensions.ex_list.warehouse.tabs.articles',
	},
	{
		type: WarehouseTabsEnum.process,
		name: 'extensions.ex_list.warehouse.tabs.process',
	},
];
