import { User } from 'entities/User/types';

export enum CoUserStatusEnum {
	active = 'ACTIVE',
	invited = 'INVITED',
	api_only = 'API_ONLY',
}

export type CoUser = {
	_id: string;
	invitedBy: Pick<User, '_id' | 'firstName' | 'lastName'>;
	email?: string;
	status?: CoUserStatusEnum;
	userId?: Pick<User, '_id' | 'email' | 'firstName' | 'lastName'>;
	joinedAt: string;
	createdAt: string;
	filesCounter: 0;
	companyName: string;
	dealerName: string;
	totalPages: 0;
};
