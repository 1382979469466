import * as yup from 'yup';
import { ProtocolEnum } from 'entities/Integrations/enums';
import { portRegEx } from 'shared/consts/RegEx';

export const FtpSettingsValidationSchema = yup.object({
	host: yup.string().required('integrations.validation.host_name.required'),
	port: yup
		.string()
		.required('integrations.validation.port.required')
		.matches(portRegEx, { message: 'integrations.validation.port.must_number' }),
	path: yup.string().required('integrations.validation.path.required'),
	protocol: yup
		.mixed<ProtocolEnum>()
		.oneOf(Object.values(ProtocolEnum))
		.required('integrations.validation.protocol.required'),
	username: yup.string().required('integrations.validation.user_name.required'),
	password: yup.string().required('integrations.validation.password.required'),
});
