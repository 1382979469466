import { Box, Typography } from '@mui/material';

export const Error = () => {
	return (
		<Box
			sx={{
				width: '100%',
				height: '100vh',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<Typography variant={'h2'}>404</Typography>
			<Typography variant={'h4'} sx={{ ml: '24px' }}>
				This page is not found
			</Typography>
		</Box>
	);
};
