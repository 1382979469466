import { Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';

export const FSHeader: FC = () => {
	const { t } = useTranslation();
	return (
		<>
			<Typography variant={'h4'} color={ColorPalette.astronaut}>
				{t('integrations.title')}
			</Typography>
			<Typography
				variant={'subtitle1'}
				color={ColorPalette.baliHai}
				sx={{ marginTop: '24px', maxWidth: '722px' }}
			>
				{t('integrations.description')}
			</Typography>
			<Typography
				variant={'subtitle1'}
				color={ColorPalette.baliHai}
				sx={{ marginTop: '24px', maxWidth: '722px' }}
			>
				{t('integrations.description_1')}
			</Typography>
			<Divider color={ColorPalette.periwinkle} sx={{ margin: '44px 0' }} />
		</>
	);
};
