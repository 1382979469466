import { ExtensionsEnum } from 'entities/Extensions/enums';

export const extensionsArray = [
	{
		extension: ExtensionsEnum.assertions_sets,
		title: 'extensions.ex_list.assertions_sets.title',
		description: 'extensions.ex_list.assertions_sets.description',
		tableDescription: 'extensions.ex_list.assertions_sets.table_description',
		isSelected: false,
	},
	{
		extension: ExtensionsEnum.custom_output_file,
		title: 'extensions.ex_list.output_file.title',
		description: 'extensions.ex_list.output_file.card_description',
		tableDescription: 'extensions.ex_list.output_file.table_description',
		isSelected: false,
	},
	{
		extension: ExtensionsEnum.warehouse,
		title: 'extensions.ex_list.warehouse.title',
		description: 'extensions.ex_list.warehouse.card_description',
		tableDescription: 'extensions.ex_list.warehouse.table_description',
		isSelected: false,
	},
];
