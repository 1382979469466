import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from 'shared/ui/PageHeader';
import { ExtensionsToSelectWidget } from 'widgets/Extensions/ExtensionsToSelect';

export const ExtensionsToSelect: FC = () => {
	const { t } = useTranslation();

	return (
		<>
			<PageHeader
				title={t('extensions.all_ex_title')}
				description={t('extensions.all_ex_description')}
				backBtn
			/>
			<ExtensionsToSelectWidget />
		</>
	);
};
