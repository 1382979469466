import { UserForAdmin } from 'entities/admin/Users/types';
import { userDetailsTabs } from 'widgets/admin/UserDetails/types';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type userDetailsInfoStore = {
	activeTab: userDetailsTabs;
	selectedUser: UserForAdmin | null;
};

type userDetailsInfoActions = {
	changeActiveTab: (tab: userDetailsTabs) => void;
	setSelectedUser: (user: UserForAdmin | null) => void;
	reset: () => void;
};

const initialState: userDetailsInfoStore = {
	activeTab: userDetailsTabs.info,
	selectedUser: null,
};

const userDetailsInfoStore = create<userDetailsInfoStore & userDetailsInfoActions>()(
	persist(
		(set) => ({
			...initialState,
			changeActiveTab: (tab) => set(() => ({ activeTab: tab })),
			setSelectedUser: (user) => set(() => ({ selectedUser: user })),
			reset: () => set(() => ({ ...initialState })),
		}),
		{
			name: 'userDetailsInfoStore',
		},
	),
);

export const useUserDetailsInfoStore = () => userDetailsInfoStore((state) => state);
