import { FunctionComponent } from 'react';
import { SubTabType } from 'widgets/Subscriptions/context/SubscriptionsTabContext';
import { useSubTab } from 'widgets/Subscriptions/context/useSubTab';
import { CurrentTab } from './Tabs/CurrentTab';
import { BillingTab } from './Tabs/BillingTab';
import { PaymentTab } from './Tabs/PaymentTab';

export const SubCurrentTab: FunctionComponent = () => {
	const { tab } = useSubTab();

	switch (tab) {
		case SubTabType.CURRENT:
			return <CurrentTab />;
		case SubTabType.BILLING:
			return <BillingTab />;
		case SubTabType.PAYMENT:
			return <PaymentTab />;
	}
	return null;
};
