import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PageHeader } from 'shared/ui/PageHeader';
import { PartnerDocumentsWidget } from 'widgets/Partners/PartnerDocuments';
import { usePartnersStore } from 'widgets/Partners/state';

export const PartnersDocuments: FC = () => {
	const { t } = useTranslation();
	const { partnerId } = useParams();
	const { partners } = usePartnersStore();
	const clientName = useMemo(
		() => partners?.results.find((item) => item._id === partnerId)?.name,
		[partnerId],
	);

	return (
		<>
			<PageHeader
				title={t('partners.document_title', { client: clientName })}
				description={t('partners.document_description')}
			/>
			<PartnerDocumentsWidget />
		</>
	);
};
