import { FC, useState, useMemo } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ExtensionsEnum } from 'entities/Extensions/enums';
import { TabButton } from 'shared/ui/TabButton';
import { ReplaceSettings } from './ui/ReplaceSettings';
import { AssertionsSettings } from './ui/AssertionsSettings';
import { CategoriesSettings } from './ui/CategoriesSettings';
import { ArticlesSettings } from './ui/ArticlesSettings';
import { useExtension } from 'shared/hooks/useExtension';

enum SettingsTabEnum {
	replace = 'replace',
	assertions = 'assertions',
	categories = 'categories',
	articles = 'articles',
}

export const PartnersSettingsWidget: FC = () => {
	const { t } = useTranslation();
	const { getExtension } = useExtension();
	const [activeTabType, setActiveTabType] = useState(SettingsTabEnum.replace);

	const settingsTabs = useMemo(() => {
		const tabs = [
			{ type: SettingsTabEnum.replace, name: 'partners.settings_tabs.tab_1' },
			{ type: SettingsTabEnum.assertions, name: 'partners.settings_tabs.tab_2' },
			{ type: SettingsTabEnum.categories, name: 'partners.settings_tabs.tab_3' },
		];
		const warehouseEnabled = getExtension(ExtensionsEnum.warehouse)?.isEnabled;

		if (warehouseEnabled) {
			tabs.push({ type: SettingsTabEnum.articles, name: 'partners.settings_tabs.tab_4' });
		}
		return tabs;
	}, [getExtension]);

	const renderTabs = () => {
		switch (activeTabType) {
			case SettingsTabEnum.replace:
				return <ReplaceSettings />;
			case SettingsTabEnum.assertions:
				return <AssertionsSettings />;
			case SettingsTabEnum.categories:
				return <CategoriesSettings />;
			case SettingsTabEnum.articles:
				return <ArticlesSettings />;
			default:
				null;
		}
	};

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-start',
					alignItems: 'center',
					gap: '20px',
					marginBottom: '44px',
				}}
			>
				{settingsTabs.map(({ type, name }) => (
					<TabButton
						key={type}
						label={t(name)}
						isActive={type === activeTabType}
						action={() => setActiveTabType(type)}
					/>
				))}
			</Box>

			{renderTabs()}
		</>
	);
};
