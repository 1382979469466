import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from 'shared/ui/Button';
import { StatusModal } from 'widgets/auth/_common/ui/StatusModal';

export const CPLinkExpiredModal: FunctionComponent = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	return (
		<StatusModal
			type={'error'}
			title={t('auth.status_modal.password_link_expired')}
			message={t('auth.status_modal.password_link_expired_message')}
		>
			<Button
				onClick={() => navigate('/forgot-password', { state: { access: true } })}
				type={'submit'}
				fullWidth
			>
				{t('auth.status_modal.password_restore')}
			</Button>
		</StatusModal>
	);
};
