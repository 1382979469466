import { FC, useState, useCallback } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { enqueueSnackbar } from 'notistack';
import { EmptyTable } from 'shared/ui/EmptyTable';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { TableWithHeader } from 'shared/ui/tables/TableWithHeader';
import { CoUser } from 'entities/CoUser/types';
import { removeCoUserOfUser } from 'entities/admin/Users/api';
import { RemoveUserModal } from 'widgets/admin/Users/RemoveUserModal';
import { CoUsersTableColumns } from 'widgets/admin/UserDetails/ui/tabs/CoUsers/CoUsersTable/CoUsersTableColumns';

type Props = {
	coUsers: Array<CoUser>;
};

export const CoUsersTable: FC<Props> = ({ coUsers }) => {
	const { t } = useTranslation();
	const [userForRemove, setUserForRemove] = useState<CoUser | undefined>(undefined);

	const queryClient = useQueryClient();
	const { mutate } = useMutation({
		mutationKey: ['admin/users', userForRemove?._id],
		mutationFn: removeCoUserOfUser,
	});

	const handleOpenModal = (coUser: CoUser) => setUserForRemove(coUser);
	const handleCloseModal = () => setUserForRemove(undefined);

	const preparedCoUsers = useMemo(() => {
		if (!coUsers.length) return [];
		return coUsers.map((coUser) => ({
			...coUser,
			openModal: (coUser: CoUser) => {
				handleOpenModal(coUser);
			},
		}));
	}, [coUsers]);

	const handleRemoveCoUser = useCallback(() => {
		mutate(userForRemove?._id, {
			onSuccess: async () => {
				await queryClient.invalidateQueries({ queryKey: ['admin/users'] });
				enqueueSnackbar({
					message: t('admin.users.snackbar.success_user_remove', { value: userForRemove?.email }),
					variant: 'success',
				});
				handleCloseModal();
			},
			onError: (err) => {
				const defaultErrorMessage = t('admin.users.snackbar.error_user_remove', {
					value: userForRemove?.email,
				});
				const errorMessage = getErrorMessage(err, defaultErrorMessage);
				enqueueSnackbar({
					message: errorMessage,
					variant: 'error',
				});
			},
		});
	}, [mutate, userForRemove?._id, userForRemove?.email, queryClient, t]);

	return (
		<>
			<RemoveUserModal
				open={!!userForRemove}
				email={userForRemove?.email}
				handleClose={handleCloseModal}
				handleRemoveUser={handleRemoveCoUser}
			/>
			{coUsers?.length ? (
				<TableWithHeader
					columns={CoUsersTableColumns}
					rows={preparedCoUsers.map((user) => ({ ...user, id: user._id }))}
				/>
			) : (
				<EmptyTable text={t('shared_texts.not_found')} />
			)}
		</>
	);
};
