import { TypedAxiosError } from 'shared/config/axios/types';
import i18next from 'i18next';

export const parseError = (errorMessage: string): string => {
	try {
		const serverError = JSON.parse(errorMessage);
		return i18next.t(serverError.key, { ...serverError.vars }) as unknown as string;
	} catch (err) {
		return errorMessage;
	}
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getErrorMessage = (err: any, defaultErrorMessage?: string): string | undefined => {
	if ((err as TypedAxiosError)?.response?.data?.statusCode === 500) {
		return i18next.t('server_errors.INTERNAL_SERVER_ERROR') as string;
	}

	if (err?.response?.data?.message) {
		return parseError(err?.response?.data?.message);
	}
	return defaultErrorMessage ?? (i18next.t('shared_texts.smth_went_wrong') as string);
};
