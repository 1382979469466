import { FC, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { getAssertionsSets } from 'entities/Assertions/api';
import { AssertionsSetsType } from 'entities/Assertions/types';
import { Loader } from 'shared/ui/Loader';
import { PageHeader } from 'shared/ui/PageHeader';
import { AsSetsTable } from 'widgets/Extensions/Details/ui/AssertionSets/ui/AsTable/ui/AssertionsSetsTable';
import { AssertionsSetManualCreationModal } from 'widgets/Extensions/Details/ui/AssertionSets/ui/AsModals/AssertionsSetManualCreationModal';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { Button } from 'shared/ui/Button';
import { ColorPalette } from 'shared/consts/colorPalette';

export const AssertionsSetsListWidget: FC = () => {
	const { t } = useTranslation();
	const [isManualModalOpen, setIsManualModalOpen] = useState(false);
	const [assertionSetToEdit, setAssertionSetToEdit] = useState<AssertionsSetsType>();

	const { data, isLoading } = useQuery({
		queryKey: ['get/assertion-sets'],
		queryFn: () => getAssertionsSets(),
		onError: (err) => {
			const errorMessage = getErrorMessage(err);
			enqueueSnackbar({
				message: errorMessage,
				variant: 'error',
			});
		},
	});

	const onCloseManualModalHandler = () => {
		setAssertionSetToEdit(undefined);
		setIsManualModalOpen(false);
	};

	const onOpenManualModalForCreation = () => setIsManualModalOpen(true);

	const onOpenManualModalForEdit = (assertionSet: AssertionsSetsType) => {
		setAssertionSetToEdit(assertionSet);
		setIsManualModalOpen(true);
	};

	return (
		<>
			<PageHeader
				title={t('extensions.ex_list.assertions_sets.title')}
				description={t('assertions.as_sets_description')}
				backBtn
			/>
			<AssertionsSetManualCreationModal
				open={isManualModalOpen}
				handleClose={onCloseManualModalHandler}
				assertionSetToEdit={assertionSetToEdit}
			/>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'space-between',
					marginBottom: '34px',
				}}
			>
				<Typography variant={'h5'} color={ColorPalette.astronaut}>
					{t('assertions.as_sets_sub_description')}
				</Typography>

				<Button onClick={onOpenManualModalForCreation}>{t('assertions.add_as_set')}</Button>
			</Box>
			{isLoading ? (
				<Loader />
			) : (
				<AsSetsTable assertionsSets={data?.data} handleOpenEdit={onOpenManualModalForEdit} />
			)}
		</>
	);
};
