import { Box } from '@mui/material';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { CategoryTypeEnum } from 'entities/Files/types';
import { PageHeader } from 'shared/ui/PageHeader';
import { enqueueSnackbar } from 'notistack';
import { DnDImportFiles } from 'widgets/importFiles/importFilesDirect/ui/DnDImportFiles';
import { useParams } from 'react-router-dom';
import { ImportFilesModelDashboard } from 'widgets/customModel/ImportFilesModelDashboard';
import { useCustomModelStore } from 'widgets/customModel/state';
import { getCustomModelFiles } from 'entities/CustomModel/api';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';

export const ImportCustomDirectPage: FC = () => {
	const { t } = useTranslation();
	const { modelId } = useParams<{ modelId: string }>();
	const { setCurrentCustomModelFiles } = useCustomModelStore();

	const query = useQuery({
		queryKey: ['get/custom-models/files'],
		queryFn: () => getCustomModelFiles(modelId || ''),
		onSuccess: (response) => {
			setCurrentCustomModelFiles(response.data);

			const isUploading = response.data.filter(
				(sessionItem) => sessionItem.status === 'UPLOADING',
			).length;
			if (isUploading) {
				setTimeout(() => {
					query.refetch();
				}, 6000);
			}
		},
		onError: (err) => {
			const errorMessage = getErrorMessage(err);
			enqueueSnackbar({
				message: errorMessage,
				variant: 'error',
			});
		},
	});

	return (
		<Box sx={{ marginBottom: '70px' }}>
			<PageHeader
				title={t('start_recognition.upload_files_section.title')}
				description={t('start_recognition.upload_files_section.description')}
			/>
			{/* TODO replace this component with shared/ui/DndImportFiles */}
			<DnDImportFiles categoryType={CategoryTypeEnum.custom} modelId={modelId} />
			<ImportFilesModelDashboard uploadMode />
		</Box>
	);
};
