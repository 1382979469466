import { FC, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Box } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useQuery, useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { ModelStatuses } from 'entities/CustomModel/types';
import { CategoriesSettingsType } from 'entities/Partners/types';
import { getCategoriesSettings, updateCategoriesSettings } from 'entities/Partners/api';
import { getCustomModels } from 'entities/CustomModel/api';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Button } from 'shared/ui/Button';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { useCustomModelStore } from 'widgets/customModel/state';
import { CategoriesModal } from 'widgets/Partners/ui/Modals/CategoriesModal';
import { CategoriesTable } from 'widgets/Partners/ui/CategoriesTable';

export const CategoriesSettings: FC = () => {
	const { partnerId } = useParams();
	const { t } = useTranslation();
	const { setCustomModels } = useCustomModelStore();
	const [isOpenCategoryModal, setIsOpenCategoryModal] = useState(false);
	const [categoriesList, setCategoriesList] = useState<CategoriesSettingsType>();

	const mappedCategories = useMemo(
		() => categoriesList?.categories.map((item) => item.customModelId ?? item.category),
		[categoriesList],
	);

	useQuery({
		queryKey: ['get/custom-models'],
		queryFn: () => getCustomModels(),
		onSuccess: (response) => {
			const filteredModels = response.data.filter((item) => item.status === ModelStatuses.trained);
			setCustomModels(filteredModels);
		},
		onError: (err) => {
			const errorMessage = getErrorMessage(err);
			enqueueSnackbar({ message: errorMessage, variant: 'error' });
		},
	});

	useQuery({
		queryKey: ['get/partners/categories-settings', partnerId],
		queryFn: () => getCategoriesSettings(partnerId || ''),
		onSuccess: (response) => setCategoriesList(response.data),
		onError: (err) => {
			const errorMessage = getErrorMessage(err);
			enqueueSnackbar({ message: errorMessage, variant: 'error' });
		},
	});

	const { mutate: updateCategoriesSettingsMutate, isLoading: putIsLoading } = useMutation({
		mutationKey: ['put/partners/categories-settings'],
		mutationFn: updateCategoriesSettings,
	});

	const saveCategoriesHandler = (categories: CategoriesSettingsType, isRemoving?: boolean) => {
		if (partnerId) {
			updateCategoriesSettingsMutate(
				{ partnerId, data: categories },
				{
					onSuccess: async (response) => {
						setCategoriesList(response.data);
						if (isRemoving) {
							enqueueSnackbar({
								message: t('partners.snackbar.success_category_removing'),
								variant: 'success',
							});
						} else {
							enqueueSnackbar({
								message: t('partners.snackbar.success_category_updating'),
								variant: 'success',
							});
						}
						setIsOpenCategoryModal(false);
					},
					onError: (err) => {
						const errorMessage = getErrorMessage(err);
						enqueueSnackbar({ message: errorMessage, variant: 'error' });
					},
				},
			);
		}
	};
	const deleteCategoryHandler = (id: string) => {
		const newCategories = categoriesList?.categories.filter((item) =>
			item.customModelId ? item.customModelId !== id : item.category !== id,
		);
		saveCategoriesHandler({ categories: newCategories || [] }, true);
	};

	return (
		<>
			<CategoriesModal
				categoriesList={mappedCategories || []}
				open={isOpenCategoryModal}
				handleClose={() => setIsOpenCategoryModal(false)}
				isLoading={putIsLoading}
				saveCategories={saveCategoriesHandler}
			/>
			<Typography variant={'subtitle1'} color={ColorPalette.baliHai}>
				{t('partners.categories_settings_description')}
			</Typography>
			<Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px', paddingTop: '30px' }}>
				<CategoriesTable data={categoriesList} deleteHandler={deleteCategoryHandler} />

				<Button
					size="small"
					sx={{ alignSelf: 'flex-end' }}
					onClick={() => setIsOpenCategoryModal(true)}
				>
					{t('shared_texts.add')}
				</Button>
			</Box>
		</>
	);
};
