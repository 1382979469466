import { FunctionComponent, useState } from 'react';
import { useAsyncEffect } from 'shared/helpers/useAsyncEffect';

export const CachedSvgSprite: FunctionComponent = () => {
	const [svgData, setSvgData] = useState('');

	useAsyncEffect(async () => {
		const response = await fetch('/assets/sprite.svg');
		const svgText = await response.text();
		setSvgData(svgText);
	}, []);

	return <div className={'cached-svg-sprite'} dangerouslySetInnerHTML={{ __html: svgData }} />;
};
