import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { deleteSessionFile, retryImportFileFromFtp } from 'entities/ImportSessions/api';
import { SessionFile } from 'entities/ImportSessions/types';
import { CategoryTypeEnum } from 'entities/Files/types';
import { enqueueSnackbar } from 'notistack';
import { FC, useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Shadow } from 'shared/consts/shadow';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { getFilteredArray } from 'shared/helpers/getFilteredArray';
import { useSearchParam } from 'shared/helpers/useSearchParam';
import { TabButton } from 'shared/ui/TabButton';
import { TableWithHeader } from 'shared/ui/tables/TableWithHeader';
import emptyFilesDashboard from 'shared/assets/empty-files-dashboard.png';
import { ImportFilesDashboardColumns } from 'widgets/importFiles/common/ui/ImportFilesDashboard/consts/DashboardColumns';
import { Loader } from 'shared/ui/Loader';

interface Props {
	categoryType?: CategoryTypeEnum;
	files?: Array<SessionFile>;
}

export const ImportFilesDashboard: FC<Props> = ({ files, categoryType }) => {
	const { t } = useTranslation();
	const [searchParam, setSearchParam] = useSearchParam('status', 'UPLOADED');
	const [stateFiles, setStateFiles] = useState<Array<SessionFile>>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { mutate: mutateRetryImportFile } = useMutation({
		mutationKey: ['import-sessions'],
		mutationFn: retryImportFileFromFtp,
	});

	useEffect(() => {
		setStateFiles(files ?? []);
	}, [files]);

	const filteredFiles = useMemo(() => {
		if (!stateFiles) return [];
		if (!searchParam) return stateFiles;

		return getFilteredArray(stateFiles, ['status'], searchParam);
	}, [stateFiles, searchParam]);

	const allFilesCounter = useMemo(() => stateFiles?.length, [stateFiles]);
	const uploadingFilesCounter = useMemo(
		() => getFilteredArray(stateFiles ?? [], ['status'], 'UPLOADING')?.length,
		[stateFiles],
	);
	const uploadedFilesCounter = useMemo(
		() => getFilteredArray(stateFiles ?? [], ['status'], 'UPLOADED')?.length,
		[stateFiles],
	);
	const errorFilesCounter = useMemo(
		() => getFilteredArray(stateFiles ?? [], ['status'], 'ERROR')?.length,
		[stateFiles],
	);

	const handleDeleteFile = (id: string) => {
		setIsLoading(true);
		deleteSessionFile(id)
			.then(() => {
				setStateFiles((prevState) => prevState.filter((file) => file._id !== id));
				setIsLoading(false);
				enqueueSnackbar({
					message: t('start_recognition.snackbar.file_deleted'),
					variant: 'success',
				});
			})
			.catch((err) => {
				const defaultErrorMessage = t('start_recognition.removal_error');
				const errorMessage = getErrorMessage(err, defaultErrorMessage);
				enqueueSnackbar({
					message: errorMessage,
					variant: 'error',
				});
			});
	};

	const handleRetryImportFile = (id: string) => {
		mutateRetryImportFile(id, {
			onSuccess: async () => {
				enqueueSnackbar({
					message: 'File was sent to recognition.',
					variant: 'success',
				});
			},
			onError: (err) => {
				const defaultErrorMessage = t('start_recognition.removal_error');
				const errorMessage = getErrorMessage(err, defaultErrorMessage);
				enqueueSnackbar({
					message: errorMessage,
					variant: 'error',
				});
			},
		});
	};

	return (
		<>
			<Typography variant={'subtitle1'} color={ColorPalette.astronaut} sx={{ margin: '64px 0 42px 0' }}>
				{t('shared_texts.total_files', { amount: allFilesCounter })}
			</Typography>
			{stateFiles?.length ? (
				<>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'flex-start',
							alignItems: 'center',
							gap: '20px',
							marginBottom: '44px',
						}}
					>
						<TabButton
							label={t('start_recognition.total_files_table.tabs.uploading', {
								amount: uploadingFilesCounter,
							})}
							isActive={searchParam === 'UPLOADING'}
							action={() => setSearchParam('UPLOADING')}
						/>
						<TabButton
							label={t('start_recognition.total_files_table.tabs.uploaded', {
								amount: uploadedFilesCounter,
							})}
							isActive={searchParam === 'UPLOADED'}
							action={() => setSearchParam('UPLOADED')}
						/>
						<TabButton
							label={t('start_recognition.total_files_table.tabs.error', {
								amount: errorFilesCounter,
							})}
							isActive={searchParam === 'ERROR'}
							action={() => setSearchParam('ERROR')}
						/>
					</Box>

					{filteredFiles.length ? (
						<>
							{isLoading && <Loader type="min" />}
							{!isLoading && (
								<TableWithHeader
									columns={ImportFilesDashboardColumns}
									rows={filteredFiles.map((file) => ({
										...file,
										id: file._id,
										categoryType,
										onDelete: handleDeleteFile,
										onRetry: handleRetryImportFile,
									}))}
								/>
							)}
						</>
					) : (
						<Typography
							variant={'subtitle1'}
							color={ColorPalette.astronaut}
							sx={{ margin: 'auto', width: '100%', textAlign: 'center' }}
						>
							{t('shared_texts.no_files')}
						</Typography>
					)}
				</>
			) : (
				<Box
					sx={{
						height: '383px',
						width: '100%',
						padding: '40px',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						backgroundColor: ColorPalette.white,
						boxShadow: Shadow['2'],
						borderRadius: '20px',
					}}
				>
					<img
						src={emptyFilesDashboard}
						alt={'empty-files-dashboard'}
						style={{ width: '134px', height: '134px', marginBottom: '20px' }}
					/>
					<Typography variant={'h5'} color={ColorPalette.manatee}>
						{t('shared_texts.no_uploaded_files')}
					</Typography>
				</Box>
			)}
		</>
	);
};
