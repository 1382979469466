import { FC, useEffect, useState } from 'react';
import { Box, Slider, Typography } from '@mui/material';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import RegionSelect from 'react-region-select';
import { Document, Page, pdfjs } from 'react-pdf';
import { enqueueSnackbar } from 'notistack';
import { Routes } from 'app/config/routes/consts';
import { AddLabelModal } from 'widgets/editFile/AddLabelModal';
import { FileDetails } from 'widgets/editFile/FileDetails';
import { FileLabels } from 'widgets/editFile/FileLabels';
import { FileNavigation } from 'widgets/editFile/FileNavigation';
import { Loader } from 'shared/ui/Loader';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Shadow } from 'shared/consts/shadow';
import { Icon } from 'shared/ui/Icon';
import { ActionsForDialog } from 'shared/ui/ActionsForDialog';
import { FileType } from 'entities/Files/types';
import { retryImportFile } from 'entities/ImportSessions/api';
import { retryImportModelFile } from 'entities/CustomModel/api';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { useFilesStore } from 'widgets/editFile/model/state';
import { Table } from './Table';
import { EditTableLabelModal } from '../EditTableLabelModal';
import { AddTableModal } from '../AddTableModal';
import { DeleteTableModal } from '../DeleteTableModal';
import styles from './file.module.scss';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `http://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface Props {
	file?: FileType;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	allLabels: any[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	labels: any[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	tableLabels?: any[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setLabels: any;
	totalFilePages: number | null;
	currentFilePage: number;
	changeTotalFilePages: (n: number) => void;
	changeCurrentFilePage: (n: number) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	externallyChangedFile?: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	handleSetLabels?: any;
	setLabelsWithPages?: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	submitChanges?: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	tableLabelsData?: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setTableLabelsData?: any;
	modelId?: string | undefined;
	currentCustomModelId?: string | undefined;
	labelsWithPages: any;
	setAllLabels: any;
}

const regionStyle = {
	background: 'rgba(71, 121, 230, 0.14)',
	border: '2px solid #908FFA',
	zIndex: '20',
};

export const FilePreview: FC<Props> = ({
	file,
	modelId,
	allLabels,
	submitChanges,
	labels,
	tableLabels,
	tableLabelsData,
	setTableLabelsData,
	setLabels: setCurrentLabels,
	//externallyChangedFile,
	totalFilePages,
	currentFilePage,
	changeTotalFilePages,
	changeCurrentFilePage,
	handleSetLabels,
	setLabelsWithPages,
	currentCustomModelId,
	labelsWithPages,
	setAllLabels,
}) => {
	const { t } = useTranslation();
	const fileUrl = `${file?.host}/${file?.convertedFilePath}`;

	const navigationPages = totalFilePages ? Array.from({ length: totalFilePages }, (_, i) => i + 1) : [];

	const navigate = useNavigate();
	const { filePartnerId, setFilePartnerId } = useFilesStore();

	const [openFileDetails, setOpenFileDetails] = useState<boolean>(true);
	const toggleFileDetails = () => setOpenFileDetails((prevState) => !prevState);
	const [openFileLabels, setOpenFileLabels] = useState<boolean>(false);
	const toggleFileLabels = () => setOpenFileLabels((prevState) => !prevState);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [regionModal, setRegionModal] = useState<any>(null);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [tableRegion, setTableRegion] = useState<any>(null);
	const [zoomDocValue, setZoomDocValue] = useState(0.7);
	const [openTableModal, setOpenTableModal] = useState<boolean>(false);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [labelsMixed, setLabelsMixed] = useState<any>(null);
	const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
	const [deleteTableIndex, setDeleteTableIndex] = useState<number>(0);

	const { mutate: modelMutation } = useMutation({
		mutationKey: ['put/custom-models/file-labels'],
		mutationFn: retryImportModelFile,
	});

	const { mutate: fileMutation } = useMutation({
		mutationKey: ['patch/files/recognition-result'],
		mutationFn: retryImportFile,
	});

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const labelsMixedArr: any[] = [];
		let index = labels.length;
		tableLabels
			?.reduce((acc: [], item) => [...acc, item.tableValues.flat()], [])
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			.map((tableItem: any, tableIndex: number) => {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				return tableItem.map((item: any) => ({
					...item.boundingRegions?.[0].boxPosition, // x, y, height, width,
					data: {
						index: index++,
						columnIndex: item.columnIndex,
						rowIndex: item.rowIndex,
						tableIndex,
						isTable: true,
					},
					page: item.boundingRegions?.[0].pageNumber,
					name: item.content + '_' + index,
					text: item.content,
					id: '',
				}));
			})
			.flat()
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			.forEach((item: any) => {
				item.x && item.text !== '' && item.page === currentFilePage && labelsMixedArr.push(item);
			});
		setLabelsMixed([...labelsMixedArr, ...labels]);
	}, [labels, tableLabels]);

	const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
		changeTotalFilePages(numPages);
	};

	function generateUniqueNumber(excludeList: any) {
		let num = Math.floor(Math.random() * 900) + 100;
		while (excludeList.includes(num)) {
			num = Math.floor(Math.random() * 900) + 100;
		}
		return num;
	}
	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	const onRegionChange = (regions: any) => {
		//eslint-disable-next-line @typescript-eslint/no-explicit-any
		const regionsLabels = regions.filter((item: any) => !item.data?.isTable);
		const regionsAllLabels = [...allLabels, regionsLabels[regionsLabels.length - 1]].filter(
			(item: any) => !item.data?.isTable,
		);
		const mappedToIndexArr = regionsLabels.map((item: any) => item.data.index);
		const countIndex = generateUniqueNumber(mappedToIndexArr);
		regionsLabels[regionsLabels.length - 1]['data'] = {
			index: countIndex,
		};
		regionsAllLabels[regionsAllLabels.length - 1]['data'] = {
			index: countIndex,
		};
		setCurrentLabels(regionsLabels);
		const checkEmptyName = regionsAllLabels.filter((item) => !item.name);
		if (modelId && checkEmptyName.length === 1) {
			setAllLabels(regionsAllLabels);
		} else if (modelId && checkEmptyName.length > 1) {
			regionsAllLabels.splice(-2, 1);
			setAllLabels(regionsAllLabels);
		}
	};

	const addRegion = (newRegion: any, replacedRegionIndex: number) => {
		const labelsToMap = modelId ? allLabels : labels;
		const copyLabelsWithPages = { ...labelsWithPages };
		let mappedRegions: any = [];
		if (typeof replacedRegionIndex === 'number') {
			const oldRegion: any = labelsToMap?.find((item) => item.data.index === newRegion.data.index);

			mappedRegions = labelsToMap
				?.filter((item) => item?.name)
				.map((item) =>
					item.data.index === newRegion.data.index
						? { ...newRegion, qwerr: 3333 }
						: item.data.index === replacedRegionIndex && oldRegion.name
						? {
								...item,
								name: oldRegion.name,
								text: '',
								id: oldRegion.id || '',
								x: -100,
								y: -100,
								height: 0,
								width: 0,
								emptyText: true,
								pp: 1,
						  }
						: item.data.index === replacedRegionIndex && !oldRegion.name
						? {
								...item,
								text: newRegion.text,
								x: newRegion.x,
								y: newRegion.y,
								height: newRegion.height,
								width: newRegion.width,
								page: newRegion.page,
								wwwww: 0,
						  }
						: { ...item, mmm: 111 },
				);
		} else {
			mappedRegions = labelsToMap?.map((item) =>
				item.data.index === newRegion.data.index ? newRegion : item,
			);
		}

		if (modelId) {
			const currentLabels = mappedRegions.filter((item: any) => item.page === currentFilePage);
			setCurrentLabels(currentLabels);
			setAllLabels(mappedRegions);
			Object.keys(copyLabelsWithPages).map((key) => {
				if (+key === currentFilePage) {
					return (copyLabelsWithPages[key] = currentLabels);
				}
				return (copyLabelsWithPages[key] = mappedRegions.filter((item: any) => item.page === +key));
			});
			setLabelsWithPages(copyLabelsWithPages);
		} else {
			setCurrentLabels(mappedRegions);
			Object.keys(copyLabelsWithPages).map((key) => {
				if (+key === currentFilePage) {
					return (copyLabelsWithPages[key] = mappedRegions);
				}
				return copyLabelsWithPages[key];
			});
			setLabelsWithPages(copyLabelsWithPages);
		}
	};
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const deleteRegion = (index: any) => {
		const copyLabelsWithPages = { ...labelsWithPages };
		const regionArr = modelId ? allLabels : labels;
		const newRegionArr = regionArr.filter((item) => item.data.index !== index);
		if (modelId) {
			const currentLabels = newRegionArr.filter((item: any) => item.page === currentFilePage);
			setCurrentLabels(currentLabels);
			setAllLabels(newRegionArr);
			Object.keys(copyLabelsWithPages).map((key) => {
				if (+key === currentFilePage) {
					return (copyLabelsWithPages[key] = currentLabels);
				}
				return (copyLabelsWithPages[key] = newRegionArr.filter((item: any) => item.page === +key));
			});
			setLabelsWithPages(copyLabelsWithPages);
		} else {
			setCurrentLabels(newRegionArr);
			Object.keys(copyLabelsWithPages).map((key) => {
				if (+key === currentFilePage) {
					return (copyLabelsWithPages[key] = newRegionArr);
				}
				return copyLabelsWithPages[key];
			});
			setLabelsWithPages(copyLabelsWithPages);
		}
	};

	const handleSubmit = () => {
		const submit = submitChanges();
		if (modelId) {
			modelMutation(
				{
					customModelFileId: file?._id || '',
					data: { ...submit },
				},
				{
					onSuccess: () => {
						handleCancel();
					},
					onError: (err) => {
						const defaultErrorMessage = t('files.snackbar.error_while_uploading');
						const errorMessage = getErrorMessage(err, defaultErrorMessage);
						enqueueSnackbar({ message: errorMessage, variant: 'error' });
					},
				},
			);
		} else {
			fileMutation(
				{
					id: file?._id || '',
					data: {
						recognizedLabels: submit?.labels || [],
						assertionSetsId: file?.assertionSetsId || null,
						isPreview: false,
						autocomplete: file?.autocomplete,
					},
				},
				{
					onSuccess: () => {
						handleCancel();
					},
					onError: (err) => {
						const defaultErrorMessage = t('files.snackbar.error_while_uploading');
						const errorMessage = getErrorMessage(err, defaultErrorMessage);
						enqueueSnackbar({ message: errorMessage, variant: 'error' });
					},
				},
			);
		}
	};

	const handleCancel = () => {
		setCurrentLabels([]);
		if (filePartnerId) {
			navigate(`${Routes.partners}/${filePartnerId}/documents`);
			setFilePartnerId(null);
		} else if (currentCustomModelId && modelId) {
			navigate(`/import-model/${currentCustomModelId}`);
		} else {
			navigate('/files/all');
		}
	};

	const addNewEmptyTabel = (name: string) => {
		const tableArr = [...tableLabelsData];
		const tableObj = {
			label: name,
			type: 'table',
			columnsHeaders: [
				{
					columnName: `Column_0`,
				},
			],
			cells: [
				{
					content: '',
					rowIndex: 0,
					columnIndex: 0,
				},
			],
		};
		tableArr.push(tableObj);
		setTableLabelsData(tableArr);
	};

	const handleOpenTableModal = () => {
		setOpenTableModal(true);
	};

	const handleCloseTableModal = () => {
		setOpenTableModal(false);
	};

	const handleOpenDeleteModal = (indexArray: number) => {
		setOpenDeleteModal(true);
		setDeleteTableIndex(indexArray);
	};

	const handleCloseDeleteModal = () => {
		setOpenDeleteModal(false);
		setDeleteTableIndex(0);
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleOpen = (region: any) => {
		if (region.data.isTable) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const tableRegion = tableLabels?.[region.data.tableIndex].tableValues.flat().find((item: any) => {
				return item.rowIndex === region.data.rowIndex && item.columnIndex === region.data.columnIndex;
			});
			setTableRegion({
				region: tableRegion,
				index: region.data.columnIndex,
				indexArray: region.data.tableIndex,
			});
		} else {
			setRegionModal(region);
		}
	};
	const handleClose = () => setRegionModal(null);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleTableModalOpen = (region: any) => {
		setTableRegion(region);
	};
	const handleTableModalClose = () => setTableRegion(null);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const regionRenderer = (regionProps: any) => {
		if (!regionProps.isChanging || regionProps.data.isTable) {
			const findLabel = labels.find((item) => item.data.index === regionProps.data.index);
			const region = findLabel ? { ...findLabel, data: regionProps.data } : { data: regionProps.data };
			return (
				<div
					style={{
						height: '100%',
						cursor: 'pointer',
						margin: '2px',
					}}
					onClick={() => {
						handleOpen(region);
					}}
				/>
			);
		}
	};

	const handleScaleChangeSlider = (event: Event, value: number | number[]) => {
		setZoomDocValue(value as number);
	};

	return (
		<>
			<AddLabelModal
				labels={modelId ? allLabels : labels}
				modelId={modelId}
				region={regionModal}
				currentFilePage={currentFilePage}
				handleClose={handleClose}
				onAddLabel={addRegion}
				file={file}
				editMode={regionModal?.text && regionModal?.name}
				deleteRegion={deleteRegion}
			/>
			<EditTableLabelModal
				modelId={modelId}
				file={file}
				region={tableRegion}
				lastChangeRegion={labels?.length && labels[labels?.length - 1]}
				currentFilePage={currentFilePage}
				handleClose={handleTableModalClose}
				allTableLabels={tableLabelsData}
				setTableLabelsData={setTableLabelsData}
				deleteRegion={deleteRegion}
			/>
			<AddTableModal
				open={openTableModal}
				handleClose={handleCloseTableModal}
				addTable={addNewEmptyTabel}
			/>
			<DeleteTableModal
				open={openDeleteModal}
				handleClose={handleCloseDeleteModal}
				indexArray={deleteTableIndex}
				allTableLabels={tableLabelsData}
				setTableLabelsData={setTableLabelsData}
			/>
			<Box
				sx={{
					width: '103px',
					height: '60px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					background: ColorPalette.zircon,
					borderRadius: '10px',
					border: `1px solid ${ColorPalette.periwinkle}`,
					marginBottom: '24px',
				}}
			>
				<Typography variant={'button'} color={ColorPalette.astronaut}>
					{currentFilePage}/{totalFilePages ?? '-'}
				</Typography>
			</Box>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'flex-start',
					justifyContent: 'space-between',
					gap: '20px',
				}}
			>
				<Box
					sx={{
						'position': 'sticky',
						'top': '20px',
						'width': '60%',
						'display': 'flex',
						'flexDirection': 'column',
						'flexGrow': '2',
						'@media screen and (min-width: 2561px)': {
							flexGrow: '4',
							width: '80%',
						},
					}}
				>
					<Document
						file={fileUrl}
						onLoadSuccess={onDocumentLoadSuccess}
						className={styles.react_pdf_document}
						loading={<Loader width="70vw" height="85vh" />}
					>
						<FileNavigation
							handleSetLabels={handleSetLabels}
							navigationPages={navigationPages}
							currentFilePage={currentFilePage}
							changeCurrentFilePage={changeCurrentFilePage}
						/>
						<Box
							sx={{
								border: `2px solid ${ColorPalette.periwinkle}`,
								borderRadius: '20px',
								overflow: 'auto',
								height: '85vh',
								width: '100vw',
								textAlign: 'center',
								backgroundColor: '#f0f0f0',
							}}
						>
							<RegionSelect
								regions={labelsMixed}
								regionStyle={regionStyle}
								onChange={onRegionChange}
								regionRenderer={regionRenderer}
								disabled
							>
								<Page
									pageNumber={currentFilePage}
									scale={zoomDocValue}
									className={styles.react_pdf_pages}
								/>
							</RegionSelect>
						</Box>
					</Document>
					<Slider
						max={2}
						min={1}
						step={0.1}
						sx={{
							width: '40%',
							margin: '20px auto 0',
						}}
						onChange={handleScaleChangeSlider}
					/>
				</Box>
				<Box
					sx={{
						'display': 'flex',
						'flexDirection': 'column',
						'gap': '20px',
						'flexGrow': '1',
						'width': '30%',
						'@media screen and (min-width: 1441px)': {
							width: '15%',
						},
					}}
				>
					<FileDetails
						openFileDetails={openFileDetails}
						toggleFileDetails={toggleFileDetails}
						file={file}
						submitChanges={submitChanges}
					/>
					<FileLabels
						labels={modelId ? allLabels : labels}
						deleteRegion={deleteRegion}
						handleOpen={handleOpen}
						openFileLabels={openFileLabels}
						toggleFileLabels={toggleFileLabels}
						file={file}
					/>
					{tableLabels &&
						tableLabels?.length > 0 &&
						tableLabels?.map((item, index) => {
							return (
								<Table
									key={index}
									indexArray={index}
									columnsHeaders={item?.columnsHeaders}
									tableKeys={item?.tableKeys}
									tableValues={item?.tableValues}
									labels={tableLabelsData[index]?.cells ?? []}
									allTableLabels={tableLabelsData}
									handleOpen={handleTableModalOpen}
									setTableLabelsData={setTableLabelsData}
									currentPage={currentFilePage}
									modelId={modelId}
									handleOpenDeleteModal={handleOpenDeleteModal}
								/>
							);
						})}
					<Box
						sx={{
							maxWidth: '100%',
							minHeight: '50px',
							borderRadius: '20px',
							background: ColorPalette.white,
							boxShadow: Shadow['2'],
							overflow: 'auto',
							padding: '20px',
						}}
					>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'space-between',
								cursor: 'pointer',
								marginLeft: '20px',
							}}
							onClick={handleOpenTableModal}
						>
							<Typography variant={'h5'} color={ColorPalette.astronaut}>
								{t('files.details.add_new_table')}
							</Typography>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
								}}
							>
								<Icon id={'add_round_blue'} style={{ marginRight: '19px' }} />
							</Box>
						</Box>
					</Box>
					<ActionsForDialog
						cancelLabel={t('shared_texts.cancel')}
						cancelAction={handleCancel}
						applyLabel={t('shared_texts.save')}
						applyAction={handleSubmit}
					/>
				</Box>
			</Box>
		</>
	);
};
