import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { changeEmail } from 'entities/Auth/api';
import { Form, Formik, FormikProps } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { useAuthStore } from 'processes/auth/model/state';
import { FunctionComponent } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { ColorPalette } from 'shared/consts/colorPalette';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { isEmptyObject } from 'shared/helpers/isEmptyObject';
import { isEmptyObjectValues } from 'shared/helpers/isEmptyObjectValues';
import { Button } from 'shared/ui/Button';
import { InputField } from 'shared/ui/InputField';
import { Paper } from 'shared/ui/Paper';
import { BackButton } from 'shared/ui/BackButton';
import { ChangeEmailValidationSchema } from 'widgets/auth/changeEmail/_MainModal/consts/validation';
import { ChangeEmail } from 'widgets/auth/changeEmail/_MainModal/types';

export const CEMainModal: FunctionComponent = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const { reset } = useAuthStore();
	const { mutate } = useMutation({
		mutationFn: changeEmail,
	});

	const initialValues = {
		email: '',
		confirmEmail: '',
	};

	const handleSubmit = (values: ChangeEmail): void => {
		const mutationValues = {
			email: location.state.email as string,
			password: location.state.password as string,
			newEmail: values.email,
		};
		mutate(mutationValues, {
			onSuccess: () => {
				reset();
				navigate('/change-email/email-sent', { state: { access: true, email: values.email } });
			},
			onError: (err) => {
				const defaultErrorMessage = t('shared_texts.smth_went_wrong');
				const errorMessage = getErrorMessage(err, defaultErrorMessage);
				enqueueSnackbar({
					message: errorMessage,
					variant: 'error',
				});
			},
		});
	};

	return (
		<Paper
			title={t('auth.new_email') as string}
			sx={{
				'& *': {
					userSelect: 'none',
				},
			}}
		>
			<BackButton action={() => navigate(-1)} />
			<Typography variant={'body1'} color={ColorPalette.baliHai} sx={{ marginBottom: '35px' }}>
				{t('auth.enter_new_email')}
			</Typography>

			<Formik
				initialValues={initialValues}
				validationSchema={ChangeEmailValidationSchema}
				onSubmit={handleSubmit}
			>
				{({ errors, values }: FormikProps<ChangeEmail>) => (
					<Form>
						<InputField
							name={'email'}
							label={t('auth.new_email')}
							placeholder={t('auth.enter_new_email')}
							required
						/>
						<InputField
							name={'confirmEmail'}
							label={t('auth.labels.email_confirm')}
							placeholder={t('auth.labels.email_confirm')}
							required
						/>
						<Button
							disabled={!isEmptyObject(errors) || isEmptyObjectValues(values)}
							type={'submit'}
							fullWidth
						>
							{t('shared_texts.continue')}
						</Button>
					</Form>
				)}
			</Formik>
		</Paper>
	);
};
