import { emailRegEx } from 'shared/consts/emailRegEx';
import { passwordRegEx } from 'shared/consts/passwordRegEx';
import * as yup from 'yup';

export const SignInValidationSchema = yup.object({
	email: yup
		.string()
		.required('auth.validation.enter_email')
		.matches(emailRegEx, { message: 'auth.validation.email_not_valid' })
		.max(50, 'auth.validation.email_too_long'),
	password: yup
		.string()
		.required('auth.validation.enter_password')
		.matches(passwordRegEx, {
			message: 'auth.validation.password_requirements',
		})
		.max(50, 'auth.validation.password_too_long'),
});
