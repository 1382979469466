import { RootLayout } from 'pages/_layouts/RootLayout';
import { useAuthStore } from 'processes/auth/model/state';
import { FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';

export const PrivacyPolicyLayout: FunctionComponent = () => {
	const { accessToken, user, company } = useAuthStore();

	if (!accessToken || !user || !company) {
		return (
			<>
				<Outlet />
			</>
		);
	}

	return <RootLayout />;
};
