import { Box, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ChangeLanguage } from 'shared/ui/ChangeLanguage';
import { SnackbarProvider } from 'notistack';
import { FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';
import { Config } from 'shared/config/_common';
import { ColorPalette } from 'shared/consts/colorPalette';
import authBackground from 'shared/assets/auth-background.png';
import logo from 'shared/assets/logo-white.png';
import { Snackbar } from 'widgets/Snackbar';

export const AuthLayout: FunctionComponent = () => {
	const { t } = useTranslation();
	return (
		<Box
			sx={{
				position: 'relative',
				width: '100vw',
				backgroundColor: ColorPalette.catskillWhite,
			}}
		>
			<Box
				component="main"
				sx={{
					'width': '60%',
					'minHeight': '100vh',
					'padding': '80px',
					'display': 'flex',
					'flexDirection': 'column',
					'& > .MuiPaper-root': {
						margin: '0px auto',
					},
					'& .notistack-SnackbarContainer': {
						'width': 'calc(60% - 80px)',
						'right': '40px',
						'& > div': {
							width: '100%',
						},
					},
				}}
			>
				<Box sx={{ position: 'fixed', top: '25px', right: 'calc(40% + 25px)', zIndex: '20' }}>
					<ChangeLanguage auth />
				</Box>
				<Box sx={{ flex: '1' }}></Box>
				<SnackbarProvider
					maxSnack={2}
					anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
					Components={{
						success: Snackbar,
						error: Snackbar,
						warning: Snackbar,
						info: Snackbar,
						default: Snackbar,
					}}
				>
					<Outlet />
				</SnackbarProvider>
				<Box sx={{ flex: '1' }}></Box>
			</Box>
			<Box
				sx={{
					'position': 'fixed',
					'right': '0',
					'top': '0',
					'height': '100%',
					'width': '40%',
					'display': 'flex',
					'flexDirection': 'column',
					'justifyContent': 'center',
					'alignItems': 'center',
					'borderRadius': '0px 0px 0px 100px',
					'backgroundImage': `url(${authBackground})`,
					'backgroundRepeat': 'no-repeat',
					'backgroundSize': 'cover',
					'& > img': {
						height: '100%',
					},
				}}
			>
				<img alt={'logo'} src={logo} style={{ width: '140px', height: '140px' }} />
				<Typography variant={'h2'} color={ColorPalette.white} sx={{ margin: '24px 0 80px' }}>
					Retica
				</Typography>
				<Box
					sx={{
						padding: '35px 30px 30px',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						border: `2px solid ${ColorPalette.white}33`,
						borderRadius: '20px',
					}}
				>
					<Typography variant={'subtitle1'} color={ColorPalette.white}>
						{t('auth.learn_more')}
					</Typography>
					<Link
						variant={'h4'}
						style={{ cursor: 'pointer' }}
						color={ColorPalette.white}
						href={Config.APP_URL}
						target={'_blank'}
						sx={{ textDecoration: 'none', marginTop: '12px' }}
					>
						retica.ai
					</Link>
				</Box>
			</Box>
		</Box>
	);
};
