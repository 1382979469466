import { Typography, Box } from '@mui/material';
import { CoUser, CoUserStatusEnum } from 'entities/CoUser/types';
import { format } from 'date-fns';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Icon } from 'shared/ui/Icon';
import { OptionsDropdown } from 'shared/ui/OptionsDropdown';
import { Column } from 'shared/ui/tables/_common/types';
import { TableTranslationLabel } from 'shared/ui/tables/_common/ui/TableTranslationLabel';

export type CoUsersDashboardType = CoUser & {
	authUserId: string;
	openModal: (coUser: CoUser) => void;
	navigateToDetails: (coUser: CoUser) => void;
	isDealer: boolean;
};

export const CoUsersDashboardColumns: Array<Column<CoUsersDashboardType>> = [
	{
		field: 'status',
		commonCellSettings: {
			style: {
				minWidth: '70px',
				maxWidth: '70px',
			},
			align: 'center',
		},
		bodyCellSettings: {
			renderCell: (coUser) => {
				const isCoUserActive =
					coUser?.status === CoUserStatusEnum.active ||
					coUser?.status === CoUserStatusEnum.api_only;
				const icon = isCoUserActive ? 'done_ring_round' : 'send_fill';
				const iconColor = isCoUserActive ? ColorPalette.jungleGreen : ColorPalette.fuelYellow;
				return <Icon id={icon} color={iconColor} />;
			},
		},
	},
	{
		field: 'email',
		commonCellSettings: {
			style: {
				minWidth: '250px',
				maxWidth: '250px',
				overflow: 'hidden',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="email" />,
		},
		bodyCellSettings: {
			prepareField: (coUser) => coUser?.userId?.email ?? coUser?.email ?? '-',
		},
	},
	{
		field: 'firstName',
		commonCellSettings: {
			style: {
				minWidth: '160px',
				maxWidth: '160px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="first_name" />,
		},
		bodyCellSettings: {
			prepareField: (coUser) => coUser?.userId?.firstName ?? '-',
		},
	},
	{
		field: 'lastName',
		commonCellSettings: {
			style: {
				minWidth: '160px',
				maxWidth: '160px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="last_name" />,
		},
		bodyCellSettings: {
			prepareField: (coUser) => coUser?.userId?.lastName ?? '-',
		},
	},
	{
		field: 'dealerName',
		commonCellSettings: {
			style: {
				minWidth: '130px',
				maxWidth: '130px',
			},
		},
		headerCellSettings: {
			renderCell: (coUser) => (coUser?.isDealer ? <TableTranslationLabel label="dealer_name" /> : null),
		},
		bodyCellSettings: {
			renderCell: (coUser) => (coUser?.isDealer ? coUser?.dealerName || '-' : null),
		},
	},
	{
		field: 'companyName',
		commonCellSettings: {
			style: {
				minWidth: '130px',
				maxWidth: '130px',
			},
		},
		headerCellSettings: {
			renderCell: (coUser) =>
				coUser?.isDealer ? <TableTranslationLabel label="company_name" /> : null,
		},
		bodyCellSettings: {
			renderCell: (coUser) => (coUser?.isDealer ? coUser?.companyName || '-' : null),
		},
	},
	{
		field: 'importedFiles',
		commonCellSettings: {
			style: {
				minWidth: '130px',
				maxWidth: '130px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="imported_files" />,
		},
		bodyCellSettings: {
			prepareField: (coUser) => {
				return coUser?.filesCounter || '-';
			},
		},
	},
	{
		field: 'totalPages',
		commonCellSettings: {
			style: {
				minWidth: '130px',
				maxWidth: '130px',
			},
		},
		headerCellSettings: {
			renderCell: (coUser) => (coUser?.isDealer ? <TableTranslationLabel label="total_pages" /> : null),
		},
		bodyCellSettings: {
			renderCell: (coUser) => (coUser?.isDealer ? coUser?.totalPages || '-' : null),
		},
	},
	{
		field: 'invitedBy',
		commonCellSettings: {
			style: {
				minWidth: '200px',
				maxWidth: '200px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="invited_by" />,
		},
		bodyCellSettings: {
			renderCell: (coUser) => {
				let invitedByFullName = '-';
				if (coUser?.invitedBy.firstName && coUser.invitedBy.lastName) {
					invitedByFullName = `${coUser.invitedBy.firstName} ${coUser.invitedBy.lastName}`;
				}
				return (
					<>
						<Typography variant={'body2'} component={'span'} color={ColorPalette.astronaut}>
							{invitedByFullName}
						</Typography>
						{coUser?.invitedBy._id === coUser?.authUserId && (
							<Typography
								variant={'body2'}
								component={'span'}
								color={ColorPalette.astronaut}
								sx={{ fontWeight: 300 }}
							>
								{' (me)'}
							</Typography>
						)}
					</>
				);
			},
		},
	},
	{
		field: 'joined',
		commonCellSettings: {
			style: {
				minWidth: '190px',
				maxWidth: '190px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="joined_the_system" />,
		},
		bodyCellSettings: {
			renderCell: (coUser) => {
				return coUser?.joinedAt ? (
					<Typography variant={'body2'} component={'span'} color={ColorPalette.astronaut}>
						{format(new Date(coUser.joinedAt), 'HH:mm\u00A0\u00A0\u00A0dd/MM/yyyy')}
					</Typography>
				) : (
					<Typography variant={'body2'} component={'span'} color={ColorPalette.baliHai}>
						{'--:--\u00A0\u00A0\u00A0--/--/----'}
					</Typography>
				);
			},
		},
	},
	{
		field: 'options',
		commonCellSettings: {
			style: {
				minWidth: '100px',
				maxWidth: '100px',
			},
		},
		bodyCellSettings: {
			renderCell: (coUser) => {
				return (
					<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
						<OptionsDropdown
							iconId={'more_horizontal'}
							optionList={[
								{
									label: 'shared_texts.table_dropdown.details',
									value: 'Details info',
									action: () => {
										coUser?.navigateToDetails && coUser.navigateToDetails(coUser);
									},
								},
								{
									label: 'shared_texts.table_dropdown.remove',
									value: 'Remove user',
									action: () => {
										coUser?.openModal && coUser.openModal(coUser);
									},
								},
							]}
						/>
					</Box>
				);
			},
		},
	},
];
