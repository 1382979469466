import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { ColorPalette } from 'shared/consts/colorPalette';
import { ListItems } from 'shared/ui/ListItems';

export const PasswordRequirements: FunctionComponent = () => {
	const { t } = useTranslation();
	return (
		<>
			<Typography variant={'body1'} color={ColorPalette.astronaut}>
				{t('auth.password_requirements.title')}
			</Typography>
			<ListItems
				sx={{
					padding: '16px 0 36px',
				}}
				list={[
					t('auth.password_requirements.req_1'),
					t('auth.password_requirements.req_2'),
					t('auth.password_requirements.req_3'),
					t('auth.password_requirements.req_4'),
				]}
			/>
		</>
	);
};
