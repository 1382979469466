import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, Navigate } from 'react-router-dom';
import { Button } from 'shared/ui/Button';
import { StatusModal } from 'widgets/auth/_common/ui/StatusModal';
import { useAuthStore } from 'processes/auth/model/state';

export const SIRemovedAccount: FunctionComponent = () => {
	const { user, reset } = useAuthStore();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const navigateHandler = () => {
		reset();
		navigate('/sign-in');
	};

	if (!user) return <Navigate to={'/sign-in'} />;

	return (
		<StatusModal
			type={'success'}
			title={t('auth.status_modal.account_removed')}
			message={t('auth.status_modal.account_removed_description')}
			icon={'user_cicrle_duotone'}
		>
			<Button onClick={navigateHandler} type={'submit'} fullWidth>
				{t('auth.sign_in')}
			</Button>
		</StatusModal>
	);
};
