import { Typography } from '@mui/material';
import { ColorPalette } from 'shared/consts/colorPalette';
import { format } from 'date-fns';
import { TableColumnsType } from 'widgets/admin/UserDetails/ui/tabs/Subscriptions';
import { Column } from 'shared/ui/tables/_common/types';
import { TableTranslationLabel } from 'shared/ui/tables/_common/ui/TableTranslationLabel';
import { capitalizeFirstLetter } from 'shared/helpers/capitalizeFirstLetter';

export const SubscriptionsTableColumns: Array<
	Column<TableColumnsType> // TODO change type
> = [
	{
		field: 'name',
		commonCellSettings: {
			style: {
				width: '150px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="name" />,
		},
		bodyCellSettings: {
			prepareField: (billing) => capitalizeFirstLetter(billing?.subscriptionType || ''),
		},
	},
	{
		field: 'cost',
		commonCellSettings: {
			style: {
				width: '150px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="cost" />,
		},
		bodyCellSettings: {
			prepareField: (subscription) =>
				subscription?.subscriptionCost ? `${subscription?.subscriptionCost / 100} €` : '0',
		},
	},
	{
		field: 'duration',
		commonCellSettings: {
			style: {
				width: '150px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="file_storage_duration" />,
		},
		bodyCellSettings: {
			renderCell: (subscription) => {
				return subscription?.autoRemovePeriod?.key ? (
					<>
						{subscription?.autoRemovePeriod?.amount}{' '}
						<TableTranslationLabel path={`shared_texts.${subscription?.autoRemovePeriod?.key}`} />
					</>
				) : (
					'-'
				);
			},
		},
	},
	{
		field: 'amountOfCoUsers',
		commonCellSettings: {
			style: {
				width: '150px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="co_users" />,
		},
		bodyCellSettings: {
			prepareField: (subscription) => `${subscription?.totalCousers}`,
		},
	},
	{
		field: 'amountOfFiles',
		commonCellSettings: {
			style: {
				width: '150px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="imported_files" />,
		},
		bodyCellSettings: {
			prepareField: (subscription) => `${subscription?.totalFiles}`,
		},
	},
	{
		field: 'totalExtraPages',
		commonCellSettings: {
			style: {
				width: '150px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="total_extra_pages" />,
		},
		bodyCellSettings: {
			prepareField: (subscription) => `${subscription?.totalExtraPages}`,
		},
	},
	{
		field: 'totalPages',
		commonCellSettings: {
			style: {
				width: '150px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="total_pages" />,
		},
		bodyCellSettings: {
			prepareField: (subscription) => `${subscription?.totalPages}`,
		},
	},
	{
		field: 'subscriptionDate',
		commonCellSettings: {
			style: {
				width: '150px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="subscription_activeted" />,
		},
		bodyCellSettings: {
			renderCell: (subscription) => {
				return subscription?.currentPeriodStart ? (
					<Typography variant={'body2'} component={'span'} color={ColorPalette.astronaut}>
						{format(new Date(subscription.currentPeriodStart), 'dd/MM/yyyy')}
					</Typography>
				) : (
					<Typography variant={'body2'} component={'span'} color={ColorPalette.baliHai}>
						{'--/--/----'}
					</Typography>
				);
			},
		},
	},
];
