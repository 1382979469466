import { useState } from 'react';

export function useLocalStorage<T>(
	key: string,
	initialValue: T,
): [storedValue: T, setValue: (value: T | ((val: T) => T)) => void] {
	const [storedValue, setStoredValue] = useState<T>(() => {
		if (typeof window === 'undefined') return initialValue;

		try {
			const item = window.localStorage.getItem(key);
			return item ? JSON.parse(item) : initialValue;
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error('useLocalStorage error\n', e);
			return initialValue;
		}
	});

	const setValue = (value: T | ((val: T) => T)) => {
		try {
			const valueToStore = value instanceof Function ? value(storedValue) : value;
			setStoredValue(valueToStore);
			if (typeof window !== 'undefined') {
				window.localStorage.setItem(key, JSON.stringify(valueToStore));
			}
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error('useLocalStorage error\n', e);
		}
	};
	return [storedValue, setValue];
}
