import { Box, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FunctionComponent } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';
import { useCoUserDetailsInfoStore } from 'widgets/CoUsers/ui/CoUserDetailsInfo/model/state';
import { FilesHistory } from 'widgets/CoUsers/ui/CoUserDetailsInfo/ui/tabs/FilesHistory';
import { Logs } from 'widgets/CoUsers/ui/CoUserDetailsInfo/ui/tabs/Logs';
import { PersonalInfo } from 'widgets/CoUsers/ui/CoUserDetailsInfo/ui/tabs/PersonalInfo';
import { Tab } from './ui/Tab';

export const CoUserDetailsInfo: FunctionComponent = () => {
	const { t } = useTranslation();
	const { activeTab, selectedCoUser } = useCoUserDetailsInfoStore();
	return (
		<>
			<Typography variant={'h4'} color={ColorPalette.astronaut}>
				{t('co_users.details_info')}
			</Typography>
			<Typography variant={'subtitle1'} color={ColorPalette.baliHai} sx={{ marginTop: '24px' }}>
				{selectedCoUser?.email}
			</Typography>

			<Divider color={ColorPalette.periwinkle} sx={{ margin: '44px 0' }} />
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-start',
					alignItems: 'center',
					gap: '20px',
					marginBottom: '44px',
				}}
			>
				<Tab label={t('co_users.tabs.tab_1')} tab={'personal-info'} />
				<Tab label={t('co_users.tabs.tab_2')} tab={'files-history'} />
				<Tab label={t('co_users.tabs.tab_3')} tab={'logs'} />
			</Box>
			{activeTab === 'personal-info' && <PersonalInfo coUser={selectedCoUser} />}
			{activeTab === 'files-history' && <FilesHistory coUser={selectedCoUser} />}
			{activeTab === 'logs' && <Logs coUser={selectedCoUser} />}
		</>
	);
};
