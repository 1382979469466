import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { CustomOutputFileType } from 'entities/Extensions/types';

type CustomOutputFileStoreType = {
	tabs?: { type: string; name: string }[];
	selectedOutputType?: CustomOutputFileType;
};

type CustomOutputFileStoreTypeActions = {
	setTabs: (tabs: { type: string; name: string }[]) => void;
	setSelectedOutputType: (selected?: CustomOutputFileType) => void;
	resetOutputStore: () => void;
};

const initialState: CustomOutputFileStoreType = {
	tabs: undefined,
	selectedOutputType: undefined,
};

const customOutputFileStore = create<CustomOutputFileStoreType & CustomOutputFileStoreTypeActions>()(
	persist(
		(set) => ({
			...initialState,
			setTabs: (tabs) => set(() => ({ tabs })),
			setSelectedOutputType: (selectedOutputType) => set(() => ({ selectedOutputType })),
			resetOutputStore: () => set(() => ({ ...initialState })),
		}),
		{
			name: 'customOutputFileStore',
		},
	),
);

export const useCustomOutputFileStore = () => customOutputFileStore((state) => state);
