import { Dialog, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { deleteFiles } from 'entities/Files/api';
import { enqueueSnackbar } from 'notistack';
import { FC, useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { ColorPalette } from 'shared/consts/colorPalette';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { ActionsForDialog } from 'shared/ui/ActionsForDialog';
import { Paper } from 'shared/ui/Paper';

interface Props {
	ids: Array<string>;
	open: boolean;
	handleClose: () => void;
}

export const DeleteFilesModal: FC<Props> = ({ ids, open, handleClose }) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const { mutate } = useMutation({
		mutationKey: ['files', ids],
		mutationFn: deleteFiles,
	});

	const handleRemoveCoUser = useCallback(() => {
		const mutationValues = { ids: ids };
		mutate(mutationValues, {
			onSuccess: async () => {
				await queryClient.refetchQueries({ queryKey: ['get/files'] });
				enqueueSnackbar({
					message: `${ids.length} ${t('files.snackbar.success_remove')}`,
					variant: 'success',
				});
				handleClose();
			},
			onError: (err) => {
				const defaultErrorMessage = t('files.snackbar.error_remove');
				const errorMessage = getErrorMessage(err, defaultErrorMessage);
				enqueueSnackbar({
					message: errorMessage,
					variant: 'error',
				});
			},
		});
	}, [ids, mutate, queryClient]);

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			PaperComponent={Paper}
			PaperProps={{ title: t('files.modal.remove_modal_title') as string }}
		>
			<Typography variant={'body1'} color={ColorPalette.baliHai}>
				{t('files.modal.remove_description_1')}
			</Typography>
			<Typography variant={'body1'} color={ColorPalette.baliHai} sx={{ margin: '24px 0 34px 0' }}>
				{t('files.modal.remove_description_2')}
			</Typography>
			<ActionsForDialog
				cancelLabel={t('shared_texts.cancel')}
				cancelAction={handleClose}
				applyLabel={t('shared_texts.yes')}
				applyAction={handleRemoveCoUser}
				applyActionDisabled={false}
			/>
		</Dialog>
	);
};
