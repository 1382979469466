import { SessionFileStatus } from 'entities/ImportSessions/types';

export enum ModelBuildMode {
	template = 'template',
	neural = 'neural',
}

export enum ModelStatuses {
	to_be_trained = 'TO_BE_TRAINED',
	training = 'TRAINING',
	trained = 'TRAINED',
	error = 'ERROR',
}

export interface CustomModelType {
	_id: string;
	customModelId: string;
	uploaderId: string;
	uploadSource: 'DIRECT' | 'FTP';
	fileName: string;
	errorMessage: string;
	host: string;
	filePath: string;
	convertedFilePath: string;
	size: 0;
	createdAt: string;
	updatedAt: string;
}

export interface CustomModelFilesType extends CustomModelType {
	status: SessionFileStatus;
}

export interface ModelsType {
	status: ModelStatuses;
	companyId: string;
	createdAt: string;
	createdBy: string;
	name: string;
	recognitionResourceId: string;
	totalFiles: number;
	updatedAt: string;
	_id: string;
	modelBuildMode: ModelBuildMode;
}
