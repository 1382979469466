import * as yup from 'yup';

export const PaymentMethodValidationSchema = yup.object({
	cardNumber: yup
		.boolean()
		.required()
		.test('is-cardNumber', 'subscriptions.tab_3.validation.card_number', (value) => value === true),
	cardExpiry: yup
		.boolean()
		.required()
		.test('is-cardExpiry', 'subscriptions.tab_3.validation.card_expiry', (value) => value === true),
	cardCvc: yup
		.boolean()
		.required()
		.test('is-cardCvc', 'subscriptions.tab_3.validation.card_cvc', (value) => value === true),
	name: yup.string().required('subscriptions.tab_3.validation.name'),
});

export type PaymentMehtodType = yup.InferType<typeof PaymentMethodValidationSchema>;
