import { CreateCompanyRequest, UpdateCompanyRequest } from 'entities/Company/api/types';
import { axiosInstance } from 'shared/config/axios';
import { getAccessToken } from 'shared/helpers/getAccessToken';

export const createCompany: CreateCompanyRequest = ({ token, company }) => {
	return axiosInstance.post('/companies', company, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const updateCompany: UpdateCompanyRequest = ({ id, data }) => {
	return axiosInstance.patch(`/companies/${id}`, data, {
		headers: {
			Authorization: `Bearer ${getAccessToken()}`,
		},
	});
};
