import { Box, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';
import { SessionFile } from 'entities/ImportSessions/types';
import { CategoryTypeEnum } from 'entities/Files/types';
import filePreview from 'shared/assets/file_preview.png';
import { ColorPalette } from 'shared/consts/colorPalette';
import { getHumanReadableFileSize } from 'shared/helpers/getHumanReadableFileSize';
import { DeleteButton } from 'shared/ui/DeleteButton';
import { Icon } from 'shared/ui/Icon';
import { TableTranslationLabel } from 'shared/ui/tables/_common/ui/TableTranslationLabel';
import { Column } from 'shared/ui/tables/_common/types';

export const ImportFilesDashboardColumns: Array<
	Column<
		SessionFile & {
			categoryType?: CategoryTypeEnum;
			onDelete: (id: string) => void;
			onRetry: (id: string) => void;
		}
	>
> = [
	{
		field: 'icon',
		commonCellSettings: {
			style: { width: '70px' },
			align: 'center',
		},
		bodyCellSettings: {
			renderCell: (file) => {
				if (file?.status === 'UPLOADING') {
					return <CircularProgress size={'14px'} sx={{ color: ColorPalette.royalBlue }} />;
				}
				if (file?.status === 'UPLOADED') {
					return (
						<Icon
							id={'done_ring_round'}
							color={ColorPalette.jungleGreen}
							style={{ width: '24px', height: '24px' }}
						/>
					);
				}
				return (
					<Icon
						id={'dell_fill'}
						color={ColorPalette.violetRed}
						style={{ width: '24px', height: '24px' }}
					/>
				);
			},
		},
	},
	{
		field: 'status',
		commonCellSettings: {
			style: { width: '150px' },
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="status" />,
		},
		bodyCellSettings: {
			renderCell: (file) => {
				const bgColor =
					file?.status === 'UPLOADED'
						? ColorPalette.jungleGreen
						: file?.status === 'UPLOADING'
						? ColorPalette.fuelYellow
						: ColorPalette.violetRed;
				const text =
					file?.status === 'UPLOADED'
						? 'uploaded'
						: file?.status === 'UPLOADING'
						? 'uploading'
						: 'error';
				return (
					<Box
						sx={{
							padding: '6px 14px',
							backgroundColor: bgColor,
							borderRadius: '10px',
							width: 'fit-content',
						}}
					>
						<TableTranslationLabel label={text} color={ColorPalette.white} />
					</Box>
				);
			},
		},
	},
	{
		field: 'fileName',
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="file_name" />,
		},
		bodyCellSettings: {
			renderCell: (file) => {
				return (
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<img
							src={filePreview}
							alt={'file-preview'}
							style={{ width: '62px', height: '62px', marginRight: '12px' }}
						/>
						<Tooltip title={file?.fileName} placement={'top'}>
							<Typography
								variant={'body2'}
								color={ColorPalette.astronaut}
								sx={{
									width: '250px',
									textOverflow: 'ellipsis',
									overflow: 'hidden',
									whiteSpace: 'nowrap',
								}}
							>
								{file?.fileName}
							</Typography>
						</Tooltip>
					</Box>
				);
			},
		},
	},
	{
		field: 'fileType',
		commonCellSettings: {
			style: { width: '110px' },
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="file_type" />,
		},
		bodyCellSettings: {
			renderCell: (file) => {
				return (
					<Typography variant={'body2'} color={ColorPalette.astronaut}>
						{file?.fileName.split('.').pop()?.toUpperCase() ?? '-'}
					</Typography>
				);
			},
		},
	},
	{
		field: 'category',
		commonCellSettings: {
			style: { width: '210px' },
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="category" />,
		},
		bodyCellSettings: {
			renderCell: (file) => {
				return file?.categoryType ? (
					<TableTranslationLabel path={`sidebar.menu./files/${file?.categoryType}`} />
				) : (
					''
				);
			},
		},
	},
	{
		field: 'fileSize',
		commonCellSettings: {
			style: { width: '110px' },
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="file_size" />,
		},
		bodyCellSettings: {
			renderCell: (file) => {
				const fileSize = file?.fileSize ? getHumanReadableFileSize(file?.fileSize, true) : '-';
				return (
					<Typography variant={'body2'} color={ColorPalette.astronaut}>
						{fileSize}
					</Typography>
				);
			},
		},
	},
	{
		field: 'retry',
		commonCellSettings: {
			style: { width: '70px' },
			align: 'center',
		},
		bodyCellSettings: {
			renderCell: (file) => {
				return (
					<>
						{file?.status === 'ERROR' && (
							<IconButton
								disableFocusRipple
								disableRipple
								onClick={() => file?.onRetry(file?._id)}
							>
								<Icon id={'refresh'} color={ColorPalette.baliHai} />
							</IconButton>
						)}
					</>
				);
			},
		},
	},
	{
		field: 'delete',
		commonCellSettings: {
			style: { width: '70px' },
			align: 'center',
		},
		bodyCellSettings: {
			renderCell: (file) => {
				return <DeleteButton action={() => file?.onDelete(file?._id)} />;
			},
		},
	},
];
