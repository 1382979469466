import { FC, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAssertionSetsStore } from 'widgets/Extensions/Details/ui/AssertionSets/model/state';
import { AssertionsSetsListWidget } from './AssertionSetsWidget';
import { AssertionsSetListWidget } from './AssertionsSetList';

export const AssertionWidget: FC = () => {
	const { setWarehousesForAssertions } = useAssertionSetsStore();
	const [searchParams] = useSearchParams();
	const assetionId = useMemo(() => searchParams.get('assertion-set-id') || '', [searchParams]);

	useEffect(() => {
		setWarehousesForAssertions(null);
	}, []);

	if (assetionId) {
		return <AssertionsSetListWidget assetionId={assetionId} />;
	}
	return <AssertionsSetsListWidget />;
};
