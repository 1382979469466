import { FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';

export const OutletLayout: FunctionComponent = () => {
	return (
		<>
			<Outlet />
		</>
	);
};
