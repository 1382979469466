import { FC, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { getAssertions } from 'entities/Assertions/api';
import { AssertionsType } from 'entities/Assertions/types';
import { Loader } from 'shared/ui/Loader';
import { PageHeader } from 'shared/ui/PageHeader';
import { Button } from 'shared/ui/Button';
import { ColorPalette } from 'shared/consts/colorPalette';
import { InitialValuesTableForm } from 'widgets/Extensions/Details/ui/AssertionSets/AssertionsSetList/ui/InitialValuesTableForm';
import { useAssertionSetsStore } from 'widgets/Extensions/Details/ui/AssertionSets/model/state';
import { AsTable } from 'widgets/Extensions/Details/ui/AssertionSets/ui/AsTable/ui/AssertionsTable';
import { AssertionsManualCreationModal } from 'widgets/Extensions/Details/ui/AssertionSets/AssertionsSetList/ui/AssertionsManualCreationModal';
import { SelectCreationMethodModal } from 'widgets/Extensions/Details/ui/AssertionSets/AssertionsSetList/ui/SelectCreationMethodModal';
import { ImportCsvModal } from 'widgets/Extensions/Details/ui/AssertionSets/AssertionsSetList/ui/ImportCsvModal';
import { ImportFromWarehouseModal } from 'widgets/Extensions/Details/ui/AssertionSets/AssertionsSetList/ui/ImportFromWarehouseModal';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';

export const AssertionsSetListWidget: FC<{ assetionId: string }> = ({ assetionId }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { warehousesForAssertions, setWarehousesForAssertions } = useAssertionSetsStore();
	const [isCreationSelectionModalOpen, setIsCreationSelectionModalOpen] = useState(false);
	const [isManualModalOpen, setIsManualModalOpen] = useState(false);
	const [isImportCsvModalOpen, setIsImportCsvModalOpen] = useState(false);
	const [isImportWarehouseModalOpen, setIsImportWarehouseModalOpen] = useState(false);
	const [assertionToEdit, setAssertionToEdit] = useState<AssertionsType>();
	const [page, setTablePage] = useState(1);

	const { data, isLoading } = useQuery({
		queryKey: ['get/assertion-sets/assertions', page],
		queryFn: () => getAssertions({ id: assetionId, perPage: 20, page }),
		onError: (err) => {
			const errorMessage = getErrorMessage(err);
			enqueueSnackbar({
				message: errorMessage,
				variant: 'error',
			});
		},
	});

	const onCloseManualModalHandler = () => {
		setAssertionToEdit(undefined);
		setIsManualModalOpen(false);
	};

	const onOpenManualModalForEdit = (assertion: AssertionsType) => {
		setAssertionToEdit(assertion);
		setIsManualModalOpen(true);
	};

	const onOpenManualModalForCreation = () => {
		setIsCreationSelectionModalOpen(false);
		setIsManualModalOpen(true);
	};

	const onOpenImportCsvModalHandler = () => {
		setIsCreationSelectionModalOpen(false);
		setIsImportCsvModalOpen(true);
	};

	const onOpenImporWarehouseModalHandler = () => {
		setIsCreationSelectionModalOpen(false);
		setIsImportWarehouseModalOpen(true);
	};

	const onBackHandler = () => {
		if (warehousesForAssertions) {
			setWarehousesForAssertions(null);
		} else {
			navigate(-1);
		}
	};
	return (
		<>
			<PageHeader
				title={t('assertions.as_title')}
				description={t('assertions.as_description')}
				onClick={onBackHandler}
				backBtn
			/>
			{warehousesForAssertions ? (
				<InitialValuesTableForm
					assetionId={assetionId}
					warehousesForAssertions={warehousesForAssertions}
				/>
			) : (
				<>
					<SelectCreationMethodModal
						open={isCreationSelectionModalOpen}
						handleClose={() => setIsCreationSelectionModalOpen(false)}
						openManualModal={onOpenManualModalForCreation}
						openImportCsvModal={onOpenImportCsvModalHandler}
						openWarehouseModal={onOpenImporWarehouseModalHandler}
					/>
					<AssertionsManualCreationModal
						id={assetionId}
						open={isManualModalOpen}
						handleClose={onCloseManualModalHandler}
						assertionToEdit={assertionToEdit}
					/>
					<ImportCsvModal
						id={assetionId}
						open={isImportCsvModalOpen}
						handleClose={() => setIsImportCsvModalOpen(false)}
					/>
					<ImportFromWarehouseModal
						open={isImportWarehouseModalOpen}
						handleClose={() => setIsImportWarehouseModalOpen(false)}
					/>
					{isLoading ? (
						<Loader />
					) : (
						<>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									justifyContent: 'space-between',
									marginBottom: '34px',
								}}
							>
								<Typography variant={'h5'} color={ColorPalette.astronaut}>
									{data?.data.assertionSet.name}
								</Typography>
								<Button onClick={() => setIsCreationSelectionModalOpen(true)}>
									{t('assertions.add_as')}
								</Button>
							</Box>
							<AsTable
								assertions={data?.data}
								handleOpenEdit={onOpenManualModalForEdit}
								onPageChange={(page) => setTablePage(page)}
							/>
						</>
					)}
				</>
			)}
		</>
	);
};
