import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { updateCompany } from 'entities/Company/api';
import { Company } from 'entities/Company/types';
import { Form, Formik, FormikProps } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { useAuthStore } from 'processes/auth/model/state';
import { FunctionComponent, useMemo } from 'react';
import { useMutation } from 'react-query';
import { ColorPalette } from 'shared/consts/colorPalette';
import { compareObjects } from 'shared/helpers/compareObjects';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { isEmptyObject } from 'shared/helpers/isEmptyObject';
import { isEmptyObjectValues } from 'shared/helpers/isEmptyObjectValues';
import { ActionsForDialog } from 'shared/ui/ActionsForDialog';
import { InputField } from 'shared/ui/InputField';
import { FormContainer } from 'widgets/PersonalAccount/ui/Paper';
import { CompanyInfoValidationSchema } from 'widgets/PersonalAccount/ui/tabs/CompanyInfo/consts/validation';
import { CompanyInfoType } from 'widgets/PersonalAccount/ui/tabs/CompanyInfo/types';

export const CompanyInfo: FunctionComponent = () => {
	const { t } = useTranslation();
	const { company, updateAuthData } = useAuthStore();
	const { mutate } = useMutation({
		mutationFn: updateCompany,
	});

	const initialValues = useMemo(
		() => ({
			name: company?.name ?? '',
			vatNumber: company?.taxInfo?.vatNumber ?? '',
			sdiCode: company?.taxInfo?.sdiCode ?? '',
			country: company?.address.country ?? '',
			city: company?.address.city ?? '',
			street: company?.address.street ?? '',
			houseNumber: company?.address.houseNumber ?? '',
			postalCode: company?.address.postalCode ?? '',
		}),
		[company],
	);

	const handleSubmit = (values: CompanyInfoType): void => {
		const mutationValues = { id: company?._id, data: { ...values } };
		mutate(mutationValues, {
			onSuccess: (res) => {
				updateAuthData({
					company: {
						...(company as Company),
						...res.data,
					},
				});
				enqueueSnackbar({
					message: t('shared_texts.success_update'),
					variant: 'success',
				});
			},
			onError: (err) => {
				const defaultErrorMessage = t('shared_texts.failed_update');
				const errorMessage = getErrorMessage(err, defaultErrorMessage);
				enqueueSnackbar({
					message: errorMessage,
					variant: 'error',
				});
			},
		});
	};

	return (
		<>
			<Typography variant={'h5'} color={ColorPalette.astronaut}>
				{t('personal_account.tab_2.title')}
			</Typography>
			<FormContainer sx={{ marginTop: '34px', marginBottom: '36px' }}>
				<Formik
					initialValues={initialValues}
					enableReinitialize
					validationSchema={CompanyInfoValidationSchema}
					onSubmit={handleSubmit}
				>
					{({ errors, values, resetForm }: FormikProps<CompanyInfoType>) => (
						<Form>
							<InputField
								name={'name'}
								label={t('auth.labels.company_name')}
								placeholder={t('auth.validation.enter_company_name')}
								required
							/>
							<InputField
								name={'vatNumber'}
								label={t('auth.labels.vat_number')}
								placeholder={t('auth.validation.enter_vat_number')}
								required
							/>
							<InputField
								name={'sdiCode'}
								label={t('auth.labels.sdi_code')}
								placeholder={t('auth.validation.enter_sdi_code')}
								required
							/>
							<Typography
								variant={'h6'}
								color={ColorPalette.astronaut}
								sx={{ marginBottom: '26px' }}
							>
								{t('auth.company_address')}
							</Typography>
							<InputField
								name={'country'}
								label={t('auth.labels.country')}
								placeholder={t('auth.validation.enter_country')}
								required
							/>
							<InputField
								name={'city'}
								label={t('auth.labels.city')}
								placeholder={t('auth.validation.enter_city')}
								required
							/>
							<InputField
								name={'street'}
								label={t('auth.labels.street')}
								placeholder={t('auth.validation.enter_street')}
								required
							/>
							<InputField
								name={'houseNumber'}
								label={t('auth.labels.house_number')}
								placeholder={t('auth.validation.enter_house_number')}
								required
							/>
							<InputField
								name={'postalCode'}
								label={t('auth.labels.postal_code')}
								placeholder={t('auth.validation.enter_postal_code')}
								required
							/>
							<ActionsForDialog
								cancelLabel={t('shared_texts.cancel')}
								cancelAction={() => {
									resetForm();
								}}
								applyLabel={t('shared_texts.save')}
								applyActionDisabled={
									!isEmptyObject(errors) ||
									isEmptyObjectValues(values) ||
									isEmptyObject(compareObjects(initialValues, values))
								}
							/>
						</Form>
					)}
				</Formik>
			</FormContainer>
		</>
	);
};
