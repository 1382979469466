import { Box, Typography, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FunctionComponent } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';
import { useSignUpByInvitationStore } from 'widgets/auth/signUpByInvitation/_MainModal/model/state';

export const Footer: FunctionComponent = () => {
	const { step } = useSignUpByInvitationStore();
	const { t } = useTranslation();

	return (
		<Box sx={{ display: 'flex', flexDirection: 'row', marginTop: '30px' }}>
			{step === 1 && (
				<Typography
					variant={'body2'}
					color={ColorPalette.royalBlue}
					sx={{ cursor: 'pointer', marginLeft: 'auto' }}
				>
					<Link href="https://retica.ai/privacy-policy/">{t('auth.privacy_policy')}</Link>
				</Typography>
			)}
		</Box>
	);
};
