import { Box } from '@mui/material';
import cn from 'classnames';
import { FC } from 'react';
import { Thumbnail } from 'react-pdf';
import { ColorPalette } from 'shared/consts/colorPalette';
import styles from './file.module.scss';

interface Props {
	currentFilePage: number;
	changeCurrentFilePage: (n: number) => void;
	navigationPages: Array<number>;
	handleSetLabels: () => void;
}

export const FileNavigation: FC<Props> = ({
	navigationPages,
	currentFilePage,
	changeCurrentFilePage,
	handleSetLabels,
}) => {
	return (
		<Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: '12px',
					height: '85vh',
					overflow: 'auto',
					padding: '15px',
					backgroundColor: '#fff',
					borderRadius: '10px',
				}}
			>
				{navigationPages.map((page) => (
					<Box
						key={`page_${page}`}
						sx={{
							'position': 'relative',
							'&:before': {
								content: `"#${page}"`,
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								top: '5px',
								left: '5px',
								position: 'absolute',
								background: ColorPalette.portage,
								fontSize: '8px',
								lineHeight: '12px',
								color: ColorPalette.white,
								borderRadius: '6px',
								zIndex: '10',
								padding: '.5px 5px',
							},
						}}
					>
						<Thumbnail
							pageNumber={page}
							width={100}
							height={500}
							onItemClick={() => {
								changeCurrentFilePage(page);
								handleSetLabels();
							}}
							scale={0.4}
							className={cn(
								styles.react_pdf_thumbnail,
								page === currentFilePage && styles.react_pdf_thumbnail_active,
							)}
						/>
					</Box>
				))}
			</Box>
		</Box>
	);
};
