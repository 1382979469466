import {
	DeleteSessionFileRequest,
	DeleteSessionRequest,
	DirectImportRequest,
	GetChangingTextRequest,
	GetFtpClientRequest,
	GetImportSessionsRequest,
	ImportFromFtpRequest,
	RetryImportFileFromFtpRequest,
	RetryImportFileRequest,
	StartRecognitionRequest,
} from 'entities/ImportSessions/api/types';
import { axiosInstance } from 'shared/config/axios';
import { getAccessToken } from 'shared/helpers/getAccessToken';

export const getImportSessions: GetImportSessionsRequest = () => {
	return axiosInstance.get('/import-sessions', {
		headers: {
			Authorization: `Bearer ${getAccessToken()}`,
		},
	});
};

export const postDirectImport: DirectImportRequest = (data) => {
	return axiosInstance.post('/import-sessions/direct-import', data, {
		headers: {
			'Content-Type': 'multipart/form-data',
			'Authorization': `Bearer ${getAccessToken()}`,
		},
	});
};

export const importFromFtp: ImportFromFtpRequest = (data) => {
	return axiosInstance.post('/import-sessions/ftp-import', data, {
		headers: {
			Authorization: `Bearer ${getAccessToken()}`,
		},
	});
};

export const retryImportFileFromFtp: RetryImportFileFromFtpRequest = (id) => {
	return axiosInstance.patch(`/import-sessions/ftp-file/${id}`, {
		headers: {
			Authorization: `Bearer ${getAccessToken()}`,
		},
	});
};

export const deleteSession: DeleteSessionRequest = (id) => {
	return axiosInstance.delete(`/import-sessions/${id}`, {
		headers: {
			Authorization: `Bearer ${getAccessToken()}`,
		},
	});
};

export const deleteSessionFile: DeleteSessionFileRequest = (id) => {
	return axiosInstance.delete(`/import-sessions/file/${id}`, {
		headers: {
			Authorization: `Bearer ${getAccessToken()}`,
		},
	});
};

export const startRecognition: StartRecognitionRequest = ({ id, data }) => {
	return axiosInstance.post(`/import-sessions/start-recognition/${id}`, data, {
		headers: {
			Authorization: `Bearer ${getAccessToken()}`,
		},
	});
};

export const getFtpClient: GetFtpClientRequest = (queries) => {
	return axiosInstance.get('/ftp/client', { params: { ...queries } });
};

export const retryImportFile: RetryImportFileRequest = ({ data, id }) => {
	return axiosInstance.patch(`/files/${id}`, data, {
		headers: {
			Authorization: `Bearer ${getAccessToken()}`,
		},
	});
};

export const getChangingText: GetChangingTextRequest = ({ data, id }) => {
	return axiosInstance.post(`/files/rectangle-content/${id}`, data, {
		headers: {
			Authorization: `Bearer ${getAccessToken()}`,
		},
	});
};

export const getRecognitionTemplate = () => {
	return axiosInstance.get(`/recognitions-templates`, {
		headers: {
			Authorization: `Bearer ${getAccessToken()}`,
		},
	});
};
