import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Typography } from '@mui/material';
import { ColorPalette } from 'shared/consts/colorPalette';
import ResourcesTable from 'widgets/admin/Resources/ResourcesTable';
import ResourceInputKey from 'widgets/admin/Resources/ResourceInputKey';
import { useQuery } from 'react-query';
import { getResources } from 'entities/admin/Users/api';

export interface IResource {
	_id: string;
	endpoint: string;
	customModelsCount: number;
	customModelsLimit: number;
	createdAt: string;
	updatedAt: string;
	customNeuralModelsQuota: number;
	customNeuralModelsUsed: number;
}

export const Resources = () => {
	const { t } = useTranslation();
	const [resources, setResources] = useState([]);

	const { data } = useQuery({
		queryKey: ['admin/resources'],
		queryFn: () => getResources(),
	});

	useEffect(() => {
		setResources(data?.data);
	}, [data]);

	return (
		<>
			<Typography variant={'h4'} color={ColorPalette.astronaut}>
				{t('sidebar.menu./resources')}
			</Typography>

			<Divider color={ColorPalette.periwinkle} sx={{ margin: '42px 0 44px 0' }} />

			<ResourcesTable resources={resources} />

			<ResourceInputKey />
		</>
	);
};
