import { GetCoUsersRequest, InviteCoUserRequest, RemoveCoUserRequest } from 'entities/CoUser/api/types';
import { axiosInstance } from 'shared/config/axios';
import { getAccessToken } from 'shared/helpers/getAccessToken';

export const getCoUsers: GetCoUsersRequest = (companyId) => {
	return axiosInstance.get(`/co-users/${companyId}`, {
		headers: {
			Authorization: `Bearer ${getAccessToken()}`,
		},
	});
};

export const inviteCoUser: InviteCoUserRequest = (data) => {
	return axiosInstance.post('/co-users/invite', data, {
		headers: {
			Authorization: `Bearer ${getAccessToken()}`,
		},
	});
};

export const removeCoUsers: RemoveCoUserRequest = (userId) => {
	return axiosInstance.delete(`/co-users/delete/${userId}`, {
		headers: {
			Authorization: `Bearer ${getAccessToken()}`,
		},
	});
};
