import { FC, useMemo, useState, useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';
import { GetAssertionsRes } from 'entities/Assertions/api/types';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { EmptyTable } from 'shared/ui/EmptyTable';
import { TableWithHeader } from 'shared/ui/tables/TableWithHeader';
import { AssertionsTableColumns } from 'widgets/Extensions/Details/ui/AssertionSets/ui/AsTable/consts/AssertionsColumns';
import { DeleteAssertionsModal } from 'widgets/Extensions/Details/ui/AssertionSets/ui/AsModals/DeleteAssertionsModal';
import { deleteAssertions } from 'entities/Assertions/api';
import { AssertionsType } from 'entities/Assertions/types';

type AsTableProps = {
	assertions: GetAssertionsRes | undefined;
	handleOpenEdit: (assertion: AssertionsType) => void;
	onPageChange: (page: number) => void;
};

export const AsTable: FC<AsTableProps> = ({ assertions, handleOpenEdit, onPageChange }) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const [assertionsToRemove, setAssertionsToRemove] = useState<string[]>([]);
	const [selectedAssertions, setSelectedAssertions] = useState<string[]>([]);

	const { mutate } = useMutation({
		mutationKey: ['/assertion-sets/assertions/delete-many'],
		mutationFn: deleteAssertions,
	});

	const handleToggleAssertion = (fileId: string) => {
		setSelectedAssertions((prevState) => {
			return prevState.includes(fileId)
				? prevState.filter((i) => i !== fileId)
				: [...prevState, fileId];
		});
	};

	const handleToggleAllAssertions = useCallback(() => {
		setSelectedAssertions((prevState) => {
			if (assertions && assertions.results?.length === prevState.length) {
				return [];
			}
			if (assertions && assertions?.results.length > prevState.length) {
				return [...assertions.results.map((assertion) => assertion._id)];
			}
			return [];
		});
	}, [assertions]);

	const handleEdit = useCallback(
		(id: string) => {
			const assertion = assertions?.results.find((item) => item._id === id);
			if (assertion) {
				handleOpenEdit(assertion);
			}
		},
		[assertions],
	);

	const handleDelete = useCallback(
		(fileId?: string) => {
			if (!fileId && selectedAssertions.length === 0) {
				return enqueueSnackbar({
					message: t('assertions.snackbar.no_sets_to_remove'),
					variant: 'error',
				});
			}
			setAssertionsToRemove(fileId ? [fileId] : selectedAssertions);
		},
		[selectedAssertions],
	);

	const preparedData = useMemo(() => {
		if (!assertions) return [];
		return assertions.results.map((item) => ({
			...item,
			id: item._id,
			assertionsCount: assertions.results.length,
			selectedAssertions,
			toggleAllAssertions: handleToggleAllAssertions,
			toggleAssertion: handleToggleAssertion,
			edit: handleEdit,
			delete: handleDelete,
		}));
	}, [assertions, selectedAssertions]);

	const handleCloseModal = () => setAssertionsToRemove([]);

	const handleConfirmDeletion = () => {
		mutate(
			{ ids: assertionsToRemove },
			{
				onSuccess: async () => {
					await queryClient.invalidateQueries({ queryKey: ['get/assertion-sets/assertions'] });
					enqueueSnackbar({
						message: t('assertions.snackbar.success_assertion_removal'),
						variant: 'success',
					});
					handleCloseModal();
				},
				onError: (err) => {
					const errorMessage = getErrorMessage(err);
					enqueueSnackbar({
						message: errorMessage,
						variant: 'error',
					});
				},
			},
		);
	};

	return (
		<>
			<DeleteAssertionsModal
				title={t('assertions.modal.remove_as_set_title')}
				description={t('assertions.modal.remove_as_description')}
				open={!!assertionsToRemove.length}
				handleClose={handleCloseModal}
				handleRemoveSet={handleConfirmDeletion}
			/>
			{assertions?.results.length ? (
				<TableWithHeader
					columns={AssertionsTableColumns}
					rows={preparedData}
					pagination={{
						page: assertions.page || 0,
						totalCount: assertions?.totalCount || 0,
						perPage: assertions?.perPage || 0,
						onPageChange,
					}}
				/>
			) : (
				<EmptyTable text={t('assertions.not_found_as_sets')} />
			)}
		</>
	);
};
