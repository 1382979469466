import { Dialog, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Form, Formik, FormikProps } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { ExtensionsEnum } from 'entities/Extensions/enums';
import { postContuctUsExtensions } from 'entities/Extensions/api';
import { ActionsForDialog } from 'shared/ui/ActionsForDialog';
import { Paper } from 'shared/ui/Paper';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { ColorPalette } from 'shared/consts/colorPalette';
import { InputField } from 'shared/ui/InputField';
import {
	ContactUsValidationSchema,
	ContactUsType,
} from 'widgets/Extensions/ExtensionsToSelect/consts/validation';
import { useAuthStore } from 'processes/auth/model/state';
import { Loader } from 'shared/ui/Loader';

interface DownloadModalTypes {
	open: boolean;
	handleClose: () => void;
	selectedExtension?: ExtensionsEnum;
}

export const ContactUsModal = ({ open, handleClose, selectedExtension }: DownloadModalTypes) => {
	const { t } = useTranslation();
	const { user } = useAuthStore();

	const { mutate, isLoading } = useMutation({
		mutationKey: ['post/extensions/contact-us'],
		mutationFn: postContuctUsExtensions,
	});

	const handleSubmit = (values: ContactUsType) => {
		if (selectedExtension) {
			mutate(
				{ ...values, extension: selectedExtension },
				{
					onSuccess: async () => {
						enqueueSnackbar({
							message: t('extensions.snackbar.success_sending_contact_us'),
							variant: 'success',
						});
						handleClose();
					},
					onError: (err) => {
						const errorMessage = getErrorMessage(err);
						enqueueSnackbar({ message: errorMessage, variant: 'error' });
					},
				},
			);
		}
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			PaperComponent={Paper}
			PaperProps={{ title: t('shared_texts.contact_us') as string }}
		>
			{isLoading ? (
				<Loader />
			) : (
				<>
					<Typography variant={'body1'} color={ColorPalette.baliHai} sx={{ margin: '0 0 34px 0' }}>
						{t('extensions.modals.contact_us.description')}
					</Typography>

					<Formik
						initialValues={{
							email: user?.email || '',
							message: '',
						}}
						validationSchema={ContactUsValidationSchema}
						onSubmit={handleSubmit}
					>
						{({ resetForm }: FormikProps<ContactUsType>) => (
							<Form>
								<InputField
									name="email"
									label={t('extensions.labels.email')}
									placeholder={t('extensions.placeholder.email')}
									required
								/>
								<InputField
									name="message"
									label={t('extensions.labels.message')}
									placeholder={t('extensions.placeholder.message') as string}
									multiline
									required
								/>

								<ActionsForDialog
									cancelLabel={t('shared_texts.cancel')}
									cancelAction={() => {
										resetForm();
										handleClose();
									}}
									applyLabel={t('shared_texts.send')}
								/>
							</Form>
						)}
					</Formik>
				</>
			)}
		</Dialog>
	);
};
