import { Box, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import { CustomContentProps, SnackbarContent, useSnackbar } from 'notistack';
import { forwardRef, useCallback } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Shadow } from 'shared/consts/shadow';
import { Icon } from 'shared/ui/Icon';
import { SnackbarBackgroundColors, SnackbarIcons } from 'widgets/Snackbar/consts';

export const Snackbar = forwardRef<HTMLDivElement, CustomContentProps>(({ id, ...props }, ref) => {
	const { closeSnackbar } = useSnackbar();

	const handleCloseSnackbar = useCallback(() => {
		closeSnackbar(id);
	}, [id, closeSnackbar]);

	return (
		<SnackbarContent ref={ref}>
			<Box
				sx={{
					width: '100%',
					minHeight: '60px',
					padding: '21px 66px 21px 21px',
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					position: 'relative',
					borderRadius: '10px',
					backgroundColor: SnackbarBackgroundColors[props.variant],
					boxShadow: Shadow['1'],
				}}
			>
				<Icon
					id={SnackbarIcons[props.variant]}
					color={ColorPalette.white}
					style={{ minWidth: '24px', minHeight: '24px', marginRight: '11px' }}
				/>
				<Typography variant={'body2'} color={ColorPalette.white}>
					{props.message}
				</Typography>
				<IconButton
					sx={{ position: 'absolute', top: '50%', right: '13px', transform: 'translateY(-50%)' }}
					onClick={() => handleCloseSnackbar()}
				>
					<Icon id={'close_round'} color={ColorPalette.white} />
				</IconButton>
			</Box>
		</SnackbarContent>
	);
});

Snackbar.displayName = 'Snackbar';
