import { emailRegEx } from 'shared/consts/emailRegEx';
import * as yup from 'yup';

export const ForgotPasswordValidationSchema = yup.object({
	email: yup
		.string()
		.required('auth.validation.enter_email')
		.matches(emailRegEx, { message: 'auth.validation.email_not_valid' })
		.max(50, 'auth.validation.email_too_long'),
});
