import { Dialog, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { changeEmail } from 'entities/Auth/api';
import { Form, Formik, FormikProps } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { FunctionComponent } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ColorPalette } from 'shared/consts/colorPalette';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { isEmptyObject } from 'shared/helpers/isEmptyObject';
import { isEmptyObjectValues } from 'shared/helpers/isEmptyObjectValues';
import { ActionsForDialog } from 'shared/ui/ActionsForDialog';
import { InputField } from 'shared/ui/InputField';
import { Paper } from 'shared/ui/Paper';
import { signOut } from 'processes/auth/helpers/signOut';
import { VerificationValidationSchema } from 'widgets/PersonalAccount/ui/tabs/Security/ui/ChangeEmail/ui/VerificationModal/consts/validation';
import { Verification } from 'widgets/PersonalAccount/ui/tabs/Security/ui/ChangeEmail/ui/VerificationModal/types';

interface Props {
	open: boolean;
	handleClose: () => void;
}

export const VerificationModal: FunctionComponent<Props> = ({ open, handleClose }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { mutate } = useMutation({
		mutationFn: changeEmail,
	});

	const initialValues = {
		email: '',
		password: '',
		newEmail: '',
	};

	const handleSubmit = (values: Verification): void => {
		const mutationValues = { ...values };
		mutate(mutationValues, {
			onSuccess: () => {
				signOut();
			},
			onError: (err) => {
				const defaultErrorMessage = t('personal_account.tab_3.email_error');
				const errorMessage = getErrorMessage(err, defaultErrorMessage);
				enqueueSnackbar({
					message: errorMessage,
					variant: 'error',
				});
			},
		});
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			PaperComponent={Paper}
			PaperProps={{ title: t('personal_account.tab_3.modal_title') as string }}
		>
			<Typography variant={'body1'} color={ColorPalette.baliHai} sx={{ marginBottom: '35px' }}>
				{t('personal_account.tab_3.placeholder.current_email')}
			</Typography>
			<Formik
				initialValues={initialValues}
				validationSchema={VerificationValidationSchema}
				onSubmit={handleSubmit}
			>
				{({ errors, values, resetForm }: FormikProps<Verification>) => (
					<Form>
						<InputField
							name={'email'}
							label={t('personal_account.tab_3.labels.current_email')}
							placeholder={t('personal_account.tab_3.placeholder.current_email')}
							required
						/>
						<InputField
							name={'newEmail'}
							label={t('personal_account.tab_3.labels.new_email')}
							placeholder={t('personal_account.tab_3.placeholder.new_email')}
							required
						/>
						<InputField
							name={'password'}
							label={t('personal_account.tab_3.labels.current_password')}
							placeholder={t('personal_account.tab_3.placeholder.current_password')}
							type={'password'}
							required
						/>
						<ActionsForDialog
							cancelLabel={t('shared_texts.cancel')}
							cancelAction={() => {
								resetForm();
								handleClose();
							}}
							applyLabel={t('shared_texts.continue')}
							applyActionDisabled={!isEmptyObject(errors) || isEmptyObjectValues(values)}
						/>
						<Typography
							variant={'body2'}
							color={ColorPalette.royalBlue}
							onClick={() => navigate('/forgot-password', { state: { access: true } })}
							sx={{
								width: 'fit-content',
								cursor: 'pointer',
								marginTop: '30px',
								marginLeft: 'auto',
							}}
						>
							{t('auth.forgot_password')}
						</Typography>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};
