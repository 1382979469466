import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Typography } from '@mui/material';
import { ColorPalette } from 'shared/consts/colorPalette';
import { ActionsForDialog } from 'shared/ui/ActionsForDialog';
import { Paper } from 'shared/ui/Paper';
import { UserForAdmin } from 'entities/admin/Users/types';

interface Props {
	user: UserForAdmin;
	open: boolean;
	handleClose: () => void;
	handleApply: () => void;
}

export const UnlimitedUserSubscription: FC<Props> = ({ user, open, handleClose, handleApply }) => {
	const { t } = useTranslation();

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			PaperComponent={Paper}
			PaperProps={{ title: `${t('admin.users.details.apply_unlimited')}?` }}
		>
			<Typography variant={'body1'} color={ColorPalette.baliHai} sx={{ margin: '24px 0 34px 0' }}>
				{t('admin.users.snackbar.confirm_user_unlimited', { value: user?.email })}
			</Typography>
			<ActionsForDialog
				cancelLabel={t('shared_texts.cancel')}
				cancelAction={handleClose}
				applyLabel={t('shared_texts.yes')}
				applyAction={handleApply}
				applyActionDisabled={false}
			/>
		</Dialog>
	);
};
