import { Box, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UserRoles } from 'entities/User/types';
import { ChangeLanguage } from 'shared/ui/ChangeLanguage';
import { useAuthStore } from 'processes/auth/model/state';
import { FunctionComponent } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';
import { usePersonalAccountStore } from 'widgets/PersonalAccount/model/state';
import { Tab } from 'widgets/PersonalAccount/ui/Tab';
import { CompanyInfo } from 'widgets/PersonalAccount/ui/tabs/CompanyInfo';
import { PersonalInfo } from 'widgets/PersonalAccount/ui/tabs/PersonalInfo';
import { Security } from 'widgets/PersonalAccount/ui/tabs/Security';

const UsersPersonalAccount: FunctionComponent = () => {
	const { t } = useTranslation();
	const { user } = useAuthStore();
	const { activeTab } = usePersonalAccountStore();

	return (
		<>
			<Typography variant={'h4'} color={ColorPalette.astronaut}>
				{t('personal_account.title')}
			</Typography>
			<Box sx={{ position: 'absolute', top: '49px', right: '40px' }}>
				<ChangeLanguage />
			</Box>
			<Divider color={ColorPalette.periwinkle} sx={{ margin: '44px 0' }} />
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-start',
					alignItems: 'center',
					gap: '20px',
					marginBottom: '44px',
				}}
			>
				<Tab label={t('personal_account.tabs.tab_1')} tab={'personal-info'} />
				{user?.role === UserRoles.user && (
					<Tab label={t('personal_account.tabs.tab_2')} tab={'company-info'} />
				)}
				<Tab label={t('personal_account.tabs.tab_3')} tab={'security'} />
			</Box>
			{activeTab === 'personal-info' && <PersonalInfo />}
			{activeTab === 'company-info' && <CompanyInfo />}
			{activeTab === 'security' && <Security />}
		</>
	);
};

export default UsersPersonalAccount;
