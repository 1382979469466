import { FC } from 'react';
import { Box, BoxProps, Typography } from '@mui/material';
import { ColorPalette } from 'shared/consts/colorPalette';
import directoryPreview from 'shared/assets/directory_preview.png';

interface Props extends BoxProps {
	directoryName: string;
	onGoToDirectory: (directoryName: string) => void;
}

export const ItemDirectoryCard: FC<Props> = ({ sx, directoryName, onGoToDirectory }) => {
	return (
		<Box
			sx={{ display: 'flex', flexDirection: 'row', cursor: 'pointer', marginLeft: '28px', ...sx }}
			onClick={() => onGoToDirectory(directoryName)}
		>
			<img
				src={directoryPreview}
				alt={'directory-preview'}
				style={{ width: '62px', height: '62px', margin: '0 8px' }}
			/>
			<Box sx={{ display: 'flex', flexDirection: 'column' }}>
				<Typography component={'p'} variant={'caption'} color={ColorPalette.baliHai}>
					{directoryName ?? '-'}
				</Typography>
			</Box>
		</Box>
	);
};
