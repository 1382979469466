import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { styled, Typography } from '@mui/material';
import { Paper } from 'shared/ui/Paper';
import { ColorPalette } from 'shared/consts/colorPalette';
import { STypesEnum } from 'entities/Subscriptions/enums';
import { ModalSubContent } from 'widgets/Subscriptions/ui/SubCurrentTab/Tabs/CurrentTab/ui/UpdateSubscriptionModal/ModalSubContent';
import { useSubscriptionStoreStore } from 'widgets/Subscriptions/model/state';

interface UpdateSubscriptionModalStep2PropsType {
	subscriptionType: STypesEnum;
}

const UpdateSubscriptionModalStep2: FC<UpdateSubscriptionModalStep2PropsType> = ({ subscriptionType }) => {
	const { t } = useTranslation();
	const { currentSubscription } = useSubscriptionStoreStore();

	const renderText = () => {
		// Text if current subscription is trial
		if (currentSubscription?.subscriptionType === STypesEnum.FREE) {
			return (
				<>
					<StyledTypography variant={'body1'} color={ColorPalette.baliHai}>
						{t('subscriptions.tab_1.step_2.trial_desctiption')}
					</StyledTypography>
				</>
			);
		}

		// Text if selected subscription is more expensive than current
		if (
			(currentSubscription?.subscriptionType === STypesEnum.STARTER ||
				currentSubscription?.subscriptionType === STypesEnum.PRO) &&
			(subscriptionType === STypesEnum.PRO || subscriptionType === STypesEnum.PAY_AS_YOU_GO)
		) {
			return (
				<>
					<StyledTypography variant={'body1'} color={ColorPalette.baliHai}>
						{t('subscriptions.tab_1.step_2.more_expensive_description_1')}
					</StyledTypography>
					<StyledTypography variant={'body1'} color={ColorPalette.baliHai}>
						{t('subscriptions.tab_1.step_2.more_expensive_description_2')}
					</StyledTypography>
				</>
			);
		}

		// Text if selected subscription is cheaper than current
		if (
			(currentSubscription?.subscriptionType === STypesEnum.PRO ||
				currentSubscription?.subscriptionType === STypesEnum.PAY_AS_YOU_GO) &&
			(subscriptionType === STypesEnum.STARTER || subscriptionType === STypesEnum.PRO)
		) {
			return (
				<>
					<StyledTypography variant={'body1'} color={ColorPalette.baliHai}>
						{t('subscriptions.tab_1.step_2.cheaper_description_1')}
					</StyledTypography>
					<StyledTypography variant={'body1'} color={ColorPalette.baliHai}>
						{t('subscriptions.tab_1.step_2.cheaper_description_2')}
					</StyledTypography>
					<StyledTypography variant={'body1'} color={ColorPalette.baliHai}>
						{t('subscriptions.tab_1.step_2.cheaper_description_3')}
					</StyledTypography>
				</>
			);
		}
		return <></>;
	};
	return (
		<>
			{renderText()}
			<StyledPaper>
				<ModalSubContent subscriptionType={subscriptionType} />
			</StyledPaper>
		</>
	);
};

export default UpdateSubscriptionModalStep2;

const StyledPaper = styled(Paper)`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 40px;
`;

const StyledTypography = styled(Typography)`
	margin-bottom: 25px;
`;
