import { Box } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { FC, useState, useCallback } from 'react';
import { useMutation } from 'react-query';
import { PageHeader } from 'shared/ui/PageHeader';
import { FtpImportFiles } from 'widgets/importFiles/importFilesFromFtp/ui/FtpImportFiles';
import { useParams } from 'react-router-dom';
import { FtpImportFilesModal } from 'widgets/importFiles/importFilesFromFtp/ui/FtpImportFilesModal';
import { ImportFilesModelDashboard } from 'widgets/customModel/ImportFilesModelDashboard';
import { getCustomModelFiles, uploadFileFromFtp } from 'entities/CustomModel/api';
import { useCustomModelStore } from 'widgets/customModel/state';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';

export const ImportCustomFilesFromFTPPage: FC = () => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const { modelId } = useParams<{ modelId: string }>();
	const [openModal, setOpenModal] = useState(false);

	const { setCurrentCustomModelFiles } = useCustomModelStore();

	const { mutate, isLoading } = useMutation({
		mutationKey: ['post/custom-models/ftp-uploading'],
		mutationFn: uploadFileFromFtp,
	});

	const query = useQuery({
		queryKey: ['get/custom-models/files'],
		queryFn: () => getCustomModelFiles(modelId || ''),
		onSuccess: (response) => {
			setCurrentCustomModelFiles(response.data);

			const isUploading = response.data.filter(
				(sessionItem) => sessionItem.status === 'UPLOADING',
			).length;
			if (isUploading) {
				setTimeout(() => {
					query.refetch();
				}, 6000);
			}
		},
		onError: (err) => {
			const errorMessage = getErrorMessage(err);
			enqueueSnackbar({
				message: errorMessage,
				variant: 'error',
			});
		},
	});

	const handleOpen = () => setOpenModal(true);
	const handleClose = () => setOpenModal(false);

	const handleImport = useCallback((localPath: string, selectedFiles: string[], isCompany: boolean) => {
		if (modelId) {
			mutate(
				{
					id: modelId,
					data: {
						ftpPath: localPath,
						filesNames: [...selectedFiles],
						isCompanyFtp: isCompany,
					},
				},
				{
					onSuccess: async () => {
						queryClient.refetchQueries({
							queryKey: ['get/custom-models/files'],
						});
						handleClose();
					},
					onError: (err) => {
						const defaultErrorMessage = t('files.snackbar.error_while_uploading');
						const errorMessage = getErrorMessage(err, defaultErrorMessage);
						enqueueSnackbar({
							message: errorMessage,
							variant: 'error',
						});
					},
				},
			);
		}
	}, []);

	return (
		<>
			<FtpImportFilesModal
				open={openModal}
				handleImport={handleImport}
				handleClose={handleClose}
				isPending={isLoading}
			/>
			<Box sx={{ marginBottom: '70px' }}>
				<PageHeader
					title={t('start_recognition.upload_files_section.title_ftp')}
					description={t('start_recognition.upload_files_section.description_ftp')}
				/>
				<FtpImportFiles handleOpen={handleOpen} />
				<ImportFilesModelDashboard uploadMode />
			</Box>
		</>
	);
};
