import { FC, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';
import { enqueueSnackbar } from 'notistack';
import { useQuery } from 'react-query';
import { EmptyTable } from 'shared/ui/EmptyTable';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { TableWithHeader } from 'shared/ui/tables/TableWithHeader';
import { BillingTableColumns } from 'widgets/Subscriptions/ui/SubCurrentTab/Tabs/BillingTab/BillingTableColumns';
import { DownloadModal } from 'shared/ui/DownloadModal';
import { SExportsEnum } from 'entities/Subscriptions/enums';
import { getSubscriptionInvoices } from 'entities/Subscriptions/api';
import { SubscriptionInvoicesType } from 'entities/Subscriptions/types';
import { getSubscriptionInvoicesExport } from 'entities/Subscriptions/api';

export const BillingTab: FC = () => {
	const { t } = useTranslation();
	const [downloadModalIsOpen, setDownloadModalIsOpen] = useState<boolean>(false);
	const [downloadFileId, setDownloadFileId] = useState<string | null>('');
	const { data: subscriptionInvoices } = useQuery({
		queryKey: ['subscription/invoices'],
		queryFn: () => getSubscriptionInvoices(),
	});

	const preparedBilling = useMemo(() => {
		if (!subscriptionInvoices?.data.length) return [];
		return subscriptionInvoices?.data.map((billingItem) => ({
			...billingItem,
			downloadAll: () => {
				setDownloadModalIsOpen(true);
				setDownloadFileId(null);
			},
			downloadSingle: (billing: SubscriptionInvoicesType) => {
				setDownloadModalIsOpen(true);
				setDownloadFileId(billing._id);
			},
		}));
	}, [subscriptionInvoices?.data]);

	const fetchExportFile = async (type: SExportsEnum, fileId: string) => {
		try {
			const response = await getSubscriptionInvoicesExport({ id: fileId, format: type });
			const file = subscriptionInvoices?.data.find((item) => item._id === fileId);
			const blob = new Blob([response.data], { type: `text/${type}` });
			saveAs(blob, `Retica-invoice-${file?.invoiceNumber}.${type}`);
		} catch (err) {
			const errorMessage = getErrorMessage(err);
			enqueueSnackbar({
				message: errorMessage,
				variant: 'error',
			});
		}
	};

	const handleDownload = async (type: SExportsEnum) => {
		if (downloadFileId) {
			fetchExportFile(type, downloadFileId);
		} else {
			subscriptionInvoices?.data.forEach((item) => {
				fetchExportFile(type, item._id);
			});
		}
	};

	return (
		<>
			{subscriptionInvoices?.data?.length ? (
				<TableWithHeader
					columns={BillingTableColumns}
					rows={preparedBilling.map((subscription) => ({ ...subscription, id: subscription._id }))}
				/>
			) : (
				<EmptyTable text={t('shared_texts.not_found')} />
			)}
			<DownloadModal
				open={downloadModalIsOpen}
				handleClose={() => setDownloadModalIsOpen(false)}
				handleDownload={handleDownload}
			/>
		</>
	);
};
