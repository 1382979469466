import { Typography } from '@mui/material';
import { FunctionComponent, ReactNode } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';

export const prepareCell = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	row: any,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	prepareFieldFn?: (field?: any) => string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	renderCellFn?: (field?: any) => FunctionComponent | JSX.Element | ReactNode,
): ReactNode => {
	const preparedField = prepareFieldFn && prepareFieldFn(row);
	return (
		renderCellFn ? (
			renderCellFn(row)
		) : (
			<Typography variant={'body2'} component={'span'} color={ColorPalette.astronaut}>
				{preparedField}
			</Typography>
		)
	) as ReactNode;
};
