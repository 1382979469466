import {
	GetExtensionsRequest,
	AddExtensionRequest,
	RemoveExtensionRequest,
	UpdateExtensionRequest,
	PostContuctUsExtensionRequest,
	GetCustomOutputFileRequest,
	PatchCustomOutputFileRequest,
	RestoreOutputFileRequest,
	GetCustomOutputFieldsRequest,
	PatchCustomOutputFieldRequest,
} from 'entities/Extensions/api/types';
import { axiosInstance } from 'shared/config/axios';

export const getExtensions: GetExtensionsRequest = () => {
	return axiosInstance.get('/extensions');
};

export const addExtension: AddExtensionRequest = (data) => {
	return axiosInstance.post('/extensions', data);
};

export const deleteExtension: RemoveExtensionRequest = (data) => {
	return axiosInstance.delete('/extensions', { data });
};

export const patchExtension: UpdateExtensionRequest = ({ data }) => {
	return axiosInstance.patch('/extensions', data);
};

export const postContuctUsExtensions: PostContuctUsExtensionRequest = (data) => {
	return axiosInstance.post('/extensions/contact-us', data);
};

export const getCustomOutputFile: GetCustomOutputFileRequest = (queries) => {
	return axiosInstance.get('/custom-output-file', { params: { ...queries } });
};

export const patchCustomOutputFile: PatchCustomOutputFileRequest = ({ id, data }) => {
	return axiosInstance.patch(`/custom-output-file/${id}`, data);
};

export const restoreCustomOutputFile: RestoreOutputFileRequest = ({ id }) => {
	return axiosInstance.post(`/custom-output-file/${id}/restore-default`);
};

export const getCustomOutputFileFields: GetCustomOutputFieldsRequest = (id) => {
	return axiosInstance.get(`/custom-output-file/${id}/fields`);
};

export const patchCustomOutputFileField: PatchCustomOutputFieldRequest = ({ id, data }) => {
	return axiosInstance.patch(`/custom-output-file/${id}/fields`, data);
};
