import { useState } from 'react';
import { Dialog, Typography, FormControlLabel, Box } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Paper } from 'shared/ui/Paper';
import { FeaturesEnum } from 'entities/Company/types';
import { ModelBuildMode } from 'entities/CustomModel/types';
import { ActionsForDialog } from 'shared/ui/ActionsForDialog';
import { Switch } from 'shared/ui/Switch';

interface TrainingSelectTypes {
	open: boolean;
	handleClose: () => void;
	handleSelect: (type: ModelBuildMode) => void;
}

export const TrainingSelectModal = ({ open, handleClose, handleSelect }: TrainingSelectTypes) => {
	const { t } = useTranslation();
	const [modelType, setModelType] = useState<ModelBuildMode>();

	const selectTypeHandler = () => {
		if (modelType) {
			handleSelect(modelType);
		} else {
			enqueueSnackbar({ message: t('custom_model.training_modal.error_message'), variant: 'error' });
		}
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			PaperComponent={Paper}
			PaperProps={{ title: t('custom_model.training_modal.title') as string }}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					margin: '20px 0',
				}}
			>
				<FormControlLabel
					control={
						<Switch
							checked={modelType === ModelBuildMode.neural}
							onChange={() => setModelType(ModelBuildMode.neural)}
							sx={{ marginRight: '10px' }}
						/>
					}
					label={
						<>
							<Typography variant={'body2'}>
								{t(`custom_model.model_types.${FeaturesEnum.NEURAL_MODEL}`)}
							</Typography>
							<Typography variant={'overline1'}>
								{t('custom_model.training_modal.neural_desctiption')}
							</Typography>
						</>
					}
					sx={{ margin: '0 0 30px 0' }}
				/>
				<FormControlLabel
					control={
						<Switch
							checked={modelType === ModelBuildMode.template}
							onChange={() => setModelType(ModelBuildMode.template)}
							sx={{ marginRight: '10px' }}
						/>
					}
					label={
						<Typography variant={'body2'}>{t('custom_model.model_types.template')}</Typography>
					}
					sx={{ margin: '0 0 30px 0' }}
				/>
			</Box>

			<ActionsForDialog
				cancelLabel={t('shared_texts.cancel')}
				cancelAction={handleClose}
				applyLabel={t('shared_texts.continue')}
				applyAction={selectTypeHandler}
				applyActionDisabled={false}
			/>
		</Dialog>
	);
};
