import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ExtensionsType } from 'entities/Extensions/types';
import { ExtensionsEnum } from 'entities/Extensions/enums';
import { EmptyTable } from 'shared/ui/EmptyTable';
import { TableWithoutHeader } from 'shared/ui/tables/TableWithoutHeader';
import { useExtension } from 'shared/hooks/useExtension';
import {
	ExtensionsTableColumns,
	ExtensionsTableColumnsType,
} from 'widgets/Extensions/ui/ExtensionsTable/ExtensionsTableColumns';

type ExtensionsTablePropsType = {
	toggleExtensionHandler: (data: ExtensionsType) => void;
	extensionDetailsHandler: (exName: ExtensionsEnum) => void;
	deleteExtensionHandler: (exName: ExtensionsEnum) => void;
};

export const ExtensionsTable: FC<ExtensionsTablePropsType> = ({
	toggleExtensionHandler,
	extensionDetailsHandler,
	deleteExtensionHandler,
}) => {
	const { t } = useTranslation();
	const { addedExtensions } = useExtension();

	const preparedExtensions: ExtensionsTableColumnsType[] = useMemo(() => {
		if (!addedExtensions?.length) return [];
		return addedExtensions.map((item) => ({
			id: item.extension,
			title: item.title,
			tableDescription: item.tableDescription,
			extension: item.extension,
			isEnabled: item.isEnabled,
			toggleExtensionHandler,
			extensionDetailsHandler,
			deleteExtensionHandler,
		}));
	}, [addedExtensions]);

	return (
		<>
			{preparedExtensions.length ? (
				<TableWithoutHeader columns={ExtensionsTableColumns} rows={preparedExtensions} />
			) : (
				<EmptyTable text={t('shared_texts.not_found')} />
			)}
		</>
	);
};
