import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';
import { useAuthStore } from 'processes/auth/model/state';
import { FunctionComponent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ColorPalette } from 'shared/consts/colorPalette';
import { isEmptyObject } from 'shared/helpers/isEmptyObject';
import { isEmptyObjectValues } from 'shared/helpers/isEmptyObjectValues';
import { Button } from 'shared/ui/Button';
import { InputField } from 'shared/ui/InputField';
import { Paper } from 'shared/ui/Paper';
import { createPassword } from 'entities/Auth/api';
import { CreatePasswordValidationSchema } from 'widgets/auth/createPassword/_MainModal/consts/validation';
import { CreatePassword } from 'widgets/auth/createPassword/_MainModal/types';
import { PasswordRequirements } from 'shared/ui/PasswordRequirements';
import { Footer } from 'widgets/auth/createPassword/_MainModal/ui/Footer';

export const CPMainModal: FunctionComponent = () => {
	const { token } = useParams();
	const { reset } = useAuthStore();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const initialValues = {
		password: '',
		confirmPassword: '',
	};

	const handleSubmit = (values: CreatePassword): void => {
		createPassword({
			token: token,
			password: values.password,
		})
			.then((res) => {
				if (res?.data.success) {
					reset();
					localStorage.clear();
					sessionStorage.clear();
					navigate('/create-password/success', { state: { access: true } });
					return;
				}
				navigate('/create-password/link-expired', { state: { access: true } });
			})
			.catch(() => {
				navigate('/create-password/link-expired', { state: { access: true } });
			});
	};

	return (
		<Paper
			title={t('auth.create_password') as string}
			sx={{
				'& *': {
					userSelect: 'none',
				},
			}}
		>
			<Typography variant={'body1'} color={ColorPalette.baliHai} sx={{ marginBottom: '35px' }}>
				{t('auth.enter_new_password')}
			</Typography>

			<Formik
				initialValues={initialValues}
				validationSchema={CreatePasswordValidationSchema}
				onSubmit={handleSubmit}
			>
				{({ errors, values }: FormikProps<CreatePassword>) => (
					<Form>
						<InputField
							name={'password'}
							label={'Password'}
							placeholder={t('auth.validation.enter_password')}
							type={'password'}
							required
						/>
						<InputField
							name={'confirmPassword'}
							label={t('auth.confirm_password')}
							placeholder={t('auth.confirm_password')}
							type={'password'}
							required
						/>
						<PasswordRequirements />
						<Button
							disabled={!isEmptyObject(errors) || isEmptyObjectValues(values)}
							type={'submit'}
							fullWidth
						>
							{t('shared_texts.continue')}
						</Button>
					</Form>
				)}
			</Formik>
			<Footer />
		</Paper>
	);
};
