export function getSortedArray(
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	array: Array<any>,
	sortParams: { key: string; rule: 'asc' | 'desc' },
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	prepareSortedField?: Record<string, (value: any) => string | number>,
) {
	return array.sort((a, b) => {
		const _a =
			prepareSortedField && prepareSortedField[sortParams.key]
				? prepareSortedField[sortParams.key](a[sortParams.key])
				: a[sortParams.key];
		const _b =
			prepareSortedField && prepareSortedField[sortParams.key]
				? prepareSortedField[sortParams.key](b[sortParams.key])
				: b[sortParams.key];

		return (_a - _b) * (sortParams.rule === 'asc' ? 1 : -1);
	});
}
