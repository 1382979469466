import { FC } from 'react';
import { Box } from '@mui/material';
import { AssertionsContent } from './AssertionsContent';
import { CustomOutputFileContent } from './CustomOutputFileContent';
import { WarehouseContent } from './WarehouseContent';
import { ExtensionsEnum } from 'entities/Extensions/enums';

type ExDrawerContentPropsType = {
	selectedExtension: ExtensionsEnum;
};

export const ExDrawerContent: FC<ExDrawerContentPropsType> = ({ selectedExtension }) => {
	const renderCrawerContent = () => {
		if (selectedExtension === ExtensionsEnum.assertions_sets) {
			return <AssertionsContent />;
		}
		if (selectedExtension === ExtensionsEnum.custom_output_file) {
			return <CustomOutputFileContent />;
		}
		if (selectedExtension === ExtensionsEnum.warehouse) {
			return <WarehouseContent />;
		}
		return null;
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: '20px',
			}}
		>
			{renderCrawerContent()}
		</Box>
	);
};
