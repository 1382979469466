import { Table as MUITable, TableCell, TableHead, TableProps, TableRow } from '@mui/material';
import { ColorPalette } from 'shared/consts/colorPalette';
import { prepareCell } from 'shared/ui/tables/_common/helpers/prepareCell';
import { Column } from 'shared/ui/tables/_common/types';

interface Props<T> extends TableProps {
	columns: Array<Column<T>>;
	additionalData?: T;
}
export function TableHeader<T>({ columns, additionalData }: Props<T>) {
	return (
		<MUITable>
			<TableHead>
				<TableRow>
					{columns.map((column) => {
						let prepareFieldFn, renderCellFn, restHeaderCellSettings;
						if (column?.headerCellSettings) {
							const { prepareField, renderCell, ...rest } = column.headerCellSettings;
							prepareFieldFn = prepareField;
							renderCellFn = renderCell;
							restHeaderCellSettings = rest;
						}

						const preparedCell = prepareCell(
							{ ...column, ...(additionalData ?? {}) },
							prepareFieldFn,
							renderCellFn,
						);

						return preparedCell !== null ? (
							<TableCell
								key={column.field}
								sx={{
									'height': 'fit-content',
									'padding': '0 10px',
									'borderBottom': 'none',
									'border': 'none',
									'color': ColorPalette.baliHai,
									'& .MuiTableCell-head': {
										borderBottom: 'none',
										border: 'none',
									},
									'&:first-of-type': {
										paddingLeft: '24px',
									},
									'&:last-of-type': {
										paddingRight: '24px',
									},
								}}
								{...column?.commonCellSettings}
								{...restHeaderCellSettings}
							>
								<div style={{ display: 'flex', alignItems: 'center' }}>{preparedCell}</div>
							</TableCell>
						) : null;
					})}
				</TableRow>
			</TableHead>
		</MUITable>
	);
}
