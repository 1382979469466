import { Dialog, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Routes } from 'app/config/routes/consts';
import { deleteSession } from 'entities/ImportSessions/api';
import { enqueueSnackbar } from 'notistack';
import { FC } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ColorPalette } from 'shared/consts/colorPalette';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { ActionsForDialog } from 'shared/ui/ActionsForDialog';
import { CloseModalButton } from 'shared/ui/CloseModalButton';
import { Paper } from 'shared/ui/Paper';

interface Props {
	open: boolean;
	handleClose: () => void;
	importSessionId: string;
}

export const CancelConfirmationModal: FC<Props> = ({ open, handleClose, importSessionId }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const { mutate } = useMutation({
		mutationKey: ['import-sessions', importSessionId],
		mutationFn: deleteSession,
	});

	const cancelSession = () => {
		mutate(importSessionId as string, {
			onSuccess: async () => {
				enqueueSnackbar({
					message: t('start_recognition.leave_page_modal.success_delete'),
					variant: 'success',
				});
				navigate(Routes.startRecognition);
			},
			onError: (err) => {
				const defaultErrorMessage = t('start_recognition.leave_page_modal.error_delete');
				const errorMessage = getErrorMessage(err, defaultErrorMessage);
				enqueueSnackbar({
					message: errorMessage,
					variant: 'error',
				});
			},
		});
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			PaperComponent={Paper}
			PaperProps={{ title: t('start_recognition.leave_page_modal.title') as string }}
			sx={{
				'& .MuiPaper-root': {
					width: '506px',
					maxWidth: '506px',
					overflow: 'inherit',
				},
			}}
		>
			<CloseModalButton action={handleClose} />
			<Typography variant={'body1'} color={ColorPalette.baliHai} sx={{ marginBottom: '35px' }}>
				{t('start_recognition.leave_page_modal.description')}
			</Typography>
			<ActionsForDialog
				cancelLabel={t('shared_texts.cancel')}
				cancelAction={handleClose}
				applyLabel={t('shared_texts.yes')}
				applyAction={cancelSession}
			/>
		</Dialog>
	);
};
