import { FC } from 'react';
import { FSForm } from 'widgets/integrations/FtpSettings/Form';
import { FSHeader } from 'widgets/integrations/FtpSettings/Header';

export const FtpSettings: FC = () => {
	return (
		<>
			<FSHeader />
			<FSForm />
		</>
	);
};
