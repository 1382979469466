import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Box, Grid, styled } from '@mui/material';
import { useQuery } from 'react-query';
import { format } from 'date-fns';
import { ColorPalette } from 'shared/consts/colorPalette';
import { InfoContainer } from 'widgets/CoUsers/ui/CoUserDetailsInfo/ui/Paper';
import { SubscriptionsTable } from 'widgets/admin/UserDetails/ui/tabs/Subscriptions/SubscriptionsTable';
import { getUserSubscriptions } from 'entities/admin/Users/api';
import { capitalizeFirstLetter } from 'shared/helpers/capitalizeFirstLetter';
import { getMaxValue } from 'shared/helpers/getMaxValue';

export type TableColumnsType = {
	_id: string;
	subscriptionType: string;
	status: string;
	subscriptionCost: number;
	currentPeriodStart: string;
	currentPeriodEnd: string;
	totalFiles: number;
	totalCousers: number;
	isCurrentSubscription: boolean;
	createdAt: string;
	updatedAt: string;
	maxCousers: number | string;
	maxFiles: number | string;
	maxFreeCousers: number | string;
	maxFreeCustomModels: number | string;
	maxFreeFiles: number | string;
	ftpImport: boolean;
	totalExtraPages: number;
	totalPages: number;
	autoRemovePeriod: {
		amount: number;
		key: string;
	};
};

const Subscriptions: FC = () => {
	const { t } = useTranslation();
	const [userSubscriptions, setUserSubscriptions] = useState<TableColumnsType[]>([]);
	const [currentSubscription, setCurrentSubscription] = useState<TableColumnsType | null>(null);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const userDetailsInfoStore: any = localStorage.getItem('userDetailsInfoStore');
	const parsedUserData = JSON.parse(userDetailsInfoStore);
	const userId = parsedUserData?.state?.selectedUser?._id;

	const { data } = useQuery({
		queryKey: [`admin/users/${userId}/subscriptions`],
		queryFn: () => getUserSubscriptions(userId),
	});
	// TODO remove this
	useEffect(() => {
		setUserSubscriptions(data?.data);
		{
			userSubscriptions?.length &&
				userSubscriptions?.length > 0 &&
				userSubscriptions?.map(
					(subscription) =>
						subscription?.isCurrentSubscription && setCurrentSubscription(subscription),
				);
		}
	}, [data, currentSubscription, userSubscriptions]);

	return (
		<>
			<Typography variant={'h5'} color={ColorPalette.astronaut} sx={{ margin: '44px 0' }}>
				{t('subscriptions.title')}
			</Typography>

			<InfoContainer sx={{ width: '100%' }}>
				<Typography variant={'h5'} color={ColorPalette.astronaut} sx={{ marginBottom: '44px' }}>
					{t('subscriptions.tabs.tab_1')}
				</Typography>

				{currentSubscription ? (
					<Grid container sx={{ gap: '20px' }}>
						<Grid item md={5}>
							<Typography variant={'h3'} color={ColorPalette.royalBlue}>
								{currentSubscription?.status === 'canceled'
									? t('subscriptions.canceled')
									: capitalizeFirstLetter(currentSubscription?.subscriptionType || '')}
							</Typography>
						</Grid>
						<Grid item md={2}>
							<StyledBox>
								<Typography variant={'body2'}>
									{t('admin.users.details.tab_2.sub_cost')}
								</Typography>
								<Typography variant={'body2'} color={ColorPalette.rockBlue}>
									{currentSubscription?.subscriptionCost
										? `${currentSubscription?.subscriptionCost / 100} €`
										: '0'}
								</Typography>
							</StyledBox>
						</Grid>
						<Grid item md={2} sx={{ display: 'flex', flexDirection: 'column', gap: '40px' }}>
							<StyledBox>
								<Typography variant={'body2'}>
									{t('admin.users.details.tab_2.trial_ends')}
								</Typography>
								<Typography variant={'body2'} color={ColorPalette.rockBlue}>
									{format(new Date(currentSubscription.currentPeriodEnd), 'dd/MM/yyyy')}
								</Typography>
							</StyledBox>
							<StyledBox>
								<Typography variant={'body2'}>
									{t('admin.users.details.tab_2.sub_bought')}
								</Typography>
								<Typography variant={'body2'} color={ColorPalette.rockBlue}>
									{format(new Date(currentSubscription.currentPeriodStart), 'dd/MM/yyyy')}
								</Typography>
							</StyledBox>
						</Grid>
						<Grid item md={2} sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
							<StyledBox>
								<Typography variant={'body2'}>
									{t('admin.users.details.tab_2.files_imported')}
								</Typography>
								<Typography variant={'body2'} color={ColorPalette.rockBlue}>
									{`${currentSubscription?.totalFiles}/${
										currentSubscription?.maxFiles === 'inf'
											? '∞'
											: currentSubscription?.maxFiles
									}`}
								</Typography>
							</StyledBox>
							<StyledBox>
								<Typography variant={'body2'}>
									{t('admin.users.details.tab_2.users_added')}
								</Typography>
								<Typography variant={'body2'} color={ColorPalette.rockBlue}>
									{`${currentSubscription?.totalCousers}/${getMaxValue(
										currentSubscription.maxCousers,
										currentSubscription.maxFreeCousers,
									)}`}
								</Typography>
							</StyledBox>
						</Grid>
					</Grid>
				) : (
					'-'
				)}
			</InfoContainer>

			<Typography variant={'h6'} color={ColorPalette.astronaut} sx={{ margin: '44px 0' }}>
				{t('admin.users.details.subscriptions_history')}
			</Typography>

			<SubscriptionsTable subscriptions={userSubscriptions || []} />
		</>
	);
};

export default Subscriptions;

const StyledBox = styled(Box)`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;
