import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { createCompany } from 'entities/Company/api';
import { Form, Formik, FormikProps } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { useAuthStore } from 'processes/auth/model/state';
import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { ColorPalette } from 'shared/consts/colorPalette';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { isEmptyObject } from 'shared/helpers/isEmptyObject';
import { isEmptyObjectValues } from 'shared/helpers/isEmptyObjectValues';
import { Button } from 'shared/ui/Button';
import { InputField } from 'shared/ui/InputField';
import { Paper } from 'shared/ui/Paper';
import { signUp } from 'entities/Auth/api';
import { Step3ValidationSchema } from 'widgets/auth/signUp/_MainModal/consts/validation';
import { useSignInStore } from 'widgets/auth/signUp/_MainModal/model/state';
import { Step3 } from 'widgets/auth/signUp/_MainModal/types';
import { Footer } from 'widgets/auth/signUp/_MainModal/ui/Footer';
import { StepLabel } from 'widgets/auth/signUp/_MainModal/ui/StepLabel';

export const SignUpStep3: FunctionComponent = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { authType, signUpData, updateCompanySignUpData, reset } = useSignInStore();
	const { accessToken, updateAuthData } = useAuthStore();

	const initialValues = {
		name: signUpData.company.name,
		vatNumber: signUpData.company.vatNumber,
		sdiCode: signUpData.company.sdiCode,
		country: signUpData.company.country,
		city: signUpData.company.city,
		street: signUpData.company.street,
		houseNumber: signUpData.company.houseNumber,
		postalCode: signUpData.company.postalCode,
	};

	const handleSubmit = (values: Step3): void => {
		switch (authType) {
			case 'credentials':
				updateCompanySignUpData({ ...values });
				signUp({
					...signUpData,
					company: {
						...signUpData.company,
						...values,
					},
				})
					.then((res) => {
						if (res?.data?.accessToken) {
							navigate('/sign-up/email-sent');
							reset();
						}
					})
					.catch((err) => {
						const defaultErrorMessage = t('shared_texts.smth_went_wrong');
						const errorMessage = getErrorMessage(err, defaultErrorMessage);
						enqueueSnackbar({
							message: errorMessage,
							variant: 'error',
						});
					});
				break;
			case 'google':
				updateCompanySignUpData({ ...values });
				createCompany({
					token: accessToken,
					company: { ...values },
				})
					.then((res) => {
						updateAuthData({
							company: res?.data,
						});
						navigate('/');
						reset();
					})
					.catch((err) => {
						const defaultErrorMessage = t('shared_texts.smth_went_wrong');
						const errorMessage = getErrorMessage(err, defaultErrorMessage);
						enqueueSnackbar({
							message: errorMessage,
							variant: 'error',
						});
					});
		}
	};

	return (
		<Paper
			title={t('auth.sign_up') as string}
			sx={{
				'& *': {
					userSelect: 'none',
				},
			}}
		>
			<StepLabel />
			<Typography variant={'body1'} color={ColorPalette.baliHai} sx={{ marginBottom: '35px' }}>
				{t('auth.company_info')}
			</Typography>
			<Formik
				initialValues={initialValues}
				validationSchema={Step3ValidationSchema}
				onSubmit={handleSubmit}
			>
				{({ errors, values }: FormikProps<Step3>) => (
					<Form>
						<InputField
							name={'name'}
							label={t('auth.labels.company_name')}
							placeholder={t('auth.validation.enter_company_name')}
							required
						/>
						<InputField
							name={'vatNumber'}
							label={t('auth.labels.vat_number')}
							placeholder={t('auth.validation.enter_vat_number')}
							required
						/>
						<InputField
							name={'sdiCode'}
							label={t('auth.labels.sdi_code')}
							placeholder={t('auth.validation.enter_sdi_code')}
							required
						/>
						<Typography
							variant={'h6'}
							color={ColorPalette.astronaut}
							sx={{ marginBottom: '26px' }}
						>
							{t('auth.company_address')}
						</Typography>
						<InputField
							name={'country'}
							label={t('auth.labels.country')}
							placeholder={t('auth.validation.enter_country')}
							required
						/>
						<InputField
							name={'city'}
							label={t('auth.labels.city')}
							placeholder={t('auth.validation.enter_city')}
							required
						/>
						<InputField
							name={'street'}
							label={t('auth.labels.street')}
							placeholder={t('auth.validation.enter_street')}
							required
						/>
						<InputField
							name={'houseNumber'}
							label={t('auth.labels.house_number')}
							placeholder={t('auth.validation.enter_house_number')}
							required
						/>
						<InputField
							name={'postalCode'}
							label={t('auth.labels.postal_code')}
							placeholder={t('auth.validation.enter_postal_code')}
							required
						/>
						<Button
							disabled={!isEmptyObject(errors) || isEmptyObjectValues(values)}
							type={'submit'}
							fullWidth
							variant={'contained'}
						>
							{t('auth.sign_up')}
						</Button>
					</Form>
				)}
			</Formik>
			<Footer />
		</Paper>
	);
};
