import { useMemo } from 'react';
import { useAuthStore } from 'processes/auth/model/state';
import { ExtensionsEnum } from 'entities/Extensions/enums';
import { extensionsArray } from 'widgets/Extensions/ExtensionsToSelect/consts/all_extensions';

export function useExtension() {
	const { company } = useAuthStore();

	const extensionsToSelect = useMemo(
		() =>
			extensionsArray.map((exItem) => {
				const selectedExtension = company?.extensions?.find(
					(userExItem) => userExItem.extension === exItem.extension,
				);
				return selectedExtension ? { ...exItem, isSelected: true } : exItem;
			}),
		[company],
	);

	const addedExtensions = useMemo(
		() =>
			company?.extensions?.map((userExItem) => {
				const selectedExtension = extensionsArray.find(
					(exItem) => exItem.extension === userExItem.extension,
				);
				return {
					...userExItem,
					title: selectedExtension?.title || '',
					tableDescription: selectedExtension?.tableDescription || '',
				};
			}),
		[company],
	);

	const getExtension = (exName: ExtensionsEnum) =>
		company?.extensions?.find((item) => item.extension === exName);

	return { extensionsToSelect, addedExtensions, getExtension };
}
