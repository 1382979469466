import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';
import { CustomOutputFieldsType } from 'entities/Extensions/types';
import { DraggableTable } from 'shared/ui/tables/DraggableTable';
import { isIncorrectLabelFormat } from 'shared/helpers/isIncorrectLabelFormat';
import { OutputFieldsColumns, OutputFieldsColumnsType } from './Columns';

type CustomOutputFileFieldsPropsType = {
	fieldsData: CustomOutputFieldsType;
	setFieldsState: (data: CustomOutputFieldsType) => void;
};
export const CustomOutputFileFields: FC<CustomOutputFileFieldsPropsType> = ({
	fieldsData,
	setFieldsState,
}) => {
	const { t } = useTranslation();
	const [isAllFieldsEnabled, setIsAllFieldsEnabled] = useState(true);

	const onKeyChange = _.debounce((fieldId: string, value: string) => {
		const newFieldsState = fieldsData.fields.map((item) =>
			item._id === fieldId ? { ...item, customFieldName: value } : item,
		);
		setFieldsState({ ...fieldsData, fields: newFieldsState });
	}, 300);

	const handleToggleAllFields = _.debounce(() => {
		const newFieldsState = fieldsData.fields.map((item) => ({
			...item,
			isEnabled: !isAllFieldsEnabled,
		}));
		setFieldsState({ ...fieldsData, fields: newFieldsState });
		setIsAllFieldsEnabled(!isAllFieldsEnabled);
	}, 300);

	const handleToggleField = (fieldId: string) => {
		const newFieldsState = fieldsData.fields.map((item) =>
			item._id === fieldId ? { ...item, isEnabled: !item.isEnabled } : item,
		);
		setFieldsState({ ...fieldsData, fields: newFieldsState });
	};

	const preparedFields = useMemo(() => {
		if (!fieldsData?.fields.length) return [];
		return fieldsData.fields.map((item) => ({
			...item,
			id: item._id,
			handleToggleField,
			handleToggleAllFields,
			allFieldsEnabled: isAllFieldsEnabled,
			isErrorText: isIncorrectLabelFormat(item.customFieldName) ? t('files.details.error_text') : '',
			onKeyChange,
		}));
	}, [isAllFieldsEnabled, fieldsData, fieldsData]);

	const onDragEndHandler = (data: OutputFieldsColumnsType[]) => {
		const mappedFieldsData = data.map((item) => ({
			_id: item._id,
			customOutputFileId: item.customOutputFileId,
			customFieldName: item.customFieldName,
			originalFieldName: item.originalFieldName,
			order: item.order,
			isEnabled: item.isEnabled,
		}));
		setFieldsState({ ...fieldsData, fields: mappedFieldsData });
	};

	return (
		<DraggableTable
			columns={OutputFieldsColumns}
			rows={preparedFields}
			onDragEndHandler={onDragEndHandler}
		/>
	);
};
