import { Routes } from 'app/config/routes/consts';
import { FunctionComponent, ReactElement } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

interface Props {
	children: ReactElement;
}

export const AccessLayout: FunctionComponent<Props> = ({ children }) => {
	const location = useLocation();

	if (!location?.state?.access) {
		return <Navigate to={Routes.startRecognition} replace />;
	}

	return <>{children}</>;
};
