import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Routes } from 'app/config/routes/consts';
import { signUpByInvitation } from 'entities/Auth/api';
import { Form, Formik, FormikProps } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { useAuthStore } from 'processes/auth/model/state';
import { FunctionComponent } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { ColorPalette } from 'shared/consts/colorPalette';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { isEmptyObject } from 'shared/helpers/isEmptyObject';
import { isEmptyObjectValues } from 'shared/helpers/isEmptyObjectValues';
import { Button } from 'shared/ui/Button';
import { InputField } from 'shared/ui/InputField';
import { Paper } from 'shared/ui/Paper';
import { Step2ValidationSchema } from 'widgets/auth/signUpByInvitation/_MainModal/consts/validation';
import { useSignUpByInvitationStore } from 'widgets/auth/signUpByInvitation/_MainModal/model/state';
import { Step2 } from 'widgets/auth/signUpByInvitation/_MainModal/types';
import { StepLabel } from 'widgets/auth/signUpByInvitation/_MainModal/ui/StepLabel';

export const SignUpStep2: FunctionComponent = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { token } = useParams();
	const { reset, updateAuthData } = useAuthStore();
	const { signUpData, updateUserSignUpData } = useSignUpByInvitationStore();

	const { mutate } = useMutation({
		mutationFn: signUpByInvitation,
	});

	const initialValues = {
		firstName: signUpData.firstName,
		lastName: signUpData.lastName,
	};

	const handleSubmit = (values: Step2): void => {
		updateUserSignUpData({ ...values });
		const mutationValues = {
			firstName: values.firstName,
			lastName: values.lastName,
			token: token,
			password: signUpData.password,
		};
		mutate(mutationValues, {
			onSuccess: (res) => {
				reset();
				localStorage.clear();
				sessionStorage.clear();

				enqueueSnackbar({
					message: 'You successfully registered by invitation link.',
					variant: 'success',
				});
				updateAuthData(res.data);
				navigate(Routes.startRecognition);
			},
			onError: (err) => {
				const errorMessage = getErrorMessage(err);
				enqueueSnackbar({
					message: errorMessage,
					variant: 'error',
				});
			},
		});
	};

	return (
		<Paper
			title={t('auth.sign_up') as string}
			sx={{
				'& *': {
					userSelect: 'none',
				},
			}}
		>
			<StepLabel />
			<Typography variant={'body1'} color={ColorPalette.baliHai} sx={{ marginBottom: '35px' }}>
				{t('auth.personal_info')}
			</Typography>
			<Formik
				initialValues={initialValues}
				validationSchema={Step2ValidationSchema}
				onSubmit={handleSubmit}
			>
				{({ errors, values }: FormikProps<Step2>) => (
					<Form>
						<InputField
							name={'firstName'}
							label={t('auth.labels.name')}
							placeholder={t('auth.validation.enter_name')}
							required
						/>
						<InputField
							name={'lastName'}
							label={t('auth.labels.last_name')}
							placeholder={t('auth.validation.enter_last_name')}
							required
						/>
						<Button
							disabled={!isEmptyObject(errors) || isEmptyObjectValues(values)}
							type={'submit'}
							fullWidth
							variant={'contained'}
						>
							{t('shared_texts.continue')}
						</Button>
					</Form>
				)}
			</Formik>
		</Paper>
	);
};
