import { Box, Typography } from '@mui/material';
import { FunctionComponent, ReactElement } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';

interface Props {
	children: ReactElement | string;
}

export const InShortParagraph: FunctionComponent<Props> = ({ children }) => {
	return (
		<Box
			sx={{
				'maxWidth': '828px',
				'marginBottom': '18px',
				'& a': {
					color: ColorPalette.royalBlue,
					textDecoration: 'underline',
				},
			}}
		>
			<Typography component={'span'} variant={'body1'} color={ColorPalette.astronaut}>
				{'In short: '}
			</Typography>
			<Typography component={'span'} variant={'body1'} color={ColorPalette.baliHai}>
				{children}
			</Typography>
		</Box>
	);
};
