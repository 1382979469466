import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Dialog } from '@mui/material';
import { ActionsForDialog } from 'shared/ui/ActionsForDialog';
import { Paper } from 'shared/ui/Paper';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Select } from 'shared/ui/Select';
import { useAssertionSetsStore } from 'widgets/Extensions/Details/ui/AssertionSets/model/state';
import { Loader } from 'shared/ui/Loader';

interface Props {
	open: boolean;
	handleClose: () => void;
	handleApply: (assertionId: string) => void;
	handleRemoveAssertions: () => void;
	loading: boolean;
	defaultAssertionId?: string;
}

export const AsModalList: FC<Props> = ({
	open,
	handleClose,
	handleApply,
	handleRemoveAssertions,
	loading,
	defaultAssertionId,
}) => {
	const { t } = useTranslation();
	const { assertionsSets } = useAssertionSetsStore();
	const [assertionSetId, setAssertionSetId] = useState('');

	const defaultValueMemo = useMemo(
		() => assertionsSets?.find((item) => item._id === defaultAssertionId)?._id,
		[defaultAssertionId],
	);

	useEffect(() => {
		if (defaultAssertionId) {
			setAssertionSetId(defaultAssertionId);
		}
	}, [defaultAssertionId]);

	const selectedOptions = useMemo(() => {
		const mappedOptions = assertionsSets?.map((item) => ({
			label: item.name,
			value: item._id,
		}));
		mappedOptions?.unshift({ label: '-', value: '' });
		return mappedOptions;
	}, [assertionsSets]);

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			PaperComponent={Paper}
			PaperProps={{ title: `${t('assertions.as_title')}` }}
		>
			{loading ? (
				<Loader type="min" />
			) : (
				<>
					<Typography variant={'body2'} color={ColorPalette.baliHai} sx={{ marginBottom: '24px' }}>
						{t('assertions.modal.select_description')}
					</Typography>

					<Select
						label={t('assertions.as_title_list') as string}
						placeholder={t('assertions.placeholder.select_as_set')}
						value={assertionSetId || ''}
						onSelect={(item) => setAssertionSetId(item as string)}
						options={selectedOptions || []}
						defaultValue={defaultValueMemo}
					/>

					<ActionsForDialog
						cancelLabel={t('assertions.modal.remove_assertion')}
						cancelAction={() => handleRemoveAssertions()}
						applyLabel={t('shared_texts.apply')}
						applyAction={() => {
							handleApply(assertionSetId);
						}}
						applyActionDisabled={false}
					/>
				</>
			)}
		</Dialog>
	);
};
