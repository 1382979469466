import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { ColorPalette } from 'shared/consts/colorPalette';

const UpdateSubscriptionModalStep3: FC = () => {
	const { t } = useTranslation();

	return (
		<Typography variant={'subtitle1'} color={ColorPalette.baliHai} sx={{ marginTop: '24px' }}>
			{t('subscriptions.tab_1.confirm_downgrading')}
		</Typography>
	);
};

export default UpdateSubscriptionModalStep3;
