import { Typography } from '@mui/material';
import { Routes } from 'app/config/routes/consts';
import { ImportSession } from 'entities/ImportSessions/types';
import { useNavigate } from 'react-router-dom';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Column } from 'shared/ui/tables/_common/types';
import { BackButton } from 'shared/ui/BackButton';
import { TableTranslationLabel } from 'shared/ui/tables/_common/ui/TableTranslationLabel';

export const SessionsDashboardColumns: Array<Column<Partial<ImportSession & { source_title?: string }>>> = [
	{
		field: 'source',
		commonCellSettings: {
			style: { width: '200px' },
			align: 'center',
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="source" />,
		},
		bodyCellSettings: {
			renderCell: (session) => {
				return (
					<Typography variant={'body2'} color={ColorPalette.baliHai}>
						{session?.source_title}
					</Typography>
				);
			},
		},
	},
	{
		field: 'total',
		commonCellSettings: {
			style: { width: '120px' },
			align: 'center',
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="total" />,
		},
		bodyCellSettings: {
			renderCell: (session) => {
				const count = session?.importSessionFiles?.length;
				return (
					<Typography variant={'body2'} color={ColorPalette.baliHai}>
						{count ?? '-'}
					</Typography>
				);
			},
		},
	},
	{
		field: 'uploading',
		commonCellSettings: {
			style: { width: '120px' },
			align: 'center',
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="uploading" />,
		},
		bodyCellSettings: {
			renderCell: (session) => {
				const count = session?.importSessionFiles?.filter(
					(file) => file.status === 'UPLOADING',
				).length;
				return (
					<Typography variant={'body2'} color={ColorPalette.baliHai}>
						{count ?? '-'}
					</Typography>
				);
			},
		},
	},
	{
		field: 'uploaded',
		commonCellSettings: {
			style: { width: '120px' },
			align: 'center',
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="uploaded" />,
		},
		bodyCellSettings: {
			renderCell: (session) => {
				const count = session?.importSessionFiles?.filter(
					(file) => file.status === 'UPLOADED',
				).length;
				return (
					<Typography variant={'body2'} color={ColorPalette.baliHai}>
						{count ?? '-'}
					</Typography>
				);
			},
		},
	},
	{
		field: 'error',
		commonCellSettings: {
			style: { width: '120px' },
			align: 'center',
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="error" />,
		},
		bodyCellSettings: {
			renderCell: (session) => {
				const count = session?.importSessionFiles?.filter((file) => file.status === 'ERROR').length;
				return (
					<Typography variant={'body2'} color={ColorPalette.baliHai}>
						{count ?? '-'}
					</Typography>
				);
			},
		},
	},
	{
		field: 'goToSession',
		commonCellSettings: {
			style: { width: '80px' },
			align: 'center',
		},
		bodyCellSettings: {
			renderCell: (session) => {
				const navigate = useNavigate();
				const route =
					session?.uploadSource === 'DIRECT' ? Routes.importFilesDirect : Routes.importFilesFromFtp;
				return (
					<>
						{session?._id && (
							<BackButton
								action={() => navigate(`${route}?category=${session?.category}`)}
								sx={{
									position: 'absolute',
									top: '50%',
									right: '30px',
									left: 'auto',
									transform: 'translateY(-50%) rotate(180deg)',
									cursor: 'pointer',
								}}
							/>
						)}
					</>
				);
			},
		},
	},
];
