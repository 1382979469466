import { Box } from '@mui/material';
import { FieldsType } from 'entities/Extensions/types';
import { Switch } from 'shared/ui/Switch';
import { Icon } from 'shared/ui/Icon';
import { ColorPalette } from 'shared/consts/colorPalette';
import { TableTranslationLabel } from 'shared/ui/tables/_common/ui/TableTranslationLabel';
import { Input } from 'shared/ui/Input';
import { Column } from 'shared/ui/tables/_common/types';

export type OutputFieldsColumnsType = FieldsType & {
	id: string;
	allFieldsEnabled: boolean;
	handleToggleAllFields: () => void;
	handleToggleField: (fieldId: string) => void;
	onKeyChange: (fieldId: string, value: string) => void;
	isErrorText: string;
};

export const OutputFieldsColumns: Array<Column<OutputFieldsColumnsType>> = [
	{
		field: 'fieldSwitcher',
		commonCellSettings: {
			style: {
				minWidth: '50px',
				width: '5%',
			},
		},
		headerCellSettings: {
			renderCell: (fieldCell) => (
				<Switch checked={fieldCell?.allFieldsEnabled} onChange={fieldCell?.handleToggleAllFields} />
			),
		},
		bodyCellSettings: {
			renderCell: (fieldCell) => (
				<Switch
					checked={fieldCell?.isEnabled}
					onChange={() => {
						fieldCell?.handleToggleField(fieldCell._id);
					}}
				/>
			),
		},
	},
	{
		field: 'fieldSorting',
		commonCellSettings: {
			style: {
				minWidth: '150px',
				width: '10%',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="sorting" color={ColorPalette.baliHai} />,
		},
		bodyCellSettings: {
			renderCell: (fieldCell) => fieldCell?.order || '-',
		},
	},
	{
		field: 'fieldStandardKey',
		commonCellSettings: {
			style: {
				minWidth: '200px',
				width: '25%',
			},
		},
		headerCellSettings: {
			renderCell: () => (
				<TableTranslationLabel label="retica_standard_key" color={ColorPalette.baliHai} />
			),
		},
		bodyCellSettings: {
			renderCell: (fieldCell) => fieldCell?.originalFieldName || '-',
		},
	},
	{
		field: 'fieldOutputKey',
		commonCellSettings: {
			style: {
				minWidth: '200px',
				width: '25%',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="output_file_key" color={ColorPalette.baliHai} />,
		},
		bodyCellSettings: {
			renderCell: (fieldCell) => {
				return (
					<Input
						label={''}
						sx={{ margin: 0, padding: fieldCell?.isErrorText ? '20px 0 40px' : '0' }}
						defaultValue={fieldCell?.customFieldName ?? ''}
						name={`customFieldName_${fieldCell?.order}`}
						placeholder={'extensions.placeholder.enter_custom_label_name'}
						onChange={(e) => {
							fieldCell?.onKeyChange(fieldCell._id, e.target.value);
						}}
						errorText={fieldCell?.isErrorText || ''}
					/>
				);
			},
		},
	},
	{
		field: 'options',
		commonCellSettings: {
			style: {
				width: '5%',
				position: 'relative',
			},
		},
		bodyCellSettings: {
			renderCell: () => (
				<Box
					sx={{
						position: 'absolute',
						height: '20px',
						width: '20px',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
					}}
				>
					<Icon
						id={'arrow_drop_up'}
						style={{ position: 'absolute', top: '-8px', cursor: 'pointer' }}
					/>
					<Icon
						id={'arrow_drop_down_big'}
						style={{ position: 'absolute', bottom: '-8px', cursor: 'pointer' }}
					/>
				</Box>
			),
		},
	},
];
