import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useStripe } from '@stripe/react-stripe-js';
import { enqueueSnackbar } from 'notistack';
import { Box, Typography, Tooltip } from '@mui/material';
import { format } from 'date-fns';
import { ColorPalette } from 'shared/consts/colorPalette';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Paper } from 'shared/ui/Paper';
import { capitalizeFirstLetter } from 'shared/helpers/capitalizeFirstLetter';
import { retrySubscriptionPayment, deleteSubscription } from 'entities/Subscriptions/api';
import { SubscriptionType } from 'entities/Subscriptions/types';
import { UpdateSubscriptionModal } from 'widgets/Subscriptions/ui/SubCurrentTab/Tabs/CurrentTab/ui/UpdateSubscriptionModal';
import { CancelSubscriptionModal } from 'widgets/Subscriptions/ui/SubCurrentTab/Tabs/CurrentTab/ui/CancelSubscriptionModal';
import { STypesEnum, SubscriptionStatus } from 'entities/Subscriptions/enums';

interface CurrentSubInfoPropsTypes {
	currentSubscription: SubscriptionType;
}

export const CurrentSubInfo: FC<CurrentSubInfoPropsTypes> = ({ currentSubscription }) => {
	const { t } = useTranslation();
	const stripe = useStripe();
	const queryClient = useQueryClient();
	const [isOpenUpdateSubscriptionModal, setIsOpenUpdateSubscriptionModal] = useState(false);
	const [isOpenCancelSubscriptionModal, setIsOpenCancelSubscriptionModal] = useState(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const confirmCardPaymentHandler = async (clientSecret: string, message: string) => {
		if (stripe) {
			const { error } = await stripe.confirmCardPayment(clientSecret);
			if (!error) {
				enqueueSnackbar({
					message: message,
					variant: 'success',
				});
			} else {
				enqueueSnackbar({
					message: error.message,
					variant: 'error',
				});
			}
		}
	};

	const cancelHandler = async () => {
		setIsLoading(true);
		try {
			const response = await deleteSubscription();
			if (response.data.clientSecret) {
				await confirmCardPaymentHandler(
					response.data.clientSecret,
					t('subscriptions.tab_1.cancel_message'),
				);
			}
			setIsLoading(false);
			setIsOpenCancelSubscriptionModal(false);
			queryClient.invalidateQueries('get/subscriptions');
		} catch (err) {
			const errorMessage = getErrorMessage(err);
			enqueueSnackbar({
				message: errorMessage,
				variant: 'error',
			});
			setIsLoading(false);
			setIsOpenCancelSubscriptionModal(false);
		}
	};

	const repeatPaymentHandler = async () => {
		setIsLoading(true);
		try {
			const response = await retrySubscriptionPayment();
			if (response.data.clientSecret) {
				await confirmCardPaymentHandler(
					response.data.clientSecret,
					t('subscriptions.snackbar.successful_payment'),
				);
			}
			setIsLoading(false);
			queryClient.invalidateQueries('get/subscriptions');
		} catch (err) {
			const errorMessage = getErrorMessage(err);
			enqueueSnackbar({
				message: errorMessage,
				variant: 'error',
			});
			setIsLoading(false);
		}
	};

	return (
		<>
			<Paper
				sx={{
					marginTop: '34px',
					marginBottom: '31px',
					display: 'flex',
					paddingBottom: '31px',
					flexDirection: 'column',
				}}
			>
				<Typography
					variant={'h4'}
					color={ColorPalette.astronaut}
					sx={{ marginBottom: '24px', display: 'flex', alignItems: 'center', gap: '18px' }}
				>
					{capitalizeFirstLetter(currentSubscription?.subscriptionType || '')}
					{currentSubscription?.subscriptionType === STypesEnum.PAY_AS_YOU_GO &&
						` - ${t('sidebar.subscriptions.starting_from', {
							value: currentSubscription.subscriptionCost / 100,
						})}`}
					{currentSubscription?.subscriptionType === STypesEnum.FREE && (
						<Tooltip
							title={t('subscriptions.tab_1.sub_trial_description')}
							placement="bottom-start"
						>
							<Box>
								<Icon id={'info_icon'} style={{ cursor: 'pointer' }} />
							</Box>
						</Tooltip>
					)}
				</Typography>
				<Typography variant={'h3'} color={ColorPalette.baliHai} sx={{ marginBottom: '44px' }}>
					{currentSubscription.subscriptionCost / 100}€
				</Typography>
				<Box sx={{ display: 'flex', gap: '80px', marginBottom: '34px' }}>
					{currentSubscription.currentPeriodStart && (
						<Box sx={{ display: 'flex', flexDirection: 'column', gap: '31px', flexBasis: '50%' }}>
							<Typography variant={'body2'} color={ColorPalette.astronaut}>
								{t('subscriptions.tab_1.current_sub.sub_bought')}
							</Typography>
							<Typography
								variant={'body2'}
								color={ColorPalette.rockBlue}
								sx={{ display: 'flex', gap: '31px' }}
							>
								{format(new Date(currentSubscription.currentPeriodStart), 'HH:mm dd/MM/yyyy')}
							</Typography>
						</Box>
					)}
					{currentSubscription.currentPeriodEnd && (
						<Box sx={{ display: 'flex', flexDirection: 'column', gap: '31px', flexBasis: '50%' }}>
							<Typography variant={'body2'} color={ColorPalette.astronaut}>
								{t('subscriptions.tab_1.current_sub.sub_ends')}
							</Typography>
							<Typography
								variant={'body2'}
								color={ColorPalette.rockBlue}
								sx={{ display: 'flex', gap: '31px' }}
							>
								{format(new Date(currentSubscription.currentPeriodEnd), 'HH:mm dd/MM/yyyy')}
							</Typography>
						</Box>
					)}
				</Box>
				<Box sx={{ display: 'flex', gap: '80px', marginBottom: '34px' }}>
					{currentSubscription.subscriptionType === STypesEnum.FREE && (
						<Box sx={{ display: 'flex', flexDirection: 'column', gap: '31px', flexBasis: '50%' }}>
							<Typography variant={'body2'} color={ColorPalette.astronaut}>
								{t('subscriptions.tab_1.current_sub.sub_trial_ends')}
							</Typography>
							<Typography
								variant={'body2'}
								color={ColorPalette.rockBlue}
								sx={{ display: 'flex', gap: '31px' }}
							>
								{format(new Date(currentSubscription.currentPeriodEnd), 'HH:mm dd/MM/yyyy')}
							</Typography>
						</Box>
					)}

					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: '31px',
							flexBasis: '50%',
						}}
					>
						<Typography variant={'body2'} color={ColorPalette.astronaut}>
							{t('subscriptions.tab_1.current_sub.sub_status')}
						</Typography>
						<Typography
							variant={'body2'}
							color={ColorPalette.rockBlue}
							sx={{ display: 'flex', gap: '31px' }}
						>
							{capitalizeFirstLetter(currentSubscription.status)}
						</Typography>
					</Box>
				</Box>
				<Button fullWidth sx={{ mt: '24px' }} onClick={() => setIsOpenUpdateSubscriptionModal(true)}>
					{t('subscriptions.tab_1.current_sub.sub_update')}
				</Button>
				{currentSubscription.subscriptionType !== STypesEnum.FREE &&
					currentSubscription.status !== SubscriptionStatus.CANCELED &&
					!currentSubscription.cancelAtPeriodEnd && (
						<Button
							fullWidth
							color={ColorPalette.astronaut}
							sx={{ mt: '24px' }}
							onClick={() => setIsOpenCancelSubscriptionModal(true)}
						>
							{t('subscriptions.tab_1.current_sub.sub_cancel')}
						</Button>
					)}
				{currentSubscription.subscriptionType !== STypesEnum.FREE &&
					currentSubscription.cancelAtPeriodEnd && (
						<Typography
							variant={'body2'}
							color={ColorPalette.astronaut}
							sx={{ marginTop: '30px' }}
						>
							{t('subscriptions.tab_1.cancel_message')}
						</Typography>
					)}
				{!!currentSubscription.unpaidInvoice && (
					<Button
						fullWidth
						color={ColorPalette.astronaut}
						sx={{ mt: '24px' }}
						disabled={isLoading}
						onClick={repeatPaymentHandler}
					>
						{t('subscriptions.tab_1.current_sub.sub_repeat_payment')}
					</Button>
				)}
			</Paper>
			<UpdateSubscriptionModal
				currentSubscription={currentSubscription}
				open={isOpenUpdateSubscriptionModal}
				handleClose={() => setIsOpenUpdateSubscriptionModal(false)}
			/>
			<CancelSubscriptionModal
				open={isOpenCancelSubscriptionModal}
				handleClose={() => setIsOpenCancelSubscriptionModal(false)}
				handleRemove={cancelHandler}
				isLoading={isLoading}
			/>
		</>
	);
};
