import { FC, useState, useEffect } from 'react';
import { Box, Typography, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getFtpSettings, saveFtpSettings } from 'entities/Integrations/api';
import { enqueueSnackbar } from 'notistack';
import { useMutation, useQuery } from 'react-query';
import { FtpSettingsTypeEnum } from 'entities/Integrations/enums';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { Loader } from 'shared/ui/Loader';
import { Checkbox } from 'shared/ui/Checkbox';
import { ColorPalette } from 'shared/consts/colorPalette';
import { FtpSettings } from 'widgets/integrations/FtpSettings/Form/types';
import { FTPForm } from './FTPForm';

export const FSForm: FC = () => {
	const { t } = useTranslation();
	const [deleteAfterImport, setDeleteAfterImport] = useState(false);
	const [copyImportFtpToExportFtp, setCopyImportFtpToExportFtp] = useState(false);
	const [importFtpValues, setImportFtpValues] = useState<FtpSettings>();
	const [exportFtpValues, setExportFtpValues] = useState<FtpSettings>();

	const { data: exportFtpSettingsData } = useQuery({
		queryKey: ['export-ftp-settings'],
		queryFn: () => getFtpSettings({ ftpSettingsType: FtpSettingsTypeEnum.generalExport }),
		retry: false,
	});

	const { data: importFtpSettingsData } = useQuery({
		queryKey: ['import-ftp-settings'],
		queryFn: () => getFtpSettings({ ftpSettingsType: FtpSettingsTypeEnum.generalImport }),
		retry: false,
	});

	const { mutate, isLoading } = useMutation({
		mutationKey: ['ftp-settings'],
		mutationFn: saveFtpSettings,
	});

	useEffect(() => {
		if (importFtpSettingsData?.data) {
			setDeleteAfterImport(!!importFtpSettingsData.data.deleteAfterImport);
			setImportFtpValues({
				host: importFtpSettingsData.data.host,
				port: String(importFtpSettingsData.data.port ?? ''),
				path: importFtpSettingsData.data.path,
				protocol: importFtpSettingsData.data.protocol,
				username: importFtpSettingsData.data.username,
				password: importFtpSettingsData.data.password,
			});
		}
	}, [importFtpSettingsData]);

	useEffect(() => {
		if (exportFtpSettingsData) {
			setExportFtpValues({
				host: exportFtpSettingsData.data.host,
				port: String(exportFtpSettingsData.data.port ?? ''),
				path: exportFtpSettingsData.data.path,
				protocol: exportFtpSettingsData.data.protocol,
				username: exportFtpSettingsData.data.username,
				password: exportFtpSettingsData.data.password,
			});
		}
	}, [exportFtpSettingsData]);

	const handleSubmit = (type: FtpSettingsTypeEnum, values: FtpSettings) => {
		const mutationValues = {
			...values,
			port: Number(values.port),
			ftpSettingsType: type,
			deleteAfterImport: type === FtpSettingsTypeEnum.generalImport ? deleteAfterImport : undefined,
		};

		mutate(mutationValues, {
			onSuccess: () => {
				if (type === FtpSettingsTypeEnum.generalImport) {
					setImportFtpValues(values);
				} else if (type === FtpSettingsTypeEnum.generalExport) {
					setExportFtpValues(values);
				}
				setCopyImportFtpToExportFtp(false);
				enqueueSnackbar({
					message: t('integrations.integration_success'),
					variant: 'success',
				});
			},
			onError: (err) => {
				setCopyImportFtpToExportFtp(false);

				const defaultErrorMessage = t('integrations.integration_error');
				const errorMessage = getErrorMessage(err, defaultErrorMessage);
				enqueueSnackbar({
					message: errorMessage,
					variant: 'error',
				});
			},
		});
	};

	return isLoading ? (
		<Loader />
	) : (
		<Box sx={{ display: 'flex', gap: '50px' }}>
			<Box>
				<Typography variant={'h5'} color={ColorPalette.astronaut} sx={{ marginBottom: '40px' }}>
					{t('integrations.import_folder')}
				</Typography>
				<FTPForm
					ftpSettingsData={importFtpValues}
					handleSubmit={(values) => handleSubmit(FtpSettingsTypeEnum.generalImport, values)}
					checkbox={
						<FormControlLabel
							control={
								<Checkbox
									checked={deleteAfterImport}
									onChange={() => {
										setDeleteAfterImport(!deleteAfterImport);
									}}
								/>
							}
							label={
								<Typography
									variant={'body2'}
									color={ColorPalette.baliHai}
									sx={{ marginLeft: '10px' }}
								>
									{t('sync_settings.ftp_form.delete_after_import')}
								</Typography>
							}
							sx={{ margin: 0, marginBottom: '40px' }}
						/>
					}
				/>
			</Box>

			<Box>
				<Typography variant={'h5'} color={ColorPalette.astronaut} sx={{ marginBottom: '40px' }}>
					{t('integrations.export_folder')}
				</Typography>
				<FTPForm
					ftpSettingsData={exportFtpValues}
					handleSubmit={(values) => handleSubmit(FtpSettingsTypeEnum.generalExport, values)}
					checkbox={
						importFtpValues?.host && (
							<FormControlLabel
								control={
									<Checkbox
										checked={copyImportFtpToExportFtp}
										onChange={() => {
											setCopyImportFtpToExportFtp(!copyImportFtpToExportFtp);
											setExportFtpValues(importFtpValues);
										}}
									/>
								}
								label={
									<Typography
										variant={'body2'}
										color={ColorPalette.baliHai}
										sx={{ marginLeft: '10px' }}
									>
										{t('sync_settings.ftp_form.copy_import')}
									</Typography>
								}
								sx={{ margin: 0, marginBottom: '40px' }}
							/>
						)
					}
				/>
			</Box>
		</Box>
	);
};
