import { Box } from '@mui/material';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { CategoryTypeEnum } from 'entities/Files/types';
import { FC, useMemo, useState, useCallback } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { importFromFtp } from 'entities/ImportSessions/api';
import { ActionsRow } from 'widgets/importFiles/importFilesDirect/ui/ActionsRow';
import { ImportFilesDashboard } from 'widgets/importFiles/common/ui/ImportFilesDashboard';
import { PageHeader } from 'shared/ui/PageHeader';
import { FtpImportFiles } from 'widgets/importFiles/importFilesFromFtp/ui/FtpImportFiles';
import { useCustomModelStore } from 'widgets/customModel/state';
import { useImportFilesStore } from 'widgets/importFiles/state';
import { FtpImportFilesModal } from 'widgets/importFiles/importFilesFromFtp/ui/FtpImportFilesModal';
import { getImportSessions } from 'entities/ImportSessions/api';
import { enqueueSnackbar } from 'notistack';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';

export const ImportFilesFromFTP: FC = () => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const { data, setData } = useImportFilesStore();
	const [openModal, setOpenModal] = useState(false);
	const [searchParams] = useSearchParams();
	const { currentCustomModelId } = useCustomModelStore();
	const categoryType = searchParams.get('category');

	const handleOpen = () => setOpenModal(true);
	const handleClose = () => setOpenModal(false);

	const { mutate, isLoading } = useMutation({
		mutationKey: ['post/import-sessions/ftp-import'],
		mutationFn: importFromFtp,
	});

	const query = useQuery({
		queryKey: ['get/import-sessions'],
		queryFn: () => getImportSessions(),
		onSuccess: (response) => {
			setData(response.data);
			const isUploading = response.data
				.map(
					(item) =>
						item.importSessionFiles.filter((sessionItem) => sessionItem.status === 'UPLOADING')
							.length,
				)
				.filter((item) => item).length;

			if (isUploading) {
				setTimeout(() => {
					query.refetch();
				}, 6000);
			}
		},
		onError: (err) => {
			const errorMessage = getErrorMessage(err);
			enqueueSnackbar({
				message: errorMessage,
				variant: 'error',
			});
		},
	});

	const importSession = useMemo(() => {
		return data?.find((session) => session.uploadSource === 'FTP');
	}, [data]);

	const files = useMemo(() => {
		return importSession ? importSession.importSessionFiles : [];
	}, [importSession]);

	const handleImport = useCallback(
		(localPath: string, selectedFiles: string[], isCompany: boolean) => {
			const mutationValues = {
				category: categoryType as CategoryTypeEnum,
				ftpPath: localPath,
				filesNames: [...selectedFiles],
				isCompanyFtp: isCompany,
				customModelId: categoryType === CategoryTypeEnum.custom ? currentCustomModelId : undefined,
			};
			mutate(mutationValues, {
				onSuccess: async () => {
					queryClient.refetchQueries({
						queryKey: ['get/import-sessions'],
					});
					handleClose();
				},
				onError: (err) => {
					const defaultErrorMessage = t('files.snackbar.error_while_uploading');
					const errorMessage = getErrorMessage(err, defaultErrorMessage);
					enqueueSnackbar({
						message: errorMessage,
						variant: 'error',
					});
				},
			});
		},
		[categoryType, currentCustomModelId],
	);

	if (!categoryType) {
		return <Navigate to={'/'} />;
	}

	return (
		<>
			<FtpImportFilesModal
				open={openModal}
				handleClose={handleClose}
				handleImport={handleImport}
				isPending={isLoading}
			/>
			<Box sx={{ marginBottom: '70px' }}>
				<PageHeader
					title={t('start_recognition.upload_files_section.title_ftp')}
					description={t('start_recognition.upload_files_section.description_ftp')}
				/>
				<FtpImportFiles handleOpen={handleOpen} />
				<ImportFilesDashboard categoryType={importSession?.category} files={files} />
				{importSession && <ActionsRow importSession={importSession} />}
			</Box>
		</>
	);
};
