import { emailRegEx } from 'shared/consts/emailRegEx';
import * as yup from 'yup';

export const ChangeEmailValidationSchema = yup.object({
	email: yup
		.string()
		.required('auth.validation.enter_email')
		.matches(emailRegEx, { message: 'auth.validation.email_not_valid' })
		.max(50, 'auth.validation.email_too_long'),
	confirmEmail: yup
		.string()
		.required('auth.validation.email_confirm')
		.oneOf([yup.ref('email')], 'auth.validation.email_match')
		.max(50, 'auth.validation.email_too_long'),
});
