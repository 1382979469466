import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, InputBase } from '@mui/material';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Button } from 'shared/ui/Button';
import { enqueueSnackbar } from 'notistack';
import { axiosInstance } from 'shared/config/axios';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';

// TODO refactor!!!!!!
const ResourceInputKey = () => {
	const { t } = useTranslation();
	const [apiKeyValue, setApiKeyValue] = useState('');
	const [endpointValue, setEndpointValue] = useState('');

	const createApiKey = async () => {
		try {
			await axiosInstance.post('/admin/resources', {
				apiKey: apiKeyValue,
				endpoint: endpointValue,
			});
			enqueueSnackbar({
				message: t('admin.resources.snackbar.success_creation'),
				variant: 'success',
			});
		} catch (err) {
			const defaultErrorMessage = t('admin.resources.snackbar.error_creation');
			const errorMessage = getErrorMessage(err, defaultErrorMessage);
			enqueueSnackbar({
				message: errorMessage,
				variant: 'error',
			});
		}

		setApiKeyValue('');
		setEndpointValue('');
	};

	return (
		<>
			<Box
				sx={{
					backgroundColor: ColorPalette.white,
					padding: '24px',
					borderRadius: '10px',
					marginTop: '64px',
					display: 'flex',
					gap: '30px',
				}}
			>
				<InputBase
					value={apiKeyValue}
					placeholder={t('admin.resources.placeholder.api_key') as string}
					onChange={(e) => setApiKeyValue(e.target.value)}
					sx={{
						'width': '100%',
						'borderRadius': '10px',
						'border': `1px solid ${ColorPalette.periwinkle}`,
						'& .MuiInputBase-input': {
							'height': 'initial',
							'margin': '17px 33px',

							'borderRadius': '10px',

							'fontWeight': 600,
							'fontSize': '14px',
							'lineHeight': '18px',

							'color': ColorPalette.astronaut,
							'&:placeholder': {
								color: ColorPalette.baliHai,
							},
						},
					}}
				/>
				<InputBase
					value={endpointValue}
					placeholder={t('admin.resources.placeholder.endpoint') as string}
					onChange={(e) => setEndpointValue(e.target.value)}
					sx={{
						'width': '100%',
						'borderRadius': '10px',
						'border': `1px solid ${ColorPalette.periwinkle}`,
						'& .MuiInputBase-input': {
							'height': 'initial',
							'margin': '17px 33px',

							'borderRadius': '10px',

							'fontWeight': 600,
							'fontSize': '14px',
							'lineHeight': '18px',

							'color': ColorPalette.astronaut,
							'&:placeholder': {
								color: ColorPalette.baliHai,
							},
						},
					}}
				/>

				<Button size={'large'} onClick={createApiKey}>
					{t('admin.resources.add_resource')}
				</Button>
			</Box>
		</>
	);
};

export default ResourceInputKey;
