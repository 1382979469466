import {
	GetAssertionsSetsRequest,
	CreateAssertionsSetRequest,
	UpdateAssertionsSetRequest,
	DeleteAssertionsSetRequest,
	GetAssertionsRequest,
	CreateAssertionRequest,
	UpdateAssertionRequest,
	DeleteAssertionsRequest,
	FindAssertionRequest,
	CreateAssertionsSetViaFileRequest,
} from 'entities/Assertions/api/types';
import { axiosInstance } from 'shared/config/axios';

export const getAssertionsSets: GetAssertionsSetsRequest = () => {
	return axiosInstance.get('/assertion-sets');
};

export const createAssertionsSet: CreateAssertionsSetRequest = (data) => {
	return axiosInstance.post('/assertion-sets', data);
};

export const updateAssertionsSet: UpdateAssertionsSetRequest = ({ id, data }) => {
	return axiosInstance.patch(`assertion-sets/${id}`, data);
};

export const deleteAssertionsSet: DeleteAssertionsSetRequest = (data) => {
	return axiosInstance.delete('/assertion-sets/delete-many', { data });
};

export const getAssertions: GetAssertionsRequest = ({ id, ...rest }) => {
	return axiosInstance.get(`/assertion-sets/assertions/${id}`, { params: { ...rest } });
};

export const createAssertion: CreateAssertionRequest = ({ id, data }) => {
	return axiosInstance.post(`/assertion-sets/assertions/${id}`, data);
};

export const createAssertionViaFile: CreateAssertionsSetViaFileRequest = ({ id, data }) => {
	return axiosInstance.post(`/assertion-sets/assertions/${id}/via-file`, data, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
};

export const updateAssertion: UpdateAssertionRequest = ({ id, data }) => {
	return axiosInstance.patch(`/assertion-sets/assertions/${id}`, data);
};

export const deleteAssertions: DeleteAssertionsRequest = (data) => {
	return axiosInstance.delete('/assertion-sets/assertions/delete-many', { data });
};

export const findAssertion: FindAssertionRequest = (data) => {
	return axiosInstance.post('/assertion-sets/assertions/find', data);
};
