import { Company } from 'entities/Company/types';
import { User } from 'entities/User/types';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type AuthStore = {
	accessToken: string | null;
	user: User | null;
	company: Company | null;
};

type AuthActions = {
	updateAuthData: (authData: Partial<AuthStore>) => void;
	reset: () => void;
};

const initialState: AuthStore = {
	accessToken: null,
	user: null,
	company: null,
};

export const authStore = create<AuthStore & AuthActions>()(
	persist(
		(set) => ({
			...initialState,
			updateAuthData: (authData) => set(() => ({ ...authData })),
			reset: () => set(() => ({ ...initialState })),
		}),
		{
			name: 'authStore',
		},
	),
);

export const useAuthStore = () => authStore((state) => state);
