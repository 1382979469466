import { FC, useState, useMemo, useEffect } from 'react';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Typography, Box, styled } from '@mui/material';
import { AssertionsSetsType } from 'entities/Assertions/types';
import { getAssertionsSets } from 'entities/Assertions/api';
import { UpdateWarehouseReq } from 'entities/Warehouse/api/types';
import { patchWarehouseProcesses } from 'entities/Warehouse/api';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Paper } from 'shared/ui/Paper';
import { Select } from 'shared/ui/Select';
import { OptionsDropdown } from 'shared/ui/OptionsDropdown';
import { Switch } from 'shared/ui/Switch';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { DrawerType } from 'widgets/Extensions/Details/ui/Warehouse/ui/ProcessDrawer';
import { useWarehouseStore } from 'widgets/Extensions/Details/ui/Warehouse/state';
import { ProcessDrawer } from 'widgets/Extensions/Details/ui/Warehouse/ui/ProcessDrawer';

interface ProcessesTabProps {
	isLoading: boolean;
}

export const ProcessesTab: FC<ProcessesTabProps> = () => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const { processes } = useWarehouseStore();
	const [drawerIsOpen, setDrawerIsOpen] = useState(false);
	const [drawerType, setDrawerType] = useState<DrawerType>('');
	const [assertionsSets, setAssertionsSetsData] = useState<AssertionsSetsType[]>();
	const [assertionSetId, setAssertionSetId] = useState('');

	const selectedOptions = useMemo(() => {
		const mappedOptions = assertionsSets?.map((item) => ({
			label: item.name,
			value: item._id,
		}));
		mappedOptions?.unshift({ label: '-', value: '' });
		return mappedOptions;
	}, [assertionsSets]);

	useQuery({
		queryKey: ['get/assertion-sets'],
		queryFn: () => getAssertionsSets(),
		onSuccess: (response) => {
			setAssertionsSetsData(response?.data);
		},
		onError: (err) => {
			const errorMessage = getErrorMessage(err);
			enqueueSnackbar({ message: errorMessage, variant: 'error' });
		},
	});

	const { mutate: patchWarehouseProcessesMutate, isLoading: patchIsLoading } = useMutation({
		mutationKey: ['patch/warehouse-process'],
		mutationFn: patchWarehouseProcesses,
	});

	useEffect(() => {
		if (processes?.assertionSetId) {
			setAssertionSetId(processes?.assertionSetId);
		}
	}, [processes]);

	const toggleExtensionHandler = (data: UpdateWarehouseReq) => {
		patchWarehouseProcessesMutate(data, {
			onSuccess: async () => {
				enqueueSnackbar({ message: t('shared_texts.success_update'), variant: 'success' });
				await queryClient.refetchQueries({ queryKey: ['get/warehouse-process'] });
			},
			onError: (err) => {
				const errorMessage = getErrorMessage(err);
				enqueueSnackbar({ message: errorMessage, variant: 'error' });
			},
		});
	};

	return (
		<>
			<ProcessDrawer
				open={drawerIsOpen}
				drawerType={drawerType}
				handleClose={() => setDrawerIsOpen(false)}
			/>
			<Paper
				sx={{
					'padding': '30px',
					'width': '100%',
					'display': 'flex',
					'flexDirection': 'column',
					'gap': '20px',
					'& > div:not(:last-child)': {
						paddingBottom: '20px',
						borderBottom: `1px solid ${ColorPalette.periwinkle}`,
					},
					'@media screen and (min-width: 1441px)': {
						width: '50%',
					},
				}}
			>
				<RowBoxStyled>
					<CheckboxBoxStyled>
						<Switch
							checked={processes?.autocomplete}
							onChange={() =>
								toggleExtensionHandler({
									autocomplete: !processes?.autocomplete,
									createAssertions: !!processes?.createAssertions,
									assertionSetId: processes?.assertionSetId,
								})
							}
							sx={{ marginRight: '10px' }}
							disabled={patchIsLoading}
						/>
						<Typography variant={'body2'} color={ColorPalette.astronaut}>
							{t('extensions.ex_list.warehouse.process_autocomplete_description')}
						</Typography>
					</CheckboxBoxStyled>

					<OptionsDropdown
						iconId={'more_horizontal'}
						optionList={[
							{
								label: 'shared_texts.table_dropdown.details',
								value: 'open_drawer',
								action: () => {
									setDrawerType('autocomplete');
									setDrawerIsOpen(true);
								},
							},
						]}
					/>
				</RowBoxStyled>
				<RowBoxStyled>
					<CheckboxBoxStyled>
						<Switch
							checked={processes?.createAssertions}
							onChange={() =>
								toggleExtensionHandler({
									autocomplete: !!processes?.autocomplete,
									createAssertions: !processes?.createAssertions,
									assertionSetId: processes?.assertionSetId,
								})
							}
							sx={{ marginRight: '10px' }}
							disabled={patchIsLoading}
						/>
						<Typography variant={'body2'} color={ColorPalette.astronaut}>
							{t('extensions.ex_list.warehouse.process_assertion_description')}
						</Typography>
					</CheckboxBoxStyled>

					<Select
						sx={{ width: '400px', margin: 0 }}
						placeholder={t('assertions.placeholder.select_as_set')}
						value={assertionSetId || ''}
						onSelect={(item) => {
							setAssertionSetId(item as string);
							toggleExtensionHandler({
								autocomplete: !!processes?.autocomplete,
								createAssertions: !!processes?.createAssertions,
								assertionSetId: item as string,
							});
						}}
						options={selectedOptions || []}
					/>
					<OptionsDropdown
						iconId={'more_horizontal'}
						optionList={[
							{
								label: 'shared_texts.table_dropdown.details',
								value: 'open_drawer',
								action: () => {
									setDrawerType('assertions');
									setDrawerIsOpen(true);
								},
							},
						]}
					/>
				</RowBoxStyled>
			</Paper>
		</>
	);
};

const RowBoxStyled = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const CheckboxBoxStyled = styled(Box)`
	display: flex;
	align-items: center;
	gap: 20px;
`;
