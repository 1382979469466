import { Divider } from '@mui/material';
import { FunctionComponent } from 'react';
import { useAuthStore } from 'processes/auth/model/state';
import { ColorPalette } from 'shared/consts/colorPalette';
import { ChangeEmail } from 'widgets/PersonalAccount/ui/tabs/Security/ui/ChangeEmail';
import { ChangePassword } from 'widgets/PersonalAccount/ui/tabs/Security/ui/ChangePassword';
import { UserRoles } from 'entities/User/types';

export const Security: FunctionComponent = () => {
	const { user } = useAuthStore();
	return (
		<>
			{user?.role !== UserRoles.admin && (
				<>
					<ChangeEmail />
					<Divider color={ColorPalette.periwinkle} sx={{ margin: '62px 0 44px' }} />
				</>
			)}
			<ChangePassword />
		</>
	);
};
