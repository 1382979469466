import { Box, Divider, Typography } from '@mui/material';
import { FunctionComponent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { ColorPalette } from 'shared/consts/colorPalette';
import { TabButton } from 'shared/ui/TabButton';
import { Loader } from 'shared/ui/Loader';
import { useUserDetailsInfoStore } from 'widgets/admin/UserDetails/state';
import {
	PersonalInfo,
	Subscriptions,
	Billing,
	CoUsers,
	AdditionalFeatures,
} from 'widgets/admin/UserDetails/ui/tabs';
import { Button } from 'shared/ui/Button';
import { UnlimitedUserSubscription } from './modals/UnlimitedUserSubscriptionsModal';
import { PatchUnlimitedSubscription, getAdminUser } from 'entities/admin/Users/api';
import { enqueueSnackbar } from 'notistack';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { userDetailsTabs } from 'widgets/admin/UserDetails/types';

const tabsArray = [
	{ name: userDetailsTabs.info, text: 'admin.users.details.tabs.tab_1' },
	{ name: userDetailsTabs.subscribtions, text: 'admin.users.details.tabs.tab_2' },
	{ name: userDetailsTabs.billing, text: 'admin.users.details.tabs.tab_3' },
	{ name: userDetailsTabs.coUsers, text: 'admin.users.details.tabs.tab_4' },
	{ name: userDetailsTabs.additionalFeatures, text: 'admin.users.labels.additional_features' },
];

export const UserDetailsInfo: FunctionComponent = () => {
	const { t } = useTranslation();
	const { userId } = useParams();

	const { activeTab, setSelectedUser, changeActiveTab } = useUserDetailsInfoStore();

	const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

	const { data: userInfo } = useQuery({
		queryKey: ['get/admin/user'],
		queryFn: () => getAdminUser(userId),
		onSuccess: (response) => {
			setSelectedUser(response.data);
		},
		onError: (err) => {
			const errorMessage = getErrorMessage(err);
			enqueueSnackbar({ message: errorMessage, variant: 'error' });
		},
	});

	const subTitle = useMemo(
		() =>
			userInfo?.data?.firstName && userInfo?.data?.lastName
				? `${userInfo?.data.firstName} ${userInfo?.data.lastName} - "${userInfo?.data.email}"`
				: `"${userInfo?.data?.email}"`,
		[userInfo],
	);

	const role = useMemo(
		() =>
			userInfo?.data?.role &&
			`${userInfo?.data?.role}`.charAt(0) + `${userInfo?.data?.role}`.slice(1).toLowerCase(),
		[userInfo],
	);

	const handleApplyUnlimited = async (userID: string) => {
		try {
			await PatchUnlimitedSubscription(userID);
			setIsOpenModal(false);
			enqueueSnackbar({
				message: t('admin.users.snackbar.success_update_unlimited'),
				variant: 'success',
			});
		} catch (err) {
			const defaultErrorMessage = t('admin.users.snackbar.error_update_unlimited');
			const errorMessage = getErrorMessage(err, defaultErrorMessage);
			enqueueSnackbar({
				message: errorMessage,
				variant: 'error',
			});
		}
	};

	if (!userInfo?.data) return <Loader />;

	return (
		<>
			<UnlimitedUserSubscription
				user={userInfo.data}
				open={isOpenModal}
				handleClose={() => setIsOpenModal(false)}
				handleApply={() => handleApplyUnlimited(userInfo.data._id)}
			/>
			<Box
				sx={{
					display: 'flex',
					width: '100%',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<Box>
					<Typography variant={'h4'} color={ColorPalette.astronaut}>
						{t('admin.users.details.title')}
					</Typography>
					<Box sx={{ display: 'flex', gap: '30px' }}>
						<Typography
							variant={'subtitle1'}
							color={ColorPalette.baliHai}
							sx={{ marginTop: '24px' }}
						>
							{subTitle}
						</Typography>
						<Typography
							variant={'subtitle1'}
							color={ColorPalette.baliHai}
							sx={{ marginTop: '24px' }}
						>
							{t('admin.users.details.role')} {role}
						</Typography>
					</Box>
				</Box>
				<Button
					size={'large'}
					onClick={() => setIsOpenModal(true)}
					sx={{
						display: 'inline-flex',
						padding: '22px 16px',
						justifyContent: 'center',
						alignItems: 'center',
						gap: '10px',
					}}
				>
					{t('admin.users.details.apply_unlimited')}
				</Button>
			</Box>

			<Divider color={ColorPalette.periwinkle} sx={{ margin: '44px 0' }} />
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'flex-start',
					alignItems: 'center',
					gap: '20px',
					marginBottom: '44px',
				}}
			>
				{tabsArray.map((item, i) => (
					<TabButton
						key={`${item.name}=${i}`}
						label={t(`${item.text}`)}
						isActive={activeTab === item.name}
						action={() => changeActiveTab(item.name)}
					/>
				))}
			</Box>
			{activeTab === userDetailsTabs.info && <PersonalInfo user={userInfo.data} />}
			{activeTab === userDetailsTabs.subscribtions && <Subscriptions />}
			{activeTab === userDetailsTabs.billing && <Billing user={userInfo.data} />}
			{activeTab === userDetailsTabs.coUsers && <CoUsers user={userInfo.data} />}
			{activeTab === userDetailsTabs.additionalFeatures && <AdditionalFeatures user={userInfo.data} />}
		</>
	);
};
