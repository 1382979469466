import { Paper, Table, TableProps } from '@mui/material';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Column } from 'shared/ui/tables/_common/types';
import { TableBody } from 'shared/ui/tables/_common/ui/TableBody';

interface Props<T> extends TableProps {
	columns: Array<Column<T>>;
	rows: Array<T & { id: string }>;
}
export function TableWithoutHeader<T>({ columns, rows }: Props<T>) {
	return (
		<Paper
			sx={{
				padding: '0',
				boxShadow: 'none',
				backgroundColor: ColorPalette.white,
				borderRadius: '20px',
				overflow: 'hidden',
			}}
		>
			<Table>
				<TableBody columns={columns} rows={rows} />
			</Table>
		</Paper>
	);
}
