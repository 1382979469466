import { FunctionComponent } from 'react';
import { TabButton } from 'shared/ui/TabButton';
import { useCoUserDetailsInfoStore } from 'widgets/CoUsers/ui/CoUserDetailsInfo/model/state';
import { CoUSerDetailsTabs } from 'widgets/CoUsers/ui/CoUserDetailsInfo/types';

interface Props {
	label: string;
	tab: CoUSerDetailsTabs;
}

export const Tab: FunctionComponent<Props> = ({ label, tab }) => {
	const { activeTab, changeActiveTab } = useCoUserDetailsInfoStore();
	const pathMatch = activeTab === tab;

	return <TabButton label={label} isActive={pathMatch} action={() => changeActiveTab(tab)} />;
};
