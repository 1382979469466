import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CategoriesSettingsType } from 'entities/Partners/types';
import { TableWithoutHeader } from 'shared/ui/tables/TableWithoutHeader';
import { EmptyTable } from 'shared/ui/EmptyTable';
import { CategoriesList } from 'widgets/startRecognition/consts/CategoryList';
import { useCustomModelStore } from 'widgets/customModel/state';
import { CategoriesTableColumns } from './Columns';

type CategoriesTablePropsType = {
	data: CategoriesSettingsType | undefined;
	deleteHandler: (id: string) => void;
};

export const CategoriesTable: FC<CategoriesTablePropsType> = ({ data, deleteHandler }) => {
	const { customModels } = useCustomModelStore();
	const { t } = useTranslation();

	const preparedFields = useMemo(
		() =>
			data?.categories.map((item) => {
				const category = CategoriesList.find((category) => category.id === item.category);
				const customModelTitle = item.customModelId
					? customModels.find((model) => model._id === item.customModelId)?.name
					: '';
				return {
					id: item.customModelId || (category?.id as string),
					img: category?.img || '',
					title: customModelTitle || (t(category?.label as string) as string),
					subtitle: category?.type ? t(category.type) : '',
					deleteHandler,
				};
			}) || [],
		[data],
	);

	if (!preparedFields.length) return <EmptyTable text={t('shared_texts.doc_not_found')} />;
	return <TableWithoutHeader columns={CategoriesTableColumns} rows={preparedFields} />;
};
