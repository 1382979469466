import { useImperativeHandle, useRef, forwardRef, ForwardRefRenderFunction } from 'react';
import { InputBaseComponentProps } from '@mui/material';
import { ColorPalette } from 'shared/consts/colorPalette';

interface StripeInputTypes extends InputBaseComponentProps {
	component: React.ElementType;
}

interface InputElement {
	focus: () => void;
}

const StripeInput: ForwardRefRenderFunction<InputElement, StripeInputTypes> = (
	{ component: Component, ...props },
	ref,
) => {
	const innerRef = useRef<InputElement | null>(null);
	useImperativeHandle(ref, () => ({
		focus: () => {
			if (innerRef.current) {
				innerRef.current.focus();
			}
		},
	}));
	return (
		<Component
			options={{
				placeholder: props.placeholder,
				style: {
					base: {
						'fontFamily': 'Source Code Pro, monospace',
						'color': ColorPalette.astronaut,
						'fontSize': '16px',
						'::placeholder': {
							color: ColorPalette.baliHai,
						},
					},
				},
			}}
			{...props}
		/>
	);
};

export const ForwardedStripeInput = forwardRef(StripeInput);
