import { ListItemText } from '@mui/material';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ColorPalette } from 'shared/consts/colorPalette';
import { usePathMatch } from 'shared/helpers/usePathMatch';
import { SidebarSubMenuLink } from 'widgets/Sidebar/types';

interface Props {
	subMenuLink: SidebarSubMenuLink;
}

export const SubMenuListItem: FunctionComponent<Props> = ({ subMenuLink }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const subMenuLinkMatch = usePathMatch(subMenuLink.path);

	return (
		<ListItemText
			sx={{
				'margin': '14px 0 18px 70px',
				'fontWeight': 700,
				'fontSize': '14px',
				'lineHeight': '18px',
				'letterSpacing': '0.5px',
				'color': subMenuLinkMatch ? ColorPalette.white : ColorPalette.manatee,
				'position': 'relative',
				'cursor': 'pointer',
				'whiteSpace': 'normal',

				'&:before': {
					content: '""',
					display: 'block',
					width: subMenuLinkMatch ? '8px' : '6px',
					height: subMenuLinkMatch ? '8px' : '6px',
					borderRadius: '50%',
					backgroundColor: subMenuLinkMatch ? ColorPalette.royalBlue : ColorPalette.manatee,
					position: 'absolute',
					transform: 'translateY(-50%)',
					top: '50%',
					left: '-20px',
				},

				'&:hover': {
					'color': ColorPalette.white,
					'&:before': {
						backgroundColor: ColorPalette.white,
					},
				},
			}}
			onClick={() => {
				if (subMenuLink.action) {
					subMenuLink.action();
				} else {
					navigate(subMenuLink.path);
				}
			}}
		>
			{t(`sidebar.menu.${subMenuLink.path}`)}
		</ListItemText>
	);
};
