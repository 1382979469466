import { FC, useCallback } from 'react';
import { Typography, Box } from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Routes } from 'app/config/routes/consts';
import { deleteExtension, patchExtension } from 'entities/Extensions/api';
import { Button } from 'shared/ui/Button';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { ColorPalette } from 'shared/consts/colorPalette';
import { ExtensionsType } from 'entities/Extensions/types';
import { ExtensionsEnum } from 'entities/Extensions/enums';
import { ExtensionsTable } from 'widgets/Extensions/ui/ExtensionsTable';
import { Loader } from 'shared/ui/Loader';

export const ExtensionsWidget: FC = () => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const navigate = useNavigate();

	const { mutate: deleteExtensionMutate, isLoading: deleteIsLoading } = useMutation({
		mutationKey: ['delete/extensions'],
		mutationFn: deleteExtension,
	});

	const { mutate: patchExtensionMutate, isLoading: patchIsLoading } = useMutation({
		mutationKey: ['patch/extensions'],
		mutationFn: patchExtension,
	});

	const addExtensionHandler = () => {
		navigate(`${Routes.extensionsList}`);
	};

	const toggleExtensionHandler = (data: ExtensionsType) => {
		patchExtensionMutate(
			{
				data,
			},
			{
				onSuccess: async () => {
					await queryClient.refetchQueries({ queryKey: ['validate-token'] });
					enqueueSnackbar({
						message: data.isEnabled
							? t('extensions.snackbar.success_activation')
							: t('extensions.snackbar.success_deactivation'),
						variant: 'success',
					});
				},
				onError: (err) => {
					const errorMessage = getErrorMessage(err);
					enqueueSnackbar({ message: errorMessage, variant: 'error' });
				},
			},
		);
	};

	const extensionDetailsHandler = (exName: ExtensionsEnum) => {
		navigate(`/extensions/${exName}`);
	};

	const deleteExtensionHandler = useCallback(
		(exName: ExtensionsEnum) => {
			deleteExtensionMutate(
				{
					extension: exName,
				},
				{
					onSuccess: async () => {
						await queryClient.refetchQueries({ queryKey: ['validate-token'] });
						enqueueSnackbar({
							message: t('extensions.snackbar.success_removing_extension'),
							variant: 'success',
						});
					},
					onError: (err) => {
						const errorMessage = getErrorMessage(err);
						enqueueSnackbar({ message: errorMessage, variant: 'error' });
					},
				},
			);
		},
		[queryClient],
	);

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					marginBottom: '30px',
				}}
			>
				<Typography variant={'h6'} color={ColorPalette.astronaut}>
					{t('extensions.ex_subtitle')}
				</Typography>
				<Button sx={{ lineHeight: '20px' }} size={'small'} onClick={addExtensionHandler}>
					{t('extensions.ex_add_btn')}
				</Button>
			</Box>
			{deleteIsLoading || patchIsLoading ? (
				<Loader />
			) : (
				<ExtensionsTable
					toggleExtensionHandler={toggleExtensionHandler}
					extensionDetailsHandler={extensionDetailsHandler}
					deleteExtensionHandler={deleteExtensionHandler}
				/>
			)}
		</>
	);
};
