import { Dialog, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';
import { ActionsForDialog } from 'shared/ui/ActionsForDialog';
import { Paper } from 'shared/ui/Paper';

interface Props {
	open: boolean;
	handleClose: () => void;
	handleRemove: () => void;
}

export const DeleteArticleModal: FC<Props> = ({ open, handleClose, handleRemove }) => {
	const { t } = useTranslation();
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			PaperComponent={Paper}
			PaperProps={{ title: t('extensions.modals.warehouse_delete_title') as string }}
		>
			<Typography variant={'body1'} color={ColorPalette.baliHai} sx={{ marginBottom: '34px' }}>
				{t('extensions.modals.warehouse_delete_description')}
			</Typography>
			<ActionsForDialog
				cancelLabel={t('shared_texts.cancel')}
				cancelAction={handleClose}
				applyLabel={t('shared_texts.confirm')}
				applyAction={handleRemove}
				applyActionDisabled={false}
			/>
		</Dialog>
	);
};
