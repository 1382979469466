import { FC } from 'react';
import { Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { Form, Formik, FormikProps } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { createAssertion, updateAssertion } from 'entities/Assertions/api';
import { AssertionsType } from 'entities/Assertions/types';
import { isEmptyObject } from 'shared/helpers/isEmptyObject';
import { isEmptyObjectValues } from 'shared/helpers/isEmptyObjectValues';
import { ActionsForDialog } from 'shared/ui/ActionsForDialog';
import { InputField } from 'shared/ui/InputField';
import { Paper } from 'shared/ui/Paper';
import { AssertionEditorValidationSchema } from 'widgets/Extensions/Details/ui/AssertionSets/AssertionsSetList/ui/AssertionsManualCreationModal/consts/validation';
import { AssertionEditorType } from 'widgets/Extensions/Details/ui/AssertionSets/AssertionsSetList/ui/AssertionsManualCreationModal/types';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';

interface Props {
	id: string;
	open: boolean;
	handleClose: () => void;
	assertionToEdit?: AssertionsType | null;
}

export const AssertionsManualCreationModal: FC<Props> = ({ id, open, handleClose, assertionToEdit }) => {
	const queryClient = useQueryClient();
	const { t } = useTranslation();

	const { mutate: createAssertionMutate } = useMutation({
		mutationKey: ['create/assertion-sets/assertions'],
		mutationFn: createAssertion,
	});

	const { mutate: updateAssertionMutate } = useMutation({
		mutationKey: ['update/assertion-sets/assertions'],
		mutationFn: updateAssertion,
	});

	const handleSubmitCreation = (values: AssertionEditorType) => {
		createAssertionMutate(
			{ id, data: { assertions: [values] } },
			{
				onSuccess: async () => {
					await queryClient.invalidateQueries({ queryKey: ['get/assertion-sets/assertions'] });
					enqueueSnackbar({
						message: t('assertions.snackbar.success_assertion_creation'),
						variant: 'success',
					});
					handleClose();
				},
				onError: (err) => {
					const errorMessage = getErrorMessage(err);
					enqueueSnackbar({
						message: errorMessage,
						variant: 'error',
					});
				},
			},
		);
	};

	const handleSubmitEdit = (values: AssertionEditorType) => {
		if (assertionToEdit) {
			updateAssertionMutate(
				{
					id: assertionToEdit._id,
					data: { replaceVal: values.replaceVal },
				},
				{
					onSuccess: async () => {
						await queryClient.invalidateQueries({ queryKey: ['get/assertion-sets/assertions'] });
						enqueueSnackbar({
							message: t('assertions.snackbar.success_assertion_edit'),
							variant: 'success',
						});
						handleClose();
					},
					onError: (err) => {
						const errorMessage = getErrorMessage(err);
						enqueueSnackbar({
							message: errorMessage,
							variant: 'error',
						});
					},
				},
			);
		}
	};

	const handleSubmit = (values: AssertionEditorType) => {
		if (assertionToEdit) {
			handleSubmitEdit(values);
		} else {
			handleSubmitCreation(values);
		}
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			PaperComponent={Paper}
			PaperProps={{
				title: (assertionToEdit
					? t('assertions.modal.manual_edit_as_title')
					: t('assertions.modal.manual_create_as_title')) as string,
			}}
		>
			<Formik
				initialValues={{
					initialVal: assertionToEdit?.initialVal || '',
					replaceVal: assertionToEdit?.replaceVal || '',
				}}
				validationSchema={AssertionEditorValidationSchema}
				onSubmit={handleSubmit}
			>
				{({ errors, values, resetForm }: FormikProps<AssertionEditorType>) => (
					<Form>
						<InputField
							name="initialVal"
							label={t('assertions.label.initial_value')}
							placeholder={t('assertions.placeholder.initial_value') as string}
							multiline
							disabled={!!assertionToEdit}
							required
						/>
						<InputField
							name="replaceVal"
							label={t('assertions.label.replaced_value')}
							placeholder={t('assertions.placeholder.replaced_value')}
							required
						/>

						<ActionsForDialog
							cancelLabel={t('shared_texts.cancel')}
							cancelAction={() => {
								resetForm();
								handleClose();
							}}
							applyLabel={t('shared_texts.save')}
							applyActionDisabled={!isEmptyObject(errors) || isEmptyObjectValues(values)}
						/>
					</Form>
				)}
			</Formik>
		</Dialog>
	);
};
