import { Switch } from 'shared/ui/Switch';
import { TableTranslationLabel } from 'shared/ui/tables/_common/ui/TableTranslationLabel';
import { Column } from 'shared/ui/tables/_common/types';

export enum FieldsSettingsEnum {
	isEnabled = 'isEnabled',
	addMissingLabels = 'addMissingLabels',
}

export type SettingsColumnsType = {
	id: string;
	setting: FieldsSettingsEnum;
	isActive: boolean;
	descriptionText: string;
	handleToggleSetting: (setting: FieldsSettingsEnum) => void;
};

export const SettingsColumns: Array<Column<SettingsColumnsType>> = [
	{
		field: 'settingsSwitcher',
		commonCellSettings: {
			style: {
				width: '5%',
			},
		},
		bodyCellSettings: {
			renderCell: (fieldCell) => (
				<Switch
					checked={fieldCell?.isActive}
					onChange={() => {
						fieldCell?.handleToggleSetting(fieldCell.setting);
					}}
				/>
			),
		},
	},
	{
		field: 'settingsDescription',
		bodyCellSettings: {
			renderCell: (settingsCell) => <TableTranslationLabel path={settingsCell?.descriptionText} />,
		},
	},
];
