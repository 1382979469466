import { PersonaAccountTabs } from 'widgets/PersonalAccount/types';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type PersonalAccountStore = {
	activeTab: PersonaAccountTabs;
};

type PersonalAccountActions = {
	changeActiveTab: (tab: PersonaAccountTabs) => void;
	reset: () => void;
};

const initialState: PersonalAccountStore = {
	activeTab: 'personal-info',
};

const personalAccountStore = create<PersonalAccountStore & PersonalAccountActions>()(
	persist(
		(set) => ({
			...initialState,
			changeActiveTab: (tab) => set(() => ({ activeTab: tab })),
			reset: () => set(() => ({ ...initialState })),
		}),
		{
			name: 'personalAccountStore',
		},
	),
);

export const usePersonalAccountStore = () => personalAccountStore((state) => state);
