import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useField } from 'formik';
import { Input } from 'shared/ui/Input';

interface Props {
	name: string;
	label: string;
	placeholder: string;
	type?: 'text' | 'email' | 'password' | 'number';
	required?: boolean;
	fullWidth?: boolean;
	disabled?: boolean;
	multiline?: boolean;
	// setFieldValue?: FormikHelpers<string | number>['setFieldValue'];
}

export const InputField: FunctionComponent<Props> = ({ ...props }) => {
	const { t } = useTranslation();
	const [field, meta] = useField(props);

	return (
		<Input
			{...field}
			{...props}
			errorText={meta.touched && meta.error ? (t(`${meta.error}`) as string) : undefined}
		/>
	);
};
