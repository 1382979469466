/* eslint-disable react/jsx-key */
import { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { useMutation, useQuery } from 'react-query';
import { Typography, FormControlLabel, Divider, Box } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { useTranslation, Trans } from 'react-i18next';
import { PartnersEnum } from 'entities/Partners/enums';
import { getReplaceSettings, updateReplaceSettings } from 'entities/Partners/api';
import { ReplaceSettingsType } from 'entities/Partners/types';
import { Paper } from 'shared/ui/Paper';
import { ActionsForDialog } from 'shared/ui/ActionsForDialog';
import { InputField } from 'shared/ui/InputField';
import { Input } from 'shared/ui/Input';
import { ColorPalette } from 'shared/consts/colorPalette';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { PartnerInfoForReplaceValidationSchema } from 'widgets/Partners/consts/validation';
import { PartnerInfoForReplaceFormType } from 'widgets/Partners/types';
import { Loader } from 'shared/ui/Loader';
import { Switch } from 'shared/ui/Switch';
import { Icon } from 'shared/ui/Icon';

export const ReplaceSettings: FC = () => {
	const { partnerId } = useParams();
	const { t } = useTranslation();
	const [replaceSettings, setReplaceSettings] = useState<ReplaceSettingsType>();

	const { data: response, isLoading: getIsLoading } = useQuery({
		queryKey: ['get/replace-settings', partnerId],
		queryFn: () => getReplaceSettings(partnerId || ''),
		onError: (err) => {
			const errorMessage = getErrorMessage(err);
			enqueueSnackbar({ message: errorMessage, variant: 'error' });
		},
	});

	const { mutate: updateReplaceSettingsMutate } = useMutation({
		mutationKey: ['put/replace-settings'],
		mutationFn: updateReplaceSettings,
	});

	const parcedReplacedSettings = useMemo(
		() =>
			response?.data && {
				replaceCompanyInfo: Boolean(
					replaceSettings?.replaceCompanyInfo ?? response?.data?.replaceCompanyInfo,
				),
				replacePartnerInfo: Boolean(
					replaceSettings?.replacePartnerInfo ?? response?.data?.replacePartnerInfo,
				),
				partnerInfoForReplace:
					replaceSettings?.partnerInfoForReplace || response?.data.partnerInfoForReplace,
				companyInfoForReplace:
					replaceSettings?.companyInfoForReplace || response?.data.companyInfoForReplace,
				type: replaceSettings?.type || response?.data.type,
			},
		[response?.data, replaceSettings],
	);

	const updateReplaceSettingsHandler = (data: ReplaceSettingsType) => {
		if (partnerId) {
			updateReplaceSettingsMutate(
				{
					partnerId: partnerId || '',
					data,
				},
				{
					onSuccess: (response) => {
						setReplaceSettings(response.data);
						enqueueSnackbar({
							message: t('partners.snackbar.success_settings_updating'),
							variant: 'success',
						});
					},
					onError: (err) => {
						const errorMessage = getErrorMessage(err);
						enqueueSnackbar({ message: errorMessage, variant: 'error' });
					},
				},
			);
		}
	};

	const handleSubmit = (partnerInfoForReplace: PartnerInfoForReplaceFormType) => {
		if (parcedReplacedSettings) {
			updateReplaceSettingsHandler({
				...parcedReplacedSettings,
				partnerInfoForReplace: {
					name: partnerInfoForReplace.name || undefined,
					vatNumber: partnerInfoForReplace.vatNumber || undefined,
					address: partnerInfoForReplace.address || undefined,
					erpCode: partnerInfoForReplace.erpCode || undefined,
				},
			});
		}
	};

	const onCustoremSwitchHandler = () => {
		if (parcedReplacedSettings) {
			updateReplaceSettingsHandler({
				...parcedReplacedSettings,
				replacePartnerInfo: !parcedReplacedSettings.replacePartnerInfo,
			});
		}
	};

	const onYourSwitchHandler = () => {
		if (parcedReplacedSettings) {
			updateReplaceSettingsHandler({
				...parcedReplacedSettings,
				replaceCompanyInfo: !parcedReplacedSettings.replaceCompanyInfo,
			});
		}
	};
	return (
		<>
			<Typography variant={'subtitle1'} color={ColorPalette.baliHai} sx={{ marginTop: '24px' }}>
				<Trans
					t={t}
					i18nKey="partners.replace_settings_description"
					components={[
						<Icon
							id={'replace_value'}
							color={ColorPalette.astronaut}
							style={{ display: 'inline-block', height: '18px', width: '18px' }}
						/>,
					]}
				/>
			</Typography>

			{getIsLoading ? (
				<Loader />
			) : (
				<Box
					sx={{
						display: 'flex',
						gap: '40px',
					}}
				>
					<Box>
						<Typography
							variant={'h5'}
							color={ColorPalette.astronaut}
							sx={{ margin: '24px 0 34px' }}
						>
							{parcedReplacedSettings?.type === PartnersEnum.client
								? t('partners.replace_settings_customer_title')
								: t('partners.replace_settings_supplier_title')}
						</Typography>
						<Paper
							sx={{
								padding: '30px',
								display: 'flex',
								flexDirection: 'column',
								gap: '15px',
							}}
						>
							<FormControlLabel
								control={
									<Switch
										checked={parcedReplacedSettings?.replacePartnerInfo}
										onChange={onCustoremSwitchHandler}
										sx={{ marginRight: '20px' }}
									/>
								}
								label={
									<Typography variant={'body2'} color={ColorPalette.baliHai}>
										{parcedReplacedSettings?.type === PartnersEnum.client
											? t('partners.replace_settings_customer_description')
											: t('partners.replace_settings_supplier_description')}
									</Typography>
								}
							/>
							<Divider color={ColorPalette.periwinkle} sx={{ margin: '15px 0' }} />

							<Formik
								initialValues={{
									name: parcedReplacedSettings?.partnerInfoForReplace?.name || undefined,
									vatNumber:
										parcedReplacedSettings?.partnerInfoForReplace?.vatNumber || undefined,
									address:
										parcedReplacedSettings?.partnerInfoForReplace?.address || undefined,
									erpCode:
										parcedReplacedSettings?.partnerInfoForReplace?.erpCode || undefined,
								}}
								validationSchema={PartnerInfoForReplaceValidationSchema}
								onSubmit={handleSubmit}
							>
								{/* FormikProps<PartnerInfoForReplaceFormType> */}
								{({ setValues }) => (
									<Form>
										<InputField
											name={'name'}
											label={t('auth.labels.company_name')}
											placeholder={t('auth.validation.enter_company_name')}
										/>
										<InputField
											name={'vatNumber'}
											label={t('auth.labels.vat_number')}
											placeholder={t('auth.validation.enter_vat_number')}
										/>
										<InputField
											name={'address'}
											label={t('partners.labels.address')}
											placeholder={t('partners.placeholder.address')}
										/>
										<InputField
											name={'erpCode'}
											label={t('partners.labels.erpCode')}
											placeholder={t('partners.placeholder.erpCode')}
										/>
										<ActionsForDialog
											cancelLabel={t('shared_texts.clear_fields')}
											cancelAction={() => {
												setValues({
													name: '',
													vatNumber: '',
													address: '',
													erpCode: '',
												});
											}}
											applyLabel={t('shared_texts.save')}
										/>
									</Form>
								)}
							</Formik>
						</Paper>
					</Box>

					<Box>
						<Typography
							variant={'h5'}
							color={ColorPalette.astronaut}
							sx={{ margin: '24px 0 34px' }}
						>
							{t('partners.replace_settings_your_title')}
						</Typography>
						<Paper
							sx={{
								padding: '30px',
								display: 'flex',
								flexDirection: 'column',
								gap: '15px',
							}}
						>
							<FormControlLabel
								control={
									<Switch
										checked={parcedReplacedSettings?.replaceCompanyInfo}
										onChange={onYourSwitchHandler}
										sx={{ marginRight: '20px' }}
									/>
								}
								label={
									<Typography variant={'body2'} color={ColorPalette.baliHai}>
										{t('partners.replace_settings_your_description')}
									</Typography>
								}
							/>
							<Divider color={ColorPalette.periwinkle} sx={{ margin: '15px 0' }} />

							<Input
								name={'name'}
								label={t('auth.labels.company_name')}
								value={parcedReplacedSettings?.companyInfoForReplace?.name ?? '-'}
								disabled
								required
							/>
							<Input
								name={'vatNumber'}
								label={t('auth.labels.vat_number')}
								value={parcedReplacedSettings?.companyInfoForReplace?.vatNumber ?? '-'}
								disabled
								required
							/>
							<Input
								name={'address'}
								label={t('partners.labels.address')}
								value={parcedReplacedSettings?.companyInfoForReplace?.address ?? '-'}
								disabled
								required
							/>
						</Paper>
					</Box>
				</Box>
			)}
		</>
	);
};
