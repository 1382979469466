import { FC } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ColorPalette } from 'shared/consts/colorPalette';

interface TableTranslationLabelProps {
	label?: string;
	path?: string;
	color?: string;
}

export const TableTranslationLabel: FC<TableTranslationLabelProps> = ({
	label,
	path,
	color = ColorPalette.astronaut,
}) => {
	const { t } = useTranslation();
	return (
		<Typography variant={'body2'} component={'span'} color={color} sx={{ width: '100%' }}>
			{path ? t(path) : t(`shared_texts.table_columns.${label}`)}
		</Typography>
	);
};
