import {
	Box,
	Collapse,
	Divider,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Menu,
} from '@mui/material';
import { RoleBasedRoutes, Routes } from 'app/config/routes/consts';
import { FunctionComponent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Icon } from 'shared/ui/Icon';
import { usePathMatch } from 'shared/helpers/usePathMatch';
import { useSidebarStore } from 'widgets/Sidebar/model/state';
import { SidebarMenuLink } from 'widgets/Sidebar/types';
import { SubMenuListItem } from 'widgets/Sidebar/ui/SubMenuListItem';

interface Props {
	menuLink: SidebarMenuLink;
}

export const MenuListItem: FunctionComponent<Props> = ({ menuLink }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { isSidebarOpened, openedMenuList, toggleMenuList } = useSidebarStore();

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const isMenuOpened = useMemo(() => Boolean(anchorEl), [anchorEl]);

	const handleToggleMenuList = (event: React.MouseEvent<HTMLElement>) => {
		if (!menuLink?.list) return;
		if (isSidebarOpened) {
			menuLink?.list && toggleMenuList(menuLink.route.path);
		} else {
			setAnchorEl(event.currentTarget);
		}
	};

	const handleCloseMenuList = () => {
		setAnchorEl(null);
	};

	const handleClickListItemButton = () => {
		if (menuLink?.action) {
			menuLink.action();
			return;
		}
		if (!menuLink?.list) {
			navigate(menuLink.route.path);
		}
	};

	const pathMatch = usePathMatch(menuLink.route.path);
	const importFilesDirectMatch = usePathMatch(Routes.importFilesDirect);
	const importFilesFromFtpMatch = usePathMatch(Routes.importFilesFromFtp);

	const menuLinkMatch = useMemo(() => {
		if (pathMatch) {
			return pathMatch;
		}
		if (!pathMatch && menuLink.route.path === RoleBasedRoutes.startRecognition.path) {
			return importFilesDirectMatch || importFilesFromFtpMatch;
		}
		return false;
	}, [pathMatch, menuLink.route.path, importFilesDirectMatch, importFilesFromFtpMatch]);

	return (
		<ListItem
			key={menuLink.route.path}
			sx={{
				padding: 0,
				paddingTop: '16px',
				overflow: 'hidden',
			}}
		>
			<ListItemButton
				disableRipple
				disableTouchRipple
				onClick={handleClickListItemButton}
				sx={{
					'padding': '0',
					'marginRight': '16px',
					'borderRadius': '0px 10px 10px 0px',
					'position': 'relative',
					'backgroundColor':
						menuLinkMatch || (menuLink.route.path && isMenuOpened)
							? ColorPalette.charade
							: 'none',
					'color': menuLink.route.path && isMenuOpened ? ColorPalette.white : ColorPalette.manatee,
					'transition': 'all .3s',
					'display': 'flex',
					'flexDirection': 'column',
					'alignItems': 'flex-start',

					'&:hover': {
						backgroundColor: ColorPalette.charade,
						color: ColorPalette.white,
					},
					'&:after': {
						content: '""',
						display: menuLinkMatch ? 'block' : 'none',
						width: '6px',
						height: '100%',
						borderRadius: '6px',
						backgroundColor: ColorPalette.royalBlue,
						position: 'absolute',
						top: '0',
						right: '-16px',
					},
				}}
			>
				<Box
					sx={{
						padding: '9px 0',
						paddingLeft: '26px',
						display: 'flex',
						flexDirection: 'row',
						width: '100%',
					}}
					onClick={handleToggleMenuList}
				>
					<ListItemIcon
						sx={{
							minWidth: 0,
							marginRight: '20px',
							justifyContent: 'center',
							color: menuLinkMatch ? ColorPalette.royalBlue : 'inherit',
						}}
					>
						<Icon
							id={`${menuLink.icon}${menuLinkMatch ? '_fill' : ''}`}
							style={{
								color: 'inherit',
							}}
						/>
					</ListItemIcon>
					<ListItemText
						primary={t(`sidebar.menu.${menuLink.route.path}`)}
						sx={{
							fontWeight: 700,
							fontSize: '14px',
							lineHeight: '18px',
							letterSpacing: '0.5px',
							color: 'inherit',
							opacity: isSidebarOpened ? 1 : 0,
							width: isSidebarOpened ? 'fit-content' : 0,
							overflow: isSidebarOpened ? 'visible' : 'hidden',
							transition: 'opacity .3s',
						}}
					/>
					{menuLink.list && (
						<Icon
							id={'custom_arrow_down'}
							style={{
								position: 'absolute',
								height: '10px',
								top: '18px',
								right: '20px',
								transform: `rotate(${
									openedMenuList === menuLink.route.path ? '180deg' : '0deg'
								})`,
								opacity: isSidebarOpened ? 1 : 0,
								width: isSidebarOpened ? '20px' : 0,
								overflow: isSidebarOpened ? 'visible' : 'hidden',
								transition: 'transform .3s',
							}}
						/>
					)}
				</Box>
				{menuLink.list && (
					<>
						<Collapse
							in={openedMenuList === menuLink.route.path}
							timeout="auto"
							unmountOnExit
							sx={{ width: '100%' }}
						>
							<Divider
								sx={{
									width: 'calc(100% - 44px)',
									marginLeft: '26px',
									marginBottom: '18px',
									borderRadius: '1.5px',
									backgroundColor: ColorPalette.brightGray,
								}}
							/>
							<List
								sx={{
									padding: 0,
								}}
							>
								{menuLink.list.map((link) => (
									<SubMenuListItem key={link.path} subMenuLink={link} />
								))}
							</List>
						</Collapse>

						<Menu
							anchorEl={anchorEl}
							id="account-menu"
							open={isMenuOpened}
							onClose={handleCloseMenuList}
							onClick={handleCloseMenuList}
							PaperProps={{
								elevation: 0,
								sx: {
									'marginLeft': '40px',
									'paddingX': '20px',
									'borderRadius': '10px',
									'backgroundColor': ColorPalette.charade,
									'overflow': 'visible',

									'& .MuiListItemText-root': {
										marginLeft: '26px',
									},

									'&:before': {
										content: '""',
										display: 'block',
										position: 'absolute',
										top: '24px',
										left: '-5px',
										width: '10px',
										height: '10px',
										backgroundColor: ColorPalette.charade,
										transform: 'translateY(-50%) rotate(45deg)',
										zIndex: 0,
									},
								},
							}}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left',
							}}
						>
							<List
								sx={{
									padding: 0,
								}}
							>
								{menuLink.list.map((link) => (
									<SubMenuListItem key={link.path} subMenuLink={link} />
								))}
							</List>
						</Menu>
					</>
				)}
			</ListItemButton>
		</ListItem>
	);
};
