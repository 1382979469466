import { Box, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Icon } from 'shared/ui/Icon';

interface Props {
	text?: string;
}

export const InputError: FunctionComponent<Props> = ({ text }) => {
	return (
		<>
			{text && (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'flex-start',
						marginTop: '8px',
						position: 'absolute',
						top: '74px',
						left: '0',
					}}
				>
					<Icon
						id={'exclamation'}
						color={ColorPalette.violetRed}
						style={{
							width: '13px',
							height: '13px',
							minWidth: '13px',
							minHeight: '13px',
							marginRight: '7px',
						}}
					/>
					<Typography variant={'caption'} color={ColorPalette.violetRed}>
						{text}
					</Typography>
				</Box>
			)}
		</>
	);
};
