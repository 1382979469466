import { Box, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';

interface Props {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	children: any;
	number?: number;
}

export const Title: FunctionComponent<Props> = ({ children, number }) => {
	return (
		<Box
			sx={{
				'maxWidth': '828px',
				'marginTop': '44px',
				'marginBottom': '22px',
				'& a': {
					color: ColorPalette.royalBlue,
					textDecoration: 'underline',
				},
			}}
		>
			{number && (
				<Typography component={'span'} variant={'h4'} color={ColorPalette.royalBlue}>
					{`${number}. `}
				</Typography>
			)}
			<Typography component={'span'} variant={'h5'} color={ColorPalette.astronaut}>
				{children}
			</Typography>
		</Box>
	);
};
