import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { ColorPalette } from 'shared/consts/colorPalette';
import { CurrentSubInfo } from './ui/CurrentSubInfo';
import { SubUsageInfo } from './ui/SubUsageInfo';
import { useSubscriptionStoreStore } from 'widgets/Subscriptions/model/state';

export const CurrentTab: FunctionComponent = () => {
	const { t } = useTranslation();
	const { currentSubscription, paymentMethodLast4 } = useSubscriptionStoreStore();

	if (currentSubscription) {
		return (
			<>
				{paymentMethodLast4 && (
					<>
						<Typography variant={'h5'} color={ColorPalette.astronaut}>
							{t('subscriptions.tab_1.current_sub.title')}
						</Typography>
						<CurrentSubInfo currentSubscription={currentSubscription} />
					</>
				)}
				<Typography variant={'h5'} color={ColorPalette.astronaut} sx={{ marginBottom: '34px' }}>
					{t('subscriptions.tab_1.usage_sub.title')}
				</Typography>
				<SubUsageInfo currentSubscription={currentSubscription} />
			</>
		);
	}

	return null;
};
