import { format } from 'date-fns';
import { Box } from '@mui/material';
import { GeneralPartnerType } from 'entities/Partners/types';
import { OptionsDropdown } from 'shared/ui/OptionsDropdown';
import { Checkbox } from 'shared/ui/Checkbox';
import { TableTranslationLabel } from 'shared/ui/tables/_common/ui/TableTranslationLabel';
import { Column } from 'shared/ui/tables/_common/types';

export type PartnersColumnsType = GeneralPartnerType & {
	id: string;
	partnersCount: number;
	selectedPartnerIds: string[];
	editHandler: (partnerId: string) => void;
	deleteHandler: (partnerId?: string) => void;
	togglePartner: (partnerId: string) => void;
	toggleAllPartners: () => void;
	documentHandler: () => void;
	settingsHandler: () => void;
};

export const PartnersTableColumns: Array<Column<PartnersColumnsType>> = [
	{
		field: 'select',
		commonCellSettings: {
			style: {
				width: '65px',
			},
		},
		headerCellSettings: {
			renderCell: (partner) => (
				<Checkbox
					checked={partner?.partnersCount === partner?.selectedPartnerIds.length}
					onChange={() => partner?.toggleAllPartners()}
				/>
			),
		},
		bodyCellSettings: {
			renderCell: (partner) => (
				<Checkbox
					checked={partner?.selectedPartnerIds.includes(partner?._id)}
					onChange={() => partner?.togglePartner(partner?._id)}
				/>
			),
		},
	},
	{
		field: 'icon',
		commonCellSettings: {
			style: {
				width: '65px',
				minWidth: '50px',
			},
		},
		headerCellSettings: {
			renderCell: (partner) => (
				<OptionsDropdown
					iconId={'more_horizontal_small'}
					optionList={[
						{
							label: 'shared_texts.table_dropdown.remove',
							value: 'Remove all partner',
							action: () => {
								partner?.deleteHandler();
							},
						},
					]}
					forTableHeader
				/>
			),
		},
	},
	{
		field: 'business_name',
		commonCellSettings: {
			style: {
				minWidth: '150px',
				maxWidth: '150px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="business_name" />,
		},
		bodyCellSettings: {
			renderCell: (partner) => partner?.name || '-',
		},
	},
	{
		field: 'vat_number',
		commonCellSettings: {
			style: {
				minWidth: '150px',
				maxWidth: '150px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="vat_number" />,
		},
		bodyCellSettings: {
			renderCell: (partner) => partner?.vatNumber || '-',
		},
	},
	{
		field: 'code',
		commonCellSettings: {
			style: {
				minWidth: '150px',
				maxWidth: '150px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="code" />,
		},
		bodyCellSettings: {
			renderCell: (partner) => partner?.erpCode || '-',
		},
	},
	{
		field: 'file_type',
		commonCellSettings: {
			style: {
				minWidth: '150px',
				maxWidth: '150px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="file_type" />,
		},
		bodyCellSettings: {
			renderCell: (partner) =>
				partner?.type ? <TableTranslationLabel path={`partners.${partner?.type}`} /> : '-',
		},
	},
	{
		field: 'create_date',
		commonCellSettings: {
			style: {
				minWidth: '200px',
				maxWidth: '200px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="create_date" />,
		},
		bodyCellSettings: {
			renderCell: (partner) => {
				return partner?.createdAt ? format(new Date(partner?.createdAt), 'HH:mm dd/MM/yyyy') : '-';
			},
		},
	},
	{
		field: 'document_no',
		commonCellSettings: {
			style: {
				minWidth: '150px',
				maxWidth: '150px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="document_no" />,
		},
		bodyCellSettings: {
			renderCell: (partner) => partner?.documentsCount || '-',
		},
	},
	{
		field: 'options',
		commonCellSettings: {
			style: {
				minWidth: '100px',
				maxWidth: '100px',
			},
		},
		bodyCellSettings: {
			renderCell: (partner) => (
				<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
					<OptionsDropdown
						iconId={'more_horizontal'}
						optionList={[
							{
								label: 'shared_texts.table_dropdown.edit',
								value: 'Edit partner',
								action: () => {
									partner?.editHandler(partner._id);
								},
							},
							{
								label: 'shared_texts.table_dropdown.documents',
								value: 'Documetns',
								action: () => {
									partner?.documentHandler();
								},
							},
							{
								label: 'shared_texts.table_dropdown.settings',
								value: 'Settings',
								action: () => {
									partner?.settingsHandler();
								},
							},
							{
								label: 'shared_texts.table_dropdown.remove',
								value: 'Remove partner',
								action: () => {
									partner?.deleteHandler(partner._id);
								},
							},
						]}
					/>
				</Box>
			),
		},
	},
];
