import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Shadow } from 'shared/consts/shadow';
import emptyFilesDashboard from 'shared/assets/empty-files-dashboard.png';
import { Button } from 'shared/ui/Button';
import { Prefixes, RoutesMap } from 'app/config/routes/consts';
import { useNavigate, useParams } from 'react-router-dom';
import { FtpSettingsTypeEnum } from 'entities/Integrations/enums';
import { deleteCustomModelFiles, startTrainingCustomModel } from 'entities/CustomModel/api';
import { FeaturesEnum } from 'entities/Company/types';
import { useCustomModelStore } from '../state';
import { TabButton } from 'shared/ui/TabButton';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { getFilteredArray } from 'shared/helpers/getFilteredArray';
import { Icon } from 'shared/ui/Icon';
import { DeleteButton } from 'shared/ui/DeleteButton';
import { TableTranslationLabel } from 'shared/ui/tables/_common/ui/TableTranslationLabel';
import { useFeature } from 'shared/hooks/useFeatures';
import { getFtpSettings } from 'entities/Integrations/api';
import { getCustomModelFiles } from 'entities/CustomModel/api';
import { ModelBuildMode } from 'entities/CustomModel/types';
import { TrainingSelectModal } from 'widgets/customModel/ui/TrainingSelectModal';

export const ImportFilesModelDashboard = ({ uploadMode }: { uploadMode: boolean }) => {
	const queryClient = useQueryClient();
	const { t } = useTranslation();
	const { modelId } = useParams<{ modelId: string }>();
	const navigate = useNavigate();
	const { getFeature } = useFeature();
	const { currentCustomModelFiles, customModels, setCurrentCustomModelFiles } = useCustomModelStore();

	const [searchParam, setSearchParam] = useState<string>('UPLOADED');
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [modelFiles, setModelFiles] = useState<Array<any>>([]);
	const [openTrainingModal, setOpenTrainingModal] = useState(false);

	const currentCustomModelName = useMemo(
		() => customModels?.find((model) => model._id === modelId)?.name,
		[customModels, modelId],
	);

	useQuery({
		queryKey: ['get/custom-models/files'],
		queryFn: () => getCustomModelFiles(modelId || ''),
		onSuccess: (response) => {
			setCurrentCustomModelFiles(response.data);
		},
		onError: (err) => {
			const errorMessage = getErrorMessage(err);
			enqueueSnackbar({
				message: errorMessage,
				variant: 'error',
			});
		},
	});

	const { data: ftpSettingsData } = useQuery({
		queryKey: ['export-ftp-settings'],
		queryFn: () => getFtpSettings({ ftpSettingsType: FtpSettingsTypeEnum.generalExport }),
	});

	const { mutate: deleteFiles } = useMutation({
		mutationKey: ['delete/custom-models/files'],
		mutationFn: deleteCustomModelFiles,
	});

	const { mutate: startTraining } = useMutation({
		mutationKey: ['post/custom-models/start-training'],
		mutationFn: startTrainingCustomModel,
	});

	const handleUploadFiles = () => {
		navigate(`${Prefixes.root}${Prefixes.importCustomFilesDirect}${modelId}`);
	};

	const handleUploadFromFTP = () => {
		navigate(`${Prefixes.root}${Prefixes.importCustomFilesFromFtp}${modelId}`);
	};

	const startTrainingMutation = (type: ModelBuildMode) => {
		if (modelId) {
			startTraining(
				{
					customModelId: modelId,
					data: {
						modelBuildMode: type,
					},
				},
				{
					onSuccess: () => {
						enqueueSnackbar({
							message: t('custom_model.snackbar.success_starting_training'),
							variant: 'success',
						});
						navigate(`${Prefixes.root}${RoutesMap.customModel.root}`);
					},
					onError: (err) => {
						const errorMessage = getErrorMessage(err);
						enqueueSnackbar({ message: errorMessage, variant: 'error' });
					},
				},
			);
		}
	};

	const trainingHandler = (type: ModelBuildMode) => {
		startTrainingMutation(type);
	};

	const chooseTrainingTypeHandler = () => {
		const neuralModel = getFeature(FeaturesEnum.NEURAL_MODEL);
		if (neuralModel?.isEnabled) {
			setOpenTrainingModal(true);
		} else {
			startTrainingMutation(ModelBuildMode.template);
		}
	};

	const handleDeleteFile = (id: string) => {
		if (modelId) {
			deleteFiles(
				{ ids: [id] },
				{
					onSuccess: () => {
						queryClient.refetchQueries({
							queryKey: ['get/custom-models/files'],
						});
					},
					onError: (err) => {
						const errorMessage = getErrorMessage(err);
						enqueueSnackbar({
							message: errorMessage,
							variant: 'error',
						});
					},
				},
			);
		}
	};

	const uploadingFiles = useMemo(
		() => getFilteredArray(currentCustomModelFiles ?? [], ['status'], 'UPLOADING'),
		[currentCustomModelFiles],
	);
	const uploadedFiles = useMemo(
		() => getFilteredArray(currentCustomModelFiles ?? [], ['status'], 'UPLOADED'),
		[currentCustomModelFiles],
	);
	const errorFiles = useMemo(
		() => getFilteredArray(currentCustomModelFiles ?? [], ['status'], 'ERROR'),
		[currentCustomModelFiles],
	);

	useEffect(() => {
		if (searchParam === 'UPLOADING') {
			setModelFiles(uploadingFiles);
		} else if (searchParam === 'UPLOADED') {
			setModelFiles(uploadedFiles);
		} else if (searchParam === 'ERROR') {
			setModelFiles(errorFiles);
		}
	}, [searchParam, currentCustomModelFiles]);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const renderStatus = (file: any) => {
		const bgColor =
			file?.status === 'UPLOADED'
				? ColorPalette.jungleGreen
				: file?.status === 'UPLOADING'
				? ColorPalette.fuelYellow
				: ColorPalette.violetRed;
		const text =
			file?.status === 'UPLOADED' ? 'uploaded' : file?.status === 'UPLOADING' ? 'uploading' : 'error';
		return (
			<Box
				sx={{
					margin: '0 10px',
					padding: '6px 14px',
					borderRadius: '10px',
					backgroundColor: bgColor,
				}}
			>
				<TableTranslationLabel label={text} color={ColorPalette.white} />
			</Box>
		);
	};

	const navigateToDetailsHandler = (fileId: string) => {
		navigate(`/${Prefixes.editModelFile}${fileId}`);
	};
	return (
		<>
			<TrainingSelectModal
				open={openTrainingModal}
				handleClose={() => setOpenTrainingModal(false)}
				handleSelect={trainingHandler}
			/>
			{!uploadMode && (
				<>
					<Box>
						<Typography variant={'h4'} sx={{ mb: '24px' }} color={ColorPalette.astronaut}>
							{currentCustomModelName ?? t('custom_model.title')}
						</Typography>
						<Typography variant={'body1'} sx={{ mb: '42px' }} color={ColorPalette.baliHai}>
							{t('custom_model.upload_pc_or_ftp')}
						</Typography>
					</Box>

					<Box
						sx={{
							width: '100%',
							height: '1px',
							backgroundColor: '#D0DEFF',
							marginBottom: '64px',
						}}
					/>

					<Box
						sx={{
							height: '383px',
							width: '100%',
							padding: '40px',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
							backgroundColor: ColorPalette.white,
							boxShadow: Shadow['2'],
							borderRadius: '20px',
							marginBottom: '126px',
						}}
					>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<Box
								sx={{
									width: '430px',
									borderRadius: '20px',
									border: '1px solid #D0DEFF',
									background: '#F4F7FA',
									padding: '15px 25px',
								}}
							>
								<Button fullWidth sx={{ width: '370px' }} onClick={handleUploadFiles}>
									{t('start_recognition.upload_files')}
								</Button>
								{ftpSettingsData?.data.host && (
									<Button
										fullWidth
										sx={{ mt: '24px', width: '370px' }}
										onClick={handleUploadFromFTP}
									>
										{t('start_recognition.upload_modal.import_from_ftp')}
									</Button>
								)}
							</Box>

							<Box
								sx={{
									width: '50%',
									display: 'flex',
									flexDirection: 'column',
									paddingLeft: '40px',
								}}
							>
								<Typography
									variant={'body2'}
									component={'span'}
									color={ColorPalette.astronaut}
									mb={'15px'}
								>
									{t('custom_model.upload_at_least_5_files')}
								</Typography>
								<Typography
									variant={'caption'}
									component={'span'}
									color={ColorPalette.astronaut}
									sx={{ pB: '8px' }}
								>
									{t('start_recognition.how_to_start.step_2.li_1')}
								</Typography>
							</Box>
						</Box>
					</Box>
				</>
			)}
			<Typography variant={'subtitle1'} color={ColorPalette.astronaut} sx={{ margin: '64px 0 42px 0' }}>
				{t('shared_texts.total_files', { amount: currentCustomModelFiles?.length })}
			</Typography>
			{currentCustomModelFiles?.length > 0 ? (
				<>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'flex-start',
							alignItems: 'center',
							gap: '20px',
							marginBottom: '44px',
						}}
					>
						<TabButton
							label={t('start_recognition.total_files_table.tabs.uploading', {
								amount: uploadingFiles?.length,
							})}
							isActive={searchParam === 'UPLOADING'}
							action={() => setSearchParam('UPLOADING')}
						/>
						<TabButton
							label={t('start_recognition.total_files_table.tabs.uploaded', {
								amount: uploadedFiles?.length,
							})}
							isActive={searchParam === 'UPLOADED'}
							action={() => setSearchParam('UPLOADED')}
						/>
						<TabButton
							label={t('start_recognition.total_files_table.tabs.error', {
								amount: errorFiles?.length,
							})}
							isActive={searchParam === 'ERROR'}
							action={() => setSearchParam('ERROR')}
						/>
					</Box>

					{modelFiles?.length > 0 ? (
						<Box
							sx={{
								width: '100%',
								height: '82px',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
								padding: '0 32px',
							}}
						>
							<Box
								sx={{
									width: '120px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									textAlign: 'center',
								}}
							>
								<Typography variant={'body2'} color={ColorPalette.baliHai}>
									{t('shared_texts.table_columns.status')}
								</Typography>
							</Box>
							<Box
								sx={{
									width: '200px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									textAlign: 'center',
								}}
							>
								<Typography variant={'body2'} color={ColorPalette.baliHai}>
									{t('shared_texts.table_columns.file_name')}
								</Typography>
							</Box>
							<Box
								sx={{
									width: '180px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									textAlign: 'center',
								}}
							>
								<Typography variant={'body2'} color={ColorPalette.baliHai}>
									{t('shared_texts.table_columns.file_size')}
								</Typography>
							</Box>
							<Box
								sx={{
									width: '180px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									textAlign: 'center',
								}}
							>
								<Typography variant={'body2'} color={ColorPalette.baliHai}>
									{t('shared_texts.table_columns.source')}
								</Typography>
							</Box>
							<Box
								sx={{
									width: '180px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									textAlign: 'center',
								}}
							>
								<Typography variant={'body2'} color={ColorPalette.baliHai}>
									{t('shared_texts.table_columns.create_date')}
								</Typography>
							</Box>
							<Box
								sx={{
									width: '200px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									textAlign: 'center',
								}}
							>
								<Typography variant={'body2'} color={ColorPalette.baliHai}>
									{t('shared_texts.table_columns.last_changes')}
								</Typography>
							</Box>
							<Box
								sx={{
									width: '210px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									textAlign: 'center',
								}}
							></Box>
						</Box>
					) : (
						<Box
							sx={{
								height: '383px',
								width: '100%',
								padding: '40px',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								justifyContent: 'center',
								backgroundColor: ColorPalette.white,
								boxShadow: Shadow['2'],
								borderRadius: '20px',
							}}
						>
							<img
								src={emptyFilesDashboard}
								alt={'empty-files-dashboard'}
								style={{ width: '134px', height: '134px', marginBottom: '20px' }}
							/>
							<Typography variant={'h5'} color={ColorPalette.manatee}>
								{t('shared_texts.no_uploaded_files')}
							</Typography>
						</Box>
					)}

					{modelFiles?.length > 0 && (
						<Box sx={{ overflowY: 'auto', overflowX: 'hidden' }}>
							{/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
							{modelFiles?.map((file: any) => {
								return (
									<Box
										key={file._id}
										sx={{
											width: '100%',
											height: '82px',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'space-between',
											padding: '0 32px',
											backgroundColor: ColorPalette.white,
											borderRadius: '8px',
											boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
											marginBottom: '1px',
										}}
									>
										{renderStatus(file)}
										<Box
											sx={{
												width: '200px',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												textAlign: 'center',
											}}
										>
											<Tooltip title={file?.fileName} placement={'top'}>
												<Typography
													variant={'body2'}
													color={ColorPalette.baliHai}
													sx={{
														width: '250px',
														textOverflow: 'ellipsis',
														overflow: 'hidden',
														whiteSpace: 'nowrap',
													}}
												>
													{file?.fileName ?? '-'}
												</Typography>
											</Tooltip>
										</Box>
										<Box
											sx={{
												width: '180px',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												textAlign: 'center',
											}}
										>
											<Typography variant={'body2'} color={ColorPalette.baliHai}>
												{file?.size ?? '-'}
											</Typography>
										</Box>
										<Box
											sx={{
												width: '180px',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												textAlign: 'center',
											}}
										>
											<Typography variant={'body2'} color={ColorPalette.baliHai}>
												{file?.uploadSource ?? '-'}
											</Typography>
										</Box>
										<Box
											sx={{
												width: '180px',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												textAlign: 'center',
											}}
										>
											<Typography variant={'body2'} color={ColorPalette.baliHai}>
												{file?.createdAt
													? format(new Date(file?.createdAt), 'dd/MM/yyyy')
													: null ?? '-'}
											</Typography>
										</Box>
										<Box
											sx={{
												width: '200px',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												textAlign: 'center',
											}}
										>
											<Typography variant={'body2'} color={ColorPalette.baliHai}>
												{file?.updatedAt
													? format(new Date(file?.updatedAt), 'dd/MM/yyyy')
													: null ?? '-'}
											</Typography>
										</Box>
										<Box
											sx={{
												width: '100px',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												textAlign: 'center',
											}}
										>
											<DeleteButton action={() => handleDeleteFile(file?._id)} />
										</Box>
										<Box
											sx={{
												width: '80px',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												textAlign: 'center',
												cursor: 'pointer',
											}}
										>
											<Box onClick={() => navigateToDetailsHandler(file?._id)}>
												<IconButton
													sx={{
														'width': '20px',
														'height': '20px',
														'padding': 0,
														'boxSizing': 'content-box',
														'border': `5px solid ${ColorPalette.catskillWhite}`,
														'backgroundColor': ColorPalette.royalBlue,
														'boxShadow': Shadow['1'],
														'&:hover': {
															backgroundColor: ColorPalette.royalBlue,
														},
													}}
												>
													<Icon
														id={'custom_arrow_down'}
														style={{
															width: '18px',
															height: '9px',
															color: ColorPalette.white,
															transform: 'rotate(270deg) translateY(0.5px)',
														}}
													/>
												</IconButton>
											</Box>
										</Box>
									</Box>
								);
							})}

							<Box
								sx={{
									display: 'flex',
									justifyContent: 'flex-end',
									alignItems: 'center',
									marginTop: '20px',
									marginBottom: '20px',
									gap: '20px',
								}}
							>
								<Tooltip title={'Start training'}>
									<div>
										<Button
											onClick={chooseTrainingTypeHandler}
											size={'small'}
											disabled={!modelId || currentCustomModelFiles?.length < 5}
										>
											{t('custom_model.start_training')}
										</Button>
									</div>
								</Tooltip>
							</Box>
						</Box>
					)}
				</>
			) : (
				<Box
					sx={{
						height: '383px',
						width: '100%',
						padding: '40px',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						backgroundColor: ColorPalette.white,
						boxShadow: Shadow['2'],
						borderRadius: '20px',
					}}
				>
					<img
						src={emptyFilesDashboard}
						alt={'empty-files-dashboard'}
						style={{ width: '134px', height: '134px', marginBottom: '20px' }}
					/>
					<Typography variant={'h5'} color={ColorPalette.manatee}>
						{t('shared_texts.no_uploaded_files')}
					</Typography>
				</Box>
			)}
		</>
	);
};
