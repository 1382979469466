import { Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';

interface Props {
	children: string;
}

export const GuideStep: FunctionComponent<Props> = ({ children }) => {
	return (
		<Typography variant={'caption'} component={'p'} color={ColorPalette.royalBlue} sx={{ mt: '34px' }}>
			{children}
		</Typography>
	);
};
