import {
	DeleteManyFilesRequest,
	GetFileRequest,
	GetFilesRequest,
	IFtpExportRequest,
	IGetFileExport,
	PatchAssertionSetToFileRequest,
	FilesDirectImportRequest,
} from 'entities/Files/api/types';
import { axiosInstance } from 'shared/config/axios';
import { getAccessToken } from 'shared/helpers/getAccessToken';

export const getFiles: GetFilesRequest = (queries) => {
	const { category, ...rest } = queries;
	const params = new URLSearchParams(rest);
	const categoryQuery = category?.map((item) => `category=${item}`).join('&');
	return axiosInstance.get(`/files/?${params.toString()}&${categoryQuery}`, {
		headers: {
			Authorization: `Bearer ${getAccessToken()}`,
		},
	});
};

export const getFile: GetFileRequest = (fileId) => {
	return axiosInstance.get(`/files/${fileId}`, {
		headers: {
			Authorization: `Bearer ${getAccessToken()}`,
		},
	});
};

export const deleteFiles: DeleteManyFilesRequest = (data) => {
	return axiosInstance.delete('/files/delete-many', {
		data: data,
		headers: {
			Authorization: `Bearer ${getAccessToken()}`,
		},
	});
};

export const getFileExport: IGetFileExport = ({ fileId, type }) => {
	return axiosInstance.get(`/files/export/${fileId}?type=${type}`, {
		headers: {
			Authorization: `Bearer ${getAccessToken()}`,
		},
		responseType: 'blob',
	});
};

export const exportFileFtp: IFtpExportRequest = (data) => {
	return axiosInstance.post(`/files/export/ftp-export`, data);
};

export const patchAssertionSetToFile: PatchAssertionSetToFileRequest = (data) => {
	return axiosInstance.patch('/files/assertion-sets', data);
};

export const startFilesDirectImport: FilesDirectImportRequest = (data) => {
	return axiosInstance.post('/files/direct-import', data, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
};
