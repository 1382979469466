import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from 'shared/ui/PageHeader';
import { ExtensionsWidget } from 'widgets/Extensions';

export const Extensions: FC = () => {
	const { t } = useTranslation();
	return (
		<>
			<PageHeader title={t('extensions.ex_title')} description={t('extensions.ex_description')} />
			<ExtensionsWidget />
		</>
	);
};
