import * as yup from 'yup';
import { InitialValuesTypesEnum } from 'entities/Warehouse/enums';

export const WarehouseForAssertionsValidationSchema = yup.object({
	valuesTypes: yup
		.mixed<InitialValuesTypesEnum>()
		.oneOf(Object.values(InitialValuesTypesEnum))
		.required('shared_texts.field_is_required'),
	partnerId: yup.string().required('shared_texts.field_is_required'),
});
