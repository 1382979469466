import { Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';

interface Props {
	children: string;
}

export const GuideText: FunctionComponent<Props> = ({ children }) => {
	return (
		<Typography variant={'body2'} color={ColorPalette.astronaut} sx={{ my: '20px', ml: '63px' }}>
			{children}
		</Typography>
	);
};
