import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Routes } from 'app/config/routes/consts';
import { startRecognition } from 'entities/ImportSessions/api';
import { ImportSession } from 'entities/ImportSessions/types';
import { FileType } from 'entities/Files/types';
import { enqueueSnackbar } from 'notistack';
import { FC, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ColorPalette } from 'shared/consts/colorPalette';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { getFilteredArray } from 'shared/helpers/getFilteredArray';
import { Button } from 'shared/ui/Button';
import { CancelConfirmationModal } from 'widgets/importFiles/importFilesDirect/ui/CancelConfirmationModal';
import { DuplicatedFilesModal } from 'widgets/importFiles/importFilesDirect/ui/DuplicatedFilesModal';

interface Props {
	importSession: ImportSession;
}

export const ActionsRow: FC<Props> = ({ importSession }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { mutate } = useMutation({
		mutationKey: ['start-recognition', importSession._id],
		mutationFn: startRecognition,
	});

	const allFilesCounter = useMemo(
		() => importSession?.importSessionFiles?.length ?? 0,
		[importSession?.importSessionFiles],
	);
	const uploadingFilesCounter = useMemo(
		() => getFilteredArray(importSession?.importSessionFiles ?? [], ['status'], 'UPLOADING')?.length,
		[importSession?.importSessionFiles],
	);

	const handleCancel = () => {
		if (importSession?._id) {
			return handleOpenCancellingModal();
		}
		navigate(Routes.startRecognition);
	};

	const handleStartRecognition = () => {
		const mutationValues = { id: importSession._id };
		mutate(mutationValues, {
			onSuccess: async (res) => {
				if (res.data.success && !res.data.duplicates) {
					enqueueSnackbar({
						message: t('start_recognition.leave_page_modal.started_success'),
						variant: 'success',
					});
					navigate('/files/all');
				} else if (res.data.duplicates) {
					const duplicates = res.data.duplicates.map((duplicate) => {
						return { existFile: duplicate.existFiles[0], newFile: duplicate.newFile };
					});
					setDuplicates(duplicates);
					handleOpenDuplicatesModal();
				}
			},
			onError: (err) => {
				const defaultErrorMessage = t('start_recognition.leave_page_modal.recognition_error');
				const errorMessage = getErrorMessage(err, defaultErrorMessage);
				enqueueSnackbar({
					message: errorMessage,
					variant: 'error',
				});
			},
		});
	};

	const [cancellingModal, setCancellingModal] = useState<boolean>(false);
	const handleOpenCancellingModal = () => setCancellingModal(true);
	const handleCloseCancellingModal = () => setCancellingModal(false);

	const [duplicatesModal, setDuplicatesModal] = useState<boolean>(false);
	const handleOpenDuplicatesModal = () => setDuplicatesModal(true);
	const handleCloseDuplicatesModal = () => setDuplicatesModal(false);

	const [duplicates, setDuplicates] = useState<
		Array<{ existFile: FileType; newFile: FileType }> | undefined
	>(undefined);

	return (
		<>
			{cancellingModal && (
				<CancelConfirmationModal
					open={cancellingModal}
					handleClose={handleCloseCancellingModal}
					importSessionId={importSession._id}
				/>
			)}
			{duplicatesModal && (
				<DuplicatedFilesModal
					open={duplicatesModal}
					handleClose={handleCloseDuplicatesModal}
					importSession={importSession}
					duplicates={duplicates}
				/>
			)}
			<Box
				sx={{
					width: '100%',
					padding: '16px 40px',
					position: 'fixed',
					bottom: '0',
					left: '0',
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'flex-end',
					backgroundColor: ColorPalette.catskillWhite,
					gap: '40px',
				}}
			>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
					onClick={handleCancel}
				>
					<Typography variant={'button'} color={ColorPalette.astronaut} sx={{ cursor: 'pointer' }}>
						{t('shared_texts.cancel')}
					</Typography>
				</Box>
				<Button
					type={'submit'}
					variant={'contained'}
					onClick={handleStartRecognition}
					disabled={allFilesCounter === 0 || (allFilesCounter > 0 && uploadingFilesCounter !== 0)}
				>
					{t('start_recognition.title')}
				</Button>
			</Box>
		</>
	);
};
