import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { removeCoUsers } from 'entities/CoUser/api';
import { CoUser, CoUserStatusEnum } from 'entities/CoUser/types';
import { enqueueSnackbar } from 'notistack';
import { useAuthStore } from 'processes/auth/model/state';
import { FunctionComponent, useCallback, useState, useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ColorPalette } from 'shared/consts/colorPalette';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Input } from 'shared/ui/Input';
import { InfoContainer } from 'widgets/CoUsers/ui/CoUserDetailsInfo/ui/Paper';
import { RemoveCoUserModal } from 'widgets/CoUsers/ui/RemoveCoUserModal';

interface Props {
	coUser: CoUser | null;
}
export const PersonalInfo: FunctionComponent<Props> = ({ coUser }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { user } = useAuthStore();

	const [coUserForRemove, setCoUserForRemove] = useState<CoUser | undefined>(undefined);
	const handleOpenModal = (coUser: CoUser) => setCoUserForRemove(coUser);
	const handleCloseModal = () => setCoUserForRemove(undefined);

	const isCoUserActive = useMemo(
		() => coUser?.status === CoUserStatusEnum.active || coUser?.status === CoUserStatusEnum.api_only,
		[coUser?.status],
	);

	const queryClient = useQueryClient();
	const { mutate } = useMutation({
		mutationKey: ['co-users', coUserForRemove?._id],
		mutationFn: removeCoUsers,
	});

	const handleRemoveCoUser = useCallback(() => {
		mutate(coUserForRemove?._id, {
			onSuccess: async () => {
				await queryClient.invalidateQueries({ queryKey: ['co-users'] });
				enqueueSnackbar({
					message: t('co_users.removed_message', {
						value:
							coUserForRemove?.userId?.email ??
							coUserForRemove?.email ??
							coUserForRemove?.email,
					}),
					variant: 'success',
				});
				handleCloseModal();
				navigate('/co-users');
			},
			onError: (err) => {
				const defaultErrorMessage = t('co_users.error_while_removing', {
					value: coUserForRemove?.userId?.email ?? coUserForRemove?.email,
				});
				const errorMessage = getErrorMessage(err, defaultErrorMessage);
				enqueueSnackbar({
					message: errorMessage,
					variant: 'error',
				});
			},
		});
	}, [coUserForRemove, queryClient]);

	return (
		<>
			<RemoveCoUserModal
				coUser={coUserForRemove}
				open={!!coUserForRemove}
				handleClose={handleCloseModal}
				handleRemoveCoUser={handleRemoveCoUser}
			/>
			<Typography variant={'h5'} color={ColorPalette.astronaut} sx={{ margin: '44px 0 38px 0' }}>
				{t('co_users.tab_1.title')}
			</Typography>
			<Box
				sx={{
					'display': 'flex',
					'flexDirection': 'row',
					'& > * + *': {
						marginLeft: '20px',
					},
					'& input': {
						caretColor: 'transparent',
						cursor: 'default',
					},
				}}
			>
				<InfoContainer>
					<Input
						name={'firstName'}
						label={t('shared_texts.table_columns.first_name')}
						placeholder={t('shared_texts.table_columns.first_name') as string}
						value={coUser?.userId?.firstName ?? '-'}
						disabled
					/>
					<Input
						name={'lastName'}
						label={t('shared_texts.table_columns.last_name')}
						placeholder={t('shared_texts.table_columns.last_name') as string}
						value={coUser?.userId?.lastName ?? '-'}
						disabled
					/>
					<Input
						name={'email'}
						label={t('co_users.tab_1.co_user_email')}
						placeholder={t('co_users.tab_1.co_user_email') as string}
						value={coUser?.email}
						disabled
					/>
					<Button
						onClick={() => coUser && handleOpenModal(coUser)}
						color={ColorPalette.violetRed}
						fullWidth
					>
						{t('co_users.remove_user')}
					</Button>
				</InfoContainer>
				<InfoContainer>
					<>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								marginBottom: '43px',
								color: isCoUserActive ? ColorPalette.jungleGreen : ColorPalette.fuelYellow,
							}}
						>
							<Icon
								id={isCoUserActive ? 'done_ring_round' : 'send_fill'}
								style={{ color: 'inherit' }}
							/>
							<Typography variant={'body2'} sx={{ color: 'inherit', marginLeft: '12px' }}>
								{isCoUserActive
									? t('co_users.tab_1.invitation_accept')
									: t('co_users.tab_1.invitation_sent')}
							</Typography>
						</Box>
						<Typography
							variant={'body2'}
							color={ColorPalette.astronaut}
							sx={{ marginBottom: '27px' }}
						>
							{t('shared_texts.table_columns.invited_by')}
						</Typography>
						<Box sx={{ marginBottom: '43px' }}>
							<Typography variant={'body2'} component={'span'} color={ColorPalette.astronaut}>
								{coUser?.invitedBy.firstName && coUser.invitedBy.lastName
									? `${coUser.invitedBy.firstName} ${coUser.invitedBy.lastName}`
									: '-'}
							</Typography>
							{coUser?.invitedBy._id === user?._id && (
								<Typography
									variant={'body2'}
									component={'span'}
									color={ColorPalette.astronaut}
									sx={{ fontWeight: 300 }}
								>
									{' (me)'}
								</Typography>
							)}
						</Box>
						<Typography
							variant={'body2'}
							color={ColorPalette.astronaut}
							sx={{ marginBottom: '27px' }}
						>
							{t('shared_texts.table_columns.joined_the_system')}
						</Typography>
						<Typography variant={'body2'} color={ColorPalette.astronaut}>
							{coUser?.joinedAt ? (
								<Typography
									variant={'body2'}
									component={'span'}
									color={ColorPalette.astronaut}
								>
									{format(new Date(coUser.joinedAt), 'HH:mm\u00A0\u00A0\u00A0dd/MM/yyyy')}
								</Typography>
							) : (
								<Typography
									variant={'body2'}
									component={'span'}
									color={ColorPalette.rockBlue}
								>
									{'--:--\u00A0\u00A0\u00A0--/--/----'}
								</Typography>
							)}
						</Typography>
					</>
				</InfoContainer>
			</Box>
		</>
	);
};
