import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Typography } from '@mui/material';
import { ColorPalette } from 'shared/consts/colorPalette';
import { PaymentMethod } from 'widgets/Subscriptions/ui/SubCurrentTab/Tabs/PaymentTab/ui/PaymentMethod';
import { PaymentModal } from 'widgets/Subscriptions/ui/SubCurrentTab/Tabs/PaymentTab/ui/PaymentModal';
import { useSubscriptionStoreStore } from 'widgets/Subscriptions/model/state';
import { getSubscriptionPaymentMethod } from 'entities/Subscriptions/api';

export const PaymentTab: FC = () => {
	const { t } = useTranslation();
	const { paymentMethodLast4, setCardNumber } = useSubscriptionStoreStore();
	const [paymentModalOpen, setPaymentModalOpen] = useState<boolean>(false);
	useQuery({
		queryKey: ['subscriptions/payment-methods'],
		queryFn: () => getSubscriptionPaymentMethod(),
		onSuccess: (response) => {
			setCardNumber(response?.data.paymentMethodLast4);
		},
		enabled: !paymentMethodLast4,
	});

	return (
		<>
			<Typography variant={'h5'} color={ColorPalette.astronaut} sx={{ marginBottom: '34px' }}>
				{t('subscriptions.tab_3.title')}
			</Typography>
			<PaymentMethod addPaymentHandler={() => setPaymentModalOpen(true)} />
			<PaymentModal open={paymentModalOpen} handleClose={() => setPaymentModalOpen(false)} />
		</>
	);
};
