import { useGoogleLogin } from '@react-oauth/google';
import { useTranslation } from 'react-i18next';
import { useAuthStore } from 'processes/auth/model/state';
import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { useSignInStore } from 'widgets/auth/signUp/_MainModal/model/state';
import { authWithGoogleToken } from 'entities/Auth/api';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { enqueueSnackbar } from 'notistack';

interface Props {
	label: string;
}

export const AuthWithGoogleButton: FunctionComponent<Props> = ({ label }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { goToStep, updateAuthType, reset: resetSignIn } = useSignInStore();
	const { updateAuthData, reset: resetAuth } = useAuthStore();

	const signUpWithGoogle = useGoogleLogin({
		scope: 'email profile openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
		onSuccess: (res) => {
			authWithGoogleToken({ token: res?.access_token }).then((res) => {
				if (res?.data.user?.isDeleted) {
					const defaultErrorMessage = t('auth.auth_removed');
					const errorMessage = getErrorMessage('Error', defaultErrorMessage);
					enqueueSnackbar({
						message: errorMessage,
						variant: 'error',
					});
					return;
				}
				if (res?.data.user && !res?.data.company) {
					updateAuthType({ authType: 'google' });
					updateAuthData({ accessToken: res.data.accessToken });
					updateAuthData({ user: res.data.user });
					goToStep(3);
					navigate('/sign-up');
					return;
				}
				if (res?.data) {
					updateAuthData(res.data);
				}
			});
		},
	});

	return (
		<Button
			variant={'outlined'}
			startIcon={<Icon id={'google_logo'} />}
			onClick={() => {
				resetSignIn();
				resetAuth();
				signUpWithGoogle();
			}}
			fullWidth
		>
			{label}
		</Button>
	);
};
