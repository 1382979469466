import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Shadow } from 'shared/consts/shadow';

interface Props {
	label: string;
	isActive: boolean;
	action: () => void;
}

export const TabButton: FC<Props> = ({ label, isActive, action }) => {
	return (
		<Box
			sx={{
				'padding': '0 14px',
				'width': '151px',
				'height': '61px',
				'display': 'flex',
				'justifyContent': 'center',
				'alignItems': 'center',
				'border': isActive
					? `1px solid ${ColorPalette.royalBlue}`
					: `1px solid ${ColorPalette.white}`,
				'backgroundColor': ColorPalette.white,
				'borderRadius': '10px',
				'boxShadow': isActive ? Shadow['1'] : Shadow['2'],
				'textDecoration': 'none',
				'cursor': 'pointer',
				'transition': 'all .3s',
				'textAlign': 'center',
				'&:hover': {
					border: `1px solid ${ColorPalette.royalBlue}80`,
				},
			}}
			onClick={action}
		>
			<Typography variant={'body1'} color={ColorPalette.royalBlue}>
				{label}
			</Typography>
		</Box>
	);
};
