import { Typography } from '@mui/material';
import { format } from 'date-fns';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Column } from 'shared/ui/tables/_common/types';
import { IResource } from 'pages/admin/Resources';
import { TableTranslationLabel } from 'shared/ui/tables/_common/ui/TableTranslationLabel';

export const ResourcesTableColumns: Array<Column<IResource>> = [
	{
		field: 'endpoint',
		commonCellSettings: {
			style: {
				minWidth: '250px',
				maxWidth: '250px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="resource_endpoint" />,
		},
		bodyCellSettings: {
			prepareField: (resource) => resource?.endpoint ?? '-',
		},
	},
	{
		field: 'addingDate',
		commonCellSettings: {
			style: {
				minWidth: '250px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="date_added" />,
		},
		bodyCellSettings: {
			renderCell: (resource) => {
				return resource?.createdAt ? (
					<Typography variant={'body2'} component={'span'} color={ColorPalette.astronaut}>
						{format(new Date(resource?.createdAt), 'HH:mm\u00A0\u00A0\u00A0dd/MM/yyyy')}
					</Typography>
				) : (
					<Typography variant={'body2'} component={'span'} color={ColorPalette.baliHai}>
						{'--:--\u00A0\u00A0\u00A0--/--/----'}
					</Typography>
				);
			},
		},
	},
	{
		field: 'counterOfCustomModels',
		commonCellSettings: {
			style: {
				minWidth: '250px',
				maxWidth: '250px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="custom_models_counter" />,
		},
		bodyCellSettings: {
			prepareField: (resource) =>
				`${resource?.customModelsCount}/${resource?.customModelsLimit}` || '-',
		},
	},

	{
		field: 'counterOfNeuralModels',
		commonCellSettings: {
			style: {
				minWidth: '250px',
				maxWidth: '250px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="neural_models_counter" />,
		},
		bodyCellSettings: {
			prepareField: (resource) =>
				`${resource?.customNeuralModelsUsed}/${resource?.customNeuralModelsQuota}` || '-',
		},
	},
];
