import { FunctionComponent } from 'react';
import { BackButton } from 'shared/ui/BackButton';
import { useSignUpByInvitationStore } from 'widgets/auth/signUpByInvitation/_MainModal/model/state';
import { SignUpStep1 } from 'widgets/auth/signUpByInvitation/_MainModal/ui/steps/Step1';
import { SignUpStep2 } from 'widgets/auth/signUpByInvitation/_MainModal/ui/steps/Step2';

export const SUBIMainModal: FunctionComponent = () => {
	const { step, goToPrevStep } = useSignUpByInvitationStore();

	return (
		<>
			{step > 1 && <BackButton action={goToPrevStep} />}
			{step === 1 && <SignUpStep1 />}
			{step === 2 && <SignUpStep2 />}
		</>
	);
};
