import { CoUser } from 'entities/CoUser/types';
import { CoUSerDetailsTabs } from 'widgets/CoUsers/ui/CoUserDetailsInfo/types';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type CoUserDetailsInfoStore = {
	activeTab: CoUSerDetailsTabs;
	selectedCoUser: CoUser | null;
};

type CoUserDetailsInfoActions = {
	changeActiveTab: (tab: CoUSerDetailsTabs) => void;
	setSelectedCoUser: (coUser: CoUser | null) => void;
	reset: () => void;
};

const initialState: CoUserDetailsInfoStore = {
	activeTab: 'personal-info',
	selectedCoUser: null,
};

const coUserDetailsInfoStore = create<CoUserDetailsInfoStore & CoUserDetailsInfoActions>()(
	persist(
		(set) => ({
			...initialState,
			changeActiveTab: (tab) => set(() => ({ activeTab: tab })),
			setSelectedCoUser: (coUser) => set(() => ({ selectedCoUser: coUser })),
			reset: () => set(() => ({ ...initialState })),
		}),
		{
			name: 'coUserDetailsInfoStore',
		},
	),
);

export const useCoUserDetailsInfoStore = () => coUserDetailsInfoStore((state) => state);
