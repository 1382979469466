/* eslint-disable @typescript-eslint/ban-types */
import { DependencyList, useEffect } from 'react';

export const useAsyncEffect = (effect: () => Promise<Function | void>, deps?: DependencyList): void => {
	useEffect(() => {
		let destroyHandler: Function | void;

		(async () => {
			destroyHandler = await effect();
		})();

		return () => {
			if (destroyHandler instanceof Function) {
				destroyHandler();
			}
		};
	}, deps);
};
