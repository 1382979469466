import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomOutputFileType } from 'entities/Extensions/types';
import { EmptyTable } from 'shared/ui/EmptyTable';
import { TableWithHeader } from 'shared/ui/tables/TableWithHeader';
import {
	CustomOutputFileTableColumnsType,
	CustomOutputFileTableColumns,
} from 'widgets/Extensions/Details/ui/CustomOutputFile/ui/OutputTypeView/Table/Columns';

type CustomOutputFileTablePropsType = {
	data: CustomOutputFileType[];
	toggleSwitchHandler: (data: { id: string; isEnabled: boolean }) => void;
	customOutputFileDetailsHandler: (typeId: string) => void;
};

export const CustomOutputFileTable: FC<CustomOutputFileTablePropsType> = ({
	data,
	toggleSwitchHandler,
	customOutputFileDetailsHandler,
}) => {
	const { t } = useTranslation();

	const preparedExtensions: CustomOutputFileTableColumnsType[] = useMemo(() => {
		if (!data?.length) return [];
		return data.map((item) => ({
			...item,
			id: item._id,
			toggleSwitchHandler,
			customOutputFileDetailsHandler,
		}));
	}, [data]);

	return (
		<>
			{preparedExtensions.length ? (
				<TableWithHeader columns={CustomOutputFileTableColumns} rows={preparedExtensions} />
			) : (
				<EmptyTable text={t('shared_texts.not_found')} />
			)}
		</>
	);
};
