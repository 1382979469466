// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getFilteredArray(array: Array<any>, keys: Array<string>, search: string) {
	return array.filter(function (item) {
		return keys.some(function (key) {
			const _key = key.split('.');

			let _item = item[_key[0]];
			if (item[_key[0]] && _key[1]) {
				_item = item[_key[0]][_key[1]];
			}

			if (!_item) return false;
			return _item?.includes(search);
		});
	});
}
