import { Box } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Sidebar } from 'widgets/Sidebar';
import { useSidebarStore } from 'widgets/Sidebar/model/state';
import { Snackbar } from 'widgets/Snackbar';

export const RootLayout: FunctionComponent = () => {
	const { isSidebarOpened } = useSidebarStore();

	return (
		<Box
			sx={{
				width: '100vw',
				minHeight: '100vh',
				display: 'flex',
				backgroundColor: ColorPalette.catskillWhite,
			}}
		>
			<Sidebar />
			<Box
				component="main"
				sx={{
					'position': 'relative',
					'padding': '36px 40px',
					'& .notistack-SnackbarContainer': {
						'width': `calc(100% - ${(isSidebarOpened ? 268 : 87) + 80}px)`,
						'right': '40px',
						'& > div': {
							width: '100%',
						},
					},
					'width': `calc(100vw - ${isSidebarOpened ? '268px' : '87px'})`,
					'transition': 'width .3s',
				}}
			>
				<SnackbarProvider
					maxSnack={2}
					anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
					Components={{
						success: Snackbar,
						error: Snackbar,
						warning: Snackbar,
						info: Snackbar,
						default: Snackbar,
					}}
				>
					<Outlet />
				</SnackbarProvider>
			</Box>
		</Box>
	);
};
