import { Box, Divider, Typography } from '@mui/material';
import { FC } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';

interface Props {
	fileName?: string;
}

export const EFHeader: FC<Props> = ({ fileName }) => {
	return (
		<>
			<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
				<Typography variant={'h4'}>{fileName}</Typography>
			</Box>
			<Divider color={ColorPalette.periwinkle} sx={{ margin: '16px 0 44px 0' }} />
		</>
	);
};
