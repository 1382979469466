import { FunctionComponent, useEffect, useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Button } from 'shared/ui/Button';
import { Loader } from 'shared/ui/Loader';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { GuideStep } from 'widgets/startRecognition/ui/GuideModal/ui/GuideStep';
import { GuideText } from 'widgets/startRecognition/ui/GuideModal/ui/GuideText';
import { CreateModelModal } from '../CreateModelModal';
import { deleteCustomModel, getCustomModels } from 'entities/CustomModel/api';
import { ModelStatuses } from 'entities/CustomModel/types';
import { useCustomModelStore } from '../state';
import ModelsTable from 'widgets/customModel/ui/ModelsTable';

export const CustomModelPage: FunctionComponent = () => {
	const { t } = useTranslation();
	const { customModels, setCustomModels, setCurrentCustomModelFiles } = useCustomModelStore();
	const queryClient = useQueryClient();

	const [open, setOpen] = useState(false);
	const [editModeId, setEditModeId] = useState<string>('');

	const { isLoading: getLoading, refetch } = useQuery({
		queryKey: ['get/custom-models'],
		queryFn: () => getCustomModels(),
		onSuccess: (response) => {
			setCustomModels(response.data);
			const isTraining = response.data.filter(
				(sessionItem) => sessionItem.status === ModelStatuses.training,
			).length;
			if (isTraining) {
				setTimeout(() => {
					refetch();
				}, 8000);
			}
		},
		onError: (err) => {
			const errorMessage = getErrorMessage(err);
			enqueueSnackbar({
				message: errorMessage,
				variant: 'error',
			});
		},
	});

	const { mutate, isLoading: deleteLoading } = useMutation({
		mutationKey: ['delete/custom-model'],
		mutationFn: deleteCustomModel,
	});

	const getCustomModelDataExternal = () => {
		queryClient.refetchQueries({
			queryKey: ['get/custom-models'],
		});
	};

	useEffect(() => {
		setCurrentCustomModelFiles([]);
	}, []);

	const handleClose = () => {
		setOpen(false);
		setEditModeId('');
	};
	const handleOpen = () => {
		setOpen(true);
		setEditModeId('');
	};

	const editHandler = (modelId: string) => {
		setOpen(true);
		setEditModeId(modelId);
	};

	const deleteHandler = (modelId: string) => {
		mutate(modelId, {
			onSuccess: async () => {
				queryClient.refetchQueries({
					queryKey: ['get/custom-models'],
				});
				handleClose();
			},
			onError: (err) => {
				const errorMessage = getErrorMessage(err);
				enqueueSnackbar({ message: errorMessage, variant: 'error' });
			},
		});
	};

	if (getLoading || deleteLoading) return <Loader />;

	return (
		<>
			<CreateModelModal
				open={open}
				handleClose={handleClose}
				editModeId={editModeId}
				replaceModel={getCustomModelDataExternal}
				customModels={customModels}
			/>

			<Typography variant={'h4'} color={ColorPalette.astronaut}>
				{t('custom_model.title')}
			</Typography>
			<Divider color={ColorPalette.periwinkle} sx={{ margin: '44px 0' }} />
			{!customModels?.length && (
				<Box sx={{ pr: '120px', overflowY: 'auto', maxWidth: '1020px', overflowX: 'hidden' }}>
					<Typography variant={'subtitle1'} color={ColorPalette.astronaut}>
						{t('custom_model.description')}
					</Typography>

					<GuideStep>{t('custom_model.step_1.title')}</GuideStep>
					<GuideText>{t('custom_model.step_1.description')}</GuideText>

					<GuideStep>{t('custom_model.step_2.title')}</GuideStep>
					<GuideText>{t('custom_model.step_2.description')}</GuideText>

					<GuideStep>{t('custom_model.step_3.title')}</GuideStep>
					<GuideText>{t('custom_model.step_3.description')}</GuideText>

					<GuideStep>{t('custom_model.step_4.title')}</GuideStep>
					<GuideText>{t('custom_model.step_4.description')}</GuideText>

					<GuideStep>{t('custom_model.step_5.title')}</GuideStep>
					<GuideText>{t('custom_model.step_5.description')}</GuideText>
				</Box>
			)}

			{customModels?.length ? (
				<ModelsTable models={customModels} editHandler={editHandler} deleteHandler={deleteHandler} />
			) : (
				''
			)}
			<Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '52px' }}>
				<Button onClick={handleOpen}>{t('custom_model.create_model')}</Button>
			</Box>
		</>
	);
};
