import { Box, Tooltip, Typography } from '@mui/material';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Shadow } from 'shared/consts/shadow';
import { Icon } from 'shared/ui/Icon';

interface ITable {
	columnsHeaders?: {
		columnName: string;
		autocomplete?: boolean;
	}[];
	tableKeys?: string[];
	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	tableValues?: any[];
	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	labels: any[];
	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	handleOpen: (region: any) => void;
	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	setTableLabelsData?: any;
	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	allTableLabels: any[];
	indexArray: number;
	currentPage: number;
	modelId?: string;
	handleOpenDeleteModal: (indexArray: number) => void;
}

export const Table = ({
	indexArray,
	columnsHeaders,
	tableKeys,
	tableValues,
	labels,
	handleOpen,
	setTableLabelsData,
	allTableLabels,
	currentPage,
	modelId,
	handleOpenDeleteModal,
}: ITable) => {
	const getNumberPage = () => {
		if (modelId) {
			return currentPage;
		} else if (labels[0]?.boundingRegions) {
			return labels[0]?.boundingRegions[0]?.pageNumber;
		} else {
			return currentPage;
		}
	};
	const numberPage = getNumberPage();

	const handleAddNewRow = (index: number) => {
		const allLabels = [...labels];
		const allScopeLabels = [...allTableLabels];
		const maxRowIndex =
			Math.max.apply(
				null,
				allLabels?.map((item: { rowIndex: number }) => item?.rowIndex),
			) + 1;
		const maxColumnIndex =
			Math.max.apply(
				null,
				allLabels?.map((item: { columnIndex: number }) => item?.columnIndex),
			) + 1;
		for (let i = 0; i < maxColumnIndex; i++) {
			const obj = {
				content: '',
				rowIndex: maxRowIndex,
				columnIndex: i,
			};
			allLabels.push(obj);
		}
		allScopeLabels[index].cells = allLabels;
		setTableLabelsData(allScopeLabels);
	};

	const handleAddNewColumn = (index: number) => {
		const allLabels = [...labels];
		const allScopeLabels = [...allTableLabels];
		const cellsColumnsHeaders = allScopeLabels[index]?.columnsHeaders;
		const maxRowIndex =
			Math.max.apply(
				null,
				allLabels?.map((item: { rowIndex: number }) => item?.rowIndex),
			) + 1;
		const maxColumnIndex =
			Math.max.apply(
				null,
				allLabels?.map((item: { columnIndex: number }) => item?.columnIndex),
			) + 1;
		cellsColumnsHeaders?.push({ columnName: `Column_${maxColumnIndex}` });
		for (let i = 0; i < maxRowIndex; i++) {
			const obj = {
				content: '',
				rowIndex: i,
				columnIndex: maxColumnIndex,
			};
			allLabels.push(obj);
		}
		allScopeLabels[index].cells = allLabels;
		allScopeLabels[index].columnsHeaders = cellsColumnsHeaders;
		setTableLabelsData(allScopeLabels);
	};

	return (
		<>
			{numberPage === currentPage && (
				<Box
					sx={{
						minHeight: '100px',
						borderRadius: '20px',
						background: ColorPalette.white,
						boxShadow: Shadow['2'],
						overflow: 'auto',
						padding: '20px',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							height: '30px',
							fontSize: '18px',
							fontWeight: 'bold',
							color: ColorPalette.astronaut,
							cursor: 'pointer',
							paddingBottom: '10px',
						}}
						onClick={() => handleOpenDeleteModal(indexArray)}
					>
						<Box
							sx={{
								marginRight: '8px',
								marginBottom: '4px',
							}}
						>
							<Icon
								id={'trush_icon_red'}
								style={{
									marginLeft: '16px',
									cursor: 'pointer',
								}}
							/>
						</Box>
						{allTableLabels[indexArray]?.label}
					</Box>
					<table style={{ width: '100%' }}>
						{columnsHeaders?.length &&
							//eslint-disable-next-line @typescript-eslint/no-explicit-any
							columnsHeaders?.map((item, index: number) => {
								return (
									<th
										key={index}
										style={{
											minWidth: '50px',
											border: `1.5px solid ${ColorPalette.zircon}`,
											borderColor: item?.autocomplete
												? ColorPalette.violetRedTransparent
												: ColorPalette.zircon,
										}}
									>
										<Typography
											variant={'body2'}
											color={ColorPalette.astronaut}
											style={{ cursor: 'pointer' }}
											onClick={() => {
												handleOpen({ columnHeader: item, index, indexArray });
											}}
										>
											{item?.columnName}
										</Typography>
										{columnsHeaders?.length - 1 === index && (
											<Tooltip title={'Add new column'} placement={'top'}>
												<Typography
													variant={'body1'}
													color={ColorPalette.astronaut}
													style={{ cursor: 'pointer', marginTop: '5px' }}
													onClick={() => {
														handleAddNewColumn(indexArray);
													}}
												>
													+
												</Typography>
											</Tooltip>
										)}
									</th>
								);
							})}
						{tableKeys?.length &&
							//eslint-disable-next-line @typescript-eslint/no-explicit-any
							tableKeys?.map((item: any, index: number) => {
								return (
									<tr key={index}>
										<td
											style={{
												width: '50px',
												border: `1.5px solid ${ColorPalette.zircon}`,
												display: 'flex',
												alignItems: 'center',
											}}
										>
											<Typography
												variant={'overline1'}
												color={ColorPalette.astronaut}
												style={{ cursor: 'pointer' }}
												onClick={() => {
													handleOpen({
														index,
														indexArray,
														tableKey: item.toString(),
													});
												}}
											>
												{item}
											</Typography>
											{tableKeys?.length - 1 === index && (
												<Tooltip title={'Add new row'} placement={'top'}>
													<Typography
														variant={'body1'}
														color={ColorPalette.astronaut}
														style={{ cursor: 'pointer', marginLeft: '10px' }}
														onClick={() => {
															handleAddNewRow(indexArray);
														}}
													>
														+
													</Typography>
												</Tooltip>
											)}
										</td>
										{tableValues?.length &&
											//eslint-disable-next-line @typescript-eslint/no-explicit-any
											tableValues[index]?.map((item: any, index: number) => {
												return (
													<td
														key={index}
														style={{
															border: `1.5px solid ${ColorPalette.zircon}`,
															borderColor: item.autocomplete
																? ColorPalette.violetRedTransparent
																: ColorPalette.zircon,
															cursor: 'pointer',
														}}
														onClick={() => {
															handleOpen({ region: item, index, indexArray });
														}}
													>
														<Typography
															variant={'overline1'}
															color={ColorPalette.astronaut}
														>
															{item.replaceVal ||
																item?.assertionsData?.replaceVal ||
																item?.content}
														</Typography>
													</td>
												);
											})}
									</tr>
								);
							})}
					</table>
				</Box>
			)}
		</>
	);
};
