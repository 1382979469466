import { Typography } from '@mui/material';
import { ColorPalette } from 'shared/consts/colorPalette';
import { format } from 'date-fns';
import { IconButton } from 'shared/ui/IconButton';
import { SubscriptionInvoicesType } from 'entities/Subscriptions/types';
import { TableTranslationLabel } from 'shared/ui/tables/_common/ui/TableTranslationLabel';
import { capitalizeFirstLetter } from 'shared/helpers/capitalizeFirstLetter';
import { Column } from 'shared/ui/tables/_common/types';

type BillingTableColumnsType = SubscriptionInvoicesType & {
	downloadAll: () => void;
	downloadSingle: (bill: SubscriptionInvoicesType) => void;
};

export const BillingTableColumns: Array<Column<BillingTableColumnsType>> = [
	{
		field: 'invoice',
		commonCellSettings: {
			style: {
				minWidth: '175px',
				maxWidth: '175px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="invoice_no" />,
		},
		bodyCellSettings: {
			prepareField: (billing) => billing?.invoiceNumber ?? '-',
		},
	},
	{
		field: 'billingDate',
		commonCellSettings: {
			style: {
				minWidth: '200px',
				maxWidth: '200px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="billing_date" />,
		},
		bodyCellSettings: {
			renderCell: (billing) => {
				return billing?.billingAt ? (
					<Typography variant={'body2'} component={'span'} color={ColorPalette.astronaut}>
						{format(new Date(billing.billingAt), 'HH:mm\u00A0\u00A0\u00A0dd/MM/yyyy')}
					</Typography>
				) : (
					<Typography variant={'body2'} component={'span'} color={ColorPalette.baliHai}>
						{'--:--\u00A0\u00A0\u00A0--/--/----'}
					</Typography>
				);
			},
		},
	},
	{
		field: 'status',
		commonCellSettings: {
			style: {
				minWidth: '100px',
				maxWidth: '100px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="status" />,
		},
		bodyCellSettings: {
			renderCell: (billing) => {
				return <TableTranslationLabel path={`shared_texts.${billing?.isPaid ? 'paid' : 'unpaid'}`} />;
			},
		},
	},
	{
		field: 'subscription',
		commonCellSettings: {
			style: {
				minWidth: '150px',
				maxWidth: '150px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="subscription" />,
		},
		bodyCellSettings: {
			prepareField: (billing) => capitalizeFirstLetter(billing?.subscriptionType || ''),
		},
	},
	{
		field: 'amount',
		commonCellSettings: {
			style: {
				minWidth: '100px',
				maxWidth: '100px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="amount" />,
		},
		bodyCellSettings: {
			prepareField: (billing) =>
				billing?.totalExcludingTax
					? `${billing.totalExcludingTax / 100} €`
					: billing?.amount
					? `${billing.amount / 100} €`
					: '--',
		},
	},
	{
		field: 'total_files',
		commonCellSettings: {
			style: {
				minWidth: '150px',
				maxWidth: '150px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="document_scanned" />,
		},
		bodyCellSettings: {
			renderCell: (billing) => billing?.totalFiles || '-',
		},
	},
	{
		field: 'total_extra_pages',
		commonCellSettings: {
			style: {
				minWidth: '150px',
				maxWidth: '150px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="total_extra_pages" />,
		},
		bodyCellSettings: {
			renderCell: (billing) => billing?.totalExtraPages || '-',
		},
	},
	{
		field: 'total_pages',
		commonCellSettings: {
			style: {
				minWidth: '150px',
				maxWidth: '150px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="total_pages" />,
		},
		bodyCellSettings: {
			renderCell: (billing) => billing?.totalPages || '-',
		},
	},
	{
		field: 'options',
		commonCellSettings: {
			style: {
				minWidth: '100px',
				maxWidth: '100px',
			},
		},
		headerCellSettings: {
			renderCell: (billing) => {
				return <IconButton iconId="download_rectangle" onClick={() => billing?.downloadAll()} />;
			},
		},
		bodyCellSettings: {
			renderCell: (billing) => (
				<IconButton onClick={() => billing?.downloadSingle(billing)} iconId="download_rectangle" />
			),
		},
	},
];
