import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FunctionComponent } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Paper } from 'shared/ui/Paper';
import { SubscriptionType } from 'entities/Subscriptions/types';
import { getMaxValue } from 'shared/helpers/getMaxValue';

interface SubUsageInfoPropsTypes {
	currentSubscription: SubscriptionType;
}

export const SubUsageInfo: FunctionComponent<SubUsageInfoPropsTypes> = ({ currentSubscription }) => {
	const { t } = useTranslation();

	return (
		<Paper
			sx={{
				marginTop: '34px',
				marginBottom: '31px',
				display: 'flex',
				paddingBottom: '31px',
				flexDirection: 'column',
			}}
		>
			<Box sx={{ display: 'flex', flexDirection: 'column', gap: '31px', marginBottom: '34px' }}>
				<Typography variant={'body2'} color={ColorPalette.astronaut}>
					{t('subscriptions.tab_1.usage_sub.imported_files')}
				</Typography>
				<Typography variant={'body2'} color={ColorPalette.rockBlue}>
					{`${currentSubscription?.totalFiles}/${getMaxValue(
						currentSubscription.maxFiles,
						currentSubscription.maxFreeFiles,
					)}`}
				</Typography>
			</Box>
			<Box sx={{ display: 'flex', flexDirection: 'column', gap: '31px', marginBottom: '34px' }}>
				<Typography variant={'body2'} color={ColorPalette.astronaut}>
					{t('subscriptions.tab_1.usage_sub.invidet_cousers')}
				</Typography>
				<Typography variant={'body2'} color={ColorPalette.rockBlue}>
					{`${currentSubscription?.totalCousers}/${getMaxValue(
						currentSubscription.maxCousers,
						currentSubscription.maxFreeCousers,
					)}`}
				</Typography>
			</Box>
			<Box sx={{ display: 'flex', flexDirection: 'column', gap: '31px', marginBottom: '34px' }}>
				<Typography variant={'body2'} color={ColorPalette.astronaut}>
					{t('subscriptions.tab_1.usage_sub.import_type')}
				</Typography>
				<Typography variant={'body2'} color={ColorPalette.rockBlue}>
					{currentSubscription?.ftpImport
						? t('subscriptions.tab_1.usage_sub.ftp_available')
						: t('subscriptions.tab_1.usage_sub.ftp_not_available')}
				</Typography>
			</Box>
			{currentSubscription.autoRemovePeriod && (
				<Box sx={{ display: 'flex', flexDirection: 'column', gap: '31px', marginBottom: '34px' }}>
					<Typography variant={'body2'} color={ColorPalette.astronaut}>
						{t('subscriptions.tab_1.usage_sub.files_duration')}
					</Typography>
					<Typography variant={'body2'} color={ColorPalette.rockBlue}>
						{currentSubscription?.autoRemovePeriod.amount}{' '}
						{t(`shared_texts.${currentSubscription?.autoRemovePeriod.key}`)}
					</Typography>
				</Box>
			)}
		</Paper>
	);
};
