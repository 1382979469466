import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Typography } from '@mui/material';
import { ColorPalette } from 'shared/consts/colorPalette';
import { ActionsForDialog } from 'shared/ui/ActionsForDialog';
import { Paper } from 'shared/ui/Paper';

interface Props {
	title: string;
	description: string;
	open: boolean;
	handleClose: () => void;
	handleRemoveSet: () => void;
}

export const DeleteAssertionsModal: FC<Props> = ({
	title,
	description,
	open,
	handleClose,
	handleRemoveSet,
}) => {
	const { t } = useTranslation();

	return (
		<Dialog open={open} onClose={handleClose} PaperComponent={Paper} PaperProps={{ title }}>
			<Typography variant={'body1'} color={ColorPalette.baliHai} sx={{ margin: '24px 0 34px 0' }}>
				{t('assertions.modal.remove_description')}
			</Typography>
			<Typography variant={'body1'} color={ColorPalette.baliHai} sx={{ margin: '0px 0 34px 0' }}>
				{description}
			</Typography>
			<ActionsForDialog
				cancelLabel={t('shared_texts.cancel')}
				cancelAction={handleClose}
				applyLabel={t('shared_texts.yes')}
				applyAction={handleRemoveSet}
				applyActionDisabled={false}
			/>
		</Dialog>
	);
};
