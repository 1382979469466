import { Box, Typography, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { ColorPalette } from 'shared/consts/colorPalette';

export const Footer: FunctionComponent = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	return (
		<Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					marginTop: '30px',
				}}
			>
				<Typography variant={'body2'} color={ColorPalette.astronaut}>
					{t('auth.dont_have_an_acc')}
				</Typography>
				<Typography
					variant={'body2'}
					color={ColorPalette.royalBlue}
					onClick={() => navigate('/sign-up')}
					sx={{ cursor: 'pointer', marginLeft: '8px' }}
				>
					{t('auth.sign_up')}
				</Typography>
			</Box>
			<Typography
				variant={'body2'}
				color={ColorPalette.royalBlue}
				sx={{ cursor: 'pointer', marginTop: '10px' }}
			>
				<Link href="https://retica.ai/privacy-policy/">{t('auth.privacy_policy')}</Link>
			</Typography>
		</Box>
	);
};
