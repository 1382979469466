export enum STypesEnum {
	UNLIMITED = 'unlimited',
	FREE = 'free',
	STARTER = 'starter',
	PRO = 'pro',
	PAY_AS_YOU_GO = 'pay-as-you-go',
}

export enum SExportsEnum {
	CSV = 'csv',
	XML = 'xml',
}

export enum SubscriptionStatus {
	ACTIVE = 'active',
	CANCELED = 'canceled',
	INCOMPLETE = 'incomplete',
	INCOMPLETE_EXPIRED = 'incomplete_expired',
	PAST_DUE = 'past_due',
	PAUSED = 'paused',
	TRIALING = 'trialing',
	UNPAID = 'unpaid',
}
