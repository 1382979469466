import { FC, useState } from 'react';
import { Box } from '@mui/material';
import { ExtensionsEnum } from 'entities/Extensions/enums';
import { useExtension } from 'shared/hooks/useExtension';
import { ExCard } from 'widgets/Extensions/ExtensionsToSelect/ui/ExCard';
import { ExDrawer } from 'widgets/Extensions/ExtensionsToSelect/ui/ExDrawer';
import { ContactUsModal } from 'widgets/Extensions/ExtensionsToSelect/ui/ContactUsModal';

export const ExtensionsToSelectWidget: FC = () => {
	const [selectedExtension, setSelectedExtension] = useState<ExtensionsEnum>();
	const [openDrawer, setOpenDrawer] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const { extensionsToSelect } = useExtension();

	const openExtensionDescriptionHandler = (extension: ExtensionsEnum) => {
		setSelectedExtension(extension);
		setOpenDrawer(true);
	};

	return (
		<>
			<Box sx={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
				{extensionsToSelect.map((item) => (
					<ExCard
						key={item.extension}
						title={item.title}
						description={item.description}
						isSelected={item.isSelected}
						applyAction={() => openExtensionDescriptionHandler(item.extension)}
					/>
				))}
			</Box>
			{selectedExtension && (
				<ExDrawer
					open={openDrawer}
					handleClose={() => setOpenDrawer(false)}
					selectedExtension={selectedExtension}
					handleOpenContactUsModal={() => setOpenModal(true)}
				/>
			)}
			<ContactUsModal
				open={openModal}
				selectedExtension={selectedExtension}
				handleClose={() => setOpenModal(false)}
			/>
		</>
	);
};
