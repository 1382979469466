import { CSSProperties, FunctionComponent } from 'react';
import { ColorPaletteValues } from 'shared/consts/colorPalette';

interface Props {
	id: string;
	color?: ColorPaletteValues;
	style?: CSSProperties;
}

export const Icon: FunctionComponent<Props> = ({ id, ...props }) => {
	return (
		<svg
			className="icon"
			style={{ width: '24px', height: '24px', display: 'flex', color: props?.color, ...props?.style }}
		>
			<use xlinkHref={`#${id}`} />
		</svg>
	);
};
