import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { ColorPalette } from 'shared/consts/colorPalette';

export const CustomOutputFileContent: FC = () => {
	const { t } = useTranslation();
	return (
		<>
			<Typography variant={'h5'} color={ColorPalette.astronaut}>
				{t('extensions.ex_list.output_file.title')}
			</Typography>
			<Typography variant={'body2'} color={ColorPalette.baliHai}>
				{t('extensions.ex_list.output_file.drawer_description')}
			</Typography>
			<Typography variant={'body1'} color={ColorPalette.astronaut}>
				{t('extensions.ex_list.overview')}
			</Typography>
			<Typography variant={'body2'} color={ColorPalette.baliHai}>
				{t('extensions.ex_list.output_file.drawer_description_2')}
			</Typography>
			<Typography variant={'body1'} color={ColorPalette.astronaut}>
				{t('extensions.ex_list.instructions_for_use')}
			</Typography>
			<Typography variant={'body2'} color={ColorPalette.baliHai}>
				{t('extensions.ex_list.output_file.instructions_for_use_description')}
			</Typography>
		</>
	);
};
