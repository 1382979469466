import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuthStore } from 'processes/auth/model/state';
import { FunctionComponent, useState } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';
import { censorEmail } from 'shared/helpers/censorEmail';
import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import { FormContainer } from 'widgets/PersonalAccount/ui/Paper';
import { VerificationModal } from 'widgets/PersonalAccount/ui/tabs/Security/ui/ChangeEmail/ui/VerificationModal';

export const ChangeEmail: FunctionComponent = () => {
	const { t } = useTranslation();
	const { user } = useAuthStore();

	const [openModal, setOpenModal] = useState(false);
	const handleOpen = () => setOpenModal(true);
	const handleClose = () => setOpenModal(false);

	return (
		<>
			<VerificationModal open={openModal} handleClose={handleClose} />
			<Typography variant={'h5'} color={ColorPalette.astronaut}>
				{t('personal_account.tab_3.title_1')}
			</Typography>
			<FormContainer sx={{ marginTop: '34px', marginBottom: '36px' }}>
				<Input
					name={'email'}
					label={t('personal_account.tab_3.labels.current_email')}
					value={user?.email ? censorEmail(user.email) : ''}
					disabled
				/>
				<Button fullWidth onClick={handleOpen}>
					{t('shared_texts.change')}
				</Button>
			</FormContainer>
		</>
	);
};
