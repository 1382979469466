import { Box, Dialog, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Routes } from 'app/config/routes/consts';
import { getFtpSettings } from 'entities/Integrations/api';
import { enqueueSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { FtpSettingsTypeEnum } from 'entities/Integrations/enums';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Button } from 'shared/ui/Button';
import { CloseModalButton } from 'shared/ui/CloseModalButton';
import { Paper } from 'shared/ui/Paper';
import { CategoryCard } from 'widgets/startRecognition/ui/CategoryCard';
import { Category } from 'widgets/startRecognition/consts/CategoryList';
import { deleteCustomModel, getQueryCustomModel, patchCustomModel } from 'entities/CustomModel/api';
import { Icon } from 'shared/ui/Icon';
import { Input } from 'shared/ui/Input';
import { useCustomModelStore } from 'widgets/customModel/state';

interface Props {
	isActiveSessions: boolean;
	category: Category;
	handleClose: () => void;
}

export const DocsUploadModal: FC<Props> = ({ isActiveSessions, category, handleClose }) => {
	const { t } = useTranslation();
	const { setCurrentCustomModelId } = useCustomModelStore();
	const navigate = useNavigate();
	const { data: ftpSettingsData } = useQuery({
		queryKey: ['import-ftp-settings'],
		queryFn: () => getFtpSettings({ ftpSettingsType: FtpSettingsTypeEnum.generalImport }),
	});
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [models, setModels] = useState<any>([]);
	const [changeModelId, setChangeModelId] = useState<string>('');
	const categoryId = category.id;

	const [modelName, setModelName] = useState('');
	const [editModelId, setEditModelId] = useState<string>('');

	useEffect(() => {
		categoryId === 'custom' &&
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			getQueryCustomModel('TRAINED').then((res: any) => {
				setModels(res?.data);
			});
	}, []);

	const handleImportDirect = useCallback(() => {
		if (isActiveSessions) {
			return enqueueSnackbar({
				message: t('start_recognition.snackbar.success_import_direct'),
				variant: 'error',
			});
		}
		navigate(`${Routes.importFilesDirect}?category=${category.id}`);
		setCurrentCustomModelId(changeModelId);
		setChangeModelId('');
	}, [isActiveSessions, navigate, changeModelId]);

	const handleImportFromFtp = useCallback(
		(isCompany: boolean) => {
			if (isActiveSessions) {
				return enqueueSnackbar({
					message: t('start_recognition.snackbar.success_import_ftp'),
					variant: 'error',
				});
			}
			if (!ftpSettingsData?.data.host) {
				return enqueueSnackbar({
					message: t('start_recognition.snackbar.setup_ftp'),
					variant: 'error',
				});
			}
			navigate(`${Routes.importFilesFromFtp}?category=${category.id}&iscompany=${isCompany}`);
			setCurrentCustomModelId(changeModelId);
			setChangeModelId('');
		},
		[isActiveSessions, ftpSettingsData, navigate, changeModelId],
	);

	const handleCreateModel = useCallback(() => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const unikModelName = models?.find((model: any) => model.name === modelName);
		if (unikModelName) {
			return;
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		patchCustomModel({ name: modelName }, editModelId).then((res: any) => {
			if (res.data) {
				setModelName('');
				setEditModelId('');
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				getQueryCustomModel('TRAINED').then((res: any) => {
					setModels(res?.data);
				});
			}
		});
	}, [modelName]);

	const handleModelNameChange = (name: string) => {
		setModelName(name);
	};

	const handleEditModel = (modelId: string) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const model = models?.find((model: any) => model._id === modelId);
		if (model) {
			setModelName(model.name);
		}
		setEditModelId(modelId);
	};

	const handleDeleteModel = (modelId: string) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		deleteCustomModel(modelId).then(() => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			getQueryCustomModel('TRAINED').then((res: any) => {
				setModels(res?.data);
			});
		});
	};

	return (
		<>
			{(!(categoryId === 'custom') || changeModelId) && (
				<Dialog
					open={true}
					onClose={handleClose}
					PaperComponent={Paper}
					PaperProps={{ title: t('start_recognition.upload_modal.recognition_title') as string }}
					sx={{
						'& .MuiPaper-root': {
							width: '506px',
							maxWidth: '506px',
							overflow: 'inherit',
						},
					}}
				>
					<CloseModalButton action={handleClose} />
					<CategoryCard category={category} />
					<Typography variant={'body1'} color={ColorPalette.baliHai} sx={{ mt: '34px' }}>
						{t('start_recognition.upload_modal.chose_import_variant')}
					</Typography>
					<Button fullWidth sx={{ mt: '24px' }} onClick={handleImportDirect}>
						{t('start_recognition.upload_files')}
					</Button>
					{ftpSettingsData?.data.host && (
						<Button fullWidth sx={{ mt: '24px' }} onClick={() => handleImportFromFtp(false)}>
							{t('start_recognition.upload_modal.import_from_ftp')}
						</Button>
					)}
				</Dialog>
			)}
			{categoryId === 'custom' && !changeModelId && (
				<Dialog
					open={true}
					onClose={handleClose}
					PaperComponent={Paper}
					PaperProps={{ title: t('custom_model.your_custom_models') as string }}
					sx={{
						'& .MuiPaper-root': {
							width: '625px',
							maxWidth: '625px',
							maxHeight: '530px',
							overflowY: 'auto',
							overflow: 'inherit',
						},
						'& .MuiTypography-root': {
							fontSize: '24px',
						},
					}}
				>
					<CloseModalButton action={handleClose} />
					<Box
						sx={{
							maxHeight: '360px',
							overflowY: 'auto',
							overflowX: 'hidden',
						}}
					>
						{models?.length > 0 &&
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							models.map((model: any) => {
								return (
									<Box key={model._id}>
										<Box
											sx={{
												width: '100%',
												height: '82px',
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
												justifyContent: 'space-between',
											}}
										>
											<Box
												sx={{
													cursor: 'pointer',
													marginLeft: '12px',
												}}
												onClick={() => {
													setChangeModelId(model._id);
												}}
											>
												{model.name}
											</Box>

											<Box
												sx={{
													display: 'flex',
												}}
											>
												<Box onClick={() => handleEditModel(model._id)}>
													<Icon
														id={'choose_subcategory'}
														style={{
															cursor: 'pointer',
														}}
													/>
												</Box>
												<Box
													onClick={() => {
														handleDeleteModel(model._id);
													}}
												>
													<Icon
														id={'trush_icon_red'}
														style={{
															marginLeft: '16px',
															cursor: 'pointer',
														}}
													/>
												</Box>
											</Box>
										</Box>
										<Box
											sx={{
												width: '545px',
												height: '1px',
												background: ColorPalette.periwinkle,
											}}
										/>
									</Box>
								);
							})}
					</Box>
				</Dialog>
			)}
			<Dialog
				open={!!editModelId}
				onClose={() => setEditModelId('')}
				PaperComponent={Paper}
				PaperProps={{ title: t('custom_model.create_edit_modal.edit_title') as string }}
				sx={{
					'& .MuiPaper-root': {
						width: '506px',
						maxWidth: '506px',
						overflow: 'inherit',
					},
				}}
			>
				<CloseModalButton action={() => setEditModelId('')} />
				<CategoryCard category={category} />

				<Box sx={{ mt: '24px', mb: '0' }}>
					<Input
						name="modelName"
						label={t('custom_model.create_edit_modal.label')}
						placeholder={t('custom_model.create_edit_modal.placeholder') as string}
						type="text"
						required
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						onChange={(event: any) => {
							handleModelNameChange(event?.target?.value);
						}}
						value={modelName}
					/>
				</Box>

				<Button fullWidth sx={{ mt: '-10px' }} onClick={handleCreateModel}>
					{t('custom_model.create_edit_modal.edit_title')}
				</Button>
			</Dialog>
		</>
	);
};
