import { FC, useState, useMemo } from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Typography, Box, styled, Chip } from '@mui/material';
import { ColorPalette } from 'shared/consts/colorPalette';
import { GetAdminInvoisesFiltersType } from 'entities/admin/Users/api/types';
import { BillingTable } from 'widgets/admin/UserDetails/ui/tabs/Billing/BillingTable';
import { UsersFilter } from 'widgets/admin/UserDetails/ui/tabs/Billing/UsersFilter';
import { useUserDetailsInfoStore } from 'widgets/admin/UserDetails/state';
import { getUserInvoice } from 'entities/admin/Users/api';
import { UserForAdmin } from 'entities/admin/Users/types';
import { STypesEnum } from 'entities/Subscriptions/enums';

interface Props {
	user: UserForAdmin | null;
}

const Billing: FC<Props> = ({ user }) => {
	const { t } = useTranslation();
	const { userId } = useParams();
	const { selectedUser } = useUserDetailsInfoStore();
	const [filters, setFilters] = useState<GetAdminInvoisesFiltersType>({
		sort: 'desc',
	});

	const mappedFilters = useMemo(() => filters, [filters]);

	const isSubscriptionUnlimited = useMemo(
		() => selectedUser?.subscription?.subscriptionType === STypesEnum.UNLIMITED,
		[],
	);

	const { data: response } = useQuery({
		queryKey: [`get/admin/users/invoices`, filters],
		queryFn: () => getUserInvoice({ userId: userId || '', ...filters }),
	});
	return (
		<>
			<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<Typography variant={'h5'} color={ColorPalette.astronaut}>
					{t('admin.users.details.tabs.tab_3')}
				</Typography>
				<UsersFilter filters={filters} setFilters={setFilters} />
			</Box>
			<Box sx={{ display: 'flex', gap: '14px', marginBottom: '20px' }}>
				{mappedFilters?.billingAtFrom && mappedFilters?.billingAtTo && (
					<Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
						<Typography>{t('admin.users.labels.user_billing_period')}: </Typography>
						<ChipStyled
							label={`${format(new Date(mappedFilters?.billingAtFrom), 'dd/MM/yyyy')}
									- ${format(new Date(mappedFilters?.billingAtTo), 'dd/MM/yyyy')}`}
							onDelete={() => {
								setFilters({
									...mappedFilters,
									billingAtFrom: undefined,
									billingAtTo: undefined,
								});
							}}
						/>
					</Box>
				)}
				{mappedFilters?.paymentAtFrom && mappedFilters?.paymentAtTo && (
					<Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
						<Typography>{t('admin.users.labels.user_payment_period')}: </Typography>
						<ChipStyled
							label={`${format(new Date(mappedFilters?.paymentAtFrom), 'dd/MM/yyyy')}
								 - ${format(new Date(mappedFilters?.paymentAtTo), 'dd/MM/yyyy')}`}
							onDelete={() => {
								setFilters({
									...filters,
									paymentAtFrom: undefined,
									paymentAtTo: undefined,
								});
							}}
						/>
					</Box>
				)}
				{mappedFilters?.isPaid && (
					<Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
						<Typography>{t('shared_texts.table_columns.status')}: </Typography>
						<ChipStyled
							label={`${
								(mappedFilters?.isPaid as unknown as string) === 'true'
									? t('shared_texts.paid')
									: t('shared_texts.unpaid')
							}`}
							onDelete={() => {
								setFilters({ ...mappedFilters, isPaid: undefined });
							}}
						/>
					</Box>
				)}
				{mappedFilters?.subscriptionType && mappedFilters?.subscriptionType?.length > 0 && (
					<Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
						<Typography>{t('subscriptions.title')}: </Typography>
						<ChipStyled
							label={`${mappedFilters?.subscriptionType}`}
							onDelete={() => {
								setFilters({ ...mappedFilters, subscriptionType: undefined });
							}}
						/>
					</Box>
				)}
			</Box>
			{response && (
				<BillingTable
					billing={response?.data}
					user={user}
					isSubscriptionUnlimited={isSubscriptionUnlimited}
				/>
			)}
		</>
	);
};

export default Billing;

const ChipStyled = styled(Chip)`
	background: #908ffa;
	padding: 0 8px 0 16px;
	height: 45px;
	border-radius: 22.5px;
	color: #fff;
	margin-right: 20px;

	box-shadow: 4.65909px 4.65909px 18.63636px 0 #babecc, -7.45455px -7.45455px 9.31818px 0px #fff;

	.MuiChip-deleteIcon {
		color: #fff;
	}

	.MuiChip-deleteIcon:hover {
		color: rgba(255, 255, 255, 0.7);
	}
`;
