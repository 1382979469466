import { IconButton, IconButtonProps } from '@mui/material';
import { FunctionComponent } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Shadow } from 'shared/consts/shadow';
import { Icon } from 'shared/ui/Icon';

interface Props extends IconButtonProps {
	action: () => void;
}

export const BackButton: FunctionComponent<Props> = ({ sx, action }) => {
	return (
		<IconButton
			sx={{
				'width': '20px',
				'height': '20px',
				'padding': 0,
				'position': 'fixed',
				'left': '15px',
				'top': '20px',
				'boxSizing': 'content-box',
				'border': `5px solid ${ColorPalette.catskillWhite}`,
				'backgroundColor': ColorPalette.royalBlue,
				'boxShadow': Shadow['1'],
				'&:hover': {
					backgroundColor: ColorPalette.royalBlue,
				},
				...sx,
			}}
			onClick={() => action()}
		>
			<Icon
				id={'custom_arrow_down'}
				style={{
					width: '18px',
					height: '9px',
					color: ColorPalette.white,
					transform: 'rotate(90deg) translateY(0.5px)',
				}}
			/>
		</IconButton>
	);
};
