import { FC, useCallback, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { getPartners, deletePartnes } from 'entities/Partners/api';
import { GeneralPartnerType } from 'entities/Partners/types';
import { PartnersEnum } from 'entities/Partners/enums';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { PartnerFilterAndSearch } from 'widgets/Partners/ui/FiltersAndSearch';
import { SelectCreationModal } from 'widgets/Partners/ui/Modals/create/SelectCreationModal';
import { DeletePartnerModal } from 'widgets/Partners/ui/Modals/RemovePartnerModal';
import { ManualModal } from 'widgets/Partners/ui/Modals/create/ManualModal';
import { ImportCsvModal } from 'widgets/Partners/ui/Modals/create/ImportCsvModal';
import { PartnersTable } from 'widgets/Partners/ui/PartnerTable';
import { AfterCsvCreationModal } from 'shared/ui/AfterCsvCreationModal';
import { Loader } from 'shared/ui/Loader';
import { usePartnersStore } from './state';

export const PartnersWidget: FC = () => {
	const queryClient = useQueryClient();
	const { t } = useTranslation();
	const { setPartnersData } = usePartnersStore();
	const [isOpenSelectCreationModal, setIsOpenSelectCreationModal] = useState(false);
	const [isManualModalOpen, setIsManualModalOpen] = useState(false);
	const [isImportCsvModalOpen, setIsImportCsvModalOpen] = useState(false);
	const [searchParam, setSearchParam] = useState('');
	const [activeTab, setActiveTab] = useState(PartnersEnum.default);
	const [selectedPartnerIds, setSelectedPartnerIds] = useState<string[]>([]);
	const [partnersToDelete, setPartnersToDelete] = useState<string[]>([]);
	const [partnerToEdit, setPartnerToEdit] = useState<GeneralPartnerType>();
	const [page, setTablePage] = useState(1);
	const [isOpenInfoModal, setIsOpenInfoModal] = useState(false);

	const { data, isLoading: getIsLoading } = useQuery({
		queryKey: ['get/partners', activeTab, searchParam, page],
		queryFn: () =>
			getPartners({
				type: activeTab || null,
				search: searchParam || null,
				page,
				perPage: 20,
			}),
		onSuccess: (response) => {
			setPartnersData(response.data);
		},
		onError: (err) => {
			const errorMessage = getErrorMessage(err);
			enqueueSnackbar({ message: errorMessage, variant: 'error' });
		},
	});

	const { mutate: deletePartnerMutate, isLoading: deleteIsLoading } = useMutation({
		mutationKey: ['delete/partner'],
		mutationFn: deletePartnes,
	});

	const onDeletePartnersHandler = () => {
		deletePartnerMutate(
			{ ids: partnersToDelete },
			{
				onSuccess: async () => {
					await queryClient.refetchQueries({ queryKey: ['get/partners'] });
					enqueueSnackbar({
						message: t('partners.snackbar.partner_delete_succsess'),
						variant: 'success',
					});
					setSelectedPartnerIds([]);
					setPartnersToDelete([]);
				},
				onError: (err) => {
					const errorMessage = getErrorMessage(err);
					enqueueSnackbar({ message: errorMessage, variant: 'error' });
					setIsManualModalOpen(false);
				},
			},
		);
	};

	const tableDeleteHandler = useCallback(
		(partnerId?: string) => {
			if (!partnerId && !selectedPartnerIds.length) {
				enqueueSnackbar({
					message: t('partners.snackbar.no_selected_partners'),
					variant: 'error',
				});
			} else {
				setPartnersToDelete(partnerId ? [partnerId] : selectedPartnerIds);
			}
		},
		[selectedPartnerIds],
	);

	const onOpenManualModalHandler = () => {
		setIsOpenSelectCreationModal(false);
		setIsManualModalOpen(true);
	};

	const onOpenImportCsvModalHandler = () => {
		setIsOpenSelectCreationModal(false);
		setIsImportCsvModalOpen(true);
	};

	const onManualModalCloseHandler = () => {
		setIsManualModalOpen(false);
		setPartnerToEdit(undefined);
	};

	const tableEditHandler = (partnerId: string) => {
		if (data?.data.results) {
			const selectedPartnerToEdit = data.data.results.find((item) => item._id === partnerId);
			setPartnerToEdit(selectedPartnerToEdit);
			setIsManualModalOpen(true);
		}
	};

	const tableTogglePartnerHandler = useCallback((partnerId: string) => {
		setSelectedPartnerIds((prevState) =>
			prevState.includes(partnerId)
				? prevState.filter((i) => i !== partnerId)
				: [...prevState, partnerId],
		);
	}, []);

	const tableToggleAllPartnersHandler = useCallback(() => {
		if (selectedPartnerIds?.length) {
			setSelectedPartnerIds([]);
		} else {
			setSelectedPartnerIds(data?.data.results.map((partner) => partner._id) || []);
		}
	}, [data?.data, selectedPartnerIds]);

	return (
		<>
			<PartnerFilterAndSearch
				activeTab={activeTab}
				setTab={setActiveTab}
				setSearch={setSearchParam}
				setTablePage={() => setTablePage(1)}
				createAction={() => setIsOpenSelectCreationModal(true)}
			/>
			<DeletePartnerModal
				open={!!partnersToDelete.length}
				handleClose={() => setPartnersToDelete([])}
				handleRemove={onDeletePartnersHandler}
			/>
			<SelectCreationModal
				open={isOpenSelectCreationModal}
				handleClose={() => setIsOpenSelectCreationModal(false)}
				openManualModal={onOpenManualModalHandler}
				openImportCsvModal={onOpenImportCsvModalHandler}
			/>
			<ManualModal
				partnerToEdit={partnerToEdit}
				open={isManualModalOpen}
				handleClose={onManualModalCloseHandler}
			/>
			<ImportCsvModal
				open={isImportCsvModalOpen}
				handleClose={() => setIsImportCsvModalOpen(false)}
				setIsOpenInfoModal={() => setIsOpenInfoModal(true)}
			/>
			<AfterCsvCreationModal open={isOpenInfoModal} handleClose={() => setIsOpenInfoModal(false)} />

			{getIsLoading || deleteIsLoading ? (
				<Loader type="mid" />
			) : (
				<>
					<PartnersTable
						data={data?.data}
						selectedPartnerIds={selectedPartnerIds}
						editHandler={tableEditHandler}
						deleteHandler={tableDeleteHandler}
						togglePartner={tableTogglePartnerHandler}
						toggleAllPartners={tableToggleAllPartnersHandler}
						onPageChange={(page) => setTablePage(page)}
					/>
				</>
			)}
		</>
	);
};
