import { Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';

interface Props {
	children: string;
}

export const GuideListItem: FunctionComponent<Props> = ({ children }) => {
	return (
		<Typography
			variant={'body2'}
			color={ColorPalette.astronaut}
			sx={{
				'my': '8px',
				'ml': '129px',
				'position': 'relative',
				'&:before': {
					content: '""',
					display: 'block',
					position: 'absolute',
					top: '0',
					left: '-20px',
					transform: 'translateY(50%)',
					width: '8px',
					height: '8px',
					borderRadius: '50%',
					backgroundColor: ColorPalette.royalBlue,
				},
				'& > b': {
					color: ColorPalette.ebonyClay,
				},
			}}
		>
			{children}
		</Typography>
	);
};
