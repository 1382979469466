import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { postDirectImport } from 'entities/ImportSessions/api';
import { directCustomModelImport } from 'entities/CustomModel/api';
import { CategoryTypeEnum } from 'entities/Files/types';
import { enqueueSnackbar } from 'notistack';
import { useRef, useState } from 'react';
import { FC } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Shadow } from 'shared/consts/shadow';
import uploadFiles from 'shared/assets/upload-files.png';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { Loader } from 'shared/ui/Loader';
interface Props {
	categoryType: CategoryTypeEnum;
	modelId?: string;
	directImport?: boolean;
}

export const DnDImportFiles: FC<Props> = ({ categoryType, modelId, directImport = false }) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const { mutate: directMutate, isLoading: directLoading } = useMutation({
		mutationKey: ['direct-import-files'],
		mutationFn: postDirectImport,
	});

	const { mutate: customDirectMutate, isLoading: customDirectLoading } = useMutation({
		mutationKey: ['custom-direct-import-files'],
		mutationFn: directCustomModelImport,
	});

	const inputRef = useRef<HTMLInputElement>(null);
	const [drag, setDrag] = useState<boolean>(false);

	const standardFilesUploadHandler = (files: Array<File>) => {
		const formData = new FormData();
		formData.append('category', categoryType);
		if (modelId) {
			formData.append('customModelId', modelId || '');
		}
		Object.values(files).forEach((file) => {
			formData.append('files', file);
		});
		directMutate(formData, {
			onSuccess: () => {
				queryClient.refetchQueries({
					queryKey: ['get/import-sessions'],
				});
			},
			onError: (err) => {
				const defaultErrorMessage = t('files.snackbar.error_while_uploading');
				const errorMessage = getErrorMessage(err, defaultErrorMessage);
				enqueueSnackbar({
					message: errorMessage,
					variant: 'error',
				});
			},
		});
	};

	const customModelUploadHandler = (files: Array<File>) => {
		const formData = new FormData();
		formData.append('category', categoryType);
		formData.append('customModelId', modelId || '');
		Object.values(files).forEach((file) => {
			formData.append('files', file);
		});
		customDirectMutate(
			{
				id: modelId,
				data: formData,
			},
			{
				onSuccess: () => {
					queryClient.refetchQueries({
						queryKey: ['get/custom-models/files'],
					});
				},
				onError: (err) => {
					const defaultErrorMessage = t('files.snackbar.error_while_uploading');
					const errorMessage = getErrorMessage(err, defaultErrorMessage);
					enqueueSnackbar({
						message: errorMessage,
						variant: 'error',
					});
				},
			},
		);
	};
	const handleDirectImport = (files: Array<File>) => {
		if (categoryType === CategoryTypeEnum.custom && !directImport) {
			customModelUploadHandler(files);
		} else {
			standardFilesUploadHandler(files);
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const dragStartHandler = (e: any) => {
		e.preventDefault();
		if (directLoading || customDirectLoading) return;
		setDrag(true);
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const dragLeaveHandler = (e: any) => {
		e.preventDefault();
		if (directLoading || customDirectLoading) return;
		setDrag(false);
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const dropHandler = (e: any) => {
		e.preventDefault();
		if (directLoading || customDirectLoading) return;
		const files = [...e.dataTransfer.files];
		handleDirectImport(files);

		setDrag(false);
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const changeHandler = (e: any) => {
		e.preventDefault();
		if (directLoading || customDirectLoading) return;
		const files = [...e.target.files];
		handleDirectImport(files);

		setDrag(false);
	};

	return (
		<Box
			sx={{
				height: '383px',
				width: '100%',
				padding: '40px',
				backgroundColor: ColorPalette.white,
				boxShadow: Shadow['2'],
				borderRadius: '20px',
			}}
		>
			<Box
				onDragStart={(e) => dragStartHandler(e)}
				onDragLeave={(e) => dragLeaveHandler(e)}
				onDragOver={(e) => dragStartHandler(e)}
				onDrop={(e) => dropHandler(e)}
				sx={{
					height: '100%',
					width: '100%',
					backgroundColor: drag ? ColorPalette.periwinkle : ColorPalette.catskillWhite,
					border: `1px solid ${ColorPalette.periwinkle}`,
					borderRadius: '20px',
					transition: 'all .3s',
				}}
			>
				<Box
					sx={{
						height: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					{directLoading || customDirectLoading ? (
						<Loader type="min" />
					) : (
						<>
							<img
								src={uploadFiles}
								alt={'upload-files'}
								style={{ width: '165px', height: '165px' }}
							/>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
								}}
							>
								<input
									hidden
									multiple
									type={'file'}
									ref={inputRef}
									onChange={(e) => changeHandler(e)}
								/>
								<Typography variant={'h5'} color={ColorPalette.astronaut}>
									{t('start_recognition.upload_files')}
								</Typography>
								<Typography
									variant={'body1'}
									component={'span'}
									color={ColorPalette.astronaut}
									sx={{ my: '24px' }}
								>
									{t('start_recognition.upload_files_section.drag_n_drop')}{' '}
									<Typography
										onClick={() => inputRef?.current?.click()}
										variant={'body1'}
										component={'span'}
										color={ColorPalette.royalBlue}
										sx={{ textDecoration: 'underline', cursor: 'pointer' }}
									>
										{t('start_recognition.upload_files_section.drag_n_drop_link')}
									</Typography>{' '}
								</Typography>
								<Typography
									variant={'caption'}
									color={ColorPalette.astronaut}
									sx={{ mb: '8px' }}
								>
									{t('start_recognition.how_to_start.step_2.li_1')}
								</Typography>
							</Box>
						</>
					)}
				</Box>
			</Box>
		</Box>
	);
};
