import { InputLabel as MUIInputLabel, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';

interface Props {
	name: string;
	label: string;
	required?: boolean;
}

export const InputLabel: FunctionComponent<Props> = ({ name, label, required }) => {
	return (
		<MUIInputLabel
			shrink
			htmlFor={name}
			sx={{
				'&.MuiInputLabel-root': {
					display: 'inline-flex',
				},
			}}
		>
			<Typography variant={'body2'} color={ColorPalette.astronaut}>
				{label}
			</Typography>
			{required && (
				<Typography variant={'body2'} color={ColorPalette.royalBlue}>
					*
				</Typography>
			)}
		</MUIInputLabel>
	);
};
