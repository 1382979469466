import {
	GetSubscriptionRequest,
	CreateSubscriptionRequest,
	GetSubscriptionInvoicesRequest,
	GetSubscriptionInvoicesExportRequest,
	GetSubscriptionPaymentMethodRequest,
	CreateSubscriptionPaymentMethodRequest,
	RetrySubscriptionPaymentMethodRequest,
	DeleteSubscriptionRequest,
} from 'entities/Subscriptions/api/types';
import { axiosInstance } from 'shared/config/axios';

export const getSubscription: GetSubscriptionRequest = () => {
	return axiosInstance.get('/subscriptions');
};

export const createSubscription: CreateSubscriptionRequest = (data) => {
	return axiosInstance.post('/subscriptions', data);
};

export const getSubscriptionInvoices: GetSubscriptionInvoicesRequest = () => {
	return axiosInstance.get('/subscriptions/invoices');
};

export const getSubscriptionInvoicesExport: GetSubscriptionInvoicesExportRequest = ({ id, ...rest }) => {
	return axiosInstance.get(`/subscriptions/invoices/export/${id}`, {
		params: {
			...rest,
		},
		responseType: 'blob',
	});
};

export const getSubscriptionPaymentMethod: GetSubscriptionPaymentMethodRequest = () => {
	return axiosInstance.get('/subscriptions/payment-methods');
};

export const createSubscriptionPaymentMethod: CreateSubscriptionPaymentMethodRequest = (data) => {
	return axiosInstance.put('/subscriptions/payment-methods', data);
};

export const retrySubscriptionPayment: RetrySubscriptionPaymentMethodRequest = () => {
	return axiosInstance.post('/subscriptions/retry-payment');
};

export const deleteSubscription: DeleteSubscriptionRequest = () => {
	return axiosInstance.delete('/subscriptions');
};

export const getSubscriptionInvoicesExportAdmin: GetSubscriptionInvoicesExportRequest = ({ id, ...rest }) => {
	return axiosInstance.get(`/admin/invoices/export/${id}`, {
		params: {
			...rest,
		},
		responseType: 'blob',
	});
};
