import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { ColorPalette } from 'shared/consts/colorPalette';
import { ListItems } from 'shared/ui/ListItems';

export const AssertionsContent: FC = () => {
	const { t } = useTranslation();
	return (
		<>
			<Typography variant={'h5'} color={ColorPalette.astronaut}>
				{t('extensions.ex_list.assertions_sets.title')}
			</Typography>
			<Typography variant={'body2'} color={ColorPalette.baliHai}>
				{t('extensions.ex_list.assertions_sets.description')}
			</Typography>
			<Typography variant={'body1'} color={ColorPalette.astronaut}>
				{t('extensions.ex_list.overview')}
			</Typography>
			<Typography variant={'body2'} color={ColorPalette.baliHai}>
				{t('extensions.ex_list.assertions_sets.drawer_description')}
			</Typography>
			<Typography variant={'body1'} color={ColorPalette.astronaut}>
				{t('extensions.ex_list.use_cases')}
			</Typography>
			<ListItems
				list={[
					'extensions.ex_list.assertions_sets.use_cases_li_1',
					'extensions.ex_list.assertions_sets.use_cases_li_2',
					'extensions.ex_list.assertions_sets.use_cases_li_3',
				]}
			/>
			<Typography variant={'body1'} color={ColorPalette.astronaut}>
				{t('extensions.ex_list.instructions_for_use')}
			</Typography>
			<Typography variant={'body2'} color={ColorPalette.baliHai}>
				{t('extensions.ex_list.assertions_sets.instructions_for_use_description_1')}
			</Typography>
			<Typography variant={'body2'} color={ColorPalette.baliHai}>
				{t('extensions.ex_list.assertions_sets.instructions_for_use_description_2')}
			</Typography>
		</>
	);
};
