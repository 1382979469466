import { Box } from '@mui/material';
import { Checkbox } from 'shared/ui/Checkbox';
import { OptionsDropdown } from 'shared/ui/OptionsDropdown';
import { Column } from 'shared/ui/tables/_common/types';
import { TableTranslationLabel } from 'shared/ui/tables/_common/ui/TableTranslationLabel';
import { AssertionsType } from 'entities/Assertions/types';

type AssertionsTableColumnsType = AssertionsType & {
	assertionsCount: number;
	selectedAssertions: Array<string>;
	toggleAllAssertions: () => void;
	toggleAssertion: (fileId: string) => void;
	edit: (id: string) => void;
	delete: (id?: string) => void;
};

export const AssertionsTableColumns: Array<Column<AssertionsTableColumnsType>> = [
	{
		field: 'select',
		commonCellSettings: {
			style: {
				width: '70px',
			},
		},
		headerCellSettings: {
			renderCell: (assertion) => {
				return (
					<Checkbox
						checked={assertion?.assertionsCount === assertion?.selectedAssertions.length}
						onChange={() => assertion?.toggleAllAssertions()}
					/>
				);
			},
		},
		bodyCellSettings: {
			renderCell: (assertion) => {
				return (
					<Checkbox
						checked={assertion?.selectedAssertions.includes(assertion?._id)}
						onChange={() => assertion?._id && assertion.toggleAssertion(assertion?._id)}
					/>
				);
			},
		},
	},
	{
		field: 'icon',
		commonCellSettings: {
			style: {
				width: '65px',
				minWidth: '50px',
			},
		},
		headerCellSettings: {
			renderCell: (assertions) => {
				return (
					<OptionsDropdown
						iconId={'more_horizontal_small'}
						optionList={[
							{
								label: 'shared_texts.table_dropdown.remove',
								value: 'Remove all assertions',
								action: () => {
									assertions?._id && assertions.delete();
								},
							},
						]}
						forTableHeader={true}
					/>
				);
			},
		},
	},
	{
		field: 'initial_value',
		commonCellSettings: {
			style: { width: '950px', minWidth: '250px' },
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="initial_value" />,
		},
		bodyCellSettings: {
			prepareField: (assertions) => assertions?.initialVal ?? '-',
		},
	},
	{
		field: 'replaced_value',
		commonCellSettings: {
			style: { width: '950px', minWidth: '250px' },
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="replaced_value" />,
		},
		bodyCellSettings: {
			prepareField: (assertions) => assertions?.replaceVal ?? '-',
		},
	},
	{
		field: 'options',
		commonCellSettings: {
			style: {
				minWidth: '100px',
				maxWidth: '100px',
			},
		},
		bodyCellSettings: {
			renderCell: (assertion) => {
				return (
					<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
						<OptionsDropdown
							iconId={'more_horizontal'}
							optionList={[
								{
									label: 'shared_texts.table_dropdown.edit',
									value: 'Edit assertion',
									action: () => {
										assertion?.edit(assertion._id);
									},
								},
								{
									label: 'shared_texts.table_dropdown.remove',
									value: 'Remove assertion',
									action: () => {
										assertion?.delete(assertion._id);
									},
								},
							]}
						/>
					</Box>
				);
			},
		},
	},
];
