import { FC } from 'react';
import { Box, PaginationItem, Stack, styled } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { GetUsersReq } from '../../../../entities/admin/Users/api/types';

const IconPrev = () => {
	return (
		<BoxStyled>
			<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
				<rect x="30" width="30" height="30" rx="15" transform="rotate(90 30 0)" fill="#F4F7FA" />
				<circle cx="15" cy="15" r="10" fill="#4779E6" />
				<path
					d="M16 19L12 15.0003L16 11"
					stroke="white"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</BoxStyled>
	);
};

const IconNext = () => {
	return (
		<BoxStyled>
			<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
				<rect y="30" width="30" height="30" rx="15" transform="rotate(-90 0 30)" fill="#F4F7FA" />
				<circle cx="15" cy="15" r="10" transform="rotate(180 15 15)" fill="#4779E6" />
				<path
					d="M13 11L17 14.9997L13 19"
					stroke="white"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		</BoxStyled>
	);
};

const BoxStyled = styled(Box)({
	padding: 0,
	height: '30px!important',
	borderRadius: '50%',
	background: 'white',
	boxShadow: '4.65909px 4.65909px 18.63636px 0px #BABECC, -7.45455px -7.45455px 9.31818px 0px #FFF',
});

type Props = {
	totalPages: number;
	setFilters: (filters: GetUsersReq) => void;
	filters: GetUsersReq;
};

const UserPagination: FC<Props> = ({ setFilters, totalPages, filters }) => {
	return (
		<>
			<Box
				sx={{
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					mt: 3,
				}}
			>
				<Stack spacing={2}>
					<Pagination
						sx={{
							'.MuiPaginationItem-root:hover': {
								backgroundColor: 'unset!important',
							},
							'& .MuiPaginationItem-root': {
								'&.Mui-selected': {
									backgroundColor: 'unset',
									fontSize: '16px',
									fontWeight: 700,
								},
							},
						}}
						onChange={(e, value) => {
							setFilters({ ...filters, page: value });
						}}
						count={totalPages || 10}
						renderItem={(item) => (
							<PaginationItem
								sx={{
									color: '#2B3674',
									padding: '0 1px',
									minWidth: 'unset',
									height: 'unset',
									lineHeight: 'unset',
								}}
								components={{ previous: IconPrev, next: IconNext }}
								{...item}
							/>
						)}
					/>
				</Stack>
			</Box>
		</>
	);
};

export default UserPagination;
