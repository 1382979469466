import { FC, useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Dialog, Typography, Box } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { postWarehouseItems, putWarehouseItems } from 'entities/Warehouse/api';
import { WarehouseItemType } from 'entities/Warehouse/types';
import { SelectOption } from 'shared/ui/Select/types';
import { InputField } from 'shared/ui/InputField';
import { ColorPalette } from 'shared/consts/colorPalette';
import { FormSelect } from 'shared/ui/Select/FormSelect';
import { Paper } from 'shared/ui/Paper';
import { Loader } from 'shared/ui/Loader';
import { Button } from 'shared/ui/Button';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { WarehouseValidationSchema } from 'widgets/Extensions/Details/ui/Warehouse/consts/validation';
import { WarehouseFormType } from 'widgets/Extensions/Details/ui/Warehouse/types';
import { usePartnersStore } from 'widgets/Partners/state';

interface ManualModalPropsType {
	articleToEdit?: WarehouseItemType;
	open: boolean;
	handleClose: () => void;
}

export const ManualModal: FC<ManualModalPropsType> = ({ articleToEdit, open, handleClose }) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const { partners } = usePartnersStore();

	const selectOptions: SelectOption[] = useMemo(
		() =>
			partners?.results
				? partners.results.map((item) => ({
						value: item._id,
						label: item.name,
						customLabel: (
							<Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
								<Typography>{item.name}</Typography>
								<Typography variant="body2" color={ColorPalette.baliHai}>
									{item.type}
								</Typography>
							</Box>
						),
				  }))
				: [],
		[partners],
	);

	const { mutate: putWarehouseItemsMutate, isLoading: patchIsLoading } = useMutation({
		mutationKey: ['put/warehouse-items'],
		mutationFn: putWarehouseItems,
	});

	const { mutate: postWarehouseItemsMutate, isLoading: postIsLoading } = useMutation({
		mutationKey: ['post/warehouse-items'],
		mutationFn: postWarehouseItems,
	});

	const onCreateWarehouseItemsHandler = (data: WarehouseFormType) => {
		postWarehouseItemsMutate(
			{ data },
			{
				onSuccess: async () => {
					await queryClient.refetchQueries({ queryKey: ['get/warehouse-items'] });
					enqueueSnackbar({
						message: t('extensions.snackbar.success_warehouse_creation'),
						variant: 'success',
					});
					handleClose();
				},
				onError: (err) => {
					const errorMessage = getErrorMessage(err);
					enqueueSnackbar({ message: errorMessage, variant: 'error' });
				},
			},
		);
	};

	const onUpdateWarehouseItemsHandler = (warehouseItemId: string, data: WarehouseFormType) => {
		putWarehouseItemsMutate(
			{ warehouseItemId, data },
			{
				onSuccess: async () => {
					await queryClient.refetchQueries({ queryKey: ['get/warehouse-items'] });
					enqueueSnackbar({
						message: t('extensions.snackbar.success_warehouse_updating'),
						variant: 'success',
					});
					handleClose();
				},
				onError: (err) => {
					const errorMessage = getErrorMessage(err);
					enqueueSnackbar({ message: errorMessage, variant: 'error' });
				},
			},
		);
	};

	const handleManualModalSubmit = (values: WarehouseFormType) => {
		if (articleToEdit) {
			onUpdateWarehouseItemsHandler(articleToEdit._id, values);
		} else {
			onCreateWarehouseItemsHandler(values);
		}
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			PaperComponent={Paper}
			PaperProps={{
				title: (articleToEdit ? t('shared_texts.edit') : t('shared_texts.add')) as string,
			}}
		>
			{!articleToEdit && (
				<Typography variant={'body1'} color={ColorPalette.baliHai} sx={{ marginBottom: '34px' }}>
					{t('extensions.modals.warehouse_manual_creation_description')}
				</Typography>
			)}
			{patchIsLoading || postIsLoading ? (
				<Loader />
			) : (
				<Formik
					initialValues={{
						code: articleToEdit?.code || '',
						description: articleToEdit?.description || '',
						partnerId: articleToEdit?.partnerId || null,
					}}
					validationSchema={WarehouseValidationSchema}
					onSubmit={handleManualModalSubmit}
				>
					{({ values, setFieldValue }) => (
						<Form>
							<InputField
								name={'code'}
								label={t('extensions.labels.article_number')}
								placeholder={t('extensions.placeholder.article_description')}
								required
							/>
							<InputField
								name={'description'}
								label={t('extensions.labels.article_description')}
								placeholder={t('extensions.placeholder.article_description')}
								required
								multiline
							/>
							<FormSelect
								name={'partnerId'}
								label={t('partners.labels.client_supplier') as string}
								placeholder={t('partners.placeholder.client_supplier')}
								options={[{ value: null, label: '-' }, ...selectOptions]}
								value={values.partnerId || ''}
								onSelect={(value) => {
									setFieldValue('partnerId', value || null);
								}}
							/>
							<Button type={'submit'} fullWidth>
								{articleToEdit ? t('shared_texts.edit') : t('shared_texts.add')}
							</Button>
						</Form>
					)}
				</Formik>
			)}
		</Dialog>
	);
};
