export const censorEmail = function (email: string) {
	const [name, domain] = email.split('@');

	const censoredLength = (name.length > 6 ? 5 : name.length - 2) + 1;
	const censoredStart = name.length - censoredLength;
	const censoredEnd = 1;

	const censoredName =
		name.slice(0, censoredStart) + new Array(censoredLength).join('*') + name.slice(-censoredEnd);

	return `${censoredName}@${domain}`;
};
