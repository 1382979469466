import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type SignUpData = {
	firstName: string;
	lastName: string;
	token: string;
	password: string;
	confirmPassword: string;
};

type SignUpStore = {
	step: number;
	signUpData: SignUpData;
	confirmFirstStep: {
		isRead: boolean;
		isAgreed: boolean;
	};
};

type SignUpActions = {
	goToStep: (step: number) => void;
	goToNextStep: () => void;
	goToPrevStep: () => void;
	updateUserSignUpData: (data: Partial<SignUpData>) => void;
	updateConfirmationFirstStep: (data: { isRead?: boolean; isAgreed?: boolean }) => void;
	reset: () => void;
};

const initialState: SignUpStore = {
	step: 1,
	signUpData: {
		firstName: '',
		lastName: '',
		token: '',
		password: '',
		confirmPassword: '',
	},
	confirmFirstStep: {
		isRead: false,
		isAgreed: false,
	},
};

const signUpByInvitationStore = create<SignUpStore & SignUpActions>()(
	persist(
		(set, get) => ({
			...initialState,
			goToStep: (step) => set(() => ({ step: step })),
			goToNextStep: () => set(() => ({ step: get().step + 1 })),
			goToPrevStep: () => set(() => ({ step: get().step - 1 })),
			updateUserSignUpData: (data) =>
				set(({ signUpData }) => ({
					signUpData: {
						...signUpData,
						...data,
					},
				})),
			updateConfirmationFirstStep: (data) =>
				set(({ confirmFirstStep }) => ({
					confirmFirstStep: { ...confirmFirstStep, ...data },
				})),
			reset: () => set(() => ({ ...initialState })),
		}),
		{
			name: 'signUpByInvitationStore',
		},
	),
);

export const useSignUpByInvitationStore = () => signUpByInvitationStore((state) => state);
