import { FC, ReactNode } from 'react';
import { TableSortLabel as MUITableSortLabel } from '@mui/material';

interface Props {
	children: ReactNode;
	sort?: 'asc' | 'desc';
	onSort?: (sort: 'asc' | 'desc') => void;
}

export const TableSortLabel: FC<Props> = ({ sort, onSort, children }) => {
	return (
		<MUITableSortLabel
			active={true}
			direction={sort}
			onClick={() => onSort && onSort(sort === 'asc' ? 'desc' : 'asc')}
		>
			{children}
		</MUITableSortLabel>
	);
};
