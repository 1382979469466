import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { styled, Box, Typography } from '@mui/material';
import { ListItems } from 'shared/ui/ListItems';
import { ColorPalette } from 'shared/consts/colorPalette';
import { STypesEnum } from 'entities/Subscriptions/enums';
import { capitalizeFirstLetter } from 'shared/helpers/capitalizeFirstLetter';
import texts from 'widgets/Subscriptions/ui/SubCurrentTab/Tabs/CurrentTab/ui/UpdateSubscriptionModal/steps/consts/subscriptions_texts.json';

interface ModalSubContentContentPropsType {
	subscriptionType: STypesEnum;
}

export const ModalSubContent: FC<ModalSubContentContentPropsType> = ({ subscriptionType }) => {
	const { t } = useTranslation();

	switch (subscriptionType) {
		case STypesEnum.FREE:
			return (
				<StyledSubInfoBlock>
					<Box>
						<Typography variant={'h6'} color={ColorPalette.astronaut}>
							{capitalizeFirstLetter(subscriptionType)}
						</Typography>
						<ListItems list={texts.trial} />
						<StyledTypography variant={'body2'} color={ColorPalette.baliHai}>
							{t('subscriptions.tab_1.subscription_free.note')}
						</StyledTypography>
					</Box>
					<Typography variant={'h3'} color={ColorPalette.royalBlue} sx={{ whiteSpace: 'nowrap' }}>
						0 €
					</Typography>
				</StyledSubInfoBlock>
			);
		case STypesEnum.STARTER:
			return (
				<StyledSubInfoBlock>
					<Box>
						<Typography variant={'h6'} color={ColorPalette.astronaut}>
							{capitalizeFirstLetter(subscriptionType)}
						</Typography>
						<ListItems list={texts.starter} />
						<StyledTypography variant={'body2'} color={ColorPalette.baliHai}>
							{t('subscriptions.tab_1.subscription_starter.note')}
						</StyledTypography>
					</Box>
					<Box sx={{ textAlign: 'center' }}>
						<Typography
							variant={'h3'}
							color={ColorPalette.royalBlue}
							sx={{ whiteSpace: 'nowrap' }}
						>
							109 €
						</Typography>
						<Typography
							variant={'h5'}
							color={ColorPalette.royalBlue}
							sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}
						>
							{t('subscriptions.tab_1.vat_excluded')}
						</Typography>
					</Box>
				</StyledSubInfoBlock>
			);
		case STypesEnum.PRO:
			return (
				<StyledSubInfoBlock>
					<Box>
						<Typography variant={'h6'} color={ColorPalette.astronaut}>
							{capitalizeFirstLetter(subscriptionType)}
						</Typography>
						<ListItems list={texts.pro} />
						<StyledTypography variant={'body2'} color={ColorPalette.baliHai}>
							{t('subscriptions.tab_1.subscription_pro.note')}
						</StyledTypography>
					</Box>
					<Box sx={{ textAlign: 'center' }}>
						<Typography
							variant={'h3'}
							color={ColorPalette.royalBlue}
							sx={{ whiteSpace: 'nowrap' }}
						>
							329 €
						</Typography>
						<Typography
							variant={'h5'}
							color={ColorPalette.royalBlue}
							sx={{ whiteSpace: 'nowrap', textAlign: 'center' }}
						>
							{t('subscriptions.tab_1.vat_excluded')}
						</Typography>
					</Box>
				</StyledSubInfoBlock>
			);
		case STypesEnum.PAY_AS_YOU_GO:
			return (
				<Box>
					<Typography variant={'h6'} color={ColorPalette.astronaut}>
						{capitalizeFirstLetter(subscriptionType)}
					</Typography>
					<ListItems list={texts.pay_as_you_go} />
					<StyledTypography variant={'body2'} color={ColorPalette.baliHai}>
						{t('subscriptions.tab_1.subscription_pay.note')}
					</StyledTypography>
				</Box>
			);
	}
	return null;
};

const StyledTypography = styled(Typography)`
	width: 70%;
	margin-top: 30px;
	line-height: 23px;
`;

const StyledSubInfoBlock = styled(Box)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
`;
