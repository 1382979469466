import { Box, Typography } from '@mui/material';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Shadow } from 'shared/consts/shadow';
import emptyTableImg from 'shared/assets/empty-table.png';

type Props = {
	text: string;
};

export const EmptyTable = ({ text }: Props) => {
	return (
		<Box
			sx={{
				height: '380px',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				backgroundColor: ColorPalette.white,
				borderRadius: '20px',
				boxShadow: Shadow['2'],
				textAlign: 'center',
			}}
		>
			<img src={emptyTableImg} alt={'user-avatar'} style={{ width: '140px', marginBottom: '17px' }} />
			<Typography variant={'h5'} color={ColorPalette.manatee} sx={{ maxWidth: '580px' }}>
				{text}
			</Typography>
		</Box>
	);
};
