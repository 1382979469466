import { FC, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';
import { Box } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useMutation, useQueryClient } from 'react-query';
import { createAssertion } from 'entities/Assertions/api';
import { CreateAssertionsPayloadType } from 'entities/Assertions/api/types';
import { ActionsForDialog } from 'shared/ui/ActionsForDialog';
import { TableWithHeader } from 'shared/ui/tables/TableWithHeader';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { useAssertionSetsStore } from 'widgets/Extensions/Details/ui/AssertionSets/model/state';
import { InitialValuesTableFormColumns } from './Columns';

type InitialValuesTableFormPropsType = {
	assetionId: string;
	warehousesForAssertions: string[];
};
export const InitialValuesTableForm: FC<InitialValuesTableFormPropsType> = ({
	assetionId,
	warehousesForAssertions,
}) => {
	const queryClient = useQueryClient();
	const { t } = useTranslation();
	const { setWarehousesForAssertions } = useAssertionSetsStore();

	const [assertions, setAssertions] = useState<CreateAssertionsPayloadType[]>();

	const { mutate: createAssertionMutate } = useMutation({
		mutationKey: ['create/assertion-sets/assertions'],
		mutationFn: createAssertion,
	});

	useEffect(() => {
		const newAssertions = warehousesForAssertions.map((item) => ({
			initialVal: item,
			replaceVal: '',
		}));
		setAssertions(newAssertions);
	}, []);

	const onReplaceValChange = _.debounce((rowIndex: number, value: string) => {
		const newAssertions = assertions?.map((item, index) => ({
			...item,
			replaceVal: rowIndex === index ? value : item.replaceVal,
		}));
		setAssertions(newAssertions);
	}, 300);

	const onRemoveValueRow = (rowIndex: number) => {
		const newAssertions = assertions?.filter((item, index) => index !== rowIndex);
		if (newAssertions?.length) {
			setAssertions(newAssertions);
		} else {
			setWarehousesForAssertions(null);
		}
	};

	const preparedFields = useMemo(() => {
		if (!assertions?.length) return [];
		return assertions.map((item, index) => ({
			...item,
			id: `${index}`,
			valuesIndex: index,
			onReplaceValChange,
			onRemoveValueRow,
		}));
	}, [assertions]);

	const cancelCreationHandler = () => {
		setWarehousesForAssertions(null);
	};

	const onCreateAssertionsHandler = () => {
		if (assertions) {
			const isReplacedValueEmpty = assertions.filter((item) => !item.replaceVal).length;
			if (isReplacedValueEmpty) {
				enqueueSnackbar({
					message: t('assertions.snackbar.entere_all_replaced_values'),
					variant: 'error',
				});
			} else {
				createAssertionMutate(
					{
						id: assetionId,
						data: { assertions },
					},
					{
						onSuccess: () => {
							queryClient.invalidateQueries({ queryKey: ['get/assertion-sets/assertions'] });
							enqueueSnackbar({
								message: t('assertions.snackbar.success_assertion_creation'),
								variant: 'success',
							});
							setWarehousesForAssertions(null);
						},
						onError: (err) => {
							const errorMessage = getErrorMessage(err);
							enqueueSnackbar({ message: errorMessage, variant: 'error' });
						},
					},
				);
			}
		}
	};
	return (
		<Box sx={{ maxWidth: '1200px', display: 'flex', flexDirection: 'column', gap: '30px' }}>
			<TableWithHeader columns={InitialValuesTableFormColumns} rows={preparedFields} />
			<Box sx={{ width: '450px', alignSelf: 'flex-end' }}>
				<ActionsForDialog
					cancelLabel={t('shared_texts.cancel')}
					cancelAction={cancelCreationHandler}
					applyLabel={t('shared_texts.confirm')}
					applyAction={onCreateAssertionsHandler}
				/>
			</Box>
		</Box>
	);
};
