import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';
import { FileType } from 'entities/Files/types';
import filePreview from 'shared/assets/file_preview.png';
import { ColorPalette } from 'shared/consts/colorPalette';
import { TableTranslationLabel } from 'shared/ui/tables/_common/ui/TableTranslationLabel';
import { Icon } from 'shared/ui/Icon';
import { Column } from 'shared/ui/tables/_common/types';

export type FileForDashboard = FileType & {
	id: string;
};

export const CouserFilesDashboardColumns: Array<Column<FileForDashboard>> = [
	{
		field: 'options',
		commonCellSettings: {
			width: '25px',
			align: 'center',
		},
		headerCellSettings: {
			style: {
				width: '56px',
			},
		},
		bodyCellSettings: {
			renderCell: () => {
				return (
					<Icon
						id={'couser_success_arrow'}
						color={ColorPalette.royalBlue}
						style={{ width: '24px', height: '24px' }}
					/>
				);
			},
		},
	},
	{
		field: 'number',
		commonCellSettings: {
			width: '70px',
			align: 'center',
		},
		headerCellSettings: {
			style: {
				width: '70px',
			},
			renderCell: () => <TableTranslationLabel label="number" />,
		},
		bodyCellSettings: {
			renderCell: () => {
				return (
					<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						<Typography variant={'body2'} color={ColorPalette.astronaut}>
							45
						</Typography>
					</Box>
				);
			},
		},
	},
	{
		field: 'docsName',
		commonCellSettings: {
			style: {
				width: '230px',
			},
		},
		headerCellSettings: {
			style: {
				width: '235px',
				paddingLeft: '30px',
			},
			renderCell: () => <TableTranslationLabel label="file_name" />,
		},
		bodyCellSettings: {
			renderCell: (file) => {
				return (
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						<img
							src={filePreview}
							alt={'file-preview'}
							style={{ width: '62px', height: '62px', marginRight: '12px' }}
						/>
						<Typography
							variant={'body2'}
							color={ColorPalette.astronaut}
							style={{
								width: '150px',
								textOverflow: 'ellipsis',
								overflow: 'hidden',
								whiteSpace: 'nowrap',
							}}
						>
							{file?.fileName}
						</Typography>
					</Box>
				);
			},
		},
	},
	{
		field: 'category',
		commonCellSettings: {
			style: { width: '210px' },
		},
		headerCellSettings: {
			style: {
				width: '210px',
			},
			renderCell: () => <TableTranslationLabel label="category" />,
		},
		bodyCellSettings: {
			renderCell: (file) => {
				return file?.category ? (
					<TableTranslationLabel path={`sidebar.menu./files/${file?.category}`} />
				) : (
					'-'
				);
			},
		},
	},
	{
		field: 'subCategory',
		commonCellSettings: {
			style: { width: '210px' },
		},
		headerCellSettings: {
			style: {
				width: '210px',
			},
			renderCell: () => <TableTranslationLabel label="sub_category" />,
		},
		bodyCellSettings: {
			renderCell: (file) => {
				return file?.category ? (
					<TableTranslationLabel path={`sidebar.menu./files/${file?.category}`} />
				) : (
					'-'
				);
			},
		},
	},
	{
		field: 'importedAt',
		commonCellSettings: {
			style: { width: '160px' },
		},
		headerCellSettings: {
			style: {
				width: '160px',
			},
			renderCell: () => <TableTranslationLabel label="imported_at" />,
		},
		bodyCellSettings: {
			renderCell: (file) => {
				return file?.createdAt ? (
					<Typography variant={'body2'} component={'span'} color={ColorPalette.astronaut}>
						{format(new Date(file.createdAt), 'HH:mm\u00A0\u00A0\u00A0dd/MM/yyyy')}
					</Typography>
				) : (
					<Typography variant={'body2'} component={'span'} color={ColorPalette.baliHai}>
						{'--:--\u00A0\u00A0\u00A0--/--/----'}
					</Typography>
				);
			},
		},
	},
	{
		field: 'automaticRemovalAt',
		commonCellSettings: {
			style: { width: '160px' },
		},
		headerCellSettings: {
			style: {
				width: '160px',
			},
			renderCell: () => <TableTranslationLabel label="automatic_removal_at" />,
		},
		bodyCellSettings: {
			renderCell: (file) => {
				return file?.autoRemoveAt ? (
					<Typography variant={'body2'} component={'span'} color={ColorPalette.astronaut}>
						{format(new Date(file.autoRemoveAt), 'HH:mm\u00A0\u00A0\u00A0dd/MM/yyyy')}
					</Typography>
				) : (
					<Typography variant={'body2'} component={'span'} color={ColorPalette.baliHai}>
						{'--:--\u00A0\u00A0\u00A0--/--/----'}
					</Typography>
				);
			},
		},
	},
];
