import {
	Table as MUITable,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableProps,
	TableRow,
} from '@mui/material';
import { TableHeader } from 'shared/ui/tables/_common/ui/TableHeader';
import { CollapsableTableInner } from 'shared/ui/tables/CollapsableTable/ui/CollapsableTableInner';
import { Column } from 'shared/ui/tables/_common/types';

interface Props<T> extends TableProps {
	columns: Array<Column<T>>;
	subTables: Record<string, Array<T & { id: string }>>;
}
export function CollapsableTable<T>({ columns, subTables }: Props<T>) {
	const firstRow = Object.values(subTables)[0][0];
	return (
		<TableContainer sx={{ padding: '0 25px', margin: '0 -25px' }}>
			<MUITable>
				<TableHead>
					<TableRow>
						<TableCell
							sx={{ padding: '17px 0px', borderBottom: 'none' }}
							colSpan={columns.length}
						>
							<TableHeader columns={columns} additionalData={firstRow} />
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<TableRow>
						<TableCell sx={{ padding: 0, borderBottom: 'none' }} colSpan={columns.length}>
							{Object.keys(subTables).map((subTableId) => (
								<CollapsableTableInner
									key={subTableId}
									columns={columns}
									rows={subTables[subTableId]}
									title={subTableId}
								/>
							))}
						</TableCell>
					</TableRow>
				</TableBody>
			</MUITable>
		</TableContainer>
	);
}
