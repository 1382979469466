import { FunctionComponent, useMemo, useState } from 'react';
import { SubTabContext, SubTabType } from './SubscriptionsTabContext';

interface Props {
	children: React.ReactNode;
}

export const SubTabProvider: FunctionComponent<Props> = ({ children }) => {
	const [tab, setTab] = useState<SubTabType>(SubTabType.CURRENT);

	const defaultProps = useMemo(
		() => ({
			tab,
			setTab,
		}),
		[tab],
	);

	return <SubTabContext.Provider value={defaultProps}>{children}</SubTabContext.Provider>;
};
