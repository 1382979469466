import { Box } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { updatePersonalInfo } from 'entities/User/api';
import { Switch } from 'shared/ui/Switch';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { useAuthStore } from 'processes/auth/model/state';
import { useSignInStore } from 'widgets/auth/signUp/_MainModal/model/state';
import { User } from 'entities/User/types';
import flagIt from 'shared/assets/flag-it.png';
import flagUk from 'shared/assets/flag-uk.png';

export const ChangeLanguage: FunctionComponent<{ auth?: boolean }> = ({ auth = false }) => {
	const { user, updateAuthData } = useAuthStore();
	const { updateUserSignUpData } = useSignInStore();

	const { i18n, t } = useTranslation();

	const { mutate } = useMutation({
		mutationFn: updatePersonalInfo,
	});

	const changeLanguage = () => {
		const newLanguage = i18n.language === 'en' ? 'it' : 'en';
		if (auth) {
			updateUserSignUpData({ language: newLanguage });
			i18n.changeLanguage(newLanguage);
		} else {
			mutate(
				{ language: newLanguage, firstName: user?.firstName, lastName: user?.lastName },
				{
					onSuccess: () => {
						i18n.changeLanguage(newLanguage);
						updateAuthData({
							user: { ...(user as User), language: newLanguage },
						});
						enqueueSnackbar({
							message: t('shared_texts.success_update'),
							variant: 'success',
						});
					},
					onError: (err) => {
						const defaultErrorMessage = t('shared_texts.failed_update');
						const errorMessage = getErrorMessage(err, defaultErrorMessage);
						enqueueSnackbar({
							message: errorMessage,
							variant: 'error',
						});
					},
				},
			);
		}
	};

	return (
		<Box
			sx={{
				width: 'fit-content',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<Box sx={{ height: '20px', width: '27px', borderRadius: '3px', overflow: 'hidden' }}>
				<img alt={'flag_it'} src={flagIt} />
			</Box>
			<Switch checked={i18n.language === 'en'} onChange={changeLanguage} sx={{ margin: '0 19px' }} />
			<Box sx={{ height: '20px', width: '27px', borderRadius: '3px', overflow: 'hidden' }}>
				<img alt={'flag_uk'} src={flagUk} />
			</Box>
		</Box>
	);
};
