import { useRef, useState, FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Shadow } from 'shared/consts/shadow';
import { Loader } from 'shared/ui/Loader';
import uploadFiles from 'shared/assets/upload-files.png';
import selectedExtension from 'shared/assets/selected-extension.png';

interface DnDImportFilesPropTypes {
	setUploadedFiles: (files: Array<File>) => void;
	fileDescription: string;
	importingProccess?: {
		loading: boolean;
		importedFiles: number;
	};
	uploaded?: boolean;
}

export const DnDImportFiles: FC<DnDImportFilesPropTypes> = ({
	setUploadedFiles,
	fileDescription,
	importingProccess,
	uploaded,
}) => {
	const { t } = useTranslation();
	const inputRef = useRef<HTMLInputElement>(null);
	const [drag, setDrag] = useState<boolean>(false);
	const [totalFiles, setTotalFiles] = useState<number | undefined>(undefined);

	useEffect(() => {
		if (importingProccess?.importedFiles === totalFiles) {
			setTimeout(() => {
				setTotalFiles(undefined);
			}, 1000);
		}
	}, [importingProccess?.importedFiles, totalFiles]);

	const dragOverHandler = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setDrag(true);
	};

	const dragLeaveHandler = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setDrag(false);
	};

	const dropHandler = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setUploadedFiles(e.dataTransfer.files as unknown as Array<File>);
		setTotalFiles(e.dataTransfer.files?.length);
		setDrag(false);
	};

	const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		const targetFiles = e.target.files as unknown as Array<File>;
		setUploadedFiles(targetFiles);
		setTotalFiles(targetFiles.length);
		setDrag(false);
	};

	return (
		<Box
			sx={{
				height: '383px',
				width: '100%',
				padding: '40px',
				backgroundColor: ColorPalette.white,
				boxShadow: Shadow['2'],
				borderRadius: '20px',
				display: 'flex',
				justifyContent: 'center',
			}}
		>
			{uploaded ||
			(importingProccess?.importedFiles && importingProccess?.importedFiles === totalFiles) ? (
				<img src={selectedExtension} alt="selected-extension" height={'100%'} />
			) : (
				<Box
					onDragStart={(e) => dragOverHandler(e)}
					onDragLeave={(e) => dragLeaveHandler(e)}
					onDragOver={(e) => dragOverHandler(e)}
					onDrop={(e) => dropHandler(e)}
					sx={{
						height: '100%',
						width: '100%',
						backgroundColor: drag ? ColorPalette.periwinkle : ColorPalette.catskillWhite,
						border: `1px solid ${ColorPalette.periwinkle}`,
						borderRadius: '20px',
						transition: 'all .3s',
					}}
				>
					<Box
						sx={{
							height: '100%',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						{importingProccess?.loading ? (
							<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
								<Loader type="min" />
								<Typography variant={'caption'} color={ColorPalette.astronaut}>
									{t('shared_texts.doc_imported')} {importingProccess.importedFiles}/
									{totalFiles}
								</Typography>
							</Box>
						) : (
							<>
								<img
									src={uploadFiles}
									alt={'upload-files'}
									style={{ width: '165px', height: '165px' }}
								/>
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<input
										hidden
										multiple
										type={'file'}
										ref={inputRef}
										onChange={(e) => changeHandler(e)}
									/>
									<Typography variant={'h5'} color={ColorPalette.astronaut}>
										{t('start_recognition.upload_files')}
									</Typography>
									<Typography
										variant={'body1'}
										component={'span'}
										color={ColorPalette.astronaut}
										sx={{ my: '24px' }}
									>
										{t('start_recognition.upload_files_section.drag_n_drop')}{' '}
										<Typography
											onClick={() => inputRef?.current?.click()}
											variant={'body1'}
											component={'span'}
											color={ColorPalette.royalBlue}
											sx={{ textDecoration: 'underline', cursor: 'pointer' }}
										>
											{t('start_recognition.upload_files_section.drag_n_drop_link')}
										</Typography>{' '}
									</Typography>
									<Typography
										variant={'caption'}
										color={ColorPalette.astronaut}
										sx={{ mb: '8px' }}
									>
										{fileDescription}
									</Typography>
								</Box>
							</>
						)}
					</Box>
				</Box>
			)}
		</Box>
	);
};
