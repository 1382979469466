import { Box, Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FC, useEffect, useState } from 'react';
import { ActionsForDialog } from 'shared/ui/ActionsForDialog';
import { Input } from 'shared/ui/Input';
import { Paper } from 'shared/ui/Paper';

interface Props {
	open: boolean;
	handleClose: () => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	addTable: any;
}

export const AddTableModal: FC<Props> = ({ open, handleClose, addTable }) => {
	const { t } = useTranslation();
	const [tableName, setTableName] = useState<string>('');
	const [errorText, setErrorText] = useState<string>('');

	const regexp = /[~`'"!?@#$^&\s<>[\]+:|/\\]/g;

	const handleLabelNameChange = (name: string) => {
		if (!name.match(regexp)) {
			setTableName(name);
			setErrorText('');
		} else {
			setTableName(name);
			setErrorText(t('files.details.error_text') as string);
		}
	};

	useEffect(() => {
		setErrorText('');
	}, []);

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			PaperComponent={Paper}
			PaperProps={{ title: t('files.details.add_table') as string }}
		>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					flexDirection: 'column',
					justifyContent: 'space-between',
					marginBottom: '40px',
				}}
			>
				<Input
					sx={{ marginBottom: '7px' }}
					name="tabelName"
					label={t('files.labels.table_name')}
					placeholder={t('files.placeholder.table_name') as string}
					type="text"
					required
					onChange={(event) => {
						handleLabelNameChange(event?.target?.value);
					}}
					value={tableName}
				/>
				{errorText && <Box sx={{ color: 'red', fontSize: '12px' }}>{errorText}</Box>}
			</Box>
			<ActionsForDialog
				applyActionDisabled={!tableName}
				cancelLabel={t('shared_texts.cancel')}
				cancelAction={() => {
					handleClose();
					setTableName('');
				}}
				applyLabel={t('shared_texts.save')}
				applyAction={() => {
					addTable(tableName);
					handleClose();
					setTableName('');
				}}
			/>
		</Dialog>
	);
};
