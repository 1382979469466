import { axiosInstance } from 'shared/config/axios';
import {
	AuthWithGoogleTokenRequest,
	ChangeEmailRequest,
	CheckUserRequest,
	ConfirmEmailRequest,
	CreatePasswordRequest,
	RestorePasswordRequest,
	SendConfirmationEmailRequest,
	SignInRequest,
	SignUpByInvitationRequest,
	SignUpRequest,
	UpdatePasswordRequest,
} from 'entities/Auth/api/types';
import { getAccessToken } from 'shared/helpers/getAccessToken';

export const checkUser: CheckUserRequest = (email) => {
	return axiosInstance.get(`/users/check?email=${encodeURIComponent(email)}`);
};

export const signUp: SignUpRequest = (data) => {
	return axiosInstance.post('/auth/signup', data);
};

export const sendConfirmationEmail: SendConfirmationEmailRequest = (data) => {
	return axiosInstance.post('/auth/activate/send', data);
};

export const confirmEmail: ConfirmEmailRequest = (token) => {
	return axiosInstance.get(`/auth/activate/${token}`);
};

export const signUpByInvitation: SignUpByInvitationRequest = (data) => {
	return axiosInstance.post('/co-users/accept', data);
};

export const signIn: SignInRequest = (data) => {
	return axiosInstance.post('/auth/signin', data);
};

export const sendRestorePasswordEmail: RestorePasswordRequest = (data) => {
	return axiosInstance.post('/auth/restore-password/send', data);
};

export const createPassword: CreatePasswordRequest = (data) => {
	return axiosInstance.patch('/auth/restore-password', data);
};

export const authWithGoogleToken: AuthWithGoogleTokenRequest = ({ token }) => {
	return axiosInstance.get('/auth/google', {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

export const updatePassword: UpdatePasswordRequest = (data) => {
	return axiosInstance.patch('/users/personal-password', data, {
		headers: {
			Authorization: `Bearer ${getAccessToken()}`,
		},
	});
};

export const changeEmail: ChangeEmailRequest = (data) => {
	return axiosInstance.patch('/users/email', data, {
		headers: {
			Authorization: `Bearer ${getAccessToken()}`,
		},
	});
};

export const validateToken = () => {
	return axiosInstance.post('/auth/validate-token');
};
