import { Box, BoxProps, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Button } from 'shared/ui/Button';

interface Props extends BoxProps {
	cancelLabel: string;
	cancelAction: () => void;
	applyLabel: string;
	applyAction?: () => void;
	applyActionDisabled?: boolean;
	cancelActionDisabled?: boolean;
}

export const ActionsForDialog: FunctionComponent<Props> = ({
	sx,
	cancelLabel,
	cancelAction,
	applyLabel,
	applyAction,
	applyActionDisabled,
	cancelActionDisabled,
}) => {
	return (
		<Box
			sx={{
				'display': 'flex',
				'justifyContent': 'space-between',
				'& > *': {
					width: 'calc(50% - 15px)',
					maxWidth: 'calc(50% - 15px)',
				},
				...sx,
			}}
		>
			<Button
				disabled={cancelActionDisabled}
				fullWidth
				variant={'outlined'}
				onClick={cancelAction}
				sx={{
					'&.MuiButton-outlined': {
						'backgroundColor': 'transparent',
						'border': '1px solid transparent',
						'color': ColorPalette.astronaut,
						'boxShadow': 'none',
						'&.MuiButton-root:hover': {
							backgroundColor: ColorPalette.periwinkle,
							color: ColorPalette.astronaut,
							boxShadow: 'none',
						},
					},
				}}
			>
				{cancelLabel}
			</Button>
			<Button
				disabled={applyActionDisabled}
				type={'submit'}
				fullWidth
				variant={'contained'}
				onClick={() => {
					applyAction && applyAction();
				}}
			>
				{applyLabel}
			</Button>
		</Box>
	);
};
