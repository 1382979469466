import { Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';

interface Props {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	children: any;
	variant?: 'regular' | 'bold';
}

const variantMatch = {
	regular: 'body1',
	bold: 'subtitle1',
} as const;

export const Paragraph: FunctionComponent<Props> = ({ children, variant = 'regular' }) => {
	return (
		<Typography
			variant={variantMatch[variant]}
			color={ColorPalette.astronaut}
			sx={{
				'maxWidth': '828px',
				'marginBottom': '23px',
				'& a': {
					color: ColorPalette.royalBlue,
					textDecoration: 'underline',
				},
			}}
		>
			{children}
		</Typography>
	);
};
