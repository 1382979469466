import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';
import { FunctionComponent } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';
import { isEmptyObject } from 'shared/helpers/isEmptyObject';
import { isEmptyObjectValues } from 'shared/helpers/isEmptyObjectValues';
import { Button } from 'shared/ui/Button';
import { InputField } from 'shared/ui/InputField';
import { Paper } from 'shared/ui/Paper';
import { Step2ValidationSchema } from 'widgets/auth/signUp/_MainModal/consts/validation';
import { useSignInStore } from 'widgets/auth/signUp/_MainModal/model/state';
import { Step2 } from 'widgets/auth/signUp/_MainModal/types';
import { Footer } from 'widgets/auth/signUp/_MainModal/ui/Footer';
import { StepLabel } from 'widgets/auth/signUp/_MainModal/ui/StepLabel';

export const SignUpStep2: FunctionComponent = () => {
	const { goToNextStep, signUpData, updateUserSignUpData } = useSignInStore();
	const { t } = useTranslation();

	const initialValues = {
		firstName: signUpData.user.firstName,
		lastName: signUpData.user.lastName,
	};

	const handleSubmit = (values: Step2): void => {
		updateUserSignUpData({ ...values });
		goToNextStep();
	};

	return (
		<Paper
			title={t('auth.sign_up') as string}
			sx={{
				'& *': {
					userSelect: 'none',
				},
			}}
		>
			<StepLabel />
			<Typography variant={'body1'} color={ColorPalette.baliHai} sx={{ marginBottom: '35px' }}>
				{t('auth.personal_info')}
			</Typography>
			<Formik
				initialValues={initialValues}
				validationSchema={Step2ValidationSchema}
				onSubmit={handleSubmit}
			>
				{({ errors, values }: FormikProps<Step2>) => (
					<Form>
						<InputField
							name={'firstName'}
							label={t('auth.labels.name')}
							placeholder={t('auth.validation.enter_name')}
							required
						/>
						<InputField
							name={'lastName'}
							label={t('auth.labels.last_name')}
							placeholder={t('auth.validation.enter_last_name')}
							required
						/>
						<Button
							disabled={!isEmptyObject(errors) || isEmptyObjectValues(values)}
							type={'submit'}
							fullWidth
							variant={'contained'}
						>
							{t('shared_texts.continue')}
						</Button>
					</Form>
				)}
			</Formik>
			<Footer />
		</Paper>
	);
};
