export const UserRoles = {
	admin: 'ADMIN',
	user: 'USER',
	coUser: 'CO_USER',
} as const;

export type User = {
	_id: string;
	email: string;
	firstName: string;
	lastName: string;
	isAccountActivated: boolean;
	isDeleted: boolean;
	role: (typeof UserRoles)[keyof typeof UserRoles];
	avatar: string;
	createdAt: string;
	updatedAt: string;
	language: 'en' | 'it';
};

export type UserForRequest = {
	email: User['email'];
	password: string;
	firstName: User['firstName'];
	lastName: User['firstName'];
	language: User['language'];
};
