import { Box } from '@mui/material';
import { FC } from 'react';
import { CategoryCard } from 'widgets/startRecognition/ui/CategoryCard';
import { CategoriesList, Category } from 'widgets/startRecognition/consts/CategoryList';

interface Props {
	openUploadModal: (category: Category) => void;
}

export const CategoryList: FC<Props> = ({ openUploadModal }) => {
	return (
		<Box
			sx={{
				width: '100%',
				display: 'flex',
				flexWrap: 'wrap',
				gridGap: '20px',
			}}
		>
			{CategoriesList.map((category) => (
				<CategoryCard
					key={category.id}
					category={category}
					action={() => openUploadModal(category)}
					sx={{
						'width': '30%',
						'@media screen and (max-width: 1440px)': {
							width: '48%',
						},
						'@media screen and (max-width: 1024px)': {
							width: '100%',
						},
					}}
				/>
			))}
		</Box>
	);
};
