import { FunctionComponent, ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { Routes } from 'app/config/routes/consts';
import { useAuthStore } from 'processes/auth/model/state';
import { UserRoles } from 'entities/User/types';

interface Props {
	children: ReactElement;
}

export const UnauthorisedLayout: FunctionComponent<Props> = ({ children }) => {
	const { accessToken, user, company } = useAuthStore();

	if (accessToken && user?.role === UserRoles.admin) {
		return <Navigate to={Routes.users} replace />;
	}

	if (accessToken && user && company) {
		return <Navigate to={Routes.startRecognition} replace />;
	}

	return <>{children}</>;
};
