import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { SubscriptionType } from 'entities/Subscriptions/types';

type SubscriptionStoreType = {
	step: number;
	paymentMethodLast4: string | null;
	currentSubscription: SubscriptionType | null;
};

type SubscriptionSignUpActions = {
	setCurrentSubscription: (sub: SubscriptionType) => void;
	setCardNumber: (cardNumber: string) => void;
	goToNextStep: () => void;
	goToInitialStep: () => void;
	reset: () => void;
};

const initialState: SubscriptionStoreType = {
	step: 1,
	paymentMethodLast4: null,
	currentSubscription: null,
};

const subscriptionStore = create<SubscriptionStoreType & SubscriptionSignUpActions>()(
	persist(
		(set, get) => ({
			...initialState,
			setCurrentSubscription: (current) => set(() => ({ currentSubscription: current })),
			setCardNumber: (cardNumber) => set(() => ({ paymentMethodLast4: cardNumber })),
			goToNextStep: () => set(() => ({ step: get().step + 1 })),
			goToInitialStep: () => set(() => ({ step: 1 })),
			reset: () => set(() => ({ ...initialState })),
		}),
		{
			name: 'subscriptionStore',
		},
	),
);

export const useSubscriptionStoreStore = () => subscriptionStore((state) => state);
