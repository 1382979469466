import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from 'shared/ui/PageHeader';
import { SubTabProvider } from 'widgets/Subscriptions/context/SubTabProvider';
import { SubTabs } from 'widgets/Subscriptions/ui/SubTabs';
import { SubCurrentTab } from 'widgets/Subscriptions/ui/SubCurrentTab';

export const Subscriptions: FunctionComponent = () => {
	const { t } = useTranslation();
	return (
		<SubTabProvider>
			<PageHeader title={t('subscriptions.title')} description={t('subscriptions.description')} />
			<SubTabs />
			<SubCurrentTab />
		</SubTabProvider>
	);
};
