import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './locales/en/translation.json';
import it from './locales/it/translation.json';

i18n.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: 'it',
		lng: 'it',
		supportedLngs: ['en', 'it'],
		debug: false,
		detection: {
			order: ['localStorage', 'cookie'],
			caches: ['localStorage', 'cookie'],
		},
		interpolation: {
			escapeValue: false,
		},
		resources: {
			it: {
				translation: { ...it },
			},
			en: {
				translation: { ...en },
			},
		},
	});

export { i18n };
