import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';
import { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import { ColorPalette } from 'shared/consts/colorPalette';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { useCountdown } from 'shared/helpers/useCountdown';
import { StatusModal } from 'widgets/auth/_common/ui/StatusModal';
import { sendConfirmationEmail } from 'entities/Auth/api';

export const CEEmailSentModal: FunctionComponent = () => {
	const location = useLocation();
	const { t } = useTranslation();
	const [timeLeft, setCountdown] = useCountdown('/sign-up/email-sent');

	const handleSendConfirmationEmail = () => {
		sendConfirmationEmail({ email: location.state.email })
			.then((res) => {
				if (res?.data?.success) {
					setCountdown(120);
				}
			})
			.catch((err) => {
				const defaultErrorMessage = t('shared_texts.smth_went_wrong');
				const errorMessage = getErrorMessage(err, defaultErrorMessage);
				enqueueSnackbar({
					message: errorMessage,
					variant: 'error',
				});
			});
	};

	return (
		<StatusModal
			type={'info'}
			title={t('auth.status_modal.email_sent')}
			message={t('auth.status_modal.email_change_message')}
		>
			<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
				<Typography variant={'body2'} color={ColorPalette.astronaut}>
					{t('auth.status_modal.dont_receive_confirmation_email')}
				</Typography>
				{timeLeft ? (
					<Typography variant={'body2'} color={ColorPalette.royalBlue} sx={{ marginLeft: '8px' }}>
						{timeLeft.minutes}:{timeLeft.seconds}
					</Typography>
				) : (
					<Typography
						variant={'body2'}
						color={ColorPalette.royalBlue}
						sx={{ cursor: 'pointer', marginLeft: '8px' }}
						onClick={handleSendConfirmationEmail}
					>
						{t('auth.status_modal.resend')}
					</Typography>
				)}
			</Box>
		</StatusModal>
	);
};
