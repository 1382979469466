import { FC } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { Routes } from 'app/config/routes/consts';
import { ExtensionsEnum } from 'entities/Extensions/enums';
import { AssertionWidget } from './ui/AssertionSets';
import { CustomOutputFileWidget } from './ui/CustomOutputFile';
import { WarehouseWidget } from './ui/Warehouse';

export const ExtensionsDetailsWidget: FC = () => {
	const { exName } = useParams();

	if (exName === ExtensionsEnum.assertions_sets) {
		return <AssertionWidget />;
	}

	if (exName === ExtensionsEnum.custom_output_file) {
		return <CustomOutputFileWidget />;
	}

	if (exName === ExtensionsEnum.warehouse) {
		return <WarehouseWidget />;
	}

	return <Navigate to={Routes.extensions} />;
};
