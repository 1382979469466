import { Dialog, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/ui/Button';
import { CloseModalButton } from 'shared/ui/CloseModalButton';
import { Paper } from 'shared/ui/Paper';
import { ColorPalette } from 'shared/consts/colorPalette';
import { SExportsEnum } from 'entities/Subscriptions/enums';

interface DownloadModalTypes {
	open: boolean;
	handleClose: () => void;
	handleDownload: (type: SExportsEnum) => void;
}

export const DownloadModal = ({ open, handleClose, handleDownload }: DownloadModalTypes) => {
	const { t } = useTranslation();
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			PaperComponent={Paper}
			PaperProps={{ title: t('shared_texts.download_modal.title') as string }}
			sx={{
				'& .MuiPaper-root': {
					width: '536px',
					maxWidth: '536px',
					overflow: 'inherit',
				},
			}}
		>
			<Typography variant={'body1'} color={ColorPalette.baliHai} sx={{ margin: '24px 0 34px 0' }}>
				{t('shared_texts.download_modal.description')}
			</Typography>
			<CloseModalButton action={handleClose} />

			<Button
				sx={{ marginTop: '24px' }}
				onClick={() => {
					handleDownload(SExportsEnum.CSV);
				}}
				variant={'contained'}
				fullWidth
			>
				{t('shared_texts.download_modal.download_csv')}
			</Button>

			<Button
				sx={{ marginTop: '24px' }}
				onClick={() => {
					handleDownload(SExportsEnum.XML);
				}}
				variant={'contained'}
				fullWidth
			>
				{t('shared_texts.download_modal.download_xml')}
			</Button>
		</Dialog>
	);
};
