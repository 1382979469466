import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import { enqueueSnackbar } from 'notistack';
import { useMutation, useQueryClient } from 'react-query';
import { ColorPalette } from 'shared/consts/colorPalette';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import { RemoveUserModal } from 'widgets/admin/Users/RemoveUserModal';
import { InfoContainer } from 'widgets/CoUsers/ui/CoUserDetailsInfo/ui/Paper';
import { UserForAdmin } from 'entities/admin/Users/types';
import { removeUser } from 'entities/admin/Users/api';

interface Props {
	user: UserForAdmin | null;
}
const PersonalInfo: FC<Props> = ({ user }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

	const queryClient = useQueryClient();
	const { mutate } = useMutation({
		mutationKey: ['admin/users', user?._id],
		mutationFn: removeUser,
	});

	const handleRemoveUser = useCallback(() => {
		mutate(user?._id, {
			onSuccess: async () => {
				await queryClient.invalidateQueries({ queryKey: ['admin/users'] });
				enqueueSnackbar({
					message: t('admin.users.snackbar.success_user_remove', { value: user?.email }),
					variant: 'success',
				});
				setIsOpenModal(false);
				navigate('/users');
			},
			onError: (err) => {
				const defaultErrorMessage = t('admin.users.snackbar.error_user_remove', {
					value: user?.email,
				});
				const errorMessage = getErrorMessage(err, defaultErrorMessage);
				enqueueSnackbar({
					message: errorMessage,
					variant: 'error',
				});
			},
		});
	}, [isOpenModal, queryClient]);

	return (
		<>
			<RemoveUserModal
				open={isOpenModal}
				email={user?.email}
				handleClose={() => setIsOpenModal(false)}
				handleRemoveUser={handleRemoveUser}
			/>
			<Typography variant={'h5'} color={ColorPalette.astronaut} sx={{ margin: '44px 0 38px 0' }}>
				{t('personal_account.tab_1.title')}
			</Typography>
			<InfoContainer>
				<Input
					name={'firstName'}
					label={t('auth.labels.name')}
					placeholder={t('auth.validation.enter_name') as string}
					value={user?.firstName ?? '-'}
					disabled
				/>
				<Input
					name={'lastName'}
					label={t('auth.labels.last_name')}
					placeholder={t('auth.validation.enter_last_name') as string}
					value={user?.lastName ?? '-'}
					disabled
				/>
				<Input name={'email'} label={t('auth.labels.users_email')} value={user?.email} disabled />
				<DatePicker
					// eslint-disable-next-line @typescript-eslint/no-empty-function
					onChange={() => {}}
					value={user?.createdAt || null}
					renderInput={() => (
						<Input
							sx={{ margin: 0 }}
							name={'registrationDate'}
							label={t('auth.labels.registration_date')}
							value={user?.createdAt ? format(new Date(user?.createdAt), 'dd/MM/yyyy') : null}
							disabled
						/>
					)}
				/>
			</InfoContainer>
			<Typography variant={'h5'} color={ColorPalette.astronaut} sx={{ margin: '100px 0 38px 0' }}>
				{t('admin.users.details.business_info')}
			</Typography>
			<InfoContainer>
				<Input
					name={'legalName'}
					label={t('auth.labels.legal_name')}
					placeholder={t('auth.labels.legal_name') as string}
					value={user?.company?.name ?? '-'}
					disabled
				/>
				<Input
					name={'vatNumber'}
					label={t('auth.labels.vat_number')}
					placeholder={t('auth.validation.enter_vat_number') as string}
					value={user?.company?.taxInfo?.vatNumber ?? '-'}
					disabled
				/>
				<Input
					name={'sdiCode'}
					label={t('auth.labels.sdi_code')}
					placeholder={t('auth.validation.enter_sdi_code') as string}
					value={user?.company?.taxInfo?.sdiCode ?? '-'}
					disabled
				/>
				<Input
					name={'country'}
					label={t('auth.labels.country')}
					placeholder={t('auth.labels.country') as string}
					value={user?.company?.address?.country ?? '-'}
					disabled
				/>
				<Input
					name={'city'}
					label={t('auth.labels.city')}
					placeholder={t('auth.labels.city') as string}
					value={user?.company?.address?.city ?? '-'}
					disabled
				/>
				<Input
					name={'streetName'}
					label={t('auth.labels.street')}
					placeholder={t('auth.labels.street') as string}
					value={user?.company?.address?.street ?? '-'}
					disabled
				/>
				<Input
					name={'houseNumber'}
					label={t('auth.labels.house_number')}
					placeholder={t('auth.labels.house_number') as string}
					value={user?.company?.address?.houseNumber ?? '-'}
					disabled
				/>
				<Input
					name={'postalCode'}
					label={t('auth.labels.postal_code')}
					placeholder={t('auth.labels.postal_code') as string}
					value={user?.company?.address?.postalCode ?? '-'}
					disabled
				/>
				<Button onClick={() => setIsOpenModal(true)} color={ColorPalette.violetRed} fullWidth>
					{t('admin.users.details.remove_user')}
				</Button>
			</InfoContainer>
		</>
	);
};

export default PersonalInfo;
