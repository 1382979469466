import { Paper as MUIPaper, PaperProps, Typography, TypographyProps } from '@mui/material';
import { FunctionComponent } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Shadow } from 'shared/consts/shadow';

interface Props extends PaperProps {
	title?: string;
	titleAlign?: TypographyProps['align'];
}

export const Paper: FunctionComponent<Props> = ({ sx, title, titleAlign, ...props }) => {
	return (
		<MUIPaper
			sx={{
				width: '503px',
				padding: '56px 45px',
				backgroundColor: ColorPalette.white,
				boxShadow: Shadow['2'],
				borderRadius: '20px',
				position: 'relative',
				...sx,
			}}
			{...props}
		>
			{title && (
				<Typography
					variant={'h4'}
					align={titleAlign}
					color={ColorPalette.astronaut}
					sx={{ marginBottom: '24px' }}
				>
					{title}
				</Typography>
			)}
			{props.children}
		</MUIPaper>
	);
};
