import { Box, Dialog, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FunctionComponent, useState } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Button } from 'shared/ui/Button';
import { CloseModalButton } from 'shared/ui/CloseModalButton';
import { Icon } from 'shared/ui/Icon';
import { Paper } from 'shared/ui/Paper';
import { GuideListItem } from 'widgets/startRecognition/ui/GuideModal/ui/GuideListItem';
import { GuideStep } from 'widgets/startRecognition/ui/GuideModal/ui/GuideStep';
import { GuideText } from 'widgets/startRecognition/ui/GuideModal/ui/GuideText';

export const GuideModal: FunctionComponent = () => {
	const { t } = useTranslation();
	const [openModal, setOpenModal] = useState(false);
	const handleOpen = () => setOpenModal(true);
	const handleClose = () => setOpenModal(false);

	return (
		<>
			<Box
				sx={{
					position: 'absolute',
					top: '36px',
					right: '40px',
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					cursor: 'pointer',
				}}
				onClick={handleOpen}
			>
				<Icon id={'info_icon'} />
				<Typography
					variant={'button'}
					color={ColorPalette.royalBlue}
					sx={{ textDecoration: 'underline', marginLeft: '16px' }}
				>
					{t('start_recognition.how_to_start.title')}
				</Typography>
			</Box>

			<Dialog
				open={openModal}
				onClose={handleClose}
				PaperComponent={Paper}
				PaperProps={{ title: t('start_recognition.how_to_start.title_guide') as string }}
				sx={{
					'& .MuiPaper-root': {
						width: '984px',
						maxWidth: '984px',
						overflow: 'inherit',
					},
				}}
			>
				<CloseModalButton action={handleClose} />

				<Box sx={{ pr: '120px', maxHeight: '600px', overflowY: 'auto' }}>
					<Typography variant={'subtitle1'} color={ColorPalette.astronaut} sx={{ mt: '44px' }}>
						{t('start_recognition.how_to_start.description')}
					</Typography>

					<GuideStep>{t('start_recognition.how_to_start.step_1.title')}</GuideStep>
					<GuideText>{t('start_recognition.how_to_start.step_1.description')}</GuideText>
					<GuideText>{t('start_recognition.how_to_start.step_1.description_2')}</GuideText>

					<GuideStep>{t('start_recognition.how_to_start.step_2.title')}</GuideStep>
					<GuideText>{t('start_recognition.how_to_start.step_2.description')}</GuideText>
					<GuideListItem>{t('start_recognition.how_to_start.step_2.li_1')}</GuideListItem>

					<GuideStep>{t('start_recognition.how_to_start.step_3.title')}</GuideStep>
					<GuideText>{t('start_recognition.how_to_start.step_3.description')}</GuideText>
					<GuideListItem>{t('start_recognition.how_to_start.step_3.li_1')}</GuideListItem>
					<GuideListItem>{t('start_recognition.how_to_start.step_3.li_2')}</GuideListItem>

					<GuideStep>{t('start_recognition.how_to_start.step_4.title')}</GuideStep>
					<GuideText>{t('start_recognition.how_to_start.step_4.description')}</GuideText>
				</Box>

				<Button sx={{ ml: 'auto', mt: '42px' }} onClick={handleClose}>
					{t('start_recognition.how_to_start.get_started')}
				</Button>
			</Dialog>
		</>
	);
};
