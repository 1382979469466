import { Box, Divider, Typography } from '@mui/material';
import { FileType } from 'entities/Files/types';
import { FC } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';
import { FileCard } from 'widgets/importFiles/importFilesDirect/ui/FileCard';

interface Props {
	existFile: FileType & { checked: boolean };
	newFile: FileType & { checked: boolean };
	onToggle: (fileId: string) => void;
}

export const FilesComparisonRow: FC<Props> = ({ existFile, newFile, onToggle }) => {
	return (
		<>
			<Divider color={ColorPalette.periwinkle} sx={{ margin: '14px 0 24px' }} />
			<Typography
				variant={'caption'}
				color={ColorPalette.baliHai}
				sx={{ marginLeft: '28px', marginBottom: '12px' }}
			>
				{newFile.fileName}
			</Typography>
			<Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: '24px' }}>
				<FileCard
					file={existFile}
					checked={existFile.checked}
					onToggle={onToggle}
					sx={{ width: '50%' }}
				/>
				<FileCard
					file={newFile}
					checked={newFile.checked}
					onToggle={onToggle}
					sx={{ width: '50%' }}
				/>
			</Box>
		</>
	);
};
