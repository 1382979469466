import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
// TODO refactor this hook
// type OutputFileSearchParams = {
//   type: CustomOutputFileTypeEnum;
//   field: CustomOutputFileCategoryEnum,
// };

// const changeSearchParams = (searchParams: Partial<OutputFileSearchParams>) =>
//   setSearchParams((prev) => ({ ...prev, ...searchParams }));

export const useSearchParam = (
	sortParamKey: string,
	initialSortParam?: string,
): [string | undefined, (value: string | undefined) => void] => {
	const [searchParams, setSearchParams] = useSearchParams();

	const currentSearchParam = useMemo(() => searchParams.get(sortParamKey), [searchParams]);

	useEffect(() => {
		if (currentSearchParam === null && initialSortParam !== undefined) {
			setSortParam(initialSortParam);
		}
	}, [currentSearchParam, initialSortParam]);

	const setSortParam = (value: string | undefined) => {
		setSearchParams((searchParams) => {
			if (value !== undefined) {
				searchParams.set(sortParamKey, value);
			}

			return searchParams;
		});
	};

	return useMemo(() => [currentSearchParam ?? undefined, setSortParam], [currentSearchParam, setSortParam]);
};
