import { Box, Dialog } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FC, useCallback, useEffect, useState, useMemo } from 'react';
import { CategoryTypeEnum } from 'entities/Files/types';
import { Button } from 'shared/ui/Button';
import { CloseModalButton } from 'shared/ui/CloseModalButton';
import { Paper } from 'shared/ui/Paper';
import { CategoryCard } from 'widgets/startRecognition/ui/CategoryCard';
import { CategoriesList } from 'widgets/startRecognition/consts/CategoryList';
import { Input } from 'shared/ui/Input';
import { createCustomModel, patchCustomModel } from 'entities/CustomModel/api';
import { enqueueSnackbar } from 'notistack';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';

interface Props {
	open: boolean;
	handleClose: () => void;
	replaceModel: () => void;
	editModeId?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	customModels?: Array<any>;
}

export const CreateModelModal: FC<Props> = ({
	open,
	handleClose,
	replaceModel,
	editModeId,
	customModels,
}) => {
	const { t } = useTranslation();
	const [modelName, setModelName] = useState('');

	const category = useMemo(() => CategoriesList.find((item) => item.id === CategoryTypeEnum.custom), []);

	useEffect(() => {
		if (editModeId) {
			const model = customModels?.find((model) => model._id === editModeId);
			if (model) {
				setModelName(model.name);
			}
		} else {
			setModelName('');
		}
	}, [editModeId]);

	const handleCreateModel = useCallback(() => {
		const unikModelName = customModels?.find((model) => model.name === modelName);
		if (unikModelName) {
			return;
		}
		if (editModeId) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			patchCustomModel({ name: modelName }, editModeId).then((res: any) => {
				if (res.data) {
					setModelName('');
					handleClose();
					replaceModel();
				}
			});
		} else {
			createCustomModel({ name: modelName })
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				.then((res: any) => {
					if (res.data) {
						setModelName('');
						handleClose();
						replaceModel();
					} else {
						setModelName('');
						handleClose();
					}
				})
				.catch((error: Error) => {
					const defaultErrorMessage = t('custom_model.create_edit_modal.error');
					const errorMessage = getErrorMessage(error, defaultErrorMessage);
					enqueueSnackbar({
						message: errorMessage,
						variant: 'error',
					});
					setModelName('');
					handleClose();
				});
		}
	}, [modelName]);

	const handleModelNameChange = (name: string) => {
		setModelName(name);
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			PaperComponent={Paper}
			PaperProps={{
				title: editModeId
					? (t('custom_model.create_edit_modal.edit_title') as string)
					: (t('custom_model.create_model') as string),
			}}
			sx={{
				'& .MuiPaper-root': {
					width: '506px',
					maxWidth: '506px',
					overflow: 'inherit',
				},
			}}
		>
			<CloseModalButton action={handleClose} />
			{category && <CategoryCard category={category} />}

			<Box sx={{ mt: '24px', mb: '0' }}>
				<Input
					name="modelName"
					label={t('custom_model.create_edit_modal.label')}
					placeholder={t('custom_model.create_edit_modal.placeholder') as string}
					type="text"
					required
					onChange={(event) => {
						handleModelNameChange(event?.target?.value);
					}}
					value={modelName}
				/>
			</Box>

			<Button fullWidth sx={{ mt: '-10px' }} onClick={handleCreateModel} disabled={!modelName}>
				{!editModeId && t('custom_model.create_model')}
				{editModeId && t('custom_model.create_edit_modal.edit_title')}
			</Button>
		</Dialog>
	);
};
