import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { CoUser } from 'entities/CoUser/types';
import { getUsersLogs } from 'entities/User/api';
import { FunctionComponent, useEffect, useState } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';
import { InfoContainer } from '../../Paper';
import { Icon } from 'shared/ui/Icon';

interface Props {
	coUser: CoUser | null;
}

export const Logs: FunctionComponent<Props> = ({ coUser }) => {
	const { t } = useTranslation();
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [userLogs, setUserLogs] = useState<any[]>([]);

	const getDate = (date: string) => {
		const fileDate = format(new Date(date), 'dd-MM-yyyy HH:mm');
		return fileDate;
	};

	useEffect(() => {
		getUsersLogs(coUser?.userId?._id ?? '')
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			.then((res: any) => {
				setUserLogs(res.data);
			})
			.catch();
	}, []);

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'flex-start',
				alignItems: 'flex-start',
				gap: '1px',
				width: '100%',
			}}
		>
			{
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				userLogs.map((log: any) => (
					<InfoContainer
						key={log._id}
						sx={{
							display: 'flex',
							justifyContent: 'flex-start',
							alignItems: 'center',
							width: '100%',
							height: '89px',
							padding: '21px 32px',
						}}
					>
						<>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'flex-start',
									alignItems: 'center',
									gap: '24px',
									minWidth: '500px',
								}}
							>
								{log?.actionType === 'IMPORT_FILE' && (
									<Icon
										id="couser_import_file"
										style={{
											width: '50px',
											height: '50px',
											marginBottom: '20px',
										}}
									/>
								)}
								{log?.actionType === 'EXPORT_FILE' && (
									<Icon
										id="couser_export_file"
										style={{
											width: '50px',
											height: '50px',
											marginBottom: '20px',
										}}
									/>
								)}
								{log?.actionType === 'MAKE_LABEL' && (
									<Icon
										id="couser_label"
										style={{
											width: '50px',
											height: '50px',
											marginBottom: '20px',
										}}
									/>
								)}
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'flex-start',
									}}
								>
									<Typography
										variant={'body2'}
										color={ColorPalette.astronaut}
										style={{ marginBottom: '12px' }}
									>
										{log?.actionType === 'IMPORT_FILE' && t('co_users.tab_3.import_file')}
										{log?.actionType === 'EXPORT_FILE' && t('co_users.tab_3.export_file')}
										{log?.actionType === 'MAKE_LABEL' &&
											t('co_users.tab_3.make_labelling')}
									</Typography>
									<Typography variant={'body2'} color={ColorPalette.astronaut}>
										{getDate(log?.createdAt)}
									</Typography>
								</Box>
							</Box>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									gap: '8px',
									minWidth: '192px',
								}}
							>
								<Icon id="couser_file" />
								<Typography
									variant={'body2'}
									color={ColorPalette.astronaut}
									style={{
										maxWidth: '160px',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										whiteSpace: 'nowrap',
									}}
								>
									{log?.fileId?.fileName}
								</Typography>
							</Box>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									flexDirection: 'column',
									paddingLeft: '95px',
								}}
							>
								<Typography
									color={ColorPalette.astronaut}
									style={{
										fontFamily: 'Comfortaa',
										fontSize: '8px',
										fontStyle: 'normal',
										fontWeight: '600',
										lineHeight: '12px',
										letterSpacing: '1.5px',
									}}
								>
									{log?.actionType === 'IMPORT_FILE' && t('co_users.tab_3.imported')}
									{log?.actionType === 'EXPORT_FILE' && t('co_users.tab_3.exported')}
									{log?.actionType === 'MAKE_LABEL' && t('co_users.tab_3.imported')}
								</Typography>
								<Typography variant={'body2'} color={ColorPalette.astronaut}>
									{log?.actionType === 'IMPORT_FILE' && t('co_users.tab_3.from_pc')}
									{log?.actionType === 'EXPORT_FILE' && t('co_users.tab_3.to_pc')}
									{log?.actionType === 'MAKE_LABEL' && t('co_users.tab_3.from_pc')}
								</Typography>
							</Box>
						</>
					</InfoContainer>
				))
			}
		</Box>
	);
};
