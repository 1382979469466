import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
	CardNumberElementComponent,
	CardExpiryElementComponent,
	CardCvcElementComponent,
} from '@stripe/react-stripe-js';
import { StripeCardExpiryElementChangeEvent } from '@stripe/stripe-js';
import { useField } from 'formik';
import { Input } from 'shared/ui/Input';
import { ForwardedStripeInput } from 'widgets/Subscriptions/ui/SubCurrentTab/Tabs/PaymentTab/ui/PaymentModal/StripeInput';
interface InputStripeFieldPropsType {
	name: string;
	label: string;
	placeholder: string;
	required?: boolean;
	stripeComponent: CardNumberElementComponent | CardExpiryElementComponent | CardCvcElementComponent;
	onChange: (event: StripeCardExpiryElementChangeEvent) => void;
}

export const InputStripeField: FC<InputStripeFieldPropsType> = ({ onChange, stripeComponent, ...props }) => {
	const { t } = useTranslation();
	const [, meta] = useField(props);
	return (
		<Input
			inputComponent={ForwardedStripeInput}
			inputProps={{
				component: stripeComponent,
			}}
			errorText={meta.touched && meta.error ? (t(meta.error) as string) : undefined}
			onChange={(event) => onChange(event as unknown as StripeCardExpiryElementChangeEvent)}
			{...props}
		/>
	);
};
