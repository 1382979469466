import { Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';
import { useSignUpByInvitationStore } from 'widgets/auth/signUpByInvitation/_MainModal/model/state';

export const StepLabel: FunctionComponent = () => {
	const { step } = useSignUpByInvitationStore();
	return (
		<Typography
			variant={'h5'}
			color={ColorPalette.royalBlue}
			sx={{
				position: 'absolute',
				top: '56px',
				right: '45px',
				lineHeight: '38px',
			}}
		>
			{step}/2
		</Typography>
	);
};
