import { UserRoles } from 'entities/User/types';
import { useAuthStore } from 'processes/auth/model/state';
import { FunctionComponent } from 'react';
import { AdminPersonalAccount, UsersPersonalAccount } from 'widgets/PersonalAccount/rolesUI';

export const PersonalAccount: FunctionComponent = () => {
	const { user } = useAuthStore();

	if (user?.role === UserRoles.admin) {
		return <AdminPersonalAccount />;
	}

	return <UsersPersonalAccount />;
};
