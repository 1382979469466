import { useMemo } from 'react';
import { Box, styled } from '@mui/material';
import { FC } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';

interface SheetTablePropsType {
	isShowHeader?: boolean;
	cells: {
		cellContent: string;
	}[];
}

export const SheetTable: FC<SheetTablePropsType> = ({ cells, isShowHeader = true }) => {
	const generateCellName = (index: number) => {
		let columnName = '';
		while (index >= 0) {
			columnName = String.fromCharCode(65 + (index % 26)) + columnName;
			index = Math.floor(index / 26) - 1;
		}
		return columnName;
	};

	const parcedCells = useMemo(
		() =>
			cells.map((item, index) => ({
				...item,
				title: generateCellName(index),
			})),
		[cells],
	);

	return (
		<Box sx={{ overflow: 'auto', paddingBottom: '15px' }}>
			<Box
				sx={{
					display: 'flex',
					borderLeft: `1px solid ${ColorPalette.lightGray}`,
					boxSizing: 'border-box',
				}}
			>
				<CellStyled
					sx={{ background: `${ColorPalette.catskillWhite}`, minWidth: '35px', maxWidth: '35px' }}
				/>
				{parcedCells.map((item, index) => (
					<CellStyled
						key={`cell_title_${index}`}
						sx={{
							background: `${ColorPalette.catskillWhite}`,
							color: `${ColorPalette.rockBlue}`,
						}}
					>
						{item.title}
					</CellStyled>
				))}
			</Box>

			{isShowHeader && (
				<Box
					sx={{
						display: 'flex',
						borderLeft: `1px solid ${ColorPalette.lightGray}`,
						boxSizing: 'border-box',
					}}
				>
					<CellStyled
						sx={{
							background: `${ColorPalette.catskillWhite}`,
							minWidth: '35px',
							maxWidth: '35px',
						}}
					/>
					{parcedCells.map((item, index) => (
						<CellStyled key={`cell_text_${index}`} sx={{ background: `${ColorPalette.zircon}` }}>
							{item.cellContent}
						</CellStyled>
					))}
				</Box>
			)}
		</Box>
	);
};

const CellStyled = styled(Box)`
	padding: 5px 15px;
	border: 1px solid ${ColorPalette.lightGray};
	box-sizing: border-box;
	border-left: none;
	min-width: 150px;
	max-width: 160px;
	flex-grow: 1;
	text-align: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 12px;
`;
