import { Suspense } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { RoutesConfig } from 'app/config/routes';
import { Config } from 'shared/config/_common';
import { theme } from 'shared/config/theme';
import { CachedSvgSprite } from 'shared/ui/CachedSvgSprite';
import { Loader } from 'shared/ui/Loader';
import { AppContainer } from './ui/AppContainer';
import 'shared/config/i18n';
import './index.scss';

const router = createBrowserRouter(RoutesConfig);

const queryClient = new QueryClient();

const stripePromise = loadStripe(Config.STRIPE_TOKEN);

export const App = () => {
	return (
		<>
			<CachedSvgSprite />
			<Suspense fallback={<Loader type="fullpage" />}>
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<GoogleOAuthProvider clientId={Config.GOOGLE_CLIENT_ID}>
						<QueryClientProvider client={queryClient}>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<Elements stripe={stripePromise}>
									<AppContainer>
										<RouterProvider router={router} />
									</AppContainer>
									<ReactQueryDevtools initialIsOpen={false} />
								</Elements>
							</LocalizationProvider>
						</QueryClientProvider>
					</GoogleOAuthProvider>
				</ThemeProvider>
			</Suspense>
		</>
	);
};
