import { CategoryTypeEnum } from 'entities/Files/types';
import { RoleBasedRoutes, Prefixes } from 'app/config/routes/consts';
import { signOut } from 'processes/auth/helpers/signOut';
import { SidebarMenuLink } from 'widgets/Sidebar/types';

export const SidebarLinks: Array<SidebarMenuLink> = [
	{
		icon: 'search_alt',
		route: RoleBasedRoutes.startRecognition,
	},
	{
		icon: 'custom_model',
		route: RoleBasedRoutes.customModel,
	},
	{
		icon: 'file_dock',
		route: RoleBasedRoutes.files,
		list: [
			{
				path: `${Prefixes.root}${Prefixes.files}/all`,
			},
			{
				path: `${Prefixes.root}${Prefixes.files}/${CategoryTypeEnum.purchase_orders}`,
			},
			{
				path: `${Prefixes.root}${Prefixes.files}/${CategoryTypeEnum.sales_orders}`,
			},
			{
				path: `${Prefixes.root}${Prefixes.files}/${CategoryTypeEnum.invoices}`,
			},
			{
				path: `${Prefixes.root}${Prefixes.files}/${CategoryTypeEnum.receipts}`,
			},
			{
				path: `${Prefixes.root}${Prefixes.files}/${CategoryTypeEnum.transport_documents}`,
			},
			{
				path: `${Prefixes.root}${Prefixes.files}/${CategoryTypeEnum.general_documents}`,
			},
			{
				path: `${Prefixes.root}${Prefixes.files}/${CategoryTypeEnum.custom}`,
			},
		],
	},
	{
		icon: 'notebook',
		route: RoleBasedRoutes.partners,
	},
	{
		icon: 'wallet_alt',
		route: RoleBasedRoutes.subscriptions,
	},
	{
		icon: 'subtasks_alt',
		route: RoleBasedRoutes.extensions,
	},
	{
		icon: 'group_share',
		route: RoleBasedRoutes.coUsers,
	},
	{
		icon: 'group_share',
		route: RoleBasedRoutes.users,
	},
	{
		icon: 'user_circle',
		route: RoleBasedRoutes.personalAccount,
	},
	{
		icon: 'setting_line',
		route: RoleBasedRoutes.integrations,
		list: [
			{
				path: RoleBasedRoutes.integrationsFtpSettings.path,
			},
			{
				path: '/API',
				action: () => {
					window.location.href = 'https://retica.ai/retica-rest-api/';
				},
			},
		],
	},
	{
		icon: 'chat',
		route: RoleBasedRoutes.support,
		action: () => {
			window.location.href = 'https://retica.ai/documentazione/';
		},
	},
	{
		icon: 'resources',
		route: RoleBasedRoutes.resources,
	},
	{
		icon: 'sign_out_square',
		route: RoleBasedRoutes.signOut,
		action: () => {
			signOut();
		},
	},
];
