import { FC, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { FilesDirectImportReq } from 'entities/Files/api/types';
import { startFilesDirectImport } from 'entities/Files/api';
import { getCategoriesSettings } from 'entities/Partners/api';
import { CategoriesSettingsType } from 'entities/Partners/types';
import { Files } from 'pages/Files';
import { CategoriesModal } from 'widgets/Partners/ui/Modals/CategoriesModal';
import { DnDImportFiles } from 'shared/ui/DndImportFiles';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';

export const PartnerDocumentsWidget: FC = () => {
	const queryClient = useQueryClient();
	const { partnerId } = useParams();
	const { t } = useTranslation();
	const [uploadedFiles, setUploadedFiles] = useState<Array<File>>();
	const [categoriesList, setCategoriesList] = useState<CategoriesSettingsType>();
	const [isOpenCategoryModal, setIsOpenCategoryModal] = useState(false);
	const [importedFiles, setImportedFiles] = useState(0);

	const mappedCategories = useMemo(
		() => categoriesList?.categories.map((item) => item.customModelId ?? item.category),
		[categoriesList],
	);

	useQuery({
		queryKey: ['get/partners/categories-settings', partnerId],
		queryFn: () => getCategoriesSettings(partnerId || ''),
		onSuccess: (response) => setCategoriesList(response.data),
		onError: (err) => {
			const errorMessage = getErrorMessage(err);
			enqueueSnackbar({ message: errorMessage, variant: 'error' });
		},
	});

	const { mutate: startFilesDirectImportMutate, isLoading: postisLoading } = useMutation({
		mutationKey: ['post/files/direct-import'],
		mutationFn: startFilesDirectImport,
	});

	const uploadedFilesHandler = (files: Array<File>) => {
		setIsOpenCategoryModal(true);
		setUploadedFiles(files);
	};

	const executeRequestsSequentially = (requestData: FilesDirectImportReq[]) => {
		const executeRequest = async (index: number) => {
			if (index >= requestData.length) {
				await queryClient.refetchQueries({ queryKey: ['get/files'] });
				return;
			}
			const { ...payload } = requestData[index];
			startFilesDirectImportMutate(
				{ ...payload },
				{
					onSuccess: () => {
						executeRequest(index + 1);
						setImportedFiles(index + 1);
					},
					onError: (err) => {
						executeRequest(index + 1);
						setImportedFiles(index + 1);
						const errorMessage = getErrorMessage(err);
						enqueueSnackbar({ message: errorMessage, variant: 'error' });
					},
				},
			);
		};
		executeRequest(0);
	};

	const saveCategoriesHandler = (categories: CategoriesSettingsType) => {
		if (uploadedFiles?.length) {
			const directImportRequestArr = Array.from(uploadedFiles).map((item) => ({
				category: categories.categories[0].category,
				file: item,
				partnerId,
				customModelId: categories.categories[0].customModelId,
			}));
			setIsOpenCategoryModal(false);
			executeRequestsSequentially(directImportRequestArr);
		}
	};

	return (
		<>
			<CategoriesModal
				categoriesList={mappedCategories || []}
				open={isOpenCategoryModal}
				handleClose={() => setIsOpenCategoryModal(false)}
				saveCategories={saveCategoriesHandler}
				isMultiple={false}
			/>
			<Box sx={{ marginBottom: '30px' }}>
				<DnDImportFiles
					setUploadedFiles={uploadedFilesHandler}
					fileDescription={t('start_recognition.how_to_start.step_2.li_1')}
					importingProccess={{
						loading: postisLoading,
						importedFiles,
					}}
				/>
			</Box>
			<Files />
		</>
	);
};
