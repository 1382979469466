import { FC } from 'react';
import { Box, InputBaseProps } from '@mui/material';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Icon } from 'shared/ui/Icon';

interface Props extends InputBaseProps {
	action: () => void;
}

export const CloseModalButton: FC<Props> = ({ action, sx }) => {
	return (
		<Box
			sx={{
				position: 'absolute',
				top: '7px',
				right: '7px',
				transform: 'translate(50%, -50%)',
				width: '20px',
				height: '20px',
				background: ColorPalette.ebonyClay,
				border: `5px solid ${ColorPalette.catskillWhite}`,
				borderRadius: '50%',
				boxSizing: 'content-box',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				cursor: 'pointer',
				...sx,
			}}
			onClick={action}
		>
			<Icon id={'close_round'} color={ColorPalette.white} style={{ width: '16px', height: '16px' }} />
		</Box>
	);
};
