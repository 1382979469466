import { RoleBasedRoutes, Routes } from 'app/config/routes/consts';
import { useAuthStore } from 'processes/auth/model/state';
import { FunctionComponent, ReactElement, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { useBasePath } from 'shared/helpers/useBasePath';
import { UserRoles } from 'entities/User/types';

interface Props {
	children: ReactElement;
}

export const AuthorisedLayout: FunctionComponent<Props> = ({ children }) => {
	const basePath = useBasePath();
	const { accessToken, user, company } = useAuthStore();

	if (!accessToken || !user || !company) {
		return <Navigate to={'/sign-in'} replace />;
	}
	const currentRoute = useMemo(() => {
		return Object.values(RoleBasedRoutes).find((route) => {
			return route.path === basePath;
		});
	}, [basePath]);

	if (!currentRoute?.access.includes(user.role) && user.role === UserRoles.admin) {
		return <Navigate to={Routes.users} replace />;
	}

	if (!currentRoute?.access.includes(user.role)) {
		return <Navigate to={Routes.startRecognition} replace />;
	}
	return <>{children}</>;
};
