import { FC, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';
import { Box, Typography } from '@mui/material';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
	getCustomOutputFileFields,
	patchCustomOutputFileField,
	restoreCustomOutputFile,
} from 'entities/Extensions/api';
import { CustomOutputFieldsType } from 'entities/Extensions/types';
import { CustomOutputFileTypeEnum } from 'entities/Extensions/enums';
import { TabButton } from 'shared/ui/TabButton';
import { Loader } from 'shared/ui/Loader';
import { ActionsForDialog } from 'shared/ui/ActionsForDialog';
import { ColorPalette } from 'shared/consts/colorPalette';
import { OptionsDropdown } from 'shared/ui/OptionsDropdown';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { isIncorrectLabelFormat } from 'shared/helpers/isIncorrectLabelFormat';
import { useCustomOutputFileStore } from 'widgets/Extensions/Details/ui/CustomOutputFile/model/state';
import { CustomOutputFileFields } from './FieldsTable';
import { SettingsTable } from './SettingsTable';
import { ConfirmRestoreModal } from './ConfirmRestoreModal';
import { FilePreviewModal } from './PreviewModal';

type OutputFieldsViewPropsType = {
	outputType: CustomOutputFileTypeEnum;
};

export const OutputFieldsView: FC<OutputFieldsViewPropsType> = ({ outputType }) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const { tabs, selectedOutputType, resetOutputStore } = useCustomOutputFileStore();
	const [activeTabId, setActiveTabId] = useState(selectedOutputType?._id || '');
	const [fieldsState, setFieldsState] = useState<CustomOutputFieldsType>();
	const [restoreModalOpen, setRestoreModalOpen] = useState(false);
	const [previewModalOpen, setPreviewModalOpen] = useState(false);

	const fieldsForPreview = useMemo(
		() =>
			fieldsState && {
				...fieldsState,
				fields: fieldsState?.fields.filter((item) => item.isEnabled),
			},
		[fieldsState],
	);

	const { isLoading: isOutputLoading } = useQuery({
		queryKey: ['get/custom-output-file/fields', activeTabId],
		queryFn: () => getCustomOutputFileFields(activeTabId),
		onSuccess: (response) => {
			setFieldsState(response.data);
		},
		onError: (err) => {
			const errorMessage = getErrorMessage(err);
			enqueueSnackbar({ message: errorMessage, variant: 'error' });
		},
		enabled: !!activeTabId && !fieldsState,
	});

	const { mutate: pacthFieldsMutate, isLoading: pacthFieldsLoading } = useMutation({
		mutationKey: ['patch/custom-output-file/fields'],
		mutationFn: patchCustomOutputFileField,
	});

	const { mutate: restoreFieldsMutate, isLoading: restoreFieldsLoading } = useMutation({
		mutationKey: ['restore/custom-output-file/fields'],
		mutationFn: restoreCustomOutputFile,
	});

	const restoreFieldsHandler = () => {
		restoreFieldsMutate(
			{ id: activeTabId },
			{
				onSuccess: async () => {
					await queryClient.refetchQueries({
						queryKey: ['get/custom-output-file/fields'],
					});
					setRestoreModalOpen(false);
				},
				onError: (err) => {
					const errorMessage = getErrorMessage(err);
					enqueueSnackbar({ message: errorMessage, variant: 'error' });
				},
			},
		);
	};

	const handleSubmitChanges = () => {
		if (fieldsState) {
			const mappedFieldsData = fieldsState.fields.map((item, index) => ({
				_id: item._id,
				customOutputFileId: item.customOutputFileId,
				customFieldName: item.customFieldName,
				order: index + 1,
				isEnabled: item.isEnabled,
			}));
			const isFieldsEnabled = mappedFieldsData.filter((item) => item.isEnabled);
			const filterFieldsByRule = mappedFieldsData.filter((item) =>
				isIncorrectLabelFormat(item.customFieldName),
			);
			if (!isFieldsEnabled.length) {
				enqueueSnackbar({
					message: t('extensions.ex_list.output_file.table_fields_at_least_one'),
					variant: 'error',
				});
			} else if (filterFieldsByRule.length) {
				enqueueSnackbar({
					message: t('extensions.ex_list.output_file.check_all_fields'),
					variant: 'error',
				});
			} else {
				pacthFieldsMutate(
					{
						id: activeTabId,
						data: {
							isEnabled: fieldsState.isEnabled,
							skipHeaders: !!fieldsState.skipHeaders,
							addMissingLabels: !!fieldsState.addMissingLabels,
							fields: mappedFieldsData,
						},
					},
					{
						onSuccess: async () => {
							await queryClient.refetchQueries({
								queryKey: ['get/custom-output-file', outputType],
							});
							resetOutputStore();
						},
						onError: (err) => {
							const errorMessage = getErrorMessage(err);
							enqueueSnackbar({ message: errorMessage, variant: 'error' });
						},
					},
				);
			}
		}
	};

	const handlePreviewChange = (skipHeaders: boolean) => {
		if (fieldsState) {
			setFieldsState({ ...fieldsState, skipHeaders });
			setPreviewModalOpen(false);
		}
	};

	const handleClose = () => {
		resetOutputStore();
	};

	if (isOutputLoading || pacthFieldsLoading || restoreFieldsLoading || !fieldsState) return <Loader />;

	return (
		<>
			<ConfirmRestoreModal
				open={restoreModalOpen}
				handleClose={() => setRestoreModalOpen(false)}
				handleApply={() => restoreFieldsHandler()}
			/>

			{fieldsForPreview && (
				<FilePreviewModal
					fieldsState={fieldsForPreview}
					open={previewModalOpen}
					handleClose={() => setPreviewModalOpen(false)}
					handleApply={handlePreviewChange}
				/>
			)}

			<Box
				sx={{
					display: 'flex',
					justifyContent: tabs ? 'space-between' : 'flex-end',
					alignItems: 'center',
					marginBottom: '44px',
				}}
			>
				{tabs && (
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'flex-start',
							alignItems: 'center',
							gap: '20px',
						}}
					>
						{tabs.map(({ type, name }) => (
							<TabButton
								key={type}
								label={name}
								isActive={type === activeTabId}
								action={() => {
									setActiveTabId(type);
									setFieldsState(undefined);
								}}
							/>
						))}
					</Box>
				)}
				<OptionsDropdown
					iconId={'setting_line_fill'}
					optionList={[
						{
							label: t('extensions.ex_list.output_file.file_preview_dropdown'),
							value: 'preview',
							action: () => {
								setPreviewModalOpen(true);
							},
						},
						{
							label: t('extensions.ex_list.output_file.restore_dropdown'),
							value: 'returnToDefault',
							action: () => {
								setRestoreModalOpen(true);
							},
						},
					]}
				/>
			</Box>

			{fieldsState && <SettingsTable fieldsStateData={fieldsState} setFieldsState={setFieldsState} />}

			<Typography variant={'h5'} color={ColorPalette.astronaut} sx={{ margin: '40px 0' }}>
				{t('extensions.ex_list.output_file.personalized_keys')}
			</Typography>

			<CustomOutputFileFields fieldsData={fieldsState} setFieldsState={setFieldsState} />

			<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
				<Box sx={{ width: '400px', marginTop: '40px' }}>
					<ActionsForDialog
						cancelLabel={t('shared_texts.cancel')}
						cancelAction={handleClose}
						applyLabel={t('shared_texts.save')}
						applyAction={handleSubmitChanges}
						applyActionDisabled={false}
					/>
				</Box>
			</Box>
		</>
	);
};
