import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { GetPartnersRes } from 'entities/Partners/api/types';

type partnersStoreType = {
	partners: GetPartnersRes | null;
};

type partnersActions = {
	setPartnersData: (partners: GetPartnersRes) => void | Promise<void>;
	reset: () => void;
};

const initialState: partnersStoreType = {
	partners: null,
};

export const partnersStore = create<partnersStoreType & partnersActions>()(
	persist(
		(set) => ({
			...initialState,
			setPartnersData: (partners) => set(() => ({ partners })),
			reset: () => set(() => ({ ...initialState })),
		}),
		{
			name: 'partnersStore',
		},
	),
);

export const usePartnersStore = () => partnersStore((state) => state);
