import { Box, Collapse, Divider, Paper, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FileType } from 'entities/Files/types';
import { FC } from 'react';
import { IconButton } from 'shared/ui/IconButton';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Shadow } from 'shared/consts/shadow';
import { Icon } from 'shared/ui/Icon';

interface Props {
	openFileLabels: boolean;
	toggleFileLabels: () => void;
	file?: FileType;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	labels: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	deleteRegion: (region: any) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	handleOpen: (region: any) => void;
}
export const FileLabels: FC<Props> = ({
	openFileLabels,
	toggleFileLabels,
	labels,
	deleteRegion,
	handleOpen,
}) => {
	const { t } = useTranslation();

	return (
		<Box
			sx={{
				'width': '100%',
				'boxShadow': Shadow['2'],
				'background': ColorPalette.white,
				'borderRadius': '20px',
				'padding': '25px',
				'@media screen and (min-width: 1441px)': {
					padding: '40px',
				},
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'space-between',
					cursor: 'pointer',
				}}
				onClick={() => toggleFileLabels()}
			>
				<Typography variant={'h5'} color={ColorPalette.astronaut}>
					{t('files.details.labels')}
				</Typography>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
					}}
				>
					<Box
						sx={{
							fontSize: '24px',
							fontWeight: '700',
							color: ColorPalette.royalBlue,
							paddingRight: '19px',
							paddingTop: '3px',
						}}
					>
						{labels.length}
					</Box>
					{/* <Icon id={'add_round_blue'} style={{ marginRight: '19px' }} /> */}
					<Icon id={'custom_arrow_down'} color={ColorPalette.astronaut} />
				</Box>
			</Box>

			<Collapse in={openFileLabels} timeout="auto" unmountOnExit>
				<Divider color={ColorPalette.periwinkle} sx={{ margin: '20px 0 20px' }} />
				{labels.length > 0 &&
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					labels.map((region: any, i: number) => {
						return (
							<Paper
								key={i}
								sx={{
									'display': 'flex',
									'flexDirection': 'column',
									'padding': '20px 25px',
									'boxShadow': '14px 17px 40px 4px rgba(112, 144, 176, 0.08)',
									'backgroundColor': ColorPalette.white,
									'borderRadius': '10px',
									'margin': '5px 0',
									'gap': '20px',
									'@media screen and (min-width: 1441px)': {
										padding: '30px',
									},
								}}
							>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'space-between',
									}}
								>
									<TitleStyled variant={'body1'} color={ColorPalette.astronaut}>
										{region.name}
									</TitleStyled>
									<Box sx={{ display: 'flex', gap: '5px' }}>
										<IconButton
											iconId={'choose_subcategory'}
											sxWrapper={{
												height: 'auto',
												width: 'auto',
												backgroundColor: 'transparent',
											}}
											onClick={() => handleOpen(region)}
										/>
										<IconButton
											iconId={'trush_icon_red'}
											sxWrapper={{
												height: 'auto',
												width: 'auto',
												backgroundColor: 'transparent',
											}}
											onClick={() => deleteRegion(region?.data?.index)}
										/>
									</Box>
								</Box>
								<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
									<TextStyled variant={'body1'} color={ColorPalette.baliHai}>
										{region.replaceVal || region.text}
									</TextStyled>
									{region.autocomplete && (
										<Icon id={'replace_value'} color={ColorPalette.astronaut} />
									)}
								</Box>
							</Paper>
						);
					})}
			</Collapse>
		</Box>
	);
};

const BaseTextStyled = styled(Typography)`
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
`;

const TitleStyled = styled(BaseTextStyled)`
	width: 75%;
`;

const TextStyled = styled(BaseTextStyled)`
	width: 100%;
`;
