import { FC, useState } from 'react';
import { useMemo } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';
import { UserForAdmin } from 'entities/admin/Users/types';
import { SExportsEnum } from 'entities/Subscriptions/enums';
import { InvoicesForAdminType } from 'entities/admin/Users/types';
import { TableWithHeader } from 'shared/ui/tables/TableWithHeader';
import { EmptyTable } from 'shared/ui/EmptyTable';
import { DownloadModal } from 'shared/ui/DownloadModal';
import { getSubscriptionInvoicesExportAdmin } from 'entities/Subscriptions/api';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { BillingTableColumns } from 'widgets/admin/UserDetails/ui/tabs/Billing/BillingTable/BillingTableColumns';

type Props = {
	billing: InvoicesForAdminType[];
	user: UserForAdmin | null;
	isSubscriptionUnlimited: boolean;
};

export const BillingTable: FC<Props> = ({ billing, isSubscriptionUnlimited }) => {
	const { t } = useTranslation();
	const [open, setOpen] = useState<boolean>(false);
	const [downloadFileId, setDownloadFileId] = useState<string | null>(null);
	const emptyTableText: string = !isSubscriptionUnlimited
		? t('shared_texts.not_found')
		: t('admin.users.details.empty_billing');
	const sortedBilling = billing?.sort(
		(a, b) => new Date(a.billingAt).getTime() - new Date(b.billingAt).getTime(),
	);

	const preparedBilling = useMemo(() => {
		if (!sortedBilling.length) return [];
		return sortedBilling.map((billingItem) => ({
			...billingItem,
			downloadAll: () => {
				setOpen(true);
				setDownloadFileId(null);
			},
			downloadSingle: () => {
				setOpen(true);
				setDownloadFileId(billingItem._id);
			},
		}));
	}, [sortedBilling]);

	const fetchExportFile = async (type: SExportsEnum, fileId: string) => {
		try {
			const response = await getSubscriptionInvoicesExportAdmin({ id: fileId, format: type });
			const file = billing?.find((item) => item._id === fileId);
			const blob = new Blob([response.data], { type: `text/${type}` });
			saveAs(blob, `Retica-invoice-${file?.invoiceNumber}.${type}`);
		} catch (err) {
			const errorMessage = getErrorMessage(err);
			enqueueSnackbar({
				message: errorMessage,
				variant: 'error',
			});
		}
	};
	const handleDownload = async (type: SExportsEnum) => {
		if (downloadFileId) {
			await fetchExportFile(type, downloadFileId);
		} else {
			sortedBilling?.forEach((item) => {
				fetchExportFile(type, item._id);
			});
		}
	};

	return (
		<>
			{billing?.length && !isSubscriptionUnlimited ? (
				<TableWithHeader
					columns={BillingTableColumns}
					rows={preparedBilling.map((billing) => ({ ...billing, id: billing._id }))}
				/>
			) : (
				<EmptyTable text={emptyTableText} />
			)}
			<DownloadModal
				open={open}
				handleDownload={handleDownload}
				handleClose={() => {
					setOpen(false);
				}}
			/>
		</>
	);
};
