import { CategoryTypeEnum } from 'entities/Files/types';
import purchase_orders from 'shared/assets/categories/purchase_orders.png';
import sales_orders from 'shared/assets/categories/sales_orders.png';
import receipts from 'shared/assets/categories/receipts.png';
import general_tables from 'shared/assets/categories/general_tables.png';
import invoices from 'shared/assets/categories/invoices.png';
import custom_model from 'shared/assets/categories/custom_model.png';
import transport_documents from 'shared/assets/categories/transport_documents.png';

export type Category = {
	id: CategoryTypeEnum;
	label: string;
	description?: string;
	img: string;
	type: string;
};

export const CategoriesList: Array<Category> = [
	{
		id: CategoryTypeEnum.purchase_orders,
		label: 'sidebar.menu./files/purchase-orders',
		description: 'start_recognition.category_descriptions.purchase-orders',
		img: purchase_orders,
		type: 'shared_texts.doc_type_standard',
	},
	{
		id: CategoryTypeEnum.sales_orders,
		label: 'sidebar.menu./files/sales-orders',
		description: 'start_recognition.category_descriptions.sales-orders',
		img: sales_orders,
		type: 'shared_texts.doc_type_standard',
	},
	{
		id: CategoryTypeEnum.invoices,
		label: 'sidebar.menu./files/invoices',
		description: 'start_recognition.category_descriptions.invoices',
		img: invoices,
		type: 'shared_texts.doc_type_standard',
	},
	{
		id: CategoryTypeEnum.receipts,
		label: 'sidebar.menu./files/receipts',
		description: 'start_recognition.category_descriptions.receipts',
		img: receipts,
		type: 'shared_texts.doc_type_standard',
	},
	{
		id: CategoryTypeEnum.transport_documents,
		label: 'sidebar.menu./files/transport-documents',
		description: 'start_recognition.category_descriptions.transport-documents',
		img: transport_documents,
		type: 'shared_texts.doc_type_standard',
	},
	{
		id: CategoryTypeEnum.general_documents,
		label: 'sidebar.menu./files/general-documents',
		description: 'start_recognition.category_descriptions.general-documents',
		img: general_tables,
		type: 'shared_texts.doc_type_standard',
	},
	{
		id: CategoryTypeEnum.custom,
		label: 'sidebar.menu./files/custom',
		description: 'start_recognition.category_descriptions.custom',
		img: custom_model,
		type: 'shared_texts.doc_type_custom',
	},
];
