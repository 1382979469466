import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Shadow } from 'shared/consts/shadow';
import { Button } from 'shared/ui/Button';

interface Props {
	handleOpen: () => void;
}

export const FtpImportFiles: FC<Props> = ({ handleOpen }) => {
	const { t } = useTranslation();
	return (
		<>
			<Box
				sx={{
					height: '383px',
					width: '100%',
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'center',
					padding: '40px',
					backgroundColor: ColorPalette.white,
					boxShadow: Shadow['2'],
					borderRadius: '20px',
				}}
			>
				<Box sx={{ width: '50%' }}>
					<Box
						sx={{
							width: 'fit-content',
							padding: '56px 57px',
							backgroundColor: ColorPalette.catskillWhite,
							borderRadius: '10px',
							border: `1px solid ${ColorPalette.periwinkle}`,
							marginLeft: 'auto',
						}}
					>
						<Button variant={'contained'} onClick={handleOpen}>
							{t('start_recognition.upload_files_section.import_files')}
						</Button>
					</Box>
				</Box>
				<Box sx={{ width: '50%', display: 'flex', flexDirection: 'column', paddingLeft: '40px' }}>
					<Typography
						variant={'caption'}
						component={'span'}
						color={ColorPalette.astronaut}
						sx={{ pB: '8px' }}
					>
						{t('start_recognition.how_to_start.step_2.li_1')}
					</Typography>
				</Box>
			</Box>
		</>
	);
};
