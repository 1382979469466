import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { Dialog, Typography, Box } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { createAssertionViaFile } from 'entities/Assertions/api';
import { ColorPalette } from 'shared/consts/colorPalette';
import { ActionsForDialog } from 'shared/ui/ActionsForDialog';
import { Paper } from 'shared/ui/Paper';
import { SheetTable } from 'shared/ui/SheetTable';
import { DnDImportFiles } from 'shared/ui/DndImportFiles';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { Loader } from 'shared/ui/Loader';

interface ImportCsvModalPropsType {
	id: string;
	open: boolean;
	handleClose: () => void;
}

export const ImportCsvModal: FC<ImportCsvModalPropsType> = ({ id, open, handleClose }) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const [file, setFile] = useState<File>();

	const sheetPreviewArr = [
		{ cellContent: t('assertions.label.initial_value_trim') },
		{ cellContent: t('assertions.label.replaced_value_trim') },
	];

	const { mutate: postViaFileMutate, isLoading: postIsLoading } = useMutation({
		mutationKey: ['post/assertions-via-file'],
		mutationFn: createAssertionViaFile,
	});

	const uploadCsvHandler = () => {
		if (file) {
			postViaFileMutate(
				{ id, data: { file } },
				{
					onSuccess: () => {
						queryClient.refetchQueries({ queryKey: ['get/assertion-sets/assertions'] });
						enqueueSnackbar({
							message: t('assertions.snackbar.success_assertion_creation'),
							variant: 'success',
						});
						handleClose();
						setFile(undefined);
					},
					onError: (err) => {
						setFile(undefined);
						const errorMessage = getErrorMessage(err);
						enqueueSnackbar({ message: errorMessage, variant: 'error' });
					},
				},
			);
		}
	};

	const uploadedFilesHandler = (files: Array<File>) => {
		if (files && files?.length > 1) {
			enqueueSnackbar({ message: t('shared_texts.import_csv_file_only_one'), variant: 'error' });
		} else if (files) {
			setFile(files[0]);
		}
	};

	return (
		<Dialog
			open={open}
			onClose={() => {
				handleClose();
				setFile(undefined);
			}}
			PaperComponent={Paper}
			PaperProps={{ title: t('assertions.modal.creation_as_via_scv_title') as string }}
			sx={{
				'width': '650px',
				'margin': '0 auto',
				'& .MuiPaper-root': {
					minWidth: '100%',
				},
			}}
		>
			{postIsLoading ? (
				<Loader />
			) : (
				<Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
					<Typography variant={'body1'} color={ColorPalette.baliHai}>
						{t('assertions.modal.creation_as_via_scv_description')}
					</Typography>

					<SheetTable cells={sheetPreviewArr} />

					<DnDImportFiles
						setUploadedFiles={uploadedFilesHandler}
						fileDescription={t('partners.modals.import_csv_file_type')}
						uploaded={Boolean(file)}
					/>

					<ActionsForDialog
						cancelLabel={t('shared_texts.cancel')}
						cancelAction={handleClose}
						applyLabel={t('shared_texts.import')}
						applyAction={uploadCsvHandler}
					/>
				</Box>
			)}
		</Dialog>
	);
};
