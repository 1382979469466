import { FunctionComponent, useState } from 'react';
import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { enqueueSnackbar } from 'notistack';
import { ColorPalette } from 'shared/consts/colorPalette';
import { CategoryList } from 'widgets/startRecognition/ui/CategoryList';
import { Category } from 'widgets/startRecognition/consts/CategoryList';
import { DocsUploadModal } from 'widgets/startRecognition/ui/DocsUploadModal';
import { PageHeader } from 'shared/ui/PageHeader';
import { GuideModal } from 'widgets/startRecognition/ui/GuideModal';
import { SessionsDashboard } from 'widgets/startRecognition/ui/SessionsDashboard';
import { useImportFilesStore } from 'widgets/importFiles/state';
import { getImportSessions } from 'entities/ImportSessions/api';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';

export const StartRecognition: FunctionComponent = () => {
	const { t } = useTranslation();
	const { data, setData } = useImportFilesStore();
	const [uploadCategory, setUploadCategory] = useState<Category | undefined>(undefined);

	useQuery({
		queryKey: ['get/import-sessions'],
		queryFn: () => getImportSessions(),
		onSuccess: (response) => {
			setData(response.data);
		},
		onError: (err) => {
			const errorMessage = getErrorMessage(err);
			enqueueSnackbar({
				message: errorMessage,
				variant: 'error',
			});
		},
	});

	const handleOpenDocsUploadModal = (category: Category) => setUploadCategory(category);
	const handleCloseDocsUploadModal = () => setUploadCategory(undefined);

	return (
		<>
			<PageHeader
				title={t('start_recognition.title')}
				description={t('start_recognition.description')}
			/>
			<GuideModal />
			<CategoryList openUploadModal={handleOpenDocsUploadModal} />
			{uploadCategory && (
				<DocsUploadModal
					isActiveSessions={Boolean(data?.length)}
					category={uploadCategory}
					handleClose={handleCloseDocsUploadModal}
				/>
			)}
			<Divider color={ColorPalette.periwinkle} sx={{ margin: '64px 0 44px 0' }} />
			<SessionsDashboard sessions={data ?? []} />
		</>
	);
};
