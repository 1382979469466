export enum ExtensionsEnum {
	assertions_sets = 'assertions-sets',
	custom_output_file = 'custom-output-file',
	warehouse = 'warehouse',
}

export enum CustomOutputFileTypeEnum {
	prebuild = 'prebuilt',
	custom = 'custom',
}

export enum CustomOutputFileCategoryEnum {
	custom = 'custom',
	invoices = 'invoices',
	purchase_orders = 'purchase-orders',
	sales_orders = 'sales-orders',
	receipts = 'receipts',
}
