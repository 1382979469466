import * as yup from 'yup';
import { FeaturesEnum } from 'entities/Company/types';

export type AdditionalFeaturesFormType = yup.InferType<typeof AdditionalFeaturesSchema>;

export const AdditionalFeaturesSchema = yup.object({
	[FeaturesEnum.NEURAL_MODEL]: yup.bool().default(false),
	neuralModelsLimit: yup.number().default(0),
	[FeaturesEnum.RESELLER]: yup.bool().default(false),
});
