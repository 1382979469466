import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Typography, Box } from '@mui/material';
import { CategoryTypeEnum } from 'entities/Files/types';
import { CategoriesSettingsType } from 'entities/Partners/types';
import { Select } from 'shared/ui/Select';
import { Loader } from 'shared/ui/Loader';
import { SelectOption } from 'shared/ui/Select/types';
import { ColorPalette } from 'shared/consts/colorPalette';
import { ActionsForDialog } from 'shared/ui/ActionsForDialog';
import { Paper } from 'shared/ui/Paper';
import { CategoriesList } from 'widgets/startRecognition/consts/CategoryList';
import { useCustomModelStore } from 'widgets/customModel/state';

interface CategoriesModalPropsType {
	categoriesList: string[];
	open: boolean;
	handleClose: () => void;
	saveCategories: (data: CategoriesSettingsType) => void;
	isLoading?: boolean;
	isMultiple?: boolean;
}

export const CategoriesModal: FC<CategoriesModalPropsType> = ({
	categoriesList,
	open,
	handleClose,
	isLoading,
	saveCategories,
	isMultiple = true,
}) => {
	const { t } = useTranslation();
	const { customModels } = useCustomModelStore();
	const [errorMessage, setErrorMessage] = useState(false);
	const [selectedOptions, setSelectedOptions] = useState<string | string[]>();

	const optionsForSelect: SelectOption[] = useMemo(() => {
		const mappedCustomModels = customModels.map((item) => ({
			value: item._id,
			label: item.name,
			customLabel: (
				<Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
					<Typography>{item.name}</Typography>
					<Typography variant="body2" color={ColorPalette.baliHai}>
						{t('shared_texts.doc_type_custom')}
					</Typography>
				</Box>
			),
		}));
		const mappedStandardList: SelectOption[] = CategoriesList.filter(
			(item) => item.id !== CategoryTypeEnum.custom,
		).map((item) => ({
			value: item.id,
			label: t(item.label),
			customLabel: (
				<Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
					<Typography>{t(item.label)}</Typography>
					<Typography variant="body2" color={ColorPalette.baliHai}>
						{t(item.type)}
					</Typography>
				</Box>
			),
		}));
		if (isMultiple) {
			return [...mappedStandardList, ...mappedCustomModels].filter(
				(item) => !categoriesList.includes(item.value as string),
			);
		}
		return [...mappedStandardList, ...mappedCustomModels].filter((item) =>
			categoriesList.includes(item.value as string),
		);
	}, [open]);

	const submitCategoriesSettingsHandler = () => {
		if (selectedOptions) {
			const categoriesToMap = isMultiple
				? [...categoriesList, ...(selectedOptions as string[])]
				: [selectedOptions as string];
			const categories = categoriesToMap.map((option) => {
				const standard = CategoriesList.find(
					(category) => category.id === (option as CategoryTypeEnum),
				);
				return standard
					? { category: option as CategoryTypeEnum }
					: {
							category: CategoryTypeEnum.custom,
							customModelId: option,
					  };
			});
			saveCategories({ categories });
			setErrorMessage(false);
			setSelectedOptions(undefined);
		} else {
			setErrorMessage(true);
		}
	};

	return (
		<Dialog
			open={open}
			onClose={() => {
				handleClose();
				setSelectedOptions(undefined);
				setErrorMessage(false);
			}}
			PaperComponent={Paper}
			PaperProps={{ title: t('partners.modals.select_model_title') as string }}
		>
			{isLoading ? (
				<Loader />
			) : (
				<Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
					<Typography variant={'body1'} color={ColorPalette.baliHai}>
						{t('partners.modals.select_model_description')}
					</Typography>

					<Select
						sx={{ margin: '0' }}
						label={t('partners.modals.select_model_title') as string}
						placeholder={t('partners.placeholder.model_type')}
						value={selectedOptions || []}
						onSelect={(value) => {
							if (isMultiple) {
								setSelectedOptions([...(value as string[])]);
							} else {
								setSelectedOptions(value as string);
							}
						}}
						options={optionsForSelect || []}
						errorText={errorMessage ? (t('partners.placeholder.model_type') as string) : ''}
						multiple={isMultiple}
						required
					/>

					<ActionsForDialog
						cancelLabel={t('shared_texts.cancel')}
						cancelAction={handleClose}
						applyLabel={t('shared_texts.save')}
						applyAction={submitCategoriesSettingsHandler}
						applyActionDisabled={false}
					/>
				</Box>
			)}
		</Dialog>
	);
};
