import { useContext } from 'react';
import { SubTabContext, SubTabType } from './SubscriptionsTabContext';

interface UseSubTabResult {
	setTab: (tab: SubTabType) => void;
	tab?: SubTabType;
}

export const useSubTab = (): UseSubTabResult => {
	const { tab, setTab } = useContext(SubTabContext);

	return {
		setTab,
		tab,
	};
};
