import { FC, useMemo } from 'react';
import { useSearchParams, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FtpSettingsTypeEnum } from 'entities/Integrations/enums';
import { PageHeader } from 'shared/ui/PageHeader';
import { SyncSettingsWidget } from 'widgets/SyncSettings';

export const SyncSettings: FC = () => {
	const { t } = useTranslation();
	const [searchParams] = useSearchParams();
	const syncType = useMemo(() => (searchParams.get('type') as FtpSettingsTypeEnum) || '', [searchParams]);

	const renderContent = () => {
		switch (syncType) {
			case FtpSettingsTypeEnum.partners:
				return (
					<>
						<PageHeader
							title={t('sync_settings.partner_title')}
							description={t('sync_settings.partner_description')}
						/>
						<SyncSettingsWidget syncSettingsType={syncType} />
					</>
				);
			case FtpSettingsTypeEnum.warehouse:
				return (
					<>
						<PageHeader
							title={t('sync_settings.warehouse_title')}
							description={t('sync_settings.warehouse_description')}
						/>
						<SyncSettingsWidget syncSettingsType={syncType} />
					</>
				);
			default:
				return <Navigate to="/" />;
		}
	};

	return <>{renderContent()}</>;
};
