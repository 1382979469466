import { FC, useState, useMemo } from 'react';
import { Box, Collapse, Divider, Typography } from '@mui/material';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';
import { format } from 'date-fns';
import { FileType, SavedFileStatus } from 'entities/Files/types';
import { retryImportFile } from 'entities/ImportSessions/api';
import { RecognizedLabelsStructureType } from 'entities/Files/types';
import { AsModalList } from 'widgets/Extensions/Details/ui/AssertionSets/ui/AsModalList';
import { useAssertionSetsStore } from 'widgets/Extensions/Details/ui/AssertionSets/model/state';
import { useCustomModelStore } from 'widgets/customModel/state';
import { useFilesStore } from 'widgets/editFile/model/state';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { useExtension } from 'shared/hooks/useExtension';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Shadow } from 'shared/consts/shadow';
import { Icon } from 'shared/ui/Icon';
import { NativeSelect } from 'shared/ui/NativeSelect';
import { ExtensionsEnum } from 'entities/Extensions/enums';
import { PartnersEnum } from 'entities/Partners/enums';

interface Props {
	openFileDetails: boolean;
	toggleFileDetails: () => void;
	file?: FileType;
	submitChanges?: any;
}

export const FileDetails: FC<Props> = ({ openFileDetails, toggleFileDetails, file, submitChanges }) => {
	const { t } = useTranslation();
	const { fileId } = useParams();
	const { getExtension } = useExtension();
	const { assertionsSets } = useAssertionSetsStore();
	const { setFileDetails } = useFilesStore();
	const { setCustomFileDetails } = useCustomModelStore();
	const [isAssertionsOpen, setIsAssertionsOpen] = useState(false);

	const assertionSet = useMemo(
		() => assertionsSets?.find((item) => item._id === file?.assertionSetsId),
		[assertionsSets, file],
	);

	const { mutate, isLoading } = useMutation({
		mutationKey: ['patch/files/recognition-result'],
		mutationFn: retryImportFile,
	});

	const updateFileAssertionsHandler = (asId?: string) => {
		const submit: RecognizedLabelsStructureType[] = submitChanges().labels;

		const newRecognized: RecognizedLabelsStructureType[] =
			submit?.map((item) => {
				if (item.type === 'table') {
					const newCells = item.cells.map((cellItem) => ({
						...cellItem,
						assertionSetsId: undefined,
						assertionsData: undefined,
					}));
					return { ...item, cells: newCells };
				}
				return { ...item, assertionSetsId: undefined, assertionsData: undefined };
			}) || [];

		mutate(
			{
				id: file?._id,
				data: {
					recognizedLabels:
						file?.assertionSetsId !== asId ? newRecognized : file?.recognizedLabels || [],
					assertionSetsId: !asId ? null : asId,
					isPreview: true,
				},
			},
			{
				onSuccess: (response) => {
					setIsAssertionsOpen(false);
					if (asId) {
						enqueueSnackbar({
							message: t('assertions.modal.success_adding'),
							variant: 'success',
						});
					} else {
						enqueueSnackbar({
							message: t('assertions.modal.success_removing'),
							variant: 'success',
						});
					}
					if (fileId) {
						setFileDetails(response.data);
					} else {
						setCustomFileDetails(response.data);
					}
				},
				onError: (err) => {
					const errorMessage = getErrorMessage(err);
					enqueueSnackbar({ message: errorMessage, variant: 'error' });
				},
			},
		);
	};

	const changePartnerObtaining = (autocomplete: boolean) => {
		mutate(
			{
				id: file?._id,
				data: {
					recognizedLabels: file?.recognizedLabels || [],
					assertionSetsId: file?.assertionSetsId || null,
					isPreview: true,
					autocomplete,
				},
			},
			{
				onSuccess: (response) => {
					enqueueSnackbar({
						message: t('partners.snackbar.success_settings_applying'),
						variant: 'success',
					});
					if (fileId) {
						setFileDetails(response.data);
					} else {
						setCustomFileDetails(response.data);
					}
				},
				onError: (err) => {
					const errorMessage = getErrorMessage(err);
					enqueueSnackbar({ message: errorMessage, variant: 'error' });
				},
			},
		);
	};

	const renderFilePreview = (fileStatus: SavedFileStatus | undefined) => {
		switch (fileStatus) {
			case 'RECOGNIZED':
				return (
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'flex-start',
						}}
					>
						<Icon
							id={'done_ring_round'}
							color={ColorPalette.jungleGreen}
							style={{ width: '24px', height: '24px', marginRight: '20px' }}
						/>
						<Typography variant={'body2'} color={ColorPalette.astronaut}>
							{t('files.tabs.tab_3')}
						</Typography>
					</Box>
				);
			case 'UNRECOGNIZED':
				return (
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'flex-start',
						}}
					>
						<Icon
							id={'dell_fill'}
							color={ColorPalette.violetRed}
							style={{ width: '24px', height: '24px', marginRight: '20px' }}
						/>
						<Typography variant={'body2'} color={ColorPalette.astronaut}>
							{t('files.tabs.tab_1')}
						</Typography>
					</Box>
				);
			default:
				return <></>;
		}
	};

	return (
		<>
			<AsModalList
				open={isAssertionsOpen}
				handleApply={updateFileAssertionsHandler}
				handleRemoveAssertions={updateFileAssertionsHandler}
				handleClose={() => setIsAssertionsOpen(false)}
				loading={isLoading}
				defaultAssertionId={file?.assertionSetsId}
			/>
			<Box
				sx={{
					'width': '100%',
					'boxShadow': Shadow['2'],
					'background': ColorPalette.white,
					'borderRadius': '20px',
					'overflow': 'hidden',
					'height': 'fit-content',
					'padding': '25px',
					'@media screen and (min-width: 1441px)': {
						padding: '40px',
					},
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between',
						cursor: 'pointer',
					}}
					onClick={() => toggleFileDetails()}
				>
					<Typography variant={'h5'} color={ColorPalette.astronaut}>
						{t('files.details.files')}
					</Typography>
					<Icon id={'custom_arrow_down'} color={ColorPalette.astronaut} />
				</Box>

				<Collapse in={openFileDetails} timeout="auto" unmountOnExit sx={{ width: '100%' }}>
					<Divider color={ColorPalette.periwinkle} sx={{ margin: '20px 0 20px' }} />
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'flex-start',
							marginBottom: '30px',
						}}
					>
						<Typography variant={'body2'} color={ColorPalette.baliHai} sx={{ width: '160px' }}>
							{t('shared_texts.table_columns.status')}
						</Typography>
						{renderFilePreview(file?.status)}
					</Box>

					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'flex-start',
							marginBottom: '30px',
						}}
					>
						<Typography variant={'body2'} color={ColorPalette.baliHai} sx={{ width: '160px' }}>
							{t('shared_texts.table_columns.imported_at')}
						</Typography>
						<Typography variant={'body2'} color={ColorPalette.astronaut}>
							{file?.autoRemoveAt
								? format(new Date(file?.autoRemoveAt), 'HH:mm dd/MM/yyyy')
								: '-'}
						</Typography>
					</Box>

					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'flex-start',
							marginBottom: '30px',
						}}
					>
						<Typography variant={'body2'} color={ColorPalette.baliHai} sx={{ width: '160px' }}>
							{t('shared_texts.table_columns.source')}
						</Typography>
						<Typography variant={'body2'} color={ColorPalette.astronaut}>
							{file?.uploadSource}
						</Typography>
					</Box>
					{file?.category && (
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'flex-start',
								marginBottom: '23px',
							}}
						>
							<Typography
								variant={'body2'}
								color={ColorPalette.baliHai}
								sx={{ width: '160px' }}
							>
								{t('shared_texts.table_columns.category')}
							</Typography>
							<Typography variant={'body2'} color={ColorPalette.astronaut}>
								{t(`sidebar.menu./files/${file?.category}`)}
							</Typography>
						</Box>
					)}

					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'flex-start',
							marginBottom: '30px',
						}}
					>
						<Typography variant={'body2'} color={ColorPalette.baliHai} sx={{ width: '160px' }}>
							{t('files.details.company_info')}
						</Typography>

						<NativeSelect
							value={file?.autocomplete ? PartnersEnum.client : PartnersEnum.default}
							placeholder=""
							options={companyInfoOptions}
							onSelect={(e) => changePartnerObtaining(Boolean(e))}
							sx={{ width: '60%' }}
						/>
					</Box>
					{fileId && getExtension(ExtensionsEnum.assertions_sets)?.isEnabled && (
						<Box sx={{ display: 'flex' }}>
							<Typography
								variant={'body2'}
								color={ColorPalette.baliHai}
								sx={{ width: '160px' }}
							>
								{t('extensions.ex_list.assertions_sets.title')}
							</Typography>
							<Typography variant={'body2'} color={ColorPalette.astronaut}>
								{assertionSet?.name || '-'}
							</Typography>
							<Box onClick={() => setIsAssertionsOpen(true)} sx={{ marginLeft: '40px' }}>
								<Icon id={'choose_subcategory'} style={{ cursor: 'pointer' }} />
							</Box>
						</Box>
					)}
				</Collapse>
			</Box>
		</>
	);
};

const companyInfoOptions = [
	{
		value: PartnersEnum.default,
		label: 'files.select_options.obtained_from_doc',
	},
	{
		value: PartnersEnum.client,
		label: 'files.select_options.obtained_from_partner',
	},
];
