import { FunctionComponent, useState } from 'react';
import { useQuery } from 'react-query';
import { Loader } from 'shared/ui/Loader';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { getUsers } from 'entities/admin/Users/api';
import { GetUsersReq } from 'entities/admin/Users/api/types';
import { ColorPalette } from 'shared/consts/colorPalette';
import { UsersTable, UsersFilter } from 'widgets/admin/Users';
import { Shadow } from 'shared/consts/shadow';
import UsersPagination from 'widgets/admin/Users/UsersPagination';

export const Users: FunctionComponent = () => {
	const { t } = useTranslation();
	const [filters, setFilters] = useState<GetUsersReq>({
		sort: 'desc',
		page: 1,
	});

	const { data: UsersData, isLoading } = useQuery({
		queryKey: ['admin/users', filters],
		queryFn: () => getUsers({ ...filters }),
	});

	return (
		<>
			<Typography variant={'h4'} color={ColorPalette.astronaut}>
				{t('admin.users.title')}
			</Typography>
			<Typography variant={'subtitle1'} color={ColorPalette.baliHai} sx={{ marginTop: '24px' }}>
				{t('admin.users.description')}
			</Typography>

			<Divider color={ColorPalette.periwinkle} sx={{ margin: '42px 0 44px 0' }} />

			<Stack gap={2} direction={'row'} sx={{ marginTop: '34px', paddingBottom: '44px' }}>
				<Box
					sx={{
						padding: '56px 46px',
						backgroundColor: ColorPalette.white,
						borderRadius: '20px',
						width: 'fit-content',
						display: 'flex',
						flexDirection: 'column',
						gap: '40px',
						boxShadow: Shadow['2'],
					}}
				>
					<Typography variant="h5" color={ColorPalette.astronaut}>
						{t('admin.users.total_amount_description_1')}
					</Typography>

					<Typography variant="h3" color={ColorPalette.royalBlue}>
						{UsersData?.data?.totalBillingAmount
							? `${UsersData?.data?.totalBillingAmount / 100} €`
							: '0 €'}
					</Typography>
				</Box>

				<Box
					sx={{
						padding: '56px 46px',
						backgroundColor: ColorPalette.white,
						borderRadius: '20px',
						width: 'fit-content',
						display: 'flex',
						flexDirection: 'column',
						gap: '40px',
						boxShadow: Shadow['2'],
					}}
				>
					<Typography variant="h5" color={ColorPalette.astronaut}>
						{t('admin.users.total_amount_description_2')}
					</Typography>

					<Typography variant="h3" color={ColorPalette.royalBlue}>
						{UsersData?.data?.totalPaymentAmount
							? `${UsersData?.data?.totalPaymentAmount / 100} €`
							: '0 €'}
					</Typography>
				</Box>
			</Stack>

			<UsersFilter filters={filters} setFilters={setFilters} />

			{isLoading ? (
				<Loader />
			) : (
				<UsersTable
					filters={filters}
					setFilters={setFilters}
					users={UsersData?.data?.results || []}
				/>
			)}

			<UsersPagination
				filters={filters}
				setFilters={setFilters}
				totalPages={UsersData ? Math.ceil(UsersData?.data?.totalCount / 10) : 1}
			/>
		</>
	);
};
