import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Box, Link } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import { ColorPalette } from 'shared/consts/colorPalette';
import mobileStub from 'shared/assets/mobile-stub.png';
import logoWithBlueText from 'shared/assets/logo-with-blue-text.png';

interface AppContainerProps {
	children: ReactElement;
}

export const AppContainer: FC<AppContainerProps> = ({ children }) => {
	const { t } = useTranslation();
	const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 769px)' });

	if (isDesktopOrLaptop) return children;

	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				flexDirection: 'column',
				padding: '20px',
			}}
		>
			<img
				src={logoWithBlueText}
				alt="logo-with-blue-text"
				style={{
					display: 'block',
					margin: '0 auto',
					height: 'auto',
					maxHeight: '50px',
					maxWidth: '100%',
				}}
			/>
			<img
				src={mobileStub}
				alt="mobile-stub"
				style={{ display: 'block', margin: '0 auto', height: 'auto', maxWidth: '100%' }}
			/>

			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					textAlign: 'center',
					gap: '30px',
				}}
			>
				<Typography variant={'h5'} color={ColorPalette.astronaut}>
					{t('shared_texts.mobile_blocking_title')}
				</Typography>
				<Typography variant={'body1'} color={ColorPalette.baliHai}>
					{t('shared_texts.mobile_blocking_text')}
				</Typography>
				<Link
					href="https://retica.ai"
					underline="none"
					color={ColorPalette.white}
					sx={{
						background: ColorPalette.royalBlue,
						padding: '15px 50px',
						borderRadius: '50px',
						marginTop: '30px',
					}}
				>
					{t('shared_texts.home')}
				</Link>
			</Box>
		</Box>
	);
};
