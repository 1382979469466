import { FC, useMemo, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';
import { Routes } from 'app/config/routes/consts';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { EmptyTable } from 'shared/ui/EmptyTable';
import { TableWithHeader } from 'shared/ui/tables/TableWithHeader';
import { AssertionsSetTableColumns } from 'widgets/Extensions/Details/ui/AssertionSets/ui/AsTable/consts/AssertionSetsColumn';
import { DeleteAssertionsModal } from 'widgets/Extensions/Details/ui/AssertionSets/ui/AsModals/DeleteAssertionsModal';
import { deleteAssertionsSet } from 'entities/Assertions/api';
import { AssertionsSetsType } from 'entities/Assertions/types';

type AsSetsTableProps = {
	assertionsSets: AssertionsSetsType[] | undefined;
	handleOpenEdit: (assertion: AssertionsSetsType) => void;
};

export const AsSetsTable: FC<AsSetsTableProps> = ({ assertionsSets, handleOpenEdit }) => {
	const navigate = useNavigate();
	const { exName } = useParams();
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const [setsToRemove, setSetsToRemove] = useState<string[]>([]);
	const [selectedSets, setSelectedSets] = useState<string[]>([]);

	const { mutate } = useMutation({
		mutationKey: ['/assertion-sets/delete-many'],
		mutationFn: deleteAssertionsSet,
	});

	const handleToggleSets = (fileId: string) => {
		setSelectedSets((prevState) => {
			return prevState.includes(fileId)
				? prevState.filter((i) => i !== fileId)
				: [...prevState, fileId];
		});
	};

	const handleToggleAllSets = useCallback(() => {
		setSelectedSets((prevState) => {
			if (assertionsSets && assertionsSets?.length === prevState.length) {
				return [];
			}
			if (assertionsSets && assertionsSets?.length > prevState.length) {
				return [...assertionsSets.map((assertion) => assertion._id)];
			}
			return [];
		});
	}, [assertionsSets]);

	const handleEdit = useCallback(
		(id: string) => {
			const assertionSet = assertionsSets?.find((item) => item._id === id);
			if (assertionSet) {
				handleOpenEdit(assertionSet);
			}
		},
		[assertionsSets],
	);

	const handleDelete = useCallback(
		(fileId?: string) => {
			if (!fileId && selectedSets.length === 0) {
				return enqueueSnackbar({
					message: t('assertions.snackbar.no_sets_to_remove'),
					variant: 'error',
				});
			}
			setSetsToRemove(fileId ? [fileId] : selectedSets);
		},
		[selectedSets],
	);

	const handleOpenDetails = (id: string) => {
		navigate(`${Routes.extensions}/${exName}?assertion-set-id=${id}`);
	};

	const preparedData = useMemo(() => {
		if (!assertionsSets) return [];
		return assertionsSets.map((assertions) => ({
			...assertions,
			id: assertions._id,
			setsCount: assertionsSets.length,
			selectedSets,
			toggleAllSets: handleToggleAllSets,
			toggleSets: handleToggleSets,
			edit: handleEdit,
			delete: handleDelete,
			details: handleOpenDetails,
		}));
	}, [assertionsSets, selectedSets]);

	const handleCloseModal = () => setSetsToRemove([]);

	const handleConfirmDeletion = () => {
		mutate(
			{ ids: setsToRemove },
			{
				onSuccess: async () => {
					await queryClient.invalidateQueries({ queryKey: ['get/assertion-sets'] });
					enqueueSnackbar({
						message: t('assertions.snackbar.success_sets_removal'),
						variant: 'success',
					});
					handleCloseModal();
				},
				onError: (err) => {
					const errorMessage = getErrorMessage(err);
					enqueueSnackbar({
						message: errorMessage,
						variant: 'error',
					});
				},
			},
		);
	};

	return (
		<>
			<DeleteAssertionsModal
				title={t('assertions.modal.remove_as_set_title')}
				description={t('assertions.modal.remove_as_set_description')}
				open={!!setsToRemove.length}
				handleClose={handleCloseModal}
				handleRemoveSet={handleConfirmDeletion}
			/>
			{assertionsSets?.length ? (
				<TableWithHeader columns={AssertionsSetTableColumns} rows={preparedData} />
			) : (
				<EmptyTable text={t('assertions.not_found_as_sets')} />
			)}
		</>
	);
};
