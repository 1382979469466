import { FC, useState, useCallback } from 'react';
import { enqueueSnackbar } from 'notistack';
import { Box } from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { CustomOutputFileTypeEnum, CustomOutputFileCategoryEnum } from 'entities/Extensions/enums';
import { patchCustomOutputFile } from 'entities/Extensions/api';
import { CustomOutputFileType } from 'entities/Extensions/types';
import { TabButton } from 'shared/ui/TabButton';
import { Loader } from 'shared/ui/Loader';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { CustomOutputFileTable } from 'widgets/Extensions/Details/ui/CustomOutputFile/ui/OutputTypeView/Table';
import { useCustomOutputFileStore } from 'widgets/Extensions/Details/ui/CustomOutputFile/model/state';

const customOutputFileTabs = [
	{ type: CustomOutputFileTypeEnum.prebuild, name: 'extensions.ex_list.output_file.standard_tab' },
	{ type: CustomOutputFileTypeEnum.custom, name: 'extensions.ex_list.output_file.custom_tab' },
];

type OutputTypeViewPropsType = {
	loading: boolean;
	outputTypeData: CustomOutputFileType[];
	outputType: CustomOutputFileTypeEnum;
};

export const OutputTypeView: FC<OutputTypeViewPropsType> = ({ loading, outputTypeData, outputType }) => {
	const queryClient = useQueryClient();
	const { t } = useTranslation();
	const { setTabs, setSelectedOutputType } = useCustomOutputFileStore();
	const [, setSearchParams] = useSearchParams();
	const [activeTabType, setActiveTabType] = useState(outputType);

	const { mutate: customOutputMutate } = useMutation({
		mutationKey: ['patch/custom-output-file'],
		mutationFn: patchCustomOutputFile,
	});

	const toggleSwitchHandler = useCallback((data: { id: string; isEnabled: boolean }) => {
		customOutputMutate(
			{
				id: data.id,
				data: { isEnabled: data.isEnabled },
			},
			{
				onSuccess: async () => {
					await queryClient.refetchQueries({ queryKey: ['get/custom-output-file', outputType] });
					enqueueSnackbar({
						message: t('shared_texts.success_update'),
						variant: 'success',
					});
				},
				onError: (err) => {
					const errorMessage = getErrorMessage(err);
					enqueueSnackbar({ message: errorMessage, variant: 'error' });
				},
			},
		);
	}, []);

	const customOutputFileDetailsHandler = useCallback(
		(typeId: string) => {
			const outputTypeObj = outputTypeData.find((item) => item._id === typeId);
			setSelectedOutputType(outputTypeObj);
			if (outputTypeObj?.category !== CustomOutputFileCategoryEnum.custom) {
				const newTabs = outputTypeData.map((item) => ({
					type: item._id,
					name: t(`sidebar.menu./files/${item?.category}`),
				}));
				setTabs(newTabs);
			}
		},
		[outputTypeData],
	);

	return (
		<>
			{loading ? (
				<Loader />
			) : (
				<>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'flex-start',
							alignItems: 'center',
							gap: '20px',
							marginBottom: '44px',
						}}
					>
						{customOutputFileTabs.map(({ type, name }) => (
							<TabButton
								key={type}
								label={t(name)}
								isActive={type === activeTabType}
								action={() => {
									setActiveTabType(type);
									setSearchParams({ type });
								}}
							/>
						))}
					</Box>

					<CustomOutputFileTable
						data={outputTypeData}
						toggleSwitchHandler={toggleSwitchHandler}
						customOutputFileDetailsHandler={customOutputFileDetailsHandler}
					/>
				</>
			)}
		</>
	);
};
