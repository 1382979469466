import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader } from 'shared/ui/PageHeader';
import { PartnersSettingsWidget } from 'widgets/Partners/PartnerSettings';

export const PartnersSettings: FC = () => {
	const { t } = useTranslation();
	return (
		<>
			<PageHeader title={t('partners.settings_title')} description={''} />
			<PartnersSettingsWidget />
		</>
	);
};
