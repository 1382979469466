import { FC } from 'react';
import { Box, styled } from '@mui/material';
import { Paper } from 'shared/ui/Paper';
import { Switch } from 'shared/ui/Switch';
import { ModalSubContent } from 'widgets/Subscriptions/ui/SubCurrentTab/Tabs/CurrentTab/ui/UpdateSubscriptionModal/ModalSubContent';
import { useSubscriptionStoreStore } from 'widgets/Subscriptions/model/state';
import { STypesEnum } from 'entities/Subscriptions/enums';

interface UpdateSubscriptionModalStep1PropsType {
	subscriptionType: STypesEnum;
	setSubscriptionType: (sub: STypesEnum) => void;
}

const UpdateSubscriptionModalStep1: FC<UpdateSubscriptionModalStep1PropsType> = ({
	subscriptionType,
	setSubscriptionType,
}) => {
	const { currentSubscription: subscription } = useSubscriptionStoreStore();

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', gap: '50px' }}>
			{subscription?.subscriptionType === STypesEnum.FREE && (
				<StyledPaper>
					<Switch
						checked={subscriptionType === STypesEnum.FREE}
						onChange={() => setSubscriptionType(STypesEnum.FREE)}
						sx={{ alignSelf: 'flex-start' }}
					/>
					<ModalSubContent subscriptionType={STypesEnum.FREE} />
				</StyledPaper>
			)}
			<StyledPaper>
				<Switch
					checked={subscriptionType === STypesEnum.STARTER}
					onChange={() => setSubscriptionType(STypesEnum.STARTER)}
					sx={{ alignSelf: 'flex-start' }}
				/>
				<ModalSubContent subscriptionType={STypesEnum.STARTER} />
			</StyledPaper>
			<StyledPaper>
				<Switch
					checked={subscriptionType === STypesEnum.PRO}
					onChange={() => setSubscriptionType(STypesEnum.PRO)}
					sx={{ alignSelf: 'flex-start' }}
				/>
				<ModalSubContent subscriptionType={STypesEnum.PRO} />
			</StyledPaper>
			<StyledPaper>
				<Switch
					checked={subscriptionType === STypesEnum.PAY_AS_YOU_GO}
					onChange={() => setSubscriptionType(STypesEnum.PAY_AS_YOU_GO)}
					sx={{ alignSelf: 'flex-start' }}
				/>
				<ModalSubContent subscriptionType={STypesEnum.PAY_AS_YOU_GO} />
			</StyledPaper>
		</Box>
	);
};

export default UpdateSubscriptionModalStep1;

const StyledPaper = styled(Paper)`
	width: 100%;
	display: flex;
	gap: 40px;
`;
