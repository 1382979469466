import { Box, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';

interface Props {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	children: any;
	title: string;
}

export const ListItemParagraph: FunctionComponent<Props> = ({ title, children }) => {
	return (
		<Box
			sx={{
				'maxWidth': '828px',
				'marginLeft': '24px',
				'marginBottom': '20px',
				'position': 'relative',
				'&:before': {
					content: '""',
					display: 'block',
					width: '8px',
					height: '8px',
					position: 'absolute',
					left: '-24px',
					top: '5px',
					backgroundColor: ColorPalette.royalBlue,
					borderRadius: '50%',
				},
				'& a': {
					color: ColorPalette.royalBlue,
					textDecoration: 'underline',
				},
			}}
		>
			<Typography variant={'subtitle1'} color={ColorPalette.astronaut} sx={{ marginBottom: '16px' }}>
				{title}
			</Typography>
			<Typography component={'span'} variant={'body1'} color={ColorPalette.baliHai}>
				{children}
			</Typography>
		</Box>
	);
};
