import { CategoryTypeEnum } from 'entities/Files/types';

export const ReadableCategories: Record<string, string> = {
	all: 'sidebar.menu./files/all',
	[CategoryTypeEnum.purchase_orders]: `sidebar.menu./files/${CategoryTypeEnum.purchase_orders}`,
	[CategoryTypeEnum.sales_orders]: `sidebar.menu./files/${CategoryTypeEnum.sales_orders}`,
	[CategoryTypeEnum.receipts]: `sidebar.menu./files/${CategoryTypeEnum.receipts}`,
	[CategoryTypeEnum.general_documents]: `sidebar.menu./files/${CategoryTypeEnum.general_documents}`,
	[CategoryTypeEnum.invoices]: `sidebar.menu./files/${CategoryTypeEnum.invoices}`,
	[CategoryTypeEnum.custom]: `sidebar.menu./files/${CategoryTypeEnum.custom}`,
	[CategoryTypeEnum.transport_documents]: `sidebar.menu./files/${CategoryTypeEnum.transport_documents}`,
};
