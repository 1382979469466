import { FC } from 'react';
import { Box, Drawer } from '@mui/material';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Shadow } from 'shared/consts/shadow';
import { CloseModalButton } from 'shared/ui/CloseModalButton';
import { Icon } from 'shared/ui/Icon';
import { Paper } from 'shared/ui/Paper';

interface Props {
	isOpen: boolean;
	handleOpen: () => void;
	handleClose: () => void;
	children: React.ReactNode;
}

export const FilterDrawer: FC<Props> = ({ isOpen, handleOpen, handleClose, children }) => {
	return (
		<>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					minWidth: '45px',
					minHeight: '45px',
					margin: '8px',
					shadow: Shadow['2'],
					borderRadius: '12px',
					backgroundColor: ColorPalette.royalBlue,
					cursor: 'pointer',
				}}
				onClick={handleOpen}
			>
				<Icon
					id={'filter_alt'}
					color={ColorPalette.white}
					style={{ width: '30px', height: '30px' }}
				/>
			</Box>
			<Drawer
				anchor={'right'}
				open={isOpen}
				onClose={handleClose}
				sx={{
					'& > .MuiPaper-root': {
						backgroundColor: 'transparent',
						boxShadow: 'none',
					},
				}}
			>
				<Paper
					title={'Filters'}
					sx={{
						margin: '24px 40px',
						height: '100%',
						overflow: 'auto',
						position: 'relative',
					}}
				>
					<CloseModalButton
						action={handleClose}
						sx={{
							position: 'fixed',
							top: '30px',
							right: '50px',
						}}
					/>
					{children}
				</Paper>
			</Drawer>
		</>
	);
};
