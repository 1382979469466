import { CoUser } from 'entities/CoUser/types';
import { IconButton } from 'shared/ui/IconButton';
import { Column } from 'shared/ui/tables/_common/types';
import { TableTranslationLabel } from 'shared/ui/tables/_common/ui/TableTranslationLabel';
import { ColorPalette } from 'shared/consts/colorPalette';

export const CoUsersTableColumns: Array<Column<CoUser & { openModal: (coUser: CoUser) => void }>> = [
	{
		field: 'email',
		commonCellSettings: {
			style: { width: '250px' },
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="email" />,
		},
		bodyCellSettings: {
			prepareField: (user) => user?.userId?.email ?? '-',
		},
	},
	{
		field: 'firstName',
		commonCellSettings: {
			style: { width: '200px' },
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="first_name" />,
		},
		bodyCellSettings: {
			prepareField: (user) => user?.userId?.firstName.trim() || '-',
		},
	},
	{
		field: 'lastName',
		commonCellSettings: {
			style: { width: '200px' },
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="last_name" />,
		},
		bodyCellSettings: {
			prepareField: (user) => user?.userId?.lastName.trim() || '-',
		},
	},
	{
		field: 'filesCounter',
		commonCellSettings: {
			style: { width: '200px' },
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="imported_files" />,
		},
		bodyCellSettings: {
			prepareField: (user) => user?.filesCounter || '-',
		},
	},
	{
		field: 'options',
		commonCellSettings: {
			style: { width: '100px' },
			align: 'center',
		},
		bodyCellSettings: {
			renderCell: (coUser) => (
				<IconButton
					sxWrapper={{ backgroundColor: ColorPalette.violetRed }}
					sxIcon={{ color: ColorPalette.white }}
					onClick={() => coUser?.openModal(coUser)}
					iconId="trash"
				/>
			),
		},
	},
];
