import { Dialog, Typography, Box, styled } from '@mui/material';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';
import { enqueueSnackbar } from 'notistack';
import {
	useStripe,
	useElements,
	CardCvcElement,
	CardExpiryElement,
	CardNumberElement,
} from '@stripe/react-stripe-js';
import { StripeCardExpiryElementChangeEvent } from '@stripe/stripe-js';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { Button } from 'shared/ui/Button';
import { Loader } from 'shared/ui/Loader';
import { CloseModalButton } from 'shared/ui/CloseModalButton';
import { Paper } from 'shared/ui/Paper';
import { ColorPalette } from 'shared/consts/colorPalette';
import { InputField } from 'shared/ui/InputField';
import { InputStripeField } from 'widgets/Subscriptions/ui/SubCurrentTab/Tabs/PaymentTab/ui/PaymentModal/InputStripeField';
import {
	PaymentMethodValidationSchema,
	PaymentMehtodType,
} from 'widgets/Subscriptions/ui/SubCurrentTab/Tabs/PaymentTab/ui/PaymentModal/validation';
import { StripeFields } from 'widgets/Subscriptions/ui/SubCurrentTab/Tabs/PaymentTab/ui/PaymentModal/enums';
import { createSubscriptionPaymentMethod } from 'entities/Subscriptions/api';
import { useSubscriptionStoreStore } from 'widgets/Subscriptions/model/state';

interface PaymentModalPropsTypes {
	open: boolean;
	handleClose: () => void;
}

export const PaymentModal = ({ open, handleClose }: PaymentModalPropsTypes) => {
	const stripe = useStripe();
	const elements = useElements();
	const { t } = useTranslation();
	const { setCardNumber, paymentMethodLast4 } = useSubscriptionStoreStore();
	const { mutate, isLoading } = useMutation({
		mutationKey: ['subscriptions/payment-methods'],
		mutationFn: createSubscriptionPaymentMethod,
	});

	const initialValues = {
		[StripeFields.CARD_NUMBER]: false,
		[StripeFields.CARD_EXPIRY]: false,
		[StripeFields.CARD_CVC]: false,
		name: '',
	};
	const handleSubmit = async (elem: PaymentMehtodType) => {
		if (!stripe || !elements) {
			return;
		}
		const cardNumberElement = elements.getElement(CardNumberElement);

		if (cardNumberElement) {
			const response = await stripe.createPaymentMethod({
				type: 'card',
				card: cardNumberElement,
				billing_details: { name: elem.name },
			});
			if (response.paymentMethod) {
				mutate(
					{ paymentMethodId: response.paymentMethod?.id },
					{
						onSuccess: (res) => {
							setCardNumber(res.data.paymentMethodLast4);
							enqueueSnackbar({
								message: t('subscriptions.tab_3.successful_adding_card'),
								variant: 'success',
							});
							handleClose();
						},
						onError: (err) => {
							const errorMessage = getErrorMessage(err);
							enqueueSnackbar({
								message: errorMessage,
								variant: 'error',
							});
						},
					},
				);
			}
		}
	};

	const handleStripeChange = (
		event: StripeCardExpiryElementChangeEvent,
		cb: (field: string, value: unknown, shouldValidate?: boolean | undefined) => void,
	) => {
		cb(event.elementType, event.complete);
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			PaperComponent={Paper}
			PaperProps={{
				title: paymentMethodLast4
					? (t('subscriptions.tab_3.new_payment') as string)
					: (t('subscriptions.tab_3.add_payment') as string),
			}}
			sx={{
				'& .MuiPaper-root': {
					overflow: 'inherit',
				},
			}}
		>
			<CloseModalButton action={handleClose} />
			{isLoading ? (
				<Box
					sx={{
						width: '100%',
						height: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Loader />
				</Box>
			) : (
				<>
					<Typography variant={'body1'} color={ColorPalette.baliHai} sx={{ marginBottom: '40px' }}>
						{t('subscriptions.tab_3.enter_card_data')}
					</Typography>

					<Formik
						initialValues={initialValues}
						validationSchema={PaymentMethodValidationSchema}
						onSubmit={handleSubmit}
					>
						{({ setFieldValue }: FormikProps<PaymentMehtodType>) => (
							<Form>
								<InputStripeField
									stripeComponent={CardNumberElement}
									name={StripeFields.CARD_NUMBER}
									label={t('subscriptions.tab_3.labels.card_number')}
									placeholder="XXXX XXXX XXXX XXXX"
									onChange={(event) => handleStripeChange(event, setFieldValue)}
									required
								/>
								<InputField
									name="name"
									label={t('subscriptions.tab_3.labels.card_holder')}
									placeholder={t('subscriptions.tab_3.placeholder.card_holder')}
									required
								/>
								<FlexStyled>
									<InputStripeField
										stripeComponent={CardExpiryElement}
										name={StripeFields.CARD_EXPIRY}
										label={t('subscriptions.tab_3.labels.expiry_date')}
										placeholder={t('subscriptions.tab_3.placeholder.expiry_date')}
										onChange={(event) => handleStripeChange(event, setFieldValue)}
										required
									/>
									<InputStripeField
										stripeComponent={CardCvcElement}
										name={StripeFields.CARD_CVC}
										label="CVC"
										placeholder="XXX"
										onChange={(event) => handleStripeChange(event, setFieldValue)}
										required
									/>
								</FlexStyled>
								<FlexStyled>
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										}}
										onClick={handleClose}
									>
										<Typography
											variant={'button'}
											color={ColorPalette.astronaut}
											sx={{ cursor: 'pointer' }}
										>
											{t('shared_texts.cancel')}
										</Typography>
									</Box>
									<Button type="submit" fullWidth>
										{t('shared_texts.add')}
									</Button>
								</FlexStyled>
							</Form>
						)}
					</Formik>
				</>
			)}
		</Dialog>
	);
};

const FlexStyled = styled(Box)({
	'display': 'flex',
	'justifyContent': 'space-between',
	'& > *': {
		width: 'calc(50% - 15px)',
		maxWidth: 'calc(50% - 15px)',
	},
});
