import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Typography, Box } from '@mui/material';
import { ColorPalette } from 'shared/consts/colorPalette';
import { ActionsForDialog } from 'shared/ui/ActionsForDialog';
import { Loader } from 'shared/ui/Loader';
import { Paper } from 'shared/ui/Paper';

interface Props {
	open: boolean;
	handleClose: () => void;
	handleRemove: () => void;
	isLoading: boolean;
}

export const CancelSubscriptionModal: FC<Props> = ({ open, handleClose, handleRemove, isLoading }) => {
	const { t } = useTranslation();

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			PaperComponent={Paper}
			PaperProps={{ title: `${t('subscriptions.tab_1.current_sub.sub_cancel')}?` }}
		>
			{isLoading ? (
				<Loader />
			) : (
				<>
					<Typography
						variant={'body1'}
						color={ColorPalette.baliHai}
						sx={{ margin: '24px 0 34px 0' }}
					>
						{t('subscriptions.tab_1.sub_cancel_description')}
					</Typography>
					<ActionsForDialog
						cancelLabel={t('shared_texts.cancel')}
						cancelAction={handleClose}
						applyLabel={t('shared_texts.save')}
						applyAction={handleRemove}
					/>
				</>
			)}
		</Dialog>
	);
};
