import { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';
import { getAssertionsSettings, updateAssertionsSettings } from 'entities/Partners/api';
import { getAssertionsSets } from 'entities/Assertions/api';
import { ColorPalette } from 'shared/consts/colorPalette';
import { ActionsForDialog } from 'shared/ui/ActionsForDialog';
import { Paper } from 'shared/ui/Paper';
import { Select } from 'shared/ui/Select';
import { useAssertionSetsStore } from 'widgets/Extensions/Details/ui/AssertionSets/model/state';
import { Loader } from 'shared/ui/Loader';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';

export const AssertionsSettings: FC = () => {
	const { partnerId } = useParams();
	const { assertionsSets, setAssertionsSetsData } = useAssertionSetsStore();
	const [assertionSetId, setAssertionSetId] = useState('');
	const { t } = useTranslation();

	const selectedOptions = useMemo(() => {
		const mappedOptions = assertionsSets?.map((item) => ({
			label: item.name,
			value: item._id,
		}));
		mappedOptions?.unshift({ label: '-', value: '' });
		return mappedOptions;
	}, [assertionsSets]);

	useQuery({
		queryKey: ['get/assertion-sets'],
		queryFn: () => getAssertionsSets(),
		onSuccess: (response) => {
			setAssertionsSetsData(response?.data);
		},
		onError: (err) => {
			const errorMessage = getErrorMessage(err);
			enqueueSnackbar({ message: errorMessage, variant: 'error' });
		},
	});

	useQuery({
		queryKey: ['get/partners/assertions-settings', partnerId],
		queryFn: () => getAssertionsSettings(partnerId || ''),
		onSuccess: (response) => {
			setAssertionSetId(response.data.assertionSetsId);
		},
		onError: (err) => {
			const errorMessage = getErrorMessage(err);
			enqueueSnackbar({ message: errorMessage, variant: 'error' });
		},
	});

	const { mutate: updateAssertionsSettingsMutate, isLoading: putIsLoading } = useMutation({
		mutationKey: ['put/partners/assertions-settings'],
		mutationFn: updateAssertionsSettings,
	});

	const submitAssertionSettingsHandler = (asId: string | null) => {
		if (partnerId) {
			updateAssertionsSettingsMutate(
				{
					partnerId: partnerId,
					data: { assertionSetsId: asId },
				},
				{
					onSuccess: async (response) => {
						setAssertionSetId(response.data.assertionSetsId);
						if (asId) {
							enqueueSnackbar({
								message: t('assertions.modal.success_adding'),
								variant: 'success',
							});
						} else {
							enqueueSnackbar({
								message: t('assertions.modal.success_removing'),
								variant: 'success',
							});
						}
					},
					onError: (err) => {
						const errorMessage = getErrorMessage(err);
						enqueueSnackbar({ message: errorMessage, variant: 'error' });
					},
				},
			);
		}
	};
	return (
		<>
			<Typography variant={'subtitle1'} color={ColorPalette.baliHai} sx={{ margin: '24px 0' }}>
				{t('partners.assertions_settings_description')}
			</Typography>

			<Paper>
				{putIsLoading ? (
					<Loader type="min" />
				) : (
					<>
						<Select
							label={t('assertions.as_title_list') as string}
							placeholder={t('assertions.placeholder.select_as_set')}
							value={assertionSetId || ''}
							onSelect={(item) => setAssertionSetId(item as string)}
							options={selectedOptions || []}
						/>

						<ActionsForDialog
							cancelLabel={t('shared_texts.clear_fields')}
							cancelAction={() => submitAssertionSettingsHandler(null)}
							applyLabel={t('shared_texts.save')}
							applyAction={() => submitAssertionSettingsHandler(assertionSetId)}
							applyActionDisabled={false}
						/>
					</>
				)}
			</Paper>
		</>
	);
};
