import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Typography } from '@mui/material';
import { getCoUsersOfUser } from 'entities/admin/Users/api';
import { UserForAdmin } from 'entities/admin/Users/types';
import { ColorPalette } from 'shared/consts/colorPalette';
import { CoUsersTable } from './CoUsersTable';

interface Props {
	user: UserForAdmin | null;
}

const CoUsers: FC<Props> = ({ user }) => {
	const { t } = useTranslation();
	const { data: coUsersData } = useQuery({
		queryKey: ['admin/co-users'],
		queryFn: () => getCoUsersOfUser(user?._id),
	});

	return (
		<>
			<Typography variant={'h5'} color={ColorPalette.astronaut} sx={{ margin: '44px 0 38px 0' }}>
				{t('co_users.title')}
			</Typography>
			<CoUsersTable coUsers={coUsersData?.data || []} />
		</>
	);
};

export default CoUsers;
