import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ModelsType } from 'entities/CustomModel/types';
import { TableWithHeader } from 'shared/ui/tables/TableWithHeader';
import { EmptyTable } from 'shared/ui/EmptyTable';
import { ModelsTableColumns } from './consts/ModelsTableColumns';

type Props = {
	models: ModelsType[];
	editHandler: (modelId: string) => void;
	deleteHandler: (modelId: string) => void;
};
const ModelsTable: FC<Props> = ({ models, editHandler, deleteHandler }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const preparedUsers = useMemo(() => {
		if (!models.length) return [];
		return models.map((model) => ({
			...model,
			openModal: (model: ModelsType) => {
				editHandler(model._id);
			},
			removeModel: (model: ModelsType) => {
				deleteHandler(model._id);
			},
			navigateToDetails: (model: ModelsType) => {
				navigate(`/import-model/${model?._id}`);
			},
		}));
	}, [models]);

	return (
		<>
			{preparedUsers?.length ? (
				<TableWithHeader
					columns={ModelsTableColumns}
					rows={preparedUsers.map((models) => ({ ...models, id: models._id }))}
				/>
			) : (
				<EmptyTable text={t('shared_texts.not_found')} />
			)}
		</>
	);
};

export default ModelsTable;
