import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { GetWarehouseItemsRes } from 'entities/Warehouse/api/types';
import { WarehouseProcessType } from 'entities/Warehouse/types';

type warehouseStoreType = {
	articlesList: GetWarehouseItemsRes | undefined;
	processes: WarehouseProcessType | undefined;
};

type warehouseActions = {
	setWarehouseListData: (articlesList: GetWarehouseItemsRes) => void | Promise<void>;
	setProcessesData: (processes: WarehouseProcessType) => void | Promise<void>;
	reset: () => void;
};

const initialState: warehouseStoreType = {
	articlesList: undefined,
	processes: undefined,
};

export const warehouseStore = create<warehouseStoreType & warehouseActions>()(
	persist(
		(set) => ({
			...initialState,
			setWarehouseListData: (articlesList) => set(() => ({ articlesList })),
			setProcessesData: (processes) => set(() => ({ processes })),
			reset: () => set(() => ({ ...initialState })),
		}),
		{
			name: 'warehouseStore',
		},
	),
);

export const useWarehouseStore = () => warehouseStore((state) => state);
