import { FC, useMemo } from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';
import { useMutation, useQueryClient } from 'react-query';
import { Typography, Box } from '@mui/material';
import { Button } from 'shared/ui/Button';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { ColorPalette } from 'shared/consts/colorPalette';
import { updateAdditionalFeatures } from 'entities/admin/Users/api';
import { UserForAdmin } from 'entities/admin/Users/types';
import { FeaturesEnum } from 'entities/Company/types';
import { AdditionalFeatureType } from 'entities/Company/types';
import { Features } from 'widgets/admin/UserDetails/ui/tabs/AdditionalFeatures/ui/Features';
import { AdditionalFeaturesSchema, AdditionalFeaturesFormType } from './consts/validation';

interface Props {
	user: UserForAdmin;
}

const AdditionalFeatures: FC<Props> = ({ user }) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	const neuralModel = useMemo(
		() => user?.company?.additionalFeatures?.find((item) => item.feature === FeaturesEnum.NEURAL_MODEL),
		[],
	);
	const reseller = useMemo(
		() => user?.company?.additionalFeatures?.find((item) => item.feature === FeaturesEnum.RESELLER),
		[],
	);

	const { mutate, isLoading } = useMutation({
		mutationKey: ['update/additional-features'],
		mutationFn: updateAdditionalFeatures,
	});

	const handleSubmit = (values: AdditionalFeaturesFormType) => {
		const newFeatures: AdditionalFeatureType[] = featuresArray.map((item) => ({
			...item,
			isEnabled: values[item.feature],
			neuralModelsLimit:
				typeof item.neuralModelsLimit === 'number' ? values.neuralModelsLimit : undefined,
		}));
		mutate(
			{
				userId: user._id,
				data: {
					additionalFeatures: newFeatures,
				},
			},
			{
				onSuccess: () => {
					queryClient.refetchQueries({ queryKey: ['get/admin/user'] });
					enqueueSnackbar({ message: t('shared_texts.success_update'), variant: 'success' });
				},
				onError: (err) => {
					const errorMessage = getErrorMessage(err);
					enqueueSnackbar({ message: errorMessage, variant: 'error' });
				},
			},
		);
	};

	return (
		<>
			<Typography variant={'h5'} color={ColorPalette.astronaut} sx={{ margin: '44px 0 38px 0' }}>
				{t('admin.users.labels.additional_features')}
			</Typography>

			<Formik
				initialValues={{
					[FeaturesEnum.NEURAL_MODEL]: neuralModel?.isEnabled ?? false,
					[FeaturesEnum.RESELLER]: reseller?.isEnabled ?? false,
					neuralModelsLimit: neuralModel?.neuralModelsLimit ?? 0,
				}}
				validationSchema={AdditionalFeaturesSchema}
				onSubmit={handleSubmit}
			>
				<Form>
					<Box
						sx={{
							'width': '100%',
							'display': 'flex',
							'flexDirection': 'column',
							'alignItems': 'flex-end',
							'gap': '30px',
							'@media screen and (min-width: 1441px)': {
								width: '50%',
							},
						}}
					>
						<Features features={featuresArray} />

						<Button disabled={isLoading} size={'small'} type={'submit'}>
							{t('shared_texts.save')}
						</Button>
					</Box>
				</Form>
			</Formik>
		</>
	);
};

export default AdditionalFeatures;

export const featuresArray = [
	{
		feature: FeaturesEnum.NEURAL_MODEL,
		isEnabled: false,
		neuralModelsLimit: 0,
	},
	{
		feature: FeaturesEnum.RESELLER,
		isEnabled: false,
	},
];
