import { Box } from '@mui/material';
import { Switch } from 'shared/ui/Switch';
import { OptionsDropdown } from 'shared/ui/OptionsDropdown';
import { ExtensionsType } from 'entities/Extensions/types';
import { ExtensionsEnum } from 'entities/Extensions/enums';
import { TableTranslationLabel } from 'shared/ui/tables/_common/ui/TableTranslationLabel';
import { Column } from 'shared/ui/tables/_common/types';
import { ColorPalette } from 'shared/consts/colorPalette';

export type ExtensionsTableColumnsType = {
	id: string;
	title: string;
	tableDescription: string;
	extension: ExtensionsEnum;
	isEnabled: boolean;
	toggleExtensionHandler: (data: ExtensionsType) => void;
	extensionDetailsHandler: (exName: ExtensionsEnum) => void;
	deleteExtensionHandler: (exName: ExtensionsEnum) => void;
};

export const ExtensionsTableColumns: Array<Column<ExtensionsTableColumnsType>> = [
	{
		field: 'extensionSwitcher',
		bodyCellSettings: {
			renderCell: (extensionCell) => {
				return (
					<Switch
						checked={extensionCell?.isEnabled}
						onChange={() => {
							extensionCell?.toggleExtensionHandler({
								extension: extensionCell.extension,
								isEnabled: !extensionCell.isEnabled,
							});
						}}
					/>
				);
			},
		},
	},
	{
		field: 'name',
		bodyCellSettings: {
			renderCell: (extensionCell) =>
				extensionCell?.title ? <TableTranslationLabel path={extensionCell?.title} /> : '-',
		},
	},
	{
		field: 'description',
		commonCellSettings: {
			width: '65%',
		},
		bodyCellSettings: {
			renderCell: (extensionCell) =>
				extensionCell?.tableDescription ? (
					<TableTranslationLabel
						path={extensionCell?.tableDescription}
						color={ColorPalette.baliHai}
					/>
				) : (
					'-'
				),
		},
	},
	{
		field: 'options',
		commonCellSettings: {
			style: {
				minWidth: '100px',
				maxWidth: '100px',
			},
		},
		bodyCellSettings: {
			renderCell: (extensionCell) => (
				<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
					<OptionsDropdown
						iconId={'more_horizontal'}
						optionList={[
							{
								label: 'shared_texts.table_dropdown.details',
								value: 'Details info',
								action: () => {
									extensionCell?.extensionDetailsHandler(extensionCell.extension);
								},
							},
							{
								label: 'shared_texts.table_dropdown.remove',
								value: 'Remove extension',
								action: () => {
									extensionCell?.deleteExtensionHandler(extensionCell.extension);
								},
							},
						]}
					/>
				</Box>
			),
		},
	},
];
