import { Box, BoxProps, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Shadow } from 'shared/consts/shadow';
import { BackButton } from 'shared/ui/BackButton';
import { Category } from 'widgets/startRecognition/consts/CategoryList';

interface Props extends BoxProps {
	category: Category;
	action?: () => void;
}

export const CategoryCard: FC<Props> = ({ sx, category, action }) => {
	const { t } = useTranslation();

	return (
		<Box
			sx={{
				'position': 'relative',
				'padding': '20px 30px',
				'backgroundColor': ColorPalette.white,
				'boxShadow': Shadow['1'],
				'borderRadius': '20px',
				'display': 'flex',
				'flexDirection': 'row',
				'transition': 'all .3s',
				'border': `1px solid ${ColorPalette.white}`,
				'&:hover': {
					border: `1px solid ${action ? ColorPalette.royalBlue : ColorPalette.white}`,
				},
				'& .MuiButtonBase-root': {
					opacity: 0,
					transition: 'all .3s',
				},
				'&:hover .MuiButtonBase-root': {
					opacity: action ? 1 : 0,
				},
				...sx,
			}}
		>
			<img
				src={category.img}
				alt={category.id}
				style={{ width: '82px', height: '82px', marginRight: '26px', alignSelf: 'center' }}
			/>
			<Box>
				<Typography variant={'subtitle1'} color={ColorPalette.astronaut}>
					{t(category.label)}
				</Typography>
				{category.description && (
					<Typography variant={'body2'} color={ColorPalette.rockBlue} sx={{ marginTop: '10px' }}>
						{t(category.description)}
					</Typography>
				)}
			</Box>
			<BackButton
				action={() => action && action()}
				sx={{
					position: 'absolute',
					top: '50%',
					right: '30px',
					left: 'auto',
					transform: 'translateY(-50%) rotate(180deg)',
					cursor: 'pointer',
				}}
			/>
		</Box>
	);
};
