import { FC } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Dialog, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { patchPartner, postPartner } from 'entities/Partners/api';
import { GeneralPartnerType } from 'entities/Partners/types';
import { PartnersEnum } from 'entities/Partners/enums';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { SelectOption } from 'shared/ui/Select/types';
import { InputField } from 'shared/ui/InputField';
import { ColorPalette } from 'shared/consts/colorPalette';
import { FormSelect } from 'shared/ui/Select/FormSelect';
import { Paper } from 'shared/ui/Paper';
import { capitalizeFirstLetter } from 'shared/helpers/capitalizeFirstLetter';
import { Loader } from 'shared/ui/Loader';
import { PartnersValidationSchema } from 'widgets/Partners/consts/validation';
import { PartnersFormType } from 'widgets/Partners/types';
import { Button } from 'shared/ui/Button';

interface ManualModalPropsType {
	partnerToEdit?: GeneralPartnerType;
	open: boolean;
	handleClose: () => void;
}

export const ManualModal: FC<ManualModalPropsType> = ({ partnerToEdit, open, handleClose }) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	const { mutate: patchPartnerMutate, isLoading: patchIsLoading } = useMutation({
		mutationKey: ['patch/partner'],
		mutationFn: patchPartner,
	});

	const { mutate: postMutate, isLoading: postIsLoading } = useMutation({
		mutationKey: ['post/partner'],
		mutationFn: postPartner,
	});

	const onCreatePartnerHandler = (data: PartnersFormType) => {
		postMutate(
			{ data },
			{
				onSuccess: async () => {
					await queryClient.refetchQueries({ queryKey: ['get/partners'] });
					enqueueSnackbar({
						message: t('partners.snackbar.partner_created_success', {
							type: capitalizeFirstLetter(data.type),
						}),
						variant: 'success',
					});
					handleClose();
				},
				onError: (err) => {
					const errorMessage = getErrorMessage(err);
					enqueueSnackbar({ message: errorMessage, variant: 'error' });
				},
			},
		);
	};

	const onUpdatePartnerHandler = (partnerId: string, data: PartnersFormType) => {
		patchPartnerMutate(
			{ partnerId, data },
			{
				onSuccess: async () => {
					await queryClient.refetchQueries({ queryKey: ['get/partners'] });
					enqueueSnackbar({
						message: t('partners.snackbar.partner_updated_success', {
							type: capitalizeFirstLetter(data.type),
						}),
						variant: 'success',
					});
					handleClose();
				},
				onError: (err) => {
					const errorMessage = getErrorMessage(err);
					enqueueSnackbar({ message: errorMessage, variant: 'error' });
				},
			},
		);
	};

	const handleManualModalSubmit = (values: PartnersFormType) => {
		if (partnerToEdit) {
			onUpdatePartnerHandler(partnerToEdit._id, values);
		} else {
			onCreatePartnerHandler(values);
		}
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			PaperComponent={Paper}
			PaperProps={{ title: (partnerToEdit ? t('shared_texts.edit') : t('shared_texts.add')) as string }}
		>
			{postIsLoading || patchIsLoading ? (
				<Loader />
			) : (
				<>
					{!partnerToEdit && (
						<Typography
							variant={'body1'}
							color={ColorPalette.baliHai}
							sx={{ marginBottom: '34px' }}
						>
							{t('partners.modals.creation_description')}
						</Typography>
					)}
					<Formik
						initialValues={{
							name: partnerToEdit?.name || '',
							vatNumber: partnerToEdit?.vatNumber || undefined,
							address: partnerToEdit?.address || undefined,
							type: partnerToEdit?.type || PartnersEnum.default,
							erpCode: partnerToEdit?.erpCode || undefined,
						}}
						validationSchema={PartnersValidationSchema}
						onSubmit={handleManualModalSubmit}
					>
						{/* FormikProps<PartnersFormType> */}
						{({ values, setFieldValue }) => (
							<Form>
								<InputField
									name={'name'}
									label={t('auth.labels.company_name')}
									placeholder={t('auth.validation.enter_company_name')}
									required
								/>
								<InputField
									name={'vatNumber'}
									label={t('auth.labels.vat_number')}
									placeholder={t('auth.validation.enter_vat_number')}
								/>
								<InputField
									name={'address'}
									label={t('partners.labels.address')}
									placeholder={t('partners.placeholder.address')}
								/>
								<FormSelect
									name={'type'}
									label={t('partners.labels.client_supplier') as string}
									placeholder={t('partners.placeholder.client_supplier')}
									options={selectOptions}
									value={values.type}
									onSelect={(value) => setFieldValue('type', value)}
									required
								/>
								<InputField
									name={'erpCode'}
									label={t('partners.labels.erpCode')}
									placeholder={t('partners.placeholder.erpCode')}
								/>
								<Button type={'submit'} fullWidth>
									{partnerToEdit ? t('shared_texts.edit') : t('shared_texts.add')}
								</Button>
							</Form>
						)}
					</Formik>
				</>
			)}
		</Dialog>
	);
};

const selectOptions: Array<SelectOption> = [
	{
		value: PartnersEnum.client,
		label: 'partners.tabs.clients',
	},
	{
		value: PartnersEnum.supplier,
		label: 'partners.tabs.suppliers',
	},
];
