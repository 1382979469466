import { FunctionComponent, useMemo } from 'react';
import { format } from 'date-fns';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { GetFilesReq } from 'entities/Files/api/types';
import { useQuery } from 'react-query';
import { CoUser } from 'entities/CoUser/types';
import { getFiles } from 'entities/Files/api';
import { Loader } from 'shared/ui/Loader';
import { ColorPalette } from 'shared/consts/colorPalette';
import { isEmptyObject } from 'shared/helpers/isEmptyObject';
import { CollapsableTable } from 'shared/ui/tables/CollapsableTable';
import { useFilesSearchParams } from 'shared/helpers/useFilesSearchParams';
import {
	CouserFilesDashboardColumns,
	FileForDashboard,
} from 'widgets/CoUsers/ui/CoUserDetailsInfo/ui/tabs/FilesHistory/consts/CouserDashboardColumns';

interface Props {
	coUser: CoUser | null;
}

export const FilesHistory: FunctionComponent<Props> = ({ coUser }) => {
	const { t } = useTranslation();
	const { searchParams } = useFilesSearchParams();

	const searchParamsForQuery: GetFilesReq = useMemo(() => {
		const { uploadSource, ...rest } = searchParams;
		const preparedSearchParamsForQuery: GetFilesReq = { ...rest };
		if (uploadSource !== 'all') preparedSearchParamsForQuery['uploadSource'] = uploadSource;
		preparedSearchParamsForQuery['coUserId'] = coUser?.userId?._id;
		return preparedSearchParamsForQuery;
	}, [searchParams]);

	const { data, isLoading } = useQuery({
		queryKey: ['files', searchParamsForQuery],
		queryFn: () =>
			getFiles({
				...searchParamsForQuery,
			}),
		enabled: searchParamsForQuery.status !== undefined,
	});

	const filesData = useMemo(() => data?.data.results, [data?.data.results]);

	const preparedFilesData = useMemo(() => {
		const preparedFiles: Record<string, Array<FileForDashboard>> = {};
		if (filesData?.length) {
			filesData
				?.map((item) => ({ ...item, id: item._id }))
				.forEach((file) => {
					const date = format(new Date(file.createdAt), 'dd/MM/yyyy');
					preparedFiles[date] = [...(preparedFiles[date] ?? []), { ...file }];
				});
			return preparedFiles;
		}
		return {};
	}, [filesData]);

	if (isLoading) {
		return <Loader />;
	}

	return (
		<>
			{!isEmptyObject(preparedFilesData) ? (
				<CollapsableTable columns={CouserFilesDashboardColumns} subTables={preparedFilesData} />
			) : (
				<Typography
					variant={'subtitle1'}
					color={ColorPalette.astronaut}
					sx={{ margin: 'auto', width: '100%', textAlign: 'center' }}
				>
					{t('shared_texts.no_files')}
				</Typography>
			)}
		</>
	);
};
