import { Box, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { useAuthStore } from 'processes/auth/model/state';
import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { useLocalStorage } from 'shared/helpers/useLocalStorage';
import { Checkbox } from 'shared/ui/Checkbox';
import { signIn } from 'entities/Auth/api';
import { AuthWithGoogleButton } from 'widgets/auth/_common/ui/AuthWithGoogleButton';
import { SignInValidationSchema } from 'widgets/auth/signIn/_MainModal/consts/validation';
import { ColorPalette } from 'shared/consts/colorPalette';
import { isEmptyObject } from 'shared/helpers/isEmptyObject';
import { isEmptyObjectValues } from 'shared/helpers/isEmptyObjectValues';
import { InputField } from 'shared/ui/InputField';
import { Paper } from 'shared/ui/Paper';
import { Button } from 'shared/ui/Button';
import { SignIn } from 'widgets/auth/signIn/_MainModal/types';
import { Footer } from 'widgets/auth/signIn/_MainModal/ui/Footer';

export const SIMainModal: FunctionComponent = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { updateAuthData } = useAuthStore();
	const [rememberMe, setRememberMe] = useLocalStorage('rememberMe', false);

	const initialValues = {
		email: '',
		password: '',
	};

	const handleSubmit = (values: SignIn): void => {
		signIn(values)
			.then((res) => {
				if (res?.data) {
					updateAuthData(res.data);
				}
				if (res?.data.user.isDeleted) {
					navigate('/sign-in/account-removed');
				}
			})
			.catch((err) => {
				const defaultErrorMessage = t('shared_texts.smth_went_wrong');
				const errorMessage = getErrorMessage(err, defaultErrorMessage);
				enqueueSnackbar({
					message: errorMessage,
					variant: 'error',
				});
			});
	};
	return (
		<Paper
			title={t('auth.sign_in') as string}
			sx={{
				'& *': {
					userSelect: 'none',
				},
			}}
		>
			<Typography variant={'body1'} color={ColorPalette.baliHai} sx={{ marginBottom: '35px' }}>
				{t('auth.sign_in_description')}
			</Typography>

			<AuthWithGoogleButton label={t('auth.sign_in_with_google')} />

			<Divider
				flexItem
				sx={{
					'margin': '18px 0 24px',
					'fontSize': '14px',
					'color': ColorPalette.baliHai,
					'&:before, &:after': {
						borderTop: `1px solid ${ColorPalette.periwinkle}`,
					},
				}}
			>
				or
			</Divider>

			<Formik
				initialValues={initialValues}
				validationSchema={SignInValidationSchema}
				onSubmit={handleSubmit}
			>
				{(props: FormikProps<SignIn>) => (
					<>
						{/*<AutofillSync props={props} />*/}
						<Form>
							<InputField
								name={'email'}
								label={'Email'}
								placeholder={t('auth.validation.enter_email')}
								required
							/>
							<InputField
								name={'password'}
								label={'Password'}
								placeholder={t('auth.validation.enter_password')}
								type={'password'}
								required
							/>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									marginBottom: '36px',
								}}
							>
								<Checkbox
									checked={rememberMe}
									onChange={(e) => {
										setRememberMe(e.target.checked);
										// authStore.persist.clearStorage();
										// authStore.persist.setOptions({
										// 	storage: createJSONStorage(() => {
										// 		return e.target.checked ? localStorage : sessionStorage;
										// 	}),
										// });
									}}
								/>
								<Typography
									variant={'body2'}
									color={ColorPalette.astronaut}
									sx={{ marginLeft: '16px' }}
								>
									{t('auth.remember_me')}
								</Typography>
								<Typography
									variant={'body2'}
									color={ColorPalette.royalBlue}
									onClick={() => navigate('/forgot-password', { state: { access: true } })}
									sx={{ cursor: 'pointer', marginLeft: 'auto' }}
								>
									{t('auth.forgot_password')}
								</Typography>
							</Box>
							<Button
								disabled={!isEmptyObject(props.errors) || isEmptyObjectValues(props.values)}
								type={'submit'}
								fullWidth
							>
								{t('auth.sign_in')}
							</Button>
						</Form>
					</>
				)}
			</Formik>
			<Footer />
		</Paper>
	);
};

// const AutofillSync = ({ props }: { props: FormikProps<SignIn> }) => {
// 	useEffect(() => {
// 		if (
// 			(document.querySelector('input[id="email"]') as HTMLInputElement)?.value ||
// 			(document.querySelector('input[id="password"]') as HTMLInputElement)?.value
// 		) {
// 			// eslint-disable-next-line react/prop-types
// 			if (!props.values.email || !props.values.password) {
// 				// eslint-disable-next-line react/prop-types
// 				props.setValues({
// 					email: (document.querySelector('input[id="email"]') as HTMLInputElement)?.value || '',
// 					password:
// 						(document.querySelector('input[id="password"]') as HTMLInputElement)?.value || '',
// 				});
// 			}
// 		}
// 	});
// 	return null;
// };
