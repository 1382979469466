import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@mui/material';
import { TableLabelsStructureType } from 'entities/Files/types';
import { Drawer } from 'shared/ui/Drawer';
import { ColorPalette } from 'shared/consts/colorPalette';
import { LabelTable } from 'shared/ui/tables/LabelTable';

export type DrawerType = 'autocomplete' | 'assertions' | '';
interface ProcessDrawerPropsType {
	drawerType: DrawerType;
	open: boolean;
	handleClose: () => void;
}

export const ProcessDrawer: FC<ProcessDrawerPropsType> = ({ drawerType, open, handleClose }) => {
	const { t } = useTranslation();

	const renderContent = () => {
		switch (drawerType) {
			case 'autocomplete':
				return (
					<>
						<Typography variant={'h5'} color={ColorPalette.astronaut}>
							{t('extensions.ex_list.warehouse.process_drawer_title')}
						</Typography>
						<Typography variant={'body2'} color={ColorPalette.baliHai}>
							{t('extensions.ex_list.warehouse.process_drawer_description')}
						</Typography>

						<Typography variant={'body1'} color={ColorPalette.astronaut}>
							{t('extensions.ex_list.overview')}
						</Typography>
						<Typography variant={'body2'} color={ColorPalette.baliHai}>
							{t('extensions.ex_list.warehouse.process_drawer_overview_description')}
						</Typography>
						<Typography variant={'body1'} color={ColorPalette.astronaut}>
							{t('extensions.ex_list.preview')}
						</Typography>

						<Typography variant={'body2'} color={ColorPalette.baliHai}>
							{t('extensions.ex_list.warehouse.process_drawer_without_autocomplete')}
						</Typography>

						<LabelTable tableData={tableCells} />

						<Typography variant={'body2'} color={ColorPalette.baliHai}>
							{t('extensions.ex_list.warehouse.process_drawer_with_autocomplete')}
						</Typography>

						<LabelTable tableData={tableWithAutoCompleteCells} />
					</>
				);
			case 'assertions':
				return (
					<>
						<Typography variant={'h5'} color={ColorPalette.astronaut}>
							{t('extensions.ex_list.warehouse.process_drawer_as_title')}
						</Typography>
						<Typography variant={'body2'} color={ColorPalette.baliHai}>
							{t('extensions.ex_list.warehouse.process_drawer_as_description')}
						</Typography>

						<Typography variant={'body1'} color={ColorPalette.astronaut}>
							{t('extensions.ex_list.overview')}
						</Typography>
						<Typography variant={'body2'} color={ColorPalette.baliHai}>
							{t('extensions.ex_list.warehouse.process_drawer_as_overview_description')}
						</Typography>

						<Typography variant={'body1'} color={ColorPalette.astronaut}>
							{t('extensions.ex_list.preview')}
						</Typography>
						<Typography variant={'body2'} color={ColorPalette.baliHai}>
							{t('extensions.ex_list.warehouse.process_drawer_as_preview_description')}
						</Typography>
					</>
				);
			default:
				return null;
		}
	};
	return (
		<Drawer open={open} onClose={handleClose} sx={{ display: 'flex', gap: '20px' }}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: '20px',
				}}
			>
				{renderContent()}
			</Box>
		</Drawer>
	);
};

const tableCells: TableLabelsStructureType = {
	columnsHeaders: [
		{ columnName: 'Description' },
		{ columnName: 'Quantity' },
		{ columnName: 'Unit' },
		{ columnName: 'UnitPrice' },
	],
	cells: [
		{ columnIndex: 0, content: 'PU AIRE 50 MM 10 MT', rowIndex: 0 },
		{ columnIndex: 1, content: '6', rowIndex: 0 },
		{ columnIndex: 2, content: 'UN', rowIndex: 0 },
		{ columnIndex: 3, content: '12,00', rowIndex: 0 },

		{ columnIndex: 0, content: 'PU AIRE 60 MM 10 MT', rowIndex: 1 },
		{ columnIndex: 1, content: '9', rowIndex: 1 },
		{ columnIndex: 2, content: 'UN', rowIndex: 1 },
		{ columnIndex: 3, content: '14,23', rowIndex: 1 },

		{ columnIndex: 0, content: 'PU AIRE 80 MM 10 MT', rowIndex: 2 },
		{ columnIndex: 1, content: '20', rowIndex: 2 },
		{ columnIndex: 2, content: 'UN', rowIndex: 2 },
		{ columnIndex: 3, content: '34,56', rowIndex: 2 },

		{ columnIndex: 0, content: 'PU AIRE 100 MM 10 MT', rowIndex: 3 },
		{ columnIndex: 1, content: '40', rowIndex: 3 },
		{ columnIndex: 2, content: 'UN', rowIndex: 3 },
		{ columnIndex: 3, content: '66,21', rowIndex: 3 },

		{ columnIndex: 0, content: 'PU AIRE 110 MM 10 MT', rowIndex: 4 },
		{ columnIndex: 1, content: '6', rowIndex: 4 },
		{ columnIndex: 2, content: 'UN', rowIndex: 4 },
		{ columnIndex: 3, content: '40,32', rowIndex: 4 },
	],
};

const tableWithAutoCompleteCells: TableLabelsStructureType = {
	columnsHeaders: [
		{ columnName: 'Description' },
		{ columnName: 'ProductCode', autocomplete: true },
		{ columnName: 'Quantity' },
		{ columnName: 'Unit' },
		{ columnName: 'UnitPrice' },
	],
	cells: [
		{ columnIndex: 0, content: 'PU AIRE 50 MM 10 MT', rowIndex: 0 },
		{ columnIndex: 1, content: '6060.00100 A-000', rowIndex: 0, autocomplete: true },
		{ columnIndex: 2, content: '6', rowIndex: 0 },
		{ columnIndex: 3, content: 'UN', rowIndex: 0 },
		{ columnIndex: 4, content: '12,00', rowIndex: 0 },

		{ columnIndex: 0, content: 'PU AIRE 60 MM 10 MT', rowIndex: 1 },
		{ columnIndex: 1, content: '6060.00100 A-000', rowIndex: 1, autocomplete: true },
		{ columnIndex: 2, content: '9', rowIndex: 1 },
		{ columnIndex: 3, content: 'UN', rowIndex: 1 },
		{ columnIndex: 4, content: '14,23', rowIndex: 1 },

		{ columnIndex: 0, content: 'PU AIRE 80 MM 10 MT', rowIndex: 2 },
		{ columnIndex: 1, content: '6060.00100 A-000', rowIndex: 2, autocomplete: true },
		{ columnIndex: 2, content: '20', rowIndex: 2 },
		{ columnIndex: 3, content: 'UN', rowIndex: 2 },
		{ columnIndex: 4, content: '34,56', rowIndex: 2 },

		{ columnIndex: 0, content: 'PU AIRE 100 MM 10 MT', rowIndex: 3 },
		{ columnIndex: 1, content: '6060.00100 A-000', rowIndex: 3, autocomplete: true },
		{ columnIndex: 2, content: '40', rowIndex: 3 },
		{ columnIndex: 3, content: 'UN', rowIndex: 3 },
		{ columnIndex: 4, content: '66,21', rowIndex: 3 },

		{ columnIndex: 0, content: 'PU AIRE 110 MM 10 MT', rowIndex: 4 },
		{ columnIndex: 1, content: '6060.00100 A-000', rowIndex: 4, autocomplete: true },
		{ columnIndex: 2, content: '6', rowIndex: 4 },
		{ columnIndex: 3, content: 'UN', rowIndex: 4 },
		{ columnIndex: 4, content: '40,32', rowIndex: 4 },
	],
};
