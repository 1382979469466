import { Typography, Box, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { checkUser } from 'entities/Auth/api';
import { Form, Formik, FormikProps } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { FunctionComponent } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ColorPalette } from 'shared/consts/colorPalette';
import { isEmptyObject } from 'shared/helpers/isEmptyObject';
import { isEmptyObjectValues } from 'shared/helpers/isEmptyObjectValues';
import { InputField } from 'shared/ui/InputField';
import { Checkbox } from 'shared/ui/Checkbox';
import { Paper } from 'shared/ui/Paper';
import { PasswordRequirements } from 'shared/ui/PasswordRequirements';
import { Button } from 'shared/ui/Button';
import { Step1ValidationSchema } from 'widgets/auth/signUpByInvitation/_MainModal/consts/validation';
import { useSignUpByInvitationStore } from 'widgets/auth/signUpByInvitation/_MainModal/model/state';
import { Step1 } from 'widgets/auth/signUpByInvitation/_MainModal/types';
import { Footer } from 'widgets/auth/signUpByInvitation/_MainModal/ui/Footer';
import { StepLabel } from 'widgets/auth/signUpByInvitation/_MainModal/ui/StepLabel';

export const SignUpStep1: FunctionComponent = () => {
	const { t } = useTranslation();
	const [searchParams] = useSearchParams();
	const { goToNextStep, signUpData, confirmFirstStep, updateUserSignUpData, updateConfirmationFirstStep } =
		useSignUpByInvitationStore();

	const initialValues = {
		email: searchParams.get('email') ?? '',
		password: signUpData.password,
		confirmPassword: signUpData.confirmPassword,
	};

	const handleSubmit = (values: Step1): void => {
		checkUser(values.email).then((res) => {
			if (!res?.data?.success) {
				enqueueSnackbar({
					message: t('auth.validation.email_taken'),
					variant: 'error',
				});
				return;
			}
			updateUserSignUpData({ ...values });
		});
		updateUserSignUpData({ ...values });
		goToNextStep();
	};

	return (
		<Paper
			title={t('auth.sigh_up_by_invitation') as string}
			sx={{
				'& *': {
					userSelect: 'none',
				},
				'& > .MuiTypography-h4': {
					maxWidth: '200px',
				},
			}}
		>
			<StepLabel />
			<Typography variant={'body1'} color={ColorPalette.baliHai} sx={{ marginBottom: '35px' }}>
				{t('auth.sign_up_description')}
			</Typography>

			<Formik
				initialValues={initialValues}
				validationSchema={Step1ValidationSchema}
				onSubmit={handleSubmit}
			>
				{({ errors, values }: FormikProps<Step1>) => (
					<Form>
						<InputField
							name={'email'}
							label={'Email'}
							placeholder={t('auth.validation.enter_email')}
							disabled
							required
						/>
						<InputField
							name={'password'}
							label={'Password'}
							placeholder={t('auth.validation.enter_password')}
							type={'password'}
							required
						/>
						<InputField
							name={'confirmPassword'}
							label={t('auth.confirm_password')}
							placeholder={t('auth.confirm_password')}
							type={'password'}
							required
						/>
						<PasswordRequirements />
						<Box
							sx={{
								display: 'flex',
								margin: '5px 0',
							}}
						>
							<Checkbox
								onChange={() =>
									updateConfirmationFirstStep({ isRead: !confirmFirstStep.isRead })
								}
							/>
							<Typography color={ColorPalette.astronaut} sx={{ marginLeft: '10px' }}>
								{t('auth.read_conditions')}{' '}
								<Link href="https://retica.ai/condizioni-generali/">
									{t('auth.general_onditions')}
								</Link>
							</Typography>
						</Box>

						<Box
							sx={{
								display: 'flex',
								margin: '5px 0',
								paddingBottom: '36px',
							}}
						>
							<Checkbox
								onChange={() =>
									updateConfirmationFirstStep({ isAgreed: !confirmFirstStep.isAgreed })
								}
							/>
							<Typography color={ColorPalette.astronaut} sx={{ marginLeft: '10px' }}>
								{t('auth.agree_with_conditions')}{' '}
								<Link href="https://retica.ai/condizioni-generali/">
									{t('auth.general_onditions')}
								</Link>
							</Typography>
						</Box>
						<Button
							disabled={
								!isEmptyObject(errors) ||
								isEmptyObjectValues(values) ||
								!confirmFirstStep.isAgreed ||
								!confirmFirstStep.isRead
							}
							type={'submit'}
							fullWidth
						>
							{t('shared_texts.continue')}
						</Button>
					</Form>
				)}
			</Formik>
			<Footer />
		</Paper>
	);
};
