import { Dialog, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';
import { ActionsForDialog } from 'shared/ui/ActionsForDialog';
import { CloseModalButton } from 'shared/ui/CloseModalButton';
import { Paper } from 'shared/ui/Paper';

interface Props {
	newLocalPath: string | undefined;
	handleClose: () => void;
	handleChangeLocalPath: (directoryName: string | undefined, fromWarningModal?: boolean) => void;
	handleClearSelectedFiles: () => void;
	handleClearTempLocalPath: () => void;
}

export const WarningModal: FC<Props> = ({
	newLocalPath,
	handleClose,
	handleChangeLocalPath,
	handleClearSelectedFiles,
	handleClearTempLocalPath,
}) => {
	const { t } = useTranslation();

	return (
		<Dialog
			open={true}
			onClose={() => {
				handleClearTempLocalPath();
				handleClose();
			}}
			PaperComponent={Paper}
			sx={{
				'& .MuiPaper-root': {
					overflow: 'inherit',
				},
			}}
		>
			<CloseModalButton
				action={() => {
					handleClearTempLocalPath();
					handleClose();
				}}
			/>
			<Typography variant={'h6'} color={ColorPalette.astronaut} sx={{ marginBottom: '40px' }}>
				{t('files.chose_files_from_folders')}
			</Typography>
			<ActionsForDialog
				cancelLabel={t('shared_texts.cancel')}
				cancelAction={() => {
					handleClearTempLocalPath();
					handleClose();
				}}
				applyLabel={t('shared_texts.yes')}
				applyAction={() => {
					handleClearSelectedFiles();
					handleClearTempLocalPath();
					handleChangeLocalPath(newLocalPath, true);
					handleClose();
				}}
			/>
		</Dialog>
	);
};
