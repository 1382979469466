import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type SidebarStore = {
	isSidebarOpened: boolean;
	openedMenuList: string | null;
};

type SidebarActions = {
	toggleSidebar: () => void;
	toggleMenuList: (menuListId: string | null) => void;
};

const initialState: SidebarStore = {
	isSidebarOpened: true,
	openedMenuList: null,
};

const sidebarStore = create<SidebarStore & SidebarActions>()(
	persist(
		(set, get) => ({
			...initialState,
			toggleSidebar: () => set(() => ({ isSidebarOpened: !get().isSidebarOpened })),
			toggleMenuList: (menuListId) =>
				set(({ openedMenuList }) => ({
					openedMenuList: openedMenuList === menuListId ? null : menuListId,
				})),
		}),
		{
			name: 'sidebarStore',
		},
	),
);

export const useSidebarStore = () => sidebarStore((state) => state);
