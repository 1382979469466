import { useEffect, useMemo } from 'react';
import { useLocalStorage } from 'shared/helpers/useLocalStorage';

export const useCountdown = (
	forKey: string,
): [
	(
		| {
				minutes: string;
				seconds: string;
		  }
		| undefined
	),
	(value: number) => void,
] => {
	const [countdown, setCountdown] = useLocalStorage(`countdownFor: ${forKey}`, 0);

	useEffect(() => {
		if (countdown > 0) {
			const interval = setInterval(() => {
				setCountdown(countdown - 1);
			}, 1000);
			return () => {
				clearInterval(interval);
			};
		}
	}, [countdown]);

	return useMemo(() => {
		const time = getMinutesAndSeconds(countdown);
		return [countdown !== 0 ? time : undefined, setCountdown];
	}, [countdown, setCountdown]);
};

function getMinutesAndSeconds(totalSeconds: number): { minutes: string; seconds: string } {
	const minutes = Math.floor(totalSeconds / 60);
	const seconds = totalSeconds % 60;
	return { minutes: minutes.toString().padStart(2, '0'), seconds: seconds.toString().padStart(2, '0') };
}
