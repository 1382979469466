import { Box, Typography } from '@mui/material';
import { UploadSource } from 'entities/Files/types';
import { FC } from 'react';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Checkbox } from 'shared/ui/Checkbox';

interface Props {
	isExistFilesSelected: boolean;
	isNewFilesSelected: boolean;
	sessionUploadSource: UploadSource;
	onSelectAllFiles: (type: 'exist' | 'new', checked: boolean) => void;
}

export const FilesComparisonHeader: FC<Props> = ({
	isExistFilesSelected,
	isNewFilesSelected,
	sessionUploadSource,
	onSelectAllFiles,
}) => {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				backgroundColor: ColorPalette.white,
				marginBottom: '14px',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					width: '50%',
				}}
			>
				<Checkbox
					checked={isExistFilesSelected}
					onChange={() => onSelectAllFiles('exist', !isExistFilesSelected)}
				/>
				<Typography variant={'caption'} color={ColorPalette.astronaut} sx={{ marginLeft: '14px' }}>
					Files from Retica
				</Typography>
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					width: '50%',
				}}
			>
				<Checkbox
					checked={isNewFilesSelected}
					onChange={() => onSelectAllFiles('new', !isNewFilesSelected)}
				/>
				<Typography variant={'caption'} color={ColorPalette.astronaut} sx={{ marginLeft: '14px' }}>
					{`Files from ${sessionUploadSource === 'DIRECT' ? 'PC' : 'FTP'}`}
				</Typography>
			</Box>
		</Box>
	);
};
