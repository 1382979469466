import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomOutputFieldsType } from 'entities/Extensions/types';
import { TableWithoutHeader } from 'shared/ui/tables/TableWithoutHeader';
import { SettingsColumns, FieldsSettingsEnum } from './Columns';

type SettingsTablePropsType = {
	fieldsStateData: CustomOutputFieldsType;
	setFieldsState: (data: CustomOutputFieldsType) => void;
};

export const SettingsTable: FC<SettingsTablePropsType> = ({ fieldsStateData, setFieldsState }) => {
	const { t } = useTranslation();
	const [settingsArray, setSettingsArray] = useState([
		{
			setting: FieldsSettingsEnum.isEnabled,
			descriptionText: t('extensions.ex_list.output_file.settings_description_1'),
			isActive: fieldsStateData.isEnabled,
		},
		{
			setting: FieldsSettingsEnum.addMissingLabels,
			descriptionText: t('extensions.ex_list.output_file.settings_description_2'),
			isActive: fieldsStateData.addMissingLabels,
		},
	]);
	const handleToggleSetting = (setting: FieldsSettingsEnum) => {
		setSettingsArray((prev) =>
			prev.map((item) => (item.setting === setting ? { ...item, isActive: !item.isActive } : item)),
		);
		setFieldsState({
			...fieldsStateData,
			[setting]: !fieldsStateData[setting],
		});
	};

	const preparedFields = useMemo(() => {
		return settingsArray.map((item) => ({
			...item,
			id: item.setting,
			handleToggleSetting,
		}));
	}, [settingsArray, fieldsStateData]);

	return <TableWithoutHeader columns={SettingsColumns} rows={preparedFields} />;
};
