export enum ProtocolEnum {
	default = '',
	FTP = 'FTP',
	FTPS = 'FTPS',
	SFTP = 'SFTP',
}

export enum SyncPeriodEnum {
	day = 'day',
	week = 'week',
	month = 'month',
}

export enum LastSyncStatusEnum {
	error = 'error',
	success = 'success',
}

export enum FtpSettingsTypeEnum {
	generalImport = 'general-import',
	generalExport = 'general-export',
	partners = 'partners',
	warehouse = 'warehouses',
}
