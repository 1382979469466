import * as yup from 'yup';
import { PartnersEnum } from 'entities/Partners/enums';

export const PartnersValidationSchema = yup.object({
	name: yup.string().required('auth.validation.enter_company_name'),
	vatNumber: yup.string(),
	address: yup.string(),
	type: yup
		.mixed<PartnersEnum>()
		.oneOf(Object.values(PartnersEnum))
		.required('partners.validation.client_supplier'),
	erpCode: yup.string(),
});

export const PartnerInfoForReplaceValidationSchema = yup.object({
	name: yup.string(),
	vatNumber: yup.string(),
	address: yup.string(),
	erpCode: yup.string(),
});
