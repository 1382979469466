import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuthStore } from 'processes/auth/model/state';
import { FunctionComponent, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Button } from 'shared/ui/Button';
import { Paper } from 'shared/ui/Paper';
import { useCountdown } from 'shared/helpers/useCountdown';
import { StatusModal } from 'widgets/auth/_common/ui/StatusModal';
import { confirmEmail, sendConfirmationEmail } from 'entities/Auth/api';

export const SUEmailReceivedModal: FunctionComponent = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { token } = useParams();
	const { reset } = useAuthStore();
	const [searchParams] = useSearchParams();
	const [timeLeft, setCountdown] = useCountdown('/sign-up/email-received');

	const { isLoading, data: confirmEmailRes } = useQuery('confirm_email', () => confirmEmail(token), {
		refetchOnWindowFocus: false,
	});

	const handleSendConfirmationEmail = () => {
		sendConfirmationEmail({ email: searchParams.get('email') ?? undefined }).then((res) => {
			if (res?.data?.success) {
				setCountdown(120);
			}
		});
	};

	useEffect(() => {
		if (confirmEmailRes?.data?.success) {
			reset();
			localStorage.clear();
			sessionStorage.clear();
		}
	}, [confirmEmailRes?.data]);

	if (isLoading) {
		return (
			<Paper
				sx={{
					'display': 'flex',
					'justifyContent': 'center',
					'alignItems': 'center',
					'& *': {
						userSelect: 'none',
					},
				}}
			>
				<CircularProgress
					size={'70px'}
					sx={{ color: ColorPalette.royalBlue, margin: '100px auto' }}
				/>
			</Paper>
		);
	}

	if (confirmEmailRes?.data?.success) {
		return (
			<StatusModal
				type={'success'}
				title={t('auth.status_modal.account_activated')}
				message={t('auth.status_modal.account_activated')}
			>
				<Button onClick={() => navigate('/sign-in')} fullWidth>
					{t('auth.sign_in')}
				</Button>
			</StatusModal>
		);
	}

	return (
		<StatusModal
			type={'error'}
			title={t('auth.status_modal.email_expired')}
			message={t('auth.status_modal.email_expired_message')}
		>
			<Button
				onClick={handleSendConfirmationEmail}
				disabled={Boolean(timeLeft)}
				type={'submit'}
				fullWidth
			>
				{timeLeft ? `${timeLeft.minutes}:${timeLeft.seconds}` : t('auth.status_modal.resend')}
			</Button>
		</StatusModal>
	);
};
