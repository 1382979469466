import { FC, useState, useEffect, useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Typography, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { FtpSettingsTypeEnum } from 'entities/Integrations/enums';
import { SyncSettingsType } from 'entities/Integrations/types';
import { postSaveFtpSyncSettingsConnection } from 'entities/Integrations/api';
import { ProtocolEnum } from 'entities/Integrations/enums';
import { FormSelect } from 'shared/ui/Select/FormSelect';
import { SelectOption } from 'shared/ui/Select/types';
import { getErrorMessage } from 'shared/helpers/getErrorMessage';
import { ActionsForDialog } from 'shared/ui/ActionsForDialog';
import { InputField } from 'shared/ui/InputField';
import { Loader } from 'shared/ui/Loader';
import { Paper } from 'shared/ui/Paper';
import { Checkbox } from 'shared/ui/Checkbox';
import { ColorPalette } from 'shared/consts/colorPalette';
import { SyncSettingsFTPValidationSchema } from './consts/validation';
import { SynctSettingsFTPType } from './types';

export type FtpFprmPropsType = {
	ftpSettingsData: SyncSettingsType;
	syncSettingsType: FtpSettingsTypeEnum;
};

export const FtpForm: FC<FtpFprmPropsType> = ({ ftpSettingsData, syncSettingsType }) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const [deleteAfterImport, setDeleteAfterImport] = useState(false);

	const { mutate, isLoading } = useMutation({
		mutationKey: ['/post/sync-settings-connection'],
		mutationFn: postSaveFtpSyncSettingsConnection,
	});

	const initialFields = useMemo(
		() => ({
			host: ftpSettingsData?.ftpSettings?.host ?? '',
			port: ftpSettingsData?.ftpSettings?.port ? String(ftpSettingsData.ftpSettings.port) : '',
			path: ftpSettingsData?.ftpSettings?.path ?? '',
			protocol: ftpSettingsData?.ftpSettings?.protocol ?? ProtocolEnum.default,
			username: ftpSettingsData?.ftpSettings?.username ?? '',
			password: '',
			confirmPassword: '',
		}),
		[ftpSettingsData],
	);

	const handleSubmit = (values: SynctSettingsFTPType) => {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { confirmPassword, ...rest } = values;
		const mutationValues = {
			...rest,
			port: Number(values.port),
			protocol: values.protocol,
			ftpSettingsType: syncSettingsType,
			deleteAfterImport,
		};
		mutate(mutationValues, {
			onSuccess: async () => {
				await queryClient.refetchQueries({ queryKey: ['get/sync-settings'] });
				enqueueSnackbar({
					message: t('sync_settings.ftp_form.success_connection', { type: values.protocol }),
					variant: 'success',
				});
			},
			onError: (err) => {
				const errorMessage = getErrorMessage(err);
				enqueueSnackbar({ message: errorMessage, variant: 'error' });
			},
		});
	};

	useEffect(() => {
		if (ftpSettingsData.ftpSettings?.deleteAfterImport) {
			setDeleteAfterImport(ftpSettingsData.ftpSettings.deleteAfterImport);
		}
	}, [ftpSettingsData]);

	return (
		<Paper
			sx={{
				width: '100%',
				padding: '25px 45px',
				display: 'flex',
				flexDirection: 'column',
				gap: '15px',
			}}
		>
			{isLoading ? (
				<Loader />
			) : (
				<>
					<Typography variant={'h5'} color={ColorPalette.astronaut}>
						{t('sync_settings.ftp_form.title')}
					</Typography>
					<Typography variant={'body2'} color={ColorPalette.baliHai}>
						{t('sync_settings.ftp_form.description')}
					</Typography>
					<Formik
						initialValues={initialFields}
						enableReinitialize
						validationSchema={SyncSettingsFTPValidationSchema}
						onSubmit={handleSubmit}
					>
						{({ values, setFieldValue, setValues }: FormikProps<SynctSettingsFTPType>) => (
							<Form>
								<InputField
									name={'host'}
									label={t('integrations.labels.host_name')}
									placeholder={t('integrations.placeholder.host_name')}
									required
								/>
								<InputField
									name={'port'}
									label={t('integrations.labels.port')}
									placeholder={t('integrations.placeholder.port')}
									required
								/>
								<InputField
									name={'path'}
									label={t('integrations.labels.path')}
									placeholder={t('integrations.placeholder.path')}
									required
								/>
								<FormSelect
									name={'protocol'}
									label={t('integrations.labels.protocol') as string}
									placeholder={t('integrations.placeholder.protocol')}
									options={selectOptions}
									value={values.protocol}
									onSelect={(value) => setFieldValue('protocol', value)}
									required
								/>
								<InputField
									name={'username'}
									label={t('integrations.labels.user_name')}
									placeholder={t('integrations.placeholder.user_name')}
									required
								/>
								<InputField
									name={'password'}
									label={t('integrations.labels.password')}
									placeholder={t('integrations.placeholder.password')}
									type={'password'}
									required
								/>
								<InputField
									name={'confirmPassword'}
									label={t('personal_account.tab_3.labels.confirm_new_password')}
									placeholder={t('personal_account.tab_3.placeholder.confirm_new_password')}
									type={'password'}
									required
								/>
								<FormControlLabel
									control={
										<Checkbox
											checked={deleteAfterImport}
											onChange={() => {
												setDeleteAfterImport(!deleteAfterImport);
											}}
										/>
									}
									label={
										<Typography
											variant={'body2'}
											color={ColorPalette.baliHai}
											sx={{ marginLeft: '10px' }}
										>
											{t('sync_settings.ftp_form.delete_after_import')}
										</Typography>
									}
									sx={{ margin: '0' }}
								/>
								<ActionsForDialog
									cancelLabel={t('shared_texts.clear_fields')}
									cancelAction={() => {
										setValues({
											host: '',
											port: '',
											path: '',
											protocol: ProtocolEnum.default,
											username: '',
											password: '',
											confirmPassword: '',
										});
									}}
									applyLabel={t('shared_texts.save')}
									sx={{ marginTop: '20px' }}
								/>
							</Form>
						)}
					</Formik>
				</>
			)}
		</Paper>
	);
};

const selectOptions: Array<SelectOption> = [
	{
		value: ProtocolEnum.FTP,
		label: ProtocolEnum.FTP,
	},
	{
		value: ProtocolEnum.FTPS,
		label: ProtocolEnum.FTPS,
	},
	{
		value: ProtocolEnum.SFTP,
		label: ProtocolEnum.SFTP,
	},
];
