import { VariantType } from 'notistack';
import { ColorPalette, ColorPaletteValues } from 'shared/consts/colorPalette';

export const SnackbarBackgroundColors: Record<VariantType, ColorPaletteValues> = {
	success: ColorPalette.jungleGreen,
	error: ColorPalette.violetRed,
	warning: ColorPalette.brightGray,
	info: ColorPalette.brightGray,
	default: ColorPalette.brightGray,
};

export const SnackbarIcons: Record<VariantType, string> = {
	success: 'done_ring_round',
	error: 'dell',
	warning: 'done_ring_round',
	info: 'done_ring_round',
	default: 'done_ring_round',
};
