import { FC, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Typography, Box, FormControlLabel } from '@mui/material';
import { CustomOutputFieldsType } from 'entities/Extensions/types';
import { ColorPalette } from 'shared/consts/colorPalette';
import { ActionsForDialog } from 'shared/ui/ActionsForDialog';
import { Paper } from 'shared/ui/Paper';
import { Checkbox } from 'shared/ui/Checkbox';
import { SheetTable } from 'shared/ui/SheetTable';

interface Props {
	fieldsState: CustomOutputFieldsType;
	open: boolean;
	handleClose: () => void;
	handleApply: (skipHeaders: boolean) => void;
}

export const FilePreviewModal: FC<Props> = ({ open, handleClose, handleApply, fieldsState }) => {
	const { t } = useTranslation();
	const [isSkipHeadersActive, setIsSkipHeadersActive] = useState<boolean>(!fieldsState.skipHeaders);

	const sheetPreviewArr = useMemo(
		() =>
			fieldsState.fields.map((item) => ({
				cellContent: item.customFieldName || item.originalFieldName || '',
			})),
		[fieldsState],
	);

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			PaperComponent={Paper}
			PaperProps={{ title: t('shared_texts.csv_modal_title') as string }}
			sx={{
				'width': '65vw',
				'margin': '0 auto',
				'& .MuiPaper-root': {
					minWidth: '100%',
				},
			}}
		>
			<Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
				<Typography variant={'body1'} color={ColorPalette.baliHai}>
					{t('extensions.ex_list.output_file.csv_modal_description')}
				</Typography>

				<FormControlLabel
					control={
						<Checkbox
							checked={isSkipHeadersActive}
							onChange={() => {
								setIsSkipHeadersActive(!isSkipHeadersActive);
							}}
						/>
					}
					label={
						<Typography
							variant={'body1'}
							color={ColorPalette.baliHai}
							sx={{ marginLeft: '10px' }}
						>
							{t('extensions.ex_list.output_file.csv_modal_description_2')}
						</Typography>
					}
					sx={{ margin: ' 0' }}
				/>

				<SheetTable cells={sheetPreviewArr} isShowHeader={isSkipHeadersActive} />

				<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
					<Box sx={{ width: '400px' }}>
						<ActionsForDialog
							cancelLabel={t('shared_texts.cancel')}
							cancelAction={handleClose}
							applyLabel={t('shared_texts.save')}
							applyAction={() => handleApply(!isSkipHeadersActive)}
							applyActionDisabled={false}
						/>
					</Box>
				</Box>
			</Box>
		</Dialog>
	);
};
