import { CreateAssertionsPayloadType } from 'entities/Assertions/api/types';
import { ColorPalette } from 'shared/consts/colorPalette';
import { IconButton } from 'shared/ui/IconButton';
import { TableTranslationLabel } from 'shared/ui/tables/_common/ui/TableTranslationLabel';
import { Input } from 'shared/ui/Input';
import { Column } from 'shared/ui/tables/_common/types';

export type InitialValuesTableFormColumnsType = CreateAssertionsPayloadType & {
	id: string;
	valuesIndex: number;
	onReplaceValChange: (rowIndex: number, value: string) => void;
	onRemoveValueRow: (rowIndex: number) => void;
};

export const InitialValuesTableFormColumns: Array<Column<InitialValuesTableFormColumnsType>> = [
	{
		field: 'initialVal',
		commonCellSettings: {
			style: {
				minWidth: '250px',
				maxWidth: '250px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="initial_value" />,
		},
		bodyCellSettings: {
			renderCell: (value) => value?.initialVal || '-',
		},
	},
	{
		field: 'replaceVal',
		commonCellSettings: {
			style: {
				minWidth: '250px',
				maxWidth: '250px',
			},
		},
		headerCellSettings: {
			renderCell: () => <TableTranslationLabel label="replaced_value" />,
		},
		bodyCellSettings: {
			renderCell: (value) => {
				return (
					<Input
						label={''}
						sx={{ margin: 0 }}
						defaultValue={value?.replaceVal ?? ''}
						name={`replaceVal_${value?.valuesIndex}`}
						placeholder={'assertions.placeholder.replaced_value'}
						onChange={(e) => {
							value?.onReplaceValChange(value.valuesIndex, e.target.value);
						}}
					/>
				);
			},
		},
	},
	{
		field: 'options',
		commonCellSettings: {
			style: {
				width: '5%',
			},
		},
		bodyCellSettings: {
			renderCell: (value) => (
				<IconButton
					onClick={() => value?.onRemoveValueRow(value.valuesIndex)}
					iconId="dell_fill"
					sxWrapper={{ color: ColorPalette.violetRed }}
				/>
			),
		},
	},
];
